"use strict";

import "angular";
import "angular-resource";
import "angular-ui-router";
import LinkablePlansFactory from "./LinkablePlansFactory";
import LinkablePlansSelectionController from "./LinkablePlansSelectionController";
import LinkedConfirmationController from "./LinkedConfirmationController";
import LinkedConfirmationFactory from "./LinkedConfirmationFactory";
import OneIdMfaController from "./OneIdMfaController";
import OneIdMfaFactory from "./OneIdMfaFactory";
import OneIdRoute from "./OneIdRoute";
import PrimaryPlanSelectionCtrl from "./PrimaryPlanSelectionCtrl";
import PrimaryPlanSelectionFactory from "./PrimaryPlanSelectionFactory";

// eslint-disable-next-line no-undef
const OneIdModule = angular.module("OneIdModule", ["ngResource", "ui.router"]);

OneIdModule.factory("LinkablePlansFactory", LinkablePlansFactory);
OneIdModule.controller("LinkablePlansSelectionController", LinkablePlansSelectionController);
OneIdModule.controller("OneIdMfaController", OneIdMfaController);
OneIdModule.controller("PrimaryPlanSelectionCtrl", PrimaryPlanSelectionCtrl);
OneIdModule.factory("OneIdMfaFactory", OneIdMfaFactory);
OneIdModule.factory("PrimaryPlanSelectionFactory", PrimaryPlanSelectionFactory);
OneIdModule.controller("LinkedConfirmationController", LinkedConfirmationController);
OneIdModule.factory("LinkedConfirmationFactory", LinkedConfirmationFactory);
OneIdModule.config(OneIdRoute);

export default OneIdModule;
