const mailPinTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
    <header class="contained-form-header">
        <h2 translate="{{heading}}"></h2>
    </header>
    <div class="inner-container with-padding with-shadow">
        <div id="frame-container-spinner" class="loaderBackground just-element" ng-if="mailPinCtrl.loading">
            <div class="loader"></div>
        </div>

        <div class="row">
            <div class="col-sm-10" style="margin-top: 0.6428571429em">
                <p><strong translate="mailPinAOR"></strong></p>
                <p translate="mailPinBody" translate-values={phone:phone}></p>
            </div>
        </div>
    </div>
</div>
`;
export default mailPinTemplate;
