const linkedConfimrationTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection ">
  <header class="contained-form-header margin-top-default">
    <h1>
      {{ 'linkingConfirmation.header' | translate }}
    </h1>
    <p> {{ 'linkingConfirmation.intro' | translate }} </p>
  </header>

  <div id="statementsDocs-spinner" class="loaderBackground just-element" ng-if="LinkedConfirmationController.loading">
    <div class="loader"></div>
  </div>

  <form class="with-padding with-top-margin margin-bottom-200" role="form" name="linkingConfirmationForm" novalidate>
    <div class="inner-container with-padding with-shadow clearfix">
      <div class="form-group panel-group">
        <label class="control-label" align="center">{{ 'linkingConfirmation.myuserName' | translate }}</label>
        <div class="panel panel-default" align="center">
          <header class="panel-heading form-group" data-ng-class="{'inactive' : true}">
            <label class="radio-inline panel-title">

              <span align="center">
                {{LinkedConfirmationController.userName}}</span>
            </label>
          </header>
          <!-- panel content -->
        </div>
      </div>

      <div class="row cta investment-button-row margin-bottom-200">
        <button 
          type="submit" 
          id="submit" 
          class="btn btn-primary btn-block btn-lg margin-top-default"
          ng-click="LinkedConfirmationController.continueClick()"
        >
          <span>
            {{ "button.continue" | translate }}
          </span>
        </button>
      </div>

      <div class="form-group panel-group">
        <label class="control-label">{{ 'linkingConfirmation.tip' | translate }}</label>
        <div class="panel panel-default padding-100">{{ 'linkingConfirmation.tipIntro'| translate }}</div>
      </div>
    </div>
  </form>
</div>
`;
export default linkedConfimrationTemplate;
