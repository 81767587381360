import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import { getAuthData } from "../../services/authentication/AuthenticationService";
import { AuthData } from "../../types";

const useAuthData = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_AUTH_DATA],
        queryFn: (): Promise<AuthData> => getAuthData()
    });
};

export default useAuthData;
