import browserUpdate from "browser-update";

// eslint-disable-next-line no-undef
const BrowserNotificationModule = angular
    .module("BrowserNotificationModule", [])
    .directive("browserNotification", [
        "$window",
        function ($window) {
            function initBrowserUpdate() {
                const $buoop = {}; //the name '$buoop' is expected by Browser-Update.org's update.js script

                $buoop.required = { e: -4, f: -3, o: -3, s: -2, c: -3 };
                $buoop.insecure = false;
                $buoop.reminder = 0;
                $buoop.test = false; //set to true to debug banner
                $buoop.text = {
                    msg: "You are using an outdated version of your browser.  Current browsers provide a more secure and optimized experience.  To maintain access, please download the latest version of <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer'>Chrome</a>, <a href='https://support.apple.com/downloads/safari' target='_blank' rel='noreferrer'>Safari</a>, <a href='https://www.mozilla.org' target='_blank' rel='noreferrer'>Firefox</a>, or <a href='https://www.microsoft.com/en-us/edge' target='_blank' rel='noreferrer'>Edge</a> today.",
                    msgmore: ""
                };
                $buoop.noclose = true;
                $buoop.no_permanent_hide = true;
                $buoop.api = "2021.05";
                $buoop.text_for_i = {
                    msg: "Microsoft is no longer supporting your browser, Internet Explorer.  Current browsers provide a more secure and optimized experience.  To maintain access, please update your browser to <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer'>Chrome</a>, <a href='https://support.apple.com/downloads/safari' target='_blank' rel='noreferrer'>Safari</a>, <a href='https://www.mozilla.org' target='_blank' rel='noreferrer'>Firefox</a>, or <a href='https://www.microsoft.com/en-us/edge' target='_blank' rel='noreferrer'>Edge</a> today.",
                    msgmore: ""
                };

                $window.$buoop = $buoop; //the variable '$buoop' must be global
                browserUpdate($buoop);
            }

            return {
                restrict: "E",
                scope: {},
                link: function ($scope) {
                    initBrowserUpdate($scope);
                }
            };
        }
    ]);
export default BrowserNotificationModule;
