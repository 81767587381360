export const formFields = {
    name: "",
    lastname: "",
    suffix: "",
    ssn: "",
    ssnMasked: "",
    dateOfBirth: "",
    country: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: ""
};

export const formValidations = {
    name: {
        required: true,
        characters: "alpha",
        limit: 35
    },
    lastname: {
        required: true,
        characters: "alpha",
        limit: 35
    },
    suffix: {
        characters: "alpha",
        limit: 15
    },
    ssn: {
        required: true,
        minlimit: 11
    },
    dateOfBirth: {
        required: true,
        date: true
    },
    country: {
        required: true
    },
    address: {
        required: true,
        characters: "address",
        limit: 35
    },
    address2: {
        characters: "address",
        limit: 35
    },
    city: {
        required: true,
        characters: "city",
        limit: 20
    },
    state: {
        required: true
    },
    zip: {
        required: true,
        zip: true
    },
    phone: {
        required: true,
        phone: true
    },
    email: {
        required: true,
        limit: 80,
        email: true
    }
};
