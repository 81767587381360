import accountSetupTemplate from "./accountSetupTemplate";
import contactUpdateTemplate from "./contactUpdateTemplate";
import registerInfoTemplate from "./registerInfoTemplate";

const accountSetupRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/register/account-setup");

    $stateProvider

        // Account Setup View =================================
        .state("accountSetup", {
            url: "/register/account-setup",
            template: accountSetupTemplate,
            controller: "accountSetupController",
            data: {
                fullHeight: true,
                title: "We found you"
            },
            params: {
                phoneNumber: {
                    value: null,
                    squash: true
                }
            }
        })
        .state("contactUpdate", {
            url: "/register/contact-update",
            template: contactUpdateTemplate,
            controller: "accountSetupController",
            data: {
                fullHeight: true,
                title: "Confirm your contact information"
            }
        })
        .state("registerInfo", {
            url: "/register/register-info",
            template: registerInfoTemplate,
            controller: "accountSetupController",
            data: {
                fullHeight: true,
                title: "Create username and password"
            }
        });
};

accountSetupRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default accountSetupRoute;
