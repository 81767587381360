import StringUtil from "core-ui/client/src/app/core/util/StringUtil";

import ReferenceData from "../constants/ReferenceData";
import { executeGet } from "../utils/appUtils.js";

export const SET_ACCU = "SET_ACCU";

export const getAccuCode = () => {
    const accu = getCookie("accu");

    return accu;

    /* return function (dispatch) {
        const accu = getCookie("accu");

        dispatch(setAccu(accu));
    }; */
};

export const setAccu = (accu) => {
    return {
        type: SET_ACCU,
        payload: accu
    };
};

export const getCookie = (cookieKey) => {
    let cookieValue = document.cookie.split("; ").find((row) => row.startsWith(`${cookieKey}=`));

    if (cookieValue) {
        cookieValue = cookieValue.split("=")[1];
    }

    return cookieValue;
};

/* 
   Save cookies;  omit 'expire' attribute  if you want cookie to expire at end of session
*/
export const saveCookie = (name, value, path, domain, inputExpire) => {
    const val = value;
    // years * days * hours * minutes * seconds * milliseconds
    // 10 * 365 * 24 * 60 * 60 * 1000
    const TEN_YEARS_IN_MILLISECONDS = 315360000000;
    const currentDate = new Date();
    const expire = new Date(currentDate.getTime() + TEN_YEARS_IN_MILLISECONDS).toUTCString();
    document.cookie =
        name +
        "=" +
        val +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        (inputExpire ? ";expires=" + expire : "");
};

export const deleteCookie = (name, path, domain) => {
    if (getCookie(name)) {
        document.cookie =
            name +
            "=" +
            (path ? ";path=" + path : "") +
            (domain ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
};

export const updateAccuCookie = (newAccuCode) => {
    deleteCookie("accu", "/", null);
    saveCookie("accu", newAccuCode, "/", null, null);

    deleteCookie("accu", "/participant", null);
    saveCookie("accu", newAccuCode, "/participant", null, null);

    deleteCookie("clientAccu", "/", null);
    saveCookie("clientAccu", newAccuCode, "/", null, null);

    deleteCookie("clientAccu", "/participant", null);
    saveCookie("clientAccu", newAccuCode, "/participant", null, null);
};

export const resetAccuService = (accuCode) => {
    const resetAccuServiceSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const url = StringUtil.supplant(ReferenceData.RESET_ACCU, {
        accu: accuCode
    });
    const liveUrl = __baseApiUrl__ + url;
    const payload = {
        accu: accuCode
    };

    return executeGet(liveUrl, "resetAccuServiceFault", payload)
        .then(resetAccuServiceSuccess)
        .catch((err) => {
            console.log("resetAccuService Error: ", err);
            throw err;
        });
};

export const setAccuService = (accuValue) => {
    const setAccuServiceSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const url = StringUtil.supplant(ReferenceData.SET_ACCU, {
        accu: accuValue
    });

    const liveUrl = __baseApiUrl__ + url;
    const payload = {
        isArray: false
    };

    return executeGet(liveUrl, "setAccuServiceFault", payload)
        .then(setAccuServiceSuccess)
        .catch((err) => {
            console.log("setAccuService Error: ", err);
            throw err;
        });
};

// PreLoginAccuRetrievalService
export const getPreLoginAccuRetrieval = (accuCode) => {
    const getPreLoginAccuRetrievalSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl =
        __styleEnv__ +
        StringUtil.supplant(ReferenceData.GET_PRELOGIN_ACCU, {
            accu: accuCode,
            suffixPath: "AccountsCustomization.json"
        });

    return executeGet(liveUrl, "getPreLoginAccuRetrievalFault")
        .then(getPreLoginAccuRetrievalSuccess)
        .catch((err) => {
            console.log("getPreLoginAccuRetrieval Error: ", err);
            throw err;
        });
};
