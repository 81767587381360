import ReferenceData from "../../constants/ReferenceData";
import { executeGet, executePostWithAuth } from "../../utils/appUtils";

export const getUsername = () => {
    const getUsernameDataSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.GET_USERNAME_API;

    return executeGet(liveUrl, "getUsernameDataDefault")
        .then(getUsernameDataSuccess)
        .catch((err) => {
            console.log("getUsernameData Error: ", err);
            throw err;
        });
};

export const resetPassword = (payload) => {
    const resetPasswordSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.RESET_ACCOUNT_API;

    return executePostWithAuth(liveUrl, "resetPasswordDefault", payload)
        .then(resetPasswordSuccess)
        .catch((err) => {
            console.log("resetPassword Error: ", err);
            throw err;
        });
};
