export const sanitizeURL = (url) => {
    //pattern
    //if the url comes like www.viacomwealth.com then is going to be updated as http://www.viacomwealth.com
    //if the url comes like bbmretirementplan.com then is going to be updated as http://bbmretirementplan.com
    const patternProtocol = new RegExp(/http(s)?:\/\//);
    url = url.trim();
    if (!patternProtocol.test(url)) {
        url = "http://" + url;
    }
    return url;
};
