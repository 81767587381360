const accountSelectionFactory = function ($resource) {
    const retrieveUserDetailsUrl =
        "/participant-web-services/rest/partialauth/userInfo/retrieveUserDetails";
    const updatePrimaryPlanByAnAccuURL =
        "/participant-web-services/rest/accounts/primaryPlanByAccu";

    return {
        retriveAccessPortal: $resource(
            __baseApiUrl__ + retrieveUserDetailsUrl,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        updatePrimaryPlanByAnAccu: $resource(
            __baseApiUrl__ + updatePrimaryPlanByAnAccuURL,
            {
                accu: "@accu"
            },
            {
                query: {
                    method: "GET"
                }
            }
        ),

        getNextGenDestination: $resource(
            __baseApiUrl__ + retrieveUserDetailsUrl,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        )
    };
};

accountSelectionFactory.$inject = ["$resource", "$window"];
export default accountSelectionFactory;
