const DefaultCustomizationRetrService = function ($resource) {
    return $resource(
        __styleEnv__ + "customizations/Default/AccountsDefaultCustomization.json",
        {},
        {
            query: { method: "GET", isArray: false }
        }
    );
};

DefaultCustomizationRetrService.$inject = ["$resource", "$window"];
export default DefaultCustomizationRetrService;
