import contactInfoTemplate from "./contactInfoTemplate";
import registerCredentialTemplate from "./registerCredentialTemplate";

const accountSetupTemplate = `
<div class="alert alert-danger" data-ng-if="errors.length > 0">
    <p data-ng-repeat="error in errors">{{error}}</p>
</div>

<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
    <header class="contained-form-header">
        <h1>
            {{ ::translatedData.setUpAccountTitle }}
        </h1>

        <p>{{ ::translatedData.completeRegistrationInfo }}</p>

    </header>

    <div class="inner-container with-padding with-shadow">
        <form role="form" id="registration-form" name="accountSetupForm" class="clearfix"
            data-ng-init="loadContactInfo()" data-ng-submit="setUpAccount()" novalidate autocomplete="off">

            <h2>{{ ::translatedData.contactInformationTitle }}</h2>

            <div class="form-group has-error">
                <span class="help-block" ng-bind-html="saveStatus | translate:saveStatusParams"></span>
            </div>
            
            ${contactInfoTemplate}

            <hr />
            <h2> {{ ::translatedData.usernamePasswordTitle }}</h2>

            ${registerCredentialTemplate}

            <div class="form-group clearfix">
                <button type="submit" id="submit" class="btn btn-primary btn-block btn-lg margin-top-default"
                    ng-disabled="showSpinner==true">

                    <span data-ng-hide="showSpinner==true">
                        Register
                    </span>

                    <span data-ng-show="showSpinner==true">
                        <i class="fa fa-circle-o-notch fa-spin"></i>
                        Processing ...
                    </span>
                </button>

            </div>

        </form>
    </div>
</div>
`;
export default accountSetupTemplate;
