import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _ from "lodash";

import oneIdEvents from "./events/OneIdEvents";
import noContactInfoModalTemplate from "./noContactInfoModalTemplate";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const LinkablePlansSelectionController = function (
    $modal,
    $rootScope,
    $scope,
    $state,
    LinkablePlansFactory,
    eventBus,
    redirectService
) {
    const vm = this;
    vm.selectedIndId = [];
    vm.selectedIndidsForLinking = [];
    vm.currentAccountEligibleForHiding = null;
    vm.error = false;
    vm.modalInstance = null;
    const logger = ExternalLogger.getInstance("LinkablePlansSelectionController");
    vm.init = function () {
        vm.loading = true;

        if ($state.current.name === "linkablePlansProfile") {
            LinkablePlansFactory.retrievePlansAvailableForLinkingForProfile.query(
                {},
                function (data) {
                    $rootScope.plansMapWithIndIdDbName = data.linkablePlansMap;
                    $scope.previousSelectedVal = "none";
                    $scope.currentUserPlans = data.currentUserPlans;
                    vm.setCheckedAttributeBasedOnMFAFinishedAttribute();
                },
                function (error) {
                    console.log(
                        "LinkablePlansFactory.retrievePlansAvailableForLinkingForProfile - ERROR: ",
                        error
                    );
                    logger.error(error);
                }
            );
        } else {
            LinkablePlansFactory.retrievePlansAvailableForLinking.query(
                {},
                function (data) {
                    $rootScope.plansMapWithIndIdDbName = data.linkablePlansMap;
                    $scope.previousSelectedVal = "none";
                    $scope.currentUserPlans = data.currentUserPlans;
                    vm.setCheckedAttributeBasedOnMFAFinishedAttribute();

                    if (_.isEmpty($rootScope.plansMapWithIndIdDbName)) {
                        vm.continueClick();
                    }
                },
                function (error) {
                    console.log(
                        "LinkablePlansFactory.retrievePlansAvailableForLinking - ERROR: ",
                        error
                    );
                    logger.error(error);
                }
            );
        }
        vm.loading = false;
    };

    vm.onVerifyAccountClick = async function (key) {
        try {
            await vm.setCurrentAccountEligibleForHiding(key);
            if (!vm.currentAccountEligibleForHiding) vm.verifyAccount(key);
        } catch (error) {
            vm.loading = false;
            console.log("LinkablePlansFactory.setCurrentAccountEligibleForHiding - ERROR: ", error);
        }
    };

    vm.verifyAccountButtonVisibility = function (key) {
        const isDisplayingAccountEligibleForHidingSection =
            vm.currentAccountEligibleForHiding === key;
        if (isDisplayingAccountEligibleForHidingSection) return false;
        return vm.checkIfLockNeeded(key);
    };

    vm.isEligibleForHidingSectionVisibility = function (key) {
        return vm.currentAccountEligibleForHiding === key;
    };

    vm.setCurrentAccountEligibleForHiding = function (key) {
        vm.loading = true;
        const [indId, dbName, accuCode] = key.split(":");
        const params = {
            indId,
            dbName,
            accuCode
        };

        return LinkablePlansFactory.getContextEligibilityForHiding
            .query(params)
            .$promise.then(function (data) {
                const isContextEligibleForHiding = data?.isContextEligibleForHiding || false;
                const errorCode = data?.errorCode;

                vm.currentAccountEligibleForHiding = isContextEligibleForHiding ? key : null;
                vm.loading = false;

                if (errorCode) {
                    console.log(
                        "LinkablePlansFactory.getContextEligibilityForHiding - ERROR: ",
                        errorCode
                    );
                    vm.error = true;
                }
            });
    };

    vm.hideAccount = function (key) {
        vm.loading = true;

        const [indId, dbName, accuCode] = key.split(":");
        const params = {
            indId,
            dbName,
            accuCode
        };

        function onSuccess() {
            vm.loading = false;
            vm.init();
        }

        function onFailure(error) {
            vm.loading = false;
            const { errorCode, errorMessage } = error;
            const msg = `${errorCode} - ${errorMessage[0]}`;

            console.log("LinkablePlansFactory.updatePinAuthCode - ERROR: ", msg);
            logger.error(msg);
            vm.error = true;
        }

        LinkablePlansFactory.updatePinAuthCode.query(params, onSuccess, onFailure);
    };

    vm.verifyAccount = function (key) {
        $rootScope.linkingContext = key;
        let keeplooking = true;
        // eslint-disable-next-line no-undef
        angular.forEach($rootScope.plansMapWithIndIdDbName, function (values, tempKey) {
            if (key === tempKey && keeplooking) {
                // eslint-disable-next-line no-undef
                angular.forEach(values, function (value) {
                    const contactThere = value.contactsThere;
                    const mfaFinished = value.mfaFinished;
                    if (!contactThere && keeplooking) {
                        vm.phoneNum = $rootScope?.siteContactInfo?.phone;
                        $rootScope.plansMapWithIndIdDbName[key].checked = false;
                        vm.openNoContactInfoModal();
                        value.checked = false;
                        keeplooking = false;
                    } else if (contactThere && !mfaFinished) {
                        $state.go("oneIdMfaActivationCode");
                        keeplooking = false;
                    } else if (contactThere && mfaFinished) {
                        $state.go("linkablePlans");
                        keeplooking = false;
                    }
                });
            }
        });
    };

    vm.dataForNoContactInfoModal = function () {
        const plansFromCurrentContext = [];
        // eslint-disable-next-line no-undef
        angular.forEach($rootScope.plansMapWithIndIdDbName, function (values, key) {
            if (key === $rootScope.linkingContext) {
                // eslint-disable-next-line no-undef
                angular.forEach(values, function (value) {
                    plansFromCurrentContext.push(value);
                });
            }
        });
        $rootScope.plansFromCurrentContext = plansFromCurrentContext;
    };

    vm.isMFAFnishedPrior = function (key) {
        let mfaFinishedPrior = false;
        eventBus.dispatch(oneIdEvents.IS_MFA_NEEDED, this);
        vm.loading = true;
        let temp = [];

        temp = key.split(":");
        const params = {
            indId: temp[0],
            dbName: temp[1]
        };

        LinkablePlansFactory.isMfaFinishedPrior.query(
            params,
            //success
            function (data) {
                mfaFinishedPrior = data.data;
                vm.loading = false;
            },
            function () {
                vm.loading = false;
                vm.error = true;
            }
        );
        return mfaFinishedPrior;
    };

    vm.cancelModal = function () {
        $rootScope.modalInstanceWithNoContact.dismiss("cancel");
    };

    vm.openNoContactInfoModal = function () {
        $rootScope.modalInstanceWithNoContact = $modal.open({
            template: noContactInfoModalTemplate,
            controller: LinkablePlansSelectionController,
            controllerAs: "LinkablePlansSelectionController",
            size: "md",
            windowClass: "modal-wrapper"
        });
    };

    vm.checkIfLockNeeded = function (keyToCompare) {
        let lockNeeded = true;
        let keeplooking = true;

        // eslint-disable-next-line no-undef
        angular.forEach($rootScope.plansMapWithIndIdDbName, function (values, key) {
            if (keeplooking && keyToCompare === key) {
                lockNeeded = !values[0].mfaFinished;
                keeplooking = false;
            }
        });

        return lockNeeded;
    };

    vm.checkForEnabling = () => {
        return (
            _.find(
                $rootScope.plansMapWithIndIdDbName,
                (account) => account[0].checked === false
            ) === undefined
        );
    };

    vm.setCheckedAttributeBasedOnMFAFinishedAttribute = function () {
        // eslint-disable-next-line no-undef
        angular.forEach($rootScope.plansMapWithIndIdDbName, function (values) {
            // eslint-disable-next-line no-undef
            angular.forEach(values, function (value) {
                value.checked = value.mfaFinished;
            });
        });
    };

    vm.continueClick = function () {
        vm.loading = true;
        const selectedIndidsForLinking = [];
        eventBus.dispatch(oneIdEvents.CONTINUE_LINKING, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_LINK,
            event_properties: {
                selection: oneIdEvents.CONTINUE_LINKING
            }
        });

        // eslint-disable-next-line no-undef
        angular.forEach($rootScope.plansMapWithIndIdDbName, function (value, key) {
            // eslint-disable-next-line no-undef
            angular.forEach(value, function (planValue) {
                if (planValue.checked && planValue.checked === true) {
                    if (selectedIndidsForLinking.indexOf(key) == -1) {
                        selectedIndidsForLinking.push(key);
                    }
                }
            });
        });

        vm.selectedIndidsForLinking = selectedIndidsForLinking;

        LinkablePlansFactory.linkAccounts.query(
            vm.selectedIndidsForLinking,
            //success
            function (data) {
                vm.loading = false;
                redirectService.redirect(data.data, "ALL");
            },
            function (error) {
                vm.loading = false;
                vm.error = true;
                console.error("LinkablePlansFactory.linkAccounts - ERROR: ", error);
            }
        );
    };
    vm.init();
};

LinkablePlansSelectionController.$inject = [
    "$modal",
    "$rootScope",
    "$scope",
    "$state",
    "LinkablePlansFactory",
    "eventBus",
    "redirectService"
];
export default LinkablePlansSelectionController;
