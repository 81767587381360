import { getPreLoginBulletin } from "../../services/preloginService";

const retrievePreLoginBulletin = async (currentAccu, primaryDB, logger): Promise<any> => {
    const response = await getPreLoginBulletin(currentAccu, primaryDB);
    if (response) {
        logger.debug("Prelogin bulletin: {0}", [JSON.stringify(response)]);
        return response;
    } else {
        return [];
    }
};

export default retrievePreLoginBulletin;
