import React from "react";
import { useTranslations } from "../../queries";

interface NpdiErrorTranslations {
    npdi: {
        npdiErrorPanel: string;
        ssnExistsStatusUpdate: string;
    };
}

const NpdiError = () => {
    const { npdi }: NpdiErrorTranslations = useTranslations();

    return (
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
            <header className="contained-form-header margin-top-default">
                <h1>{npdi?.npdiErrorPanel}</h1>
            </header>
            <div className="inner-container with-padding with-shadow">
                <p>{npdi?.ssnExistsStatusUpdate}</p>
            </div>
        </div>
    );
};

export default NpdiError;
