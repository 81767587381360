import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../../services/translationService";
import ValidationMessagesComponent from "../../registration/ValidationMessagesComponent";

export default class ChangePasswordComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            cancel: this.props.cancel,
            saveStatus: this.props.saveStatus,
            saveStatusParams: this.props.saveStatusParams,
            error: this.props.error,
            password: {
                value: "",
                isValid: false,
                errors: [],
                setErrors: false
            },
            newPassword: {
                value: "",
                isValid: false,
                errors: [],
                errorHelpList: [],
                setErrors: false
            },
            confirmPassword: {
                value: "",
                isValid: false,
                errors: [],
                setErrors: false
            },
            isFormValid: false
        };

        this.errorHelpListNewPassword = [
            { txt: "", status: "error" },
            { txt: "", status: "error" },
            { txt: "", status: "error" },
            { txt: "", status: "error" },
            { txt: "", status: "error" }
        ];
        this.errorHelpListNewPasswordIsValid = false;

        this.errorListNewPassword = [
            { txt: "", status: "error" },
            { txt: "", status: "error" },
            { txt: "", status: "error" },
            { txt: "", status: "error" }
        ];
        this.errorListNewPasswordIsValid = false;

        this.errorListConfirmPassword = [
            { txt: "", status: "error" },
            { txt: "", status: "error" }
        ];
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    //close modal
    handleClickCancel = () => {
        this.props.cancel("password");
    };

    //close modal, saving values
    handleClickUpdate = () => {
        const isFormValid = !!(
            this.state.password.isValid &&
            this.state.newPassword.isValid &&
            this.state.confirmPassword.isValid
        );

        if (isFormValid) {
            this.props.updatePassWord(
                "profile",
                isFormValid,
                this.state.password.value,
                this.state.newPassword.value
            );
        } else {
            //this will validate if we have to show the validations rules no meet
            this.validateValue(this.state.password.value, "password", true);
            this.validateValue(this.state.newPassword.value, "newPassword", true);
            this.validateValue(this.state.confirmPassword.value, "confirmPassword", true);

            //then they will go red after a few miliseconds
            setTimeout(
                function () {
                    //activate the red class
                    this.setState({
                        password: {
                            ...this.state.password,
                            setErrors: true
                        },
                        newPassword: {
                            ...this.state.newPassword,
                            setErrors: true
                        },
                        confirmPassword: {
                            ...this.state.confirmPassword,
                            setErrors: true
                        }
                    });
                }.bind(this),
                500
            );
        }
    };

    /*-- Handle red class for errors --*/

    handleFocus = (e) => {
        const currentValue = e.target.value;
        const field = e.target.name;

        this.validateValue(currentValue, field, true);
    };

    handleBlur = (e) => {
        const field = e.target.name;

        //activate the red class
        this.setState({
            [field]: {
                ...this.state[field],
                setErrors: true
            }
        });

        //check password match
        if (field === "newPassword") {
            this.validateValue(this.state.confirmPassword.value, "confirmPassword", true);
        }
    };

    /*-- Validations --*/

    //Each field has different validations , but also they will behave different.

    handleChange = (e) => {
        const currentValue = e.target.value;
        const field = e.target.name;

        //this will not let updated bigger than a length size
        if (currentValue.length <= 16) {
            this.validateValue(currentValue, field, false, true);
        }
    };

    //validations on confirmation password
    confirmPasswordValidation = (currentValue) => {
        if (currentValue === "" || currentValue === undefined || currentValue.length < 1) {
            this.errorListConfirmPassword[0].status = "error";
        } else {
            if (this.errorListConfirmPassword[0].status == "error") {
                this.errorListConfirmPassword[0].status = "success";
            } else {
                this.errorListConfirmPassword[0].status = "hide";
            }
        }

        if (currentValue !== this.state.newPassword.value) {
            this.errorListConfirmPassword[1].status = "error";
        } else {
            if (this.errorListConfirmPassword[1].status == "error") {
                this.errorListConfirmPassword[1].status = "success";
            } else {
                this.errorListConfirmPassword[1].status = "hide";
            }
        }
    };

    updateConfirmPassword = (field, fromFocus) => {
        let numberOfMessageErrors = 0;
        for (let i = 0; i < this.errorListConfirmPassword.length; i++) {
            if (this.errorListConfirmPassword[i].status === "error") {
                numberOfMessageErrors++;
            }
        }

        this.setState({
            [field]: {
                ...this.state[field],
                isValid: !(numberOfMessageErrors > 0),
                errors: this.errorListConfirmPassword,
                setErrors: fromFocus ? false : this.state[field].setErrors
            }
        });
    };

    updateNewPassword = (field, fromFocus) => {
        this.setState({
            [field]: {
                ...this.state[field],
                isValid: !!(
                    this.errorListNewPasswordIsValid && this.errorHelpListNewPasswordIsValid
                ),
                errors: this.errorListNewPassword,
                errorHelpList: this.errorHelpListNewPassword,
                setErrors: fromFocus ? false : this.state[field].setErrors
            }
        });
    };

    //The for rule validations for the new password
    check4ruleValidation = (currentValue) => {
        const numberPattern = /\d{1}/;
        const lowercasePattern = /^(?=.*[a-z]){1}/;
        const uppercasePattern = /^(?=.*[A-Z]){1}/;
        const specialCharPattern = /^(?=.*[=$@$!#%*?&+#._-]){1}/;

        if (!uppercasePattern.test(currentValue)) {
            this.errorListNewPassword[0].status = "error";
        } else {
            if (this.errorListNewPassword[0].status == "error") {
                this.errorListNewPassword[0].status = "success";
            } else {
                this.errorListNewPassword[0].status = "hide";
            }
        }

        if (!lowercasePattern.test(currentValue)) {
            this.errorListNewPassword[1].status = "error";
        } else {
            if (this.errorListNewPassword[1].status == "error") {
                this.errorListNewPassword[1].status = "success";
            } else {
                this.errorListNewPassword[1].status = "hide";
            }
        }

        if (!numberPattern.test(currentValue)) {
            this.errorListNewPassword[2].status = "error";
        } else {
            if (this.errorListNewPassword[2].status == "error") {
                this.errorListNewPassword[2].status = "success";
            } else {
                this.errorListNewPassword[2].status = "hide";
            }
        }

        if (!specialCharPattern.test(currentValue)) {
            this.errorListNewPassword[3].status = "error";
        } else {
            if (this.errorListNewPassword[3].status == "error") {
                this.errorListNewPassword[3].status = "success";
            } else {
                this.errorListNewPassword[3].status = "hide";
            }
        }

        ///Check rule messages List
        const minLengthPattern = /^[\s\S]{8,}$/;

        if (currentValue === "" || currentValue === undefined || currentValue.length < 1) {
            this.errorHelpListNewPassword[0].status = "error";
        } else {
            if (this.errorHelpListNewPassword[0].status == "error") {
                this.errorHelpListNewPassword[0].status = "success";
            } else {
                this.errorHelpListNewPassword[0].status = "hide";
            }
        }

        if (!minLengthPattern.test(currentValue)) {
            this.errorHelpListNewPassword[1].status = "error";
        } else {
            if (this.errorHelpListNewPassword[1].status == "error") {
                this.errorHelpListNewPassword[1].status = "success";
            } else {
                this.errorHelpListNewPassword[1].status = "hide";
            }
        }

        let numberOfErrors = 0;
        for (let i = 0; i < this.errorListNewPassword.length; i++) {
            if (this.errorListNewPassword[i].status === "error") {
                numberOfErrors++;
            }
        }

        if (numberOfErrors === 4) {
            this.errorHelpListNewPassword[2].status = "error";
        } else {
            if (this.errorHelpListNewPassword[2].status == "error") {
                this.errorHelpListNewPassword[2].status = "success";
            } else {
                this.errorHelpListNewPassword[2].status = "hide";
            }
        }

        if (numberOfErrors === 3) {
            this.errorHelpListNewPassword[3].status = "error";
        } else {
            if (this.errorHelpListNewPassword[3].status == "error") {
                this.errorHelpListNewPassword[3].status = "success";
            } else {
                this.errorHelpListNewPassword[3].status = "hide";
            }
        }

        if (numberOfErrors === 2) {
            this.errorHelpListNewPassword[4].status = "error";
        } else {
            if (this.errorHelpListNewPassword[4].status == "error") {
                this.errorHelpListNewPassword[4].status = "success";
            } else {
                this.errorHelpListNewPassword[4].status = "hide";
            }
        }

        //Detec validations of both sets of rules
        if (numberOfErrors > 1) {
            this.errorListNewPasswordIsValid = false;
        } else {
            this.errorListNewPasswordIsValid = true;
        }

        let numberOfMessageErrors = 0;
        for (let i = 0; i < this.errorHelpListNewPassword.length; i++) {
            if (this.errorHelpListNewPassword[i].status === "error") {
                numberOfMessageErrors++;
            }
        }

        if (numberOfMessageErrors > 0) {
            this.errorHelpListNewPasswordIsValid = false;
        } else {
            this.errorHelpListNewPasswordIsValid = true;
        }
    };

    //This will validate each field in the form
    validateValue = (currentValue, field, fromFocus, updateValue) => {
        this.errorHelpListNewPassword[0].txt = this.state.translations?.passwordRequired;
        this.errorHelpListNewPassword[1].txt = this.state.translations?.passwordMinLength;
        this.errorHelpListNewPassword[2].txt = this.state.translations?.passwordCondition0;
        this.errorHelpListNewPassword[3].txt = this.state.translations?.passwordCondition1;
        this.errorHelpListNewPassword[4].txt = this.state.translations?.passwordCondition2;

        this.errorListNewPassword[0].txt = this.state.translations?.passwordUppercasePattern;
        this.errorListNewPassword[1].txt = this.state.translations?.passwordLowercasePattern;
        this.errorListNewPassword[2].txt = this.state.translations?.passwordNumberPattern;
        this.errorListNewPassword[3].txt = this.state.translations?.passwordSpecialCharPattern;

        this.errorListConfirmPassword[0].txt = this.state.translations?.confirmPasswordRequired;
        this.errorListConfirmPassword[1].txt = this.state.translations?.confirmPasswordNotMatch;

        if (field === "password") {
            const errorList = [];

            if (currentValue === "" || currentValue === undefined || currentValue.length < 1) {
                errorList.push(this.state.translations?.passwordRequired);
            }

            this.setState({
                [field]: {
                    ...this.state[field],
                    isValid: !(errorList.length > 0),
                    errors: errorList,
                    setErrors: fromFocus ? false : this.state[field].setErrors,
                    value: updateValue ? currentValue : this.state[field].value
                }
            });
        } else if (field === "newPassword") {
            this.check4ruleValidation(currentValue);

            this.updateNewPassword(field, fromFocus);

            setTimeout(
                function () {
                    this.check4ruleValidation(currentValue);
                    this.updateNewPassword(field, fromFocus);
                }.bind(this),
                500
            );

            this.setState({
                [field]: {
                    ...this.state[field],
                    value: updateValue ? currentValue : this.state[field].value
                }
            });
        } else if (field === "confirmPassword") {
            this.confirmPasswordValidation(currentValue);

            this.updateConfirmPassword(field, fromFocus);

            setTimeout(
                function () {
                    this.confirmPasswordValidation(currentValue);
                    this.updateConfirmPassword(field, fromFocus);
                }.bind(this),
                500
            );

            this.setState({
                [field]: {
                    ...this.state[field],
                    value: updateValue ? currentValue : this.state[field].value
                }
            });
        }
    };

    //NOT IN USE --DELETE?
    updateValue = (currentValue, field) => {
        this.setState({
            [field]: {
                ...this.state[field],
                value: currentValue
            }
        });
    };

    componentDidUpdate(nextProps) {
        if (this.state.saveStatus !== nextProps.saveStatus) {
            this.setState({
                saveStatus: nextProps.saveStatus
            });
        }
        if (this.state.saveStatusParams !== nextProps.saveStatusParams) {
            this.setState({
                saveStatusParams: nextProps.saveStatusParams
            });
        }
    }

    render() {
        if (!this.state.translations) {
            return null;
        }
        return (
            <section data-testid="change-password-component">
                <div className="modal-header">
                    <div className="modal-title">
                        {" "}
                        {this.state.translations?.profile?.login?.header?.changePassword}{" "}
                    </div>
                </div>
                <div className="modal-body clearfix profile">
                    <div className="modal-well with-background">
                        {this.state.saveStatus !== null ? (
                            <div className="form-group has-error">
                                <span className="help-block">
                                    {TranslationService.translate(
                                        this.state.translations[this.state.saveStatus],
                                        this.state.saveStatusParams
                                    )}
                                </span>
                            </div>
                        ) : null}

                        <form name="credentialForm2" autoComplete="off" noValidate>
                            <div
                                className={
                                    !this.state.password?.isValid && this.state.password?.setErrors
                                        ? "form-group has-error"
                                        : "form-group"
                                }
                            >
                                <label htmlFor="passwordId" className="control-label">
                                    {
                                        this.state.translations?.profile?.login?.label
                                            ?.passwordCurrent
                                    }
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordId"
                                    name="password"
                                    required={true}
                                    value={this.state.password.value}
                                    maxLength="16"
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                />
                                <ValidationMessagesComponent
                                    errors={this.state.password?.errors}
                                    inValid={!this.state.password?.isValid}
                                ></ValidationMessagesComponent>
                            </div>

                            <div
                                className={
                                    !this.state.newPassword.isValid &&
                                    this.state.newPassword.setErrors
                                        ? "form-group has-error"
                                        : "form-group"
                                }
                            >
                                <label htmlFor="newPasswordId" className="control-label">
                                    {this.state.translations?.profile?.login?.label?.passwordNew}
                                </label>
                                {/*Screen reader only*/}
                                <span className="sr-only" id="passwordRules">
                                    Must be eight to sixteen characters. Must include three of these
                                    four.
                                    {this.state.translations?.passwordUppercasePattern}.{" "}
                                    {this.state.translations?.passwordLowercasePattern}.
                                    {this.state.translations?.passwordNumberPattern}.{" "}
                                    {this.state.translations?.passwordSpecialCharPattern}.
                                    {this.state.translations?.passwordNotMatchUsername}.
                                </span>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="newPasswordId"
                                    name="newPassword"
                                    required={true}
                                    value={this.state.newPassword.value}
                                    maxLength="16"
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                    aria-describedby="passwordRules"
                                />

                                {/*Rules to match message*/}

                                <ValidationMessagesComponent
                                    errors={this.state.newPassword.errorHelpList}
                                    inValid={!this.errorHelpListNewPasswordIsValid}
                                    individualTransisions={true}
                                ></ValidationMessagesComponent>

                                <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-11">
                                        <ValidationMessagesComponent
                                            errors={this.state.newPassword.errors}
                                            inValid={!this.errorListNewPasswordIsValid}
                                            individualTransisions={true}
                                        ></ValidationMessagesComponent>
                                    </div>
                                </div>
                                {this.state.newPassword.isValid && (
                                    <p className="success">
                                        {this.state.translations?.passwordAcceptable}
                                    </p>
                                )}
                            </div>

                            <div
                                className={
                                    !this.state.confirmPassword?.isValid &&
                                    this.state.confirmPassword?.setErrors
                                        ? "form-group has-error"
                                        : "form-group"
                                }
                            >
                                <label htmlFor="confirmPasswordId" className="control-label">
                                    {
                                        this.state.translations?.profile?.login?.label
                                            ?.passwordConfirm
                                    }
                                </label>
                                <span className="sr-only" id="confirmPasswordRules">
                                    {this.state.translations?.confirmPasswordRequired}.{" "}
                                    {this.state.translations?.confirmPasswordNotMatch}.
                                </span>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confirmPasswordId"
                                    name="confirmPassword"
                                    maxLength="16"
                                    value={this.state.confirmPassword?.value}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                    aria-describedby="confirmPasswordRules"
                                />

                                <ValidationMessagesComponent
                                    errors={this.state.confirmPassword?.errors}
                                    inValid={!this.state.confirmPassword?.isValid}
                                    individualTransisions={true}
                                ></ValidationMessagesComponent>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer">
                    <button onClick={this.handleClickCancel} className="btn btn-link">
                        {" "}
                        {this.state.translations?.preference?.button?.cancel}{" "}
                    </button>
                    <button onClick={this.handleClickUpdate} className="btn btn-primary">
                        {" "}
                        {this.state.translations?.preference?.button?.save}{" "}
                    </button>
                </div>
            </section>
        );
    }

    componentDidMount() {
        this.getTranslations();

        this.setState({
            error: this.props.error,
            saveStatus: this.props.saveStatus,
            saveStatusParams: this.props.saveStatusParams
        });
    }
}

ChangePasswordComponent.propTypes = {
    cancel: PropTypes.func,
    updatePassWord: PropTypes.func,
    saveStatus: PropTypes.any,
    saveStatusParams: PropTypes.any,
    error: PropTypes.any
};
