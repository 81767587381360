/**
 *
 * @name app.accountRecovery.events.AccountRevoveryEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for account recovery pages
 */

"use strict";

const root = "AccountRecoveryEvent.";

const AccountRecoveryEvents = {
    CONFIRM_CLICK: root + "confirmation_link_clicked_event",
    PASSWORD_RESET: root + "password_need_help_link_clicked_event",
    PASSWORD_BLUR: root + "password_input_blur_event",
    CONFIRM_PASSWORD_BLUR: root + "confirm_password_input_blur_event",
    CONTINUE_CLICK: root + "continue_button_clicked_event",
    RETURN_CLICK: root + "return_to_login_clicked_event",
    NO_CODE: root + "did_not_receive_code_link_click_event",
    CODE_SUBMIT: root + "login_help_code_submit_button_clicked_event",
    CODE_CHANGE: root + "activation_code_input_changed_event",
    CODE_DELIVERY_CHANGE: root + "activation_code_delivery_format_change_event",
    VERIFY_CODE: root + "verify_activation_code_clicked_event",
    VERIFY_CODE_LOGIN_HELP: root + "verify_activation_code_for_login_help_clicked_event",
    SEND_CODE: root + "send_code_button_clicked_event",

    SSN_CHANGE: root + "login_help_ssn_input_field_change_event",
    ZIP_CHANGE: root + "login_help_zip_code_input_field_change_event",
    LAST_NAME_CHANGE: root + "login_help_last_name_input_field_change_event",
    DOB_CHANGE: root + "login_help_date_of_birth_input_field_change_event",
    STREET_CHANGE: root + "login_help_street_address_input_field_change_event",
    SUBMIT: root + "login_help_submit_button_clicked_event",
    LOGIN_ERROR_CODE: root + "login_error_code"
};

export default AccountRecoveryEvents;
