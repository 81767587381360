import confirmPasswordTemplate from "./confirmPasswordTemplate";

const forceChangePasswordTemplate = `
<div id="content-container" class="col-md-6 col-md-offset-3">
	<form role="form" name="credentialForm" data-ng-submit="updatePassWord('mfa')" novalidate>
		<div class="profile-information personal-information clearfix">
			${confirmPasswordTemplate}
			<button class="btn btn-primary pull-right" type="submit" id="submit" ng-disabled="showSpinner==true">
				<span data-ng-hide="showSpinner==true">
					{{ "preference.button.save" | translate }}
				</span>
				<span data-ng-show="showSpinner==true">
					<i class="fa fa-circle-o-notch fa-spin"></i>
					Processing ...
				</span>
			</button>
		</div>
	</form>

	<!--todo: refactor to common component-->
	<form id="portalRedirectForm" method="post">
		<input type="hidden" id="synctoken" value="">
		<input type="hidden" id="accu" value="">
		<input type="hidden" id="gaId" value="">
	</form>
</div>
`;
export default forceChangePasswordTemplate;
