const HomeAddressFactory = function ($resource) {
    const retrieveHomeAddressApi =
        "/participant-web-services/rest/homeAddress/retrieveHomeAddress/:stline1/:stline2/:city/:state/:zipcode/:country";
    const getAddressApi =
        "/participant-web-services/rest/participant/getAddress/:country/:state/:zipcode";
    const updateHomeAddressApi = "/participant-web-services/rest/homeAddress/updateHomeAddress";

    return {
        retrieveHomeAddress: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrieveHomeAddressApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        getAddress: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + getAddressApi,
            {},
            {
                query: {
                    method: "GET",
                    transformResponse: function (data, headers) {
                        return {
                            // eslint-disable-next-line no-undef
                            validZipcode: angular.fromJson(data),
                            // eslint-disable-next-line no-undef
                            headersvalue: angular.fromJson(headers)
                        };
                    }
                }
            }
        ),

        updateHomeAddress: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + updateHomeAddressApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

HomeAddressFactory.$inject = ["$resource"];
export default HomeAddressFactory;
