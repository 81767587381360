import "angular";
import "angular-resource";
import "angular-ui-router";
import ReSetACCUService from "../authentication/ReSetACCUService";
import PrimaryPlanSelectionFactory from "../oneid/PrimaryPlanSelectionFactory";

import deepLinkController from "./DeepLinkController";
import deepLinkFactory from "./DeepLinkFactory";
import deepLinkRoute from "./deepLinkRoute";

// eslint-disable-next-line no-undef
const deepLink = angular.module("deepLink", ["ngMessages", "ngResource", "ui.router"]);

deepLink.controller("deepLinkController", deepLinkController);
deepLink.factory("deepLinkFactory", deepLinkFactory);
deepLink.factory("PrimaryPlanSelectionFactory", PrimaryPlanSelectionFactory);
deepLink.factory("ReSetACCUService", ReSetACCUService);
deepLink.config(deepLinkRoute);

export default deepLink;
