const deepLinkFactory = function ($resource) {
    return {
        getCustomization: $resource(
            // eslint-disable-next-line no-undef
            __styleEnv__ + "customizations/:accu/deeplink/:featureName" + ".json",
            { featureName: "@featureName", accu: "@accu" },
            { query: { method: "GET", isArray: false } }
        ),

        getPrimaryPlan: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + "/participant-web-services/rest/deepLink/primaryPlanForLogin",
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        getDestination: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + "/participant-web-services/rest/deepLink/getDestination/:gaID",
            { gaID: "@gaID" },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        retrieveLanguageCode: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ +
                "/participant-web-services/rest/partialauth/profile/retrieveWebLanguageCode",
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        setLanguageCookies: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + "/participant-web-services/rest/partialauth/profile/setSpnLangCookies",
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

deepLinkFactory.$inject = ["$resource"];
export default deepLinkFactory;
