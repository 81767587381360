import { useQuery } from "@tanstack/react-query";
import retrieveStateCode from "../../components/accountOpen/services/retrieveStateCode";

import { QUERY_KEYS } from "../constants";
const useStateCodes = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_STATE_CODE],
        queryFn: retrieveStateCode
    });
};

export default useStateCodes;
