"use strict";

import authenticationTemplate from "./authenticationTemplate";
import contactUsTemplate from "./contactUsTemplate";
import inboundSSOSpinnerTemplate from "./ssolanding/inboundSSOSpinnerTemplate";
import mobileSSOIntermediateTemplate from "./ssolanding/mobileSSOIntermediateTemplate";

const routeStates = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/login");

    $stateProvider

        .state("login", {
            url: "/login/:flowName",
            template: authenticationTemplate,
            controller: "authenticationController"
        })

        .state("loginDTW", {
            url: "/loginDTW/:flowName",
            template: authenticationTemplate,
            controller: "authenticationController"
        })

        .state("loginPart", {
            url: "/loginPart/:flowName",
            template: authenticationTemplate,
            controller: "authenticationController"
        })

        .state("paeSSOlanding", {
            url: "/paeSSOlanding",
            controller: "PAESSOLandingController",
            template: "<ui-view></ui-view>",
            ncyBreadcrumb: {
                label: "PAE Home"
            }
        })

        .state("ssoSpinner", {
            url: "/ssoSpinner",
            controller: "InboundSSOLandingController",
            template: inboundSSOSpinnerTemplate,
            ncyBreadcrumb: {
                label: "InboundSSOLandingController"
            }
        })
        .state("mobileSSOIntermediate", {
            url: "/mobileSSO",
            controller: "MobileSSOController",
            template: mobileSSOIntermediateTemplate,
            ncyBreadcrumb: {
                label: "MobileSSOController"
            }
        })
        .state("mobileSSORedirect", {
            url: "/mobileSSORedirect",
            controller: "MobileSSOController",
            template: mobileSSOIntermediateTemplate,
            ncyBreadcrumb: {
                label: "MobileSSOController"
            }
        })

        .state("loginSessionError", {
            url: "/login/:errorCode",
            template: authenticationTemplate,
            controller: "authenticationController",
            data: {
                title: "Login Error"
            }
        })

        .state("contactUs", {
            url: "/contactUs",
            template: contactUsTemplate,
            controller: "authenticationController",
            data: {
                fullHeight: true,
                title: "Contact Us"
            }
        })

        // Login Error View =================================
        .state("loginError", {
            url: "/loginError",
            template: authenticationTemplate,
            controller: "authenticationController",
            data: {
                fullHeight: true,
                title: "Login Error"
            }
        });
};

routeStates.$inject = ["$stateProvider", "$urlRouterProvider"];
export default routeStates;
