const npdiFactory = function ($resource) {
    const validatePPTDataWithNPDIPostApi = "rest/nonauth/npdi/validatePPTDataWithNPDI";
    const retrieveContactDetailsDropdown = "rest/nonauth/npdi/retrieveContactDetailsDropdown";
    const retrieveDropdownValuesOnNpdiPageLoadApi =
        "rest/nonauth/npdi/retrieveDropdownValuesOnNpdiPageLoad";
    const retrievePlansToLinkApi = "rest/nonauth/npdi/npdiLinkedPlans";
    const addNPDIpptPostApi = "rest/nonauth/npdi/addNPDIPPTEnroll";
    const updNPDIPPTEnrollApi = "rest/nonauth/npdi/updNPDIPPTEnroll";
    const isPECKTMGEnabled = "rest/nonauth/npdi/isPECKTMGEnabled";

    return {
        validatePPTDataWithNPDI: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + validatePPTDataWithNPDIPostApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        ),
        retrieveContactDetailsDropdown: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + retrieveContactDetailsDropdown,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),
        retrieveDropdownValuesOnNpdiPageLoad: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + retrieveDropdownValuesOnNpdiPageLoadApi,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),
        addNPDIppt: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + addNPDIpptPostApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),
        retrievePlansToLink: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + retrievePlansToLinkApi,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        isPECKTMGEnabled: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + isPECKTMGEnabled,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        updNPDIPPTEnroll: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + updNPDIPPTEnrollApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

npdiFactory.$inject = ["$resource"];
export default npdiFactory;
