const profileContactInfoTemplate = `
<pwr-profile-breadcrumb-component 
    ng-if="domainURL"
    url="domainURL"
/>

<div id="content-container" class="col-xs-12">
    <div class="inner-container with-padding">
        <h1>{{firstName}} {{lastName}}</h1>
        <span ng-if="!(('EmpStatus.' + empStatus) === ('EmpStatus.' + empStatus | translate))">Employment status: {{'EmpStatus.' + empStatus | translate}}</span> 
        <hr />
         
        <span ng-if="dataLoaded">
            <pwr-personal-contact-info-component
                profile-info="profileInfo"
                on-click-function="openContactModal"
            />
        </span>
		<span ng-if="dataLoaded">
            <pwr-work-email-address-component 
                ng-if="workEmailAddress || workPhoneNumber"
                profile-info="profileInfo"
            />  
		</span>
        <!--//work-information//-->

        <pwr-home-mailing-address-component 
            ng-if="dataLoaded && pinAuthCode"
            country="country"
            zipcode="zipcode"
            city="city"
            state="state"
            stline1="stline1"
            stline2="stline2"
            isAddrUpdateAllowed="isAddrUpdateAllowed" 
            pinAuthCode="pinAuthCode"
            openHomeAddressModal="openModalTemplateHomeAddress"
        />

        <span ng-if="dataLoaded &&profileInfo.linkablePlansThere">
            <pwr-linkable-plans-component 
                profileInfo="profileInfo" 
                openLinkablePlansPage="openLinkablePlansPage"
            />  
        </span>
		
        <!-- Name - Password -->
        <pwr-username-password-component 
            ng-if="dataLoaded && pinAuthCode"
            isUserNameUpdateAllowed="isUserNameUpdateAllowed" 
            pinAuthCode="pinAuthCode"
            userName="userName"
            openUsernameModal="openModalTemplateUsername"
            openPasswordModal="openModalTemplatePassword"
        />

        <!-- Comunication preferences -->
        <pwr-e-consent-component 
            ng-if="dataLoaded"
            allowedToOverwrite="allowedToOverwrite" 
            displayChangeCommPrefsButton="displayChangeCommPrefsButton"
            openModalTemplateCommunicationPreferences="openModalTemplateCommunicationPreferences"
            econsentemailAddress="econsentemailAddress"
            eConsentDetails="eConsentDetails"
        />

        <!-- Beneficiaries -->
        <pwr-beneficiaries-edit-component 
            ng-if="ctrl.hasBeneficiaries"
            plans="ctrl.plans" 
            pinAuthCode="pinAuthCode"
            getBeneficiaryName="getBeneficiaryName"
        />

        <!-- Authorized devices -->
        <pwr-authorized-devices ng-if="dataLoaded && authorizedDevicesLoaded"
            isPae="ctrl.isPae"
            devices="authorizedDevices"
            openModalTemplateAuthorizedDevices="openModalTemplateAuthorizedDevices"
            eventbus="eventBus"
            events="events"
        />

        <pw-feva-disclosure is-subject-to-feva="ctrl.isSubjectToFeva" />
    </div>
</div>
`;
export default profileContactInfoTemplate;
