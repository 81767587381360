import React from "react";
import { NavLink } from "react-router-dom";

interface RegistrationNavLinksProps {
    path: string;
    tabLabel: string;
}

const RegistrationNavLink = ({ path, tabLabel }: RegistrationNavLinksProps) => {
    return (
        <li data-testid="registration-nav-link" role="tab" className="register-tab">
            <NavLink to={path} end>
                {tabLabel}
            </NavLink>
        </li>
    );
};

export default RegistrationNavLink;
