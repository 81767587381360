import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import _forEach from "lodash/forEach";
import _includes from "lodash/includes";
import _isArray from "lodash/isArray";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";
import _pull from "lodash/pull";

import profileEvents from "./events/ProfileEvents";

const profileChangePasswordController = function (
    $scope,
    $state,
    profileFactory,
    redirectService,
    eventBus
) {
    $scope.profile = {
        username: "",
        password: "",
        newPassword: "",
        confirmPassword: ""
    };

    $scope.showSpinner = false;
    $scope.submitted = false;

    $scope.forceValidations = function (inputs) {
        inputs.forEach(function (input) {
            $scope.credentialForm[input].$touched = true;
            $scope.credentialForm[input].$dirty = true;
        });
    };

    retrieveUsername();

    /**
     * focus manager
     * indexed by fieldName
     * @type {{}}
     */
    $scope.focusMgr = {};

    $scope.removeInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = false;
    };

    $scope.setInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = true;
        $scope.credentialForm[event.target.name].$setTouched(true);
        $scope.credentialForm[event.target.name].$setDirty(true);
    };

    $scope.getFormGroupClass = function (fieldName) {
        if (_isUndefined($scope.credentialForm)) {
            return "";
        }
        const field = $scope.credentialForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field && field.$invalid && field.$dirty && !fieldFocused) {
            status = "has-error";
        }

        if (field && field.$valid && field.$dirty) {
            status = "has-success";
        }
        return status;
    };

    $scope.fieldOnBlur = function (fieldName) {
        if (_isUndefined($scope.credentialForm)) {
            return "";
        }
        const field = $scope.credentialForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field.$touched && field.$invalid && !fieldFocused) {
            status = "has-error";
        }
        return status;
    };

    $scope.getValidField = function (fieldName) {
        const field = $scope.credentialForm[fieldName].$valid;

        return field;
    };

    $scope.displayFieldMessages = function (fieldName) {
        const field = $scope.credentialForm[fieldName];
        if (_isUndefined($scope.credentialForm) || !field) {
            return true;
        }

        const conditions =
            (field.$invalid && field.$dirty) ||
            (field.$invalid && $scope.credentialForm.$submitted) ||
            field.$touched ||
            !field.$pristine;
        return conditions;
    };

    $scope.isDirty = function (fieldName) {
        if (_isUndefined($scope.credentialForm)) {
            return false;
        }
        const dirty =
            $scope.credentialForm[fieldName] !== undefined &&
            $scope.credentialForm[fieldName].$dirty;

        return dirty;
    };

    $scope.getFieldError = function (fieldName) {
        if (
            _isUndefined($scope.credentialForm) ||
            !fieldName ||
            _isUndefined($scope.credentialForm[fieldName])
        ) {
            return "";
        }
        return $scope.credentialForm[fieldName].$error;
    };

    /**
     * determine the validation error (style) class.
     * different class if one error vs multiples.
     */
    $scope.getValidationErrorClass = function (fieldName) {
        let styleName = "rule-validations";

        if (
            _isUndefined($scope.credentialForm) ||
            !fieldName ||
            _isUndefined($scope.credentialForm[fieldName])
        ) {
            return styleName;
        }
        const errs = $scope.credentialForm[fieldName].$error;
        let errorKeys = _keys(errs);

        if (fieldName === "confirmPassword") {
            const otherErrors = [
                "minLength",
                "numberPattern",
                "uppercasePattern",
                "specialCharPattern",
                "meetCondition1"
            ];
            if (includesAny(errorKeys, otherErrors)) {
                errorKeys = pullAll(errorKeys, otherErrors);
            }
        }
        const errorCnt = errorKeys.length;

        //if >1 errors, the style class should be bulleted.
        if (errorCnt > 1) {
            styleName = "form-validation-rule";
        }

        return styleName;
    };

    /**
     * returns true if "two" is in "one".
     */
    function includesAny(one, two) {
        let rc = false;

        if (one && two && _isArray(one)) {
            if (_isArray(two)) {
                _forEach(two, function (value) {
                    if (_includes(one, value)) {
                        rc = true;
                    }
                });
            } else {
                rc = _includes(one, two);
            }
        }
        return rc;
    }

    /**
     * remove "two" from "one".
     * _pullAll(..) wasn't working
     */
    function pullAll(one, two) {
        if (one && two && _isArray(one)) {
            if (_isArray(two)) {
                _forEach(two, function (value) {
                    _pull(one, value);
                });
            } else {
                _pull(one, two);
            }
        }
        return one;
    }

    function retrieveUsername() {
        profileFactory.retrieveUsername.query(
            {},
            function (data) {
                if (data !== null && data.status == "successful") {
                    if (data.username !== null) {
                        $scope.profile.username = data.username;
                    }
                }
            },

            function (error) {
                console.error("profileFactory.retrieveUsername - ERROR: ", error);
            }
        );
    }

    $scope.updatePassWord = function (flowName) {
        if ($scope.credentialForm.$valid) {
            $scope.showSpinner = true;
            eventBus.dispatch(profileEvents.FORCE_UPDATE_PASS_VALID, this);
            const updatePassWordData = {
                username: "NOT_NEEDED",
                currentpassword: $scope.profile.password,
                newpassword: $scope.profile.newPassword,
                flowName: flowName
            };

            profileFactory.updateForceChangePassword.query(
                updatePassWordData,
                function (data) {
                    if (flowName === "mfa") {
                        redirectService.redirect(data, "ALL");
                    }
                },

                function (response) {
                    $scope.showSpinner = false;
                    if (
                        response.headers("exception") !== null ||
                        response.headers("exceptionMessage") !== null
                    ) {
                        $scope.saveStatus = response.data.error.code;
                        $scope.saveStatusParams = response.data.error.errors[0];
                    }
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue

            // Manually set the $dirty flag for each input in the form to trigger validation
            eventBus.dispatch(profileEvents.FORCE_UPDATE_PASS_INVALID, this);
            const inputs = ["password", "newPassword", "confirmPassword"];

            inputs.forEach(function (input) {
                $scope.credentialForm[input].$touched = true;
                $scope.credentialForm[input].$dirty = true;
            });
        }
    };

    $scope.cancelClick = function () {
        eventBus.dispatch(profileEvents.FORCE_CANCEL, this);
    };
    $scope.changeCurrentPasswordInput = function () {
        eventBus.dispatch(profileEvents.FORCE_CHANGE_CURRENT_PASS_INPUT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: profileEvents.FORCE_CHANGE_CURRENT_PASS_INPUT
            }
        });
    };
    $scope.changeNewPasswordInput = function () {
        eventBus.dispatch(profileEvents.FORCE_CHANGE_NEW_PASS_INPUT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: profileEvents.FORCE_CHANGE_NEW_PASS_INPUT
            }
        });
    };
    $scope.changeNewConfirmPasswordInput = function () {
        eventBus.dispatch(profileEvents.FORCE_CHANGE_NEW_CONFIRM_PASS_INPUT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: profileEvents.FORCE_CHANGE_NEW_CONFIRM_PASS_INPUT
            }
        });
    };
};

profileChangePasswordController.$inject = [
    "$scope",
    "$state",
    "profileFactory",
    "redirectService",
    "eventBus"
];
export default profileChangePasswordController;
