import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../../services/translationService";

export default class ChangeCommunicationPreferenceComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            isStandalonePage: this.props.isStandalonePage,
            primaryPlanT: this.props.primaryPlanT,
            eConsentDetails: this.props.eConsentDetails,
            saveStatus: this.props.saveStatus,
            saveStatusParams: this.props.saveStatusParams,
            email: this.props.email,
            eConsent: this.props.eConsent,
            isModalWindow: this.props.isModalWindow,
            mustHaveEmail: this.props.mustHaveEmail,
            emailValidations: {
                emailInvalid: false,
                emailEmpty: false
            }
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    handleUpdateEConsentDetail = (index) => {
        const value = !this.state.eConsentDetails[index].pptConsentForElectronic;

        this.setState((state) => {
            state.eConsentDetails[index].pptConsentForElectronic = value;
            return state;
        });

        this.props.updateEConsentDetail();
    };

    submitUpdatePPTConsent = (e) => {
        e.preventDefault();
        this.props.updatePPTConsent(null, !this.state.emailValidations.error);
    };

    validateEmail = (e, initvalue) => {
        //TODO
        const value = e ? e.target.value : initvalue;
        let emailEmpty = false;
        let emailInvalid = false;

        const regAllDigits =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z0-9\-'`]+[.])+[a-zA-Z]{2,}$/;

        if (!value || value.length < 1) {
            emailEmpty = true;
        }

        if (value && !regAllDigits.test(value)) {
            emailInvalid = true;
        }

        if (this.state && this.state.eConsent && this.state.emailValidations) {
            this.setState({
                emailEmpty,
                emailInvalid,
                error: !!(emailEmpty || emailInvalid),
                emailchoice: value
            });
        }
    };

    render() {
        if (this.state.translations) {
            return (
                <div
                    className={this.state.isStandalonePage ? "inner-container with-padding" : ""}
                    data-testid="change-communication-preference-component"
                >
                    <div className="modal-header">
                        <div className="modal-title">
                            {this.state.translations?.preference?.eDelivery?.modalheadline}
                        </div>
                    </div>

                    <div className="modal-body clearfix" id="myModal" data-backdrop="static">
                        <form
                            name="eConsentForm"
                            autoComplete="off"
                            noValidate
                            onSubmit={this.submitUpdatePPTConsent}
                        >
                            {this.state.saveStatus &&
                                this.state.saveStatus !== null &&
                                this.state.primaryPlanT !== true && (
                                    <div className="form-group has-error">
                                        <span className="help-block">
                                            {TranslationService.translate(
                                                this.state.saveStatus,
                                                this.state.saveStatusParams
                                            )}
                                        </span>
                                    </div>
                                )}

                            {this.state.saveStatus &&
                                this.state.saveStatus !== null &&
                                this.state.primaryPlanT === true && (
                                    <div className="alert alert-warning">
                                        <p>
                                            {TranslationService.translate(
                                                this.state.saveStatus,
                                                this.state.saveStatusParams
                                            )}
                                        </p>
                                    </div>
                                )}

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="col-md-6">
                                            <strong>
                                                {" "}
                                                {
                                                    this.state.translations?.preference?.eDelivery
                                                        ?.label.myPlans
                                                }
                                            </strong>
                                        </th>
                                        <th className="col-md-3"></th>
                                        <th className="col-md-1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.eConsentDetails &&
                                        this.state.eConsentDetails
                                            .sort(function (a, b) {
                                                return a.planName.localeCompare(b.planName);
                                            })
                                            .map((eConsentDetail, index) => (
                                                <tr key={eConsentDetail.planName}>
                                                    {eConsentDetail.statusCode == "A" && (
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <label
                                                                        htmlFor={
                                                                            "eConsentPlanName--" +
                                                                            index
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {
                                                                            eConsentDetail.planName
                                                                        }{" "}
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    {" "}
                                                                    {TranslationService.translate(
                                                                        this.state.translations
                                                                            ?.preference?.eDelivery
                                                                            ?.documentsInclude,
                                                                        "documentTypes:" +
                                                                            eConsentDetail.docTypes
                                                                    )}{" "}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}

                                                    {eConsentDetail.statusCode == "A" && (
                                                        <td className="pull-right">
                                                            <div className="checkbox-inline">
                                                                {eConsentDetail.pinAuthCode ===
                                                                    "U" && (
                                                                    <label
                                                                        htmlFor={
                                                                            "pptConsentForElectronic--" +
                                                                            index
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                eConsentDetail.pptConsentForElectronic
                                                                            }
                                                                            value={
                                                                                eConsentDetail.checkBoxvalue
                                                                            }
                                                                            disabled={
                                                                                this.state
                                                                                    .primaryPlanT ===
                                                                                true
                                                                            }
                                                                            id={
                                                                                "pptConsentForElectronic--" +
                                                                                index
                                                                            }
                                                                            name={
                                                                                "pptConsentForElectronic--" +
                                                                                index
                                                                            }
                                                                            onChange={() => {
                                                                                this.handleUpdateEConsentDetail(
                                                                                    index
                                                                                );
                                                                            }}
                                                                        />
                                                                        {
                                                                            this.state.translations
                                                                                ?.preference
                                                                                ?.eDelivery
                                                                                ?.deliveryMethod
                                                                                .option5
                                                                        }
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </td>
                                                    )}

                                                    {eConsentDetail.statusCode == "A" && <td></td>}
                                                </tr>
                                            ))}
                                </tbody>
                            </table>

                            <p>
                                {
                                    this.state.translations?.preference?.eDelivery
                                        ?.mailDeliveryInstruction
                                }
                            </p>
                            {this.state.email &&
                                this.state.email.primary &&
                                this.state.email.secondary && (
                                    <div className="form-group">
                                        <label htmlFor="emailChoice" className="control-label">
                                            {" "}
                                            {
                                                this.state.translations?.preference?.eDelivery
                                                    ?.label.email
                                            }{" "}
                                        </label>
                                        <select
                                            id="emailChoice"
                                            className="form-control col-md-6"
                                            name="emailchoice"
                                            value={this.state.eConsent.emailchoice}
                                        >
                                            {this.state.email.map((emailOption, index) => (
                                                <option
                                                    id={index}
                                                    value={emailOption}
                                                    key={emailOption}
                                                >
                                                    {" "}
                                                    {emailOption}{" "}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                            {this.state.email &&
                                !(this.state.email.primary || this.state.email.secondary) && (
                                    <div
                                        className={
                                            this.state.emailValidations.error
                                                ? "form-group has-error"
                                                : "form-group"
                                        }
                                    >
                                        <label htmlFor="emailChoice" className="control-label">
                                            {" "}
                                            {
                                                this.state.translations?.profile?.personal
                                                    ?.emailAddress
                                            }{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            id="emailId"
                                            className="form-control"
                                            value={this.state.eConsent.emailchoice}
                                            required={this.state.mustHaveEmail}
                                            maxLength="80"
                                            onChange={this.validateEmail}
                                        />

                                        {this.state.emailValidations.emailInvalid && (
                                            <p className="help-text">
                                                {" "}
                                                {
                                                    this.state.translations?.preference?.eDelivery
                                                        ?.errorEmailInvalid
                                                }
                                            </p>
                                        )}

                                        {this.state.emailValidations.emailEmpty && (
                                            <p className="help-text">
                                                {" "}
                                                {
                                                    this.state.translations?.preference?.eDelivery
                                                        ?.errorEmailRequired
                                                }
                                            </p>
                                        )}
                                    </div>
                                )}

                            <div className="document-review">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.translations?.preference?.eDelivery
                                            ?.legal.section
                                    }}
                                ></p>
                            </div>

                            <div className="pull-right margin-bottom-100 margin-top-100">
                                {this.state.isModalWindow && (
                                    <button
                                        type="submit"
                                        disabled={this.state.primaryPlanT === true}
                                        className="btn btn-primary"
                                        id="submitModal"
                                    >
                                        {" "}
                                        {
                                            this.state.translations?.preference?.eDelivery?.button
                                                .save
                                        }
                                    </button>
                                )}

                                {!this.state.isModalWindow && (
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block margin-top-default"
                                        id="submit"
                                        disabled={this.state.showSpinner === true}
                                    >
                                        {!this.state.showSpinner && (
                                            <span>
                                                {" "}
                                                {
                                                    this.state.translations?.preference?.eDelivery
                                                        ?.button.save
                                                }{" "}
                                            </span>
                                        )}

                                        {this.state.showSpinner && (
                                            <span>
                                                {" "}
                                                <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                                                Processing ...
                                            </span>
                                        )}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    componentDidMount() {
        this.getTranslations();
        this.validateEmail(null, this.state.eConsent?.emailchoice);
    }
}

ChangeCommunicationPreferenceComponent.propTypes = {
    primaryPlanT: PropTypes.bool,
    eConsentDetails: PropTypes.array,
    saveStatus: PropTypes.string,
    saveStatusParams: PropTypes.string,
    updatePPTConsent: PropTypes.func,
    updateEConsentDetail: PropTypes.func,
    mustHaveEmail: PropTypes.bool,
    isStandalonePage: PropTypes.bool,
    email: PropTypes.object,
    eConsent: PropTypes.object,
    isModalWindow: PropTypes.bool
};
