import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ACCOUNT_OPEN_DETAILS } from "../../routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../queries/constants";
import { TranslationsServiceProvider } from "../../providers/TranslationsServiceProvider";
import AccountOpenDetailsForm from "./AccountOpenDetailsForm";

//TODO:This wrapper will remove once angular components completely migrated to react
interface MFAProps {
    $state: { go: (path: string) => void };
}
const AccountOpenDetailsFormWrapper = ({ $state }: MFAProps) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: DEFAULT_STALE_TIME
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <TranslationsServiceProvider>
                <HashRouter>
                    <Routes>
                        <Route
                            path={ACCOUNT_OPEN_DETAILS}
                            element={
                                <AccountOpenDetailsForm hasReferrerParam={true} state={$state} />
                            }
                        ></Route>
                    </Routes>
                </HashRouter>
            </TranslationsServiceProvider>
        </QueryClientProvider>
    );
};

export default AccountOpenDetailsFormWrapper;
