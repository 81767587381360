import idProofErrorTemplate from "./idProofErrorTemplate";
import idProofingWithPinFlowTemplate from "./idProofingWithPinFlowTemplate";
import idProofQueriesTemplate from "./idProofQueriesTemplate";
import mailPinTemplate from "./mailPinTemplate";
import otpIdentityVerificationTemplate from "./otpIdentityVerificationTemplate";
import otpVerificationCodeEntryTemplate from "./otpVerificationCodeEntryTemplate";

const idProofRouteStates = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when("/idProof", "/idProof");

    $stateProvider
        .state("idProofQueries", {
            url: "/idProof/idProofQueries",
            template: idProofQueriesTemplate,
            controller: "idProofController",
            controllerAs: "idProofCtrl",
            params: {
                flowName: null
            },
            data: {
                fullHeight: true,
                title: "IDProof Queries"
            }
        })
        .state("otpIdentityVerification", {
            url: "/idProof/otp-verify",
            template: otpIdentityVerificationTemplate,
            controller: "otpIdentityVerificationController",
            controllerAs: "ctrl",
            params: {
                flowName: null
            },
            data: {
                fullHeight: true,
                title: "Identity verification"
            }
        })
        .state("otpIdentityVerificationCodeEntry", {
            url: "/idProof/otp-verify-code",
            template: otpVerificationCodeEntryTemplate,
            controller: "otpVerificationCodeEntryController",
            controllerAs: "ctrl",
            data: {
                fullHeight: true,
                title: "Identity verification"
            },
            params: {
                phoneNumber: {
                    value: null,
                    squash: true
                },
                flowName: null
            }
        })
        .state("idProofWithPin", {
            url: "/idProof/idProofWithPin",
            template: idProofingWithPinFlowTemplate,
            controller: "registrationController",
            controllerAs: "registrationController",
            data: {
                fullHeight: true,
                title: "IDProof with pin"
            }
        })
        .state("idProofError", {
            url: "/idProof/idProofError",
            template: idProofErrorTemplate,
            controller: "idProofController",
            data: {
                fullHeight: true,
                title: "Registration npdi"
            },
            params: {
                errorCode: {
                    value: "",
                    squash: true
                }
            }
        })
        .state("mailPin", {
            url: "/idProof/mailPin",
            template: mailPinTemplate,
            controller: "mailPinController",
            controllerAs: "mailPinCtrl",
            data: {
                fullHeight: true,
                title: "Mail Pin"
            },
            params: {
                optOut: {
                    value: "false",
                    squash: true
                }
            }
        });
};

idProofRouteStates.$inject = ["$stateProvider", "$urlRouterProvider"];
export default idProofRouteStates;
