import React, { StrictMode } from "react";

//import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import coreuiReactComponents from "core-ui/dist/core-ui-react-components";

//import { useAccuCustomization } from "../../hooks/useAccuCustomization";
import { DEFAULT_STALE_TIME } from "../../queries/constants";
import { Application } from "../Application";
import { TranslationsServiceProvider } from "../../providers/TranslationsServiceProvider";

import { headerStoreContext } from "../../contexts";

const { headerStore, HeaderViewProviderComponent, FooterViewProviderComponent } =
    coreuiReactComponents;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: DEFAULT_STALE_TIME
        }
    }
});

export const ApplicationContainer = () => {
    // TBD
    //   const accuCustomization = useAccuCustomization();
    //    console.log('acc custom', accuCustomization)

    //   if (!accuCustomization) return <React.Fragment></React.Fragment>;

    const newAppContainer = document.getElementById("new-app-container");

    return (
        <StrictMode>
            {/*   {accuCustomization ? (
                <Helmet>
                    <link
                        rel="apple-touch-icon"
                        href={__styleEnv__ + `${accuCustomization?.mobileIcons?.default}`}
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="58x58"
                        href={__styleEnv__ + `${accuCustomization?.mobileIcons?.icon58x58}`}
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="76x76"
                        href={__styleEnv__ + `${accuCustomization?.mobileIcons?.icon76x76}`}
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="152x152"
                        href={__styleEnv__ + `${accuCustomization?.mobileIcons?.icon152x152}`}
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="167x167"
                        href={__styleEnv__ + `${accuCustomization?.mobileIcons?.icon167x167}`}
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href={__styleEnv__ + `${accuCustomization?.mobileIcons?.icon180x180}`}
                    />
                    <link
                        rel="icon"
                        sizes="128x128"
                        href={__styleEnv__ + `${accuCustomization?.mobileIcons?.icon128x128}`}
                    />
                    <link
                        rel="icon"
                        sizes="192x192"
                        href={__styleEnv__ + `${accuCustomization?.mobileIcons?.icon192x192}`}
                    />
                </Helmet>
            ) : null} */}
            {newAppContainer && (
                <>
                    <HeaderViewProviderComponent
                        store={headerStore}
                        externalContext={headerStoreContext}
                        queryClient={queryClient}
                    />

                    <QueryClientProvider client={queryClient}>
                        <TranslationsServiceProvider>
                            <Application />
                        </TranslationsServiceProvider>
                    </QueryClientProvider>

                    <FooterViewProviderComponent store={headerStore} context={headerStoreContext} />
                </>
            )}
        </StrictMode>
    );
};
