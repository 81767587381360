import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";

import accountSetupEvents from "./events/AccountSetupEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { sentryAccountSetupMetrics } from "../utils/sentryMetricsLogging";

const PHONE_NUMBER_PLACEHOLDER = "(###) ###-####";

const accountSetupController = function (
    $cookies,
    $q,
    $rootScope,
    $scope,
    $state,
    $translate,
    $window,
    AccuCodeService,
    ContactDetailsFactory,
    DefaultCustomizationRetrService,
    PreLoginAccuRetrievalService,
    PreLoginContactInfoService,
    PersonalContactInfoUpdateUtil,
    PersonalContactInfoCustomValidator,
    ReSetACCUService,
    accountSetupFactory,
    eventBus,
    redirectService,
    otsRegistrationService
) {
    /** * Logger. */
    const logger = ExternalLogger.getInstance("accountSetupController");

    let myFlowName = "";
    const deferred = $q.defer();

    sentryAccountSetupMetrics();

    $scope.providedPhoneNumber =
        $state.params && $state.params.phoneNumber ? $state.params.phoneNumber : null;

    $scope.saveStatus = "";
    $scope.saveStatusParams = "";
    $scope.errors = [];

    $scope.emailError = "";
    $scope.phoneNumerError = "";
    $scope.usernameError = "";
    $scope.passwordError = "";

    $scope.contactInfo = {
        email: "",
        phoneNumber: "",
        phoneNumberDisplay: ""
    };
    $scope.userInfo = {
        username: "",
        password: "",
        confirmPassword: ""
    };

    $scope.submitted = false;
    $scope.showSpinner = false;

    $scope.forceValidations = function (inputs) {
        inputs.forEach(function (input) {
            $scope.accountSetupForm[input].$touched = true;
            $scope.accountSetupForm[input].$dirty = true;
        });
    };

    /**
     * focus manager
     * indexed by fieldName
     * @type {{}}
     */
    $scope.focusMgr = {};

    $scope.getValidField = function (fieldName) {
        const field = $scope.accountSetupForm[fieldName].$valid;

        return field;
    };

    $scope.isDirty = function (fieldName) {
        const dirty = $scope.accountSetupForm[fieldName].$dirty;

        return dirty;
    };

    $scope.displayFieldMessages = function (fieldName) {
        const field = $scope.accountSetupForm[fieldName];
        if (!field) {
            return true;
        }
        const conditions =
            (field.$invalid && field.$dirty) ||
            (field.$invalid && $scope.accountSetupForm.$submitted) ||
            field.$touched ||
            !field.$pristine;
        return conditions;
    };

    $scope.getFormGroupClass = function (fieldName) {
        const field = $scope.accountSetupForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field.$invalid && field.$dirty && !fieldFocused) {
            status = "has-error";
        }

        if (field.$valid && field.$dirty) {
            status = "has-success";
        }
        return status;
    };

    $scope.isTouched = function (fieldName) {
        return $scope.accountSetupForm[fieldName].$dirty;
    };

    $scope.getFieldError = function (fieldName) {
        return $scope.accountSetupForm[fieldName].$error;
    };

    /**
     * determine the validation error (style) class.
     * different class if one error vs multiples.
     */
    $scope.getValidationErrorClass = function (fieldName) {
        let styleName = "rule-validations";

        if (
            _isUndefined($scope.accountSetupForm) ||
            !fieldName ||
            _isUndefined($scope.accountSetupForm[fieldName])
        ) {
            return styleName;
        }

        const errs = $scope.accountSetupForm[fieldName].$error;
        const errorKeys = _keys(errs);

        const errorCnt = errorKeys.length;

        //if >1 errors, the style class should be bulleted.
        if (errorCnt > 1) {
            styleName = "form-validation-rule";
        }
        return styleName;
    };

    $scope.removeInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = false;
    };

    $scope.setInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = true;
        $scope.accountSetupForm[event.target.name].$setTouched(true);
        $scope.accountSetupForm[event.target.name].$setDirty(true);
    };

    $scope.loadContactInfo = function () {
        eventBus.dispatch(accountSetupEvents.CONTINUE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: accountSetupEvents.CONTINUE
            }
        });

        ContactDetailsFactory.retrieveContactDetails.query(
            {},
            function (data) {
                //console.log("   Contact info retrived: " + data ? JSON.stringify(data) : null);
                if (data === null) {
                    if ($scope.providedPhoneNumber) {
                        console.log(
                            "phone number provided as state param [" +
                                $scope.providedPhoneNumber +
                                "]"
                        );
                        $scope.contactDetails = {
                            domesticCntryCode: "US1",
                            intlNumber: null,
                            domesticMobile: $scope.providedPhoneNumber
                        };
                        $scope.phoneNumberIdD = $scope.contactDetails.domesticMobile;
                        $scope.selectedCntryPhCodeD = "+1";

                        $scope.contactDetails.countryDetails = [
                            {
                                countryCode: "US",
                                countryPhNo: "1",
                                countryName: "UNITED STATES",
                                countryPhAndCd: "US1"
                            }
                        ];
                    }
                } else {
                    $scope.contactDetails = data;
                    $scope.intlNumber = data.intlNumber;
                    $scope.personalEmailAddress = data.personalEmailAddress;
                    $scope.mobilePhoneNumber = data.domesticMobile;
                    if (data.intlNumber != null) {
                        $scope.intlNumberIdI = $scope.intlNumber;
                        $scope.intlNumberIdD = $scope.intlNumber;
                    }

                    if (data.domesticMobile !== null) {
                        $scope.mobilePhoneNumber = data.domesticMobile;
                        $scope.phoneNumberIdD = data.domesticMobile;
                        $scope.phoneNumberIdI = data.domesticMobile;
                        $scope.selectedCntryPhCodeD = "+" + data.domesticCntryCode.substring(2);
                    }

                    if (data.intCntryCode != null && data.intCntryCode != "") {
                        const intlCntryCode = String(data.intCntryCode).replace("null", "");
                        $scope.selectedCntryPhCodeI = "+" + intlCntryCode.substring(2);
                    }

                    /* $scope.contactInfo.phoneNumber = data.data.phoneNumber;
                     $scope.contactInfo.phoneNumberDisplay = "(" + data.data.phoneNumber.substring(0, 3) + ") " +
                         data.data.phoneNumber.substring(3, 6) +
                         "-" + data.data.phoneNumber.substring(6);*/

                    if ($scope.providedPhoneNumber) {
                        console.log(
                            "phone number provided as state param [" +
                                $scope.providedPhoneNumber +
                                "]"
                        );
                        $scope.contactDetails.domesticCntryCode = "US1";
                        $scope.contactDetails.domesticMobile = $scope.providedPhoneNumber;
                        $scope.phoneNumberIdD = $scope.contactDetails.domesticMobile;
                        $scope.selectedCntryPhCodeD = "+1";
                    }
                }
            },
            function (error) {
                if (error.data.status == "FAIL") {
                    $scope.saveStatus = error.data.error.code;
                    $scope.saveStatusParams = error.data.error.errors[0];

                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "account setup - loadContactInfo",
                        event_properties: {
                            login_error_code: error.data.error.code,
                            error_message: error.data.error.errors[0]
                        }
                    });
                }
            }
        );
    };

    $scope.populateSelectedCntryPhoneCode = function (fieldName) {
        const temp = document.getElementById(fieldName).value;
        if (fieldName === "ContactCountryNameI")
            $scope.selectedCntryPhCodeI = "+" + temp.substring(2);
        else if (fieldName === "ContactCountryNameD")
            $scope.selectedCntryPhCodeD = "+" + temp.substring(2);
    };

    $scope.setUpAccount = function () {
        myFlowName = "registrationFlow";
        $rootScope.flowName = myFlowName;

        const contactPostData = PersonalContactInfoUpdateUtil.getUpdateContactInfoJson(
            $rootScope.flowName,
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        const validaityOfForm = PersonalContactInfoCustomValidator.validatePersonalInfo(
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode,
            PHONE_NUMBER_PLACEHOLDER
        );
        if (!validaityOfForm.validForm) {
            $scope.accountSetupForm.$valid = false;

            $scope.phoneNum_error = validaityOfForm.errorCode;
        }

        if ($scope.accountSetupForm.$valid) {
            $scope.showSpinner = true;
            setTimeout(function () {
                accountSetupFactory.updateContact
                    .query(contactPostData)
                    .$promise.then(onUpdateContactSuccess, onUpdateContactFailure);
            }, 10);
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue
            // Manually set the $dirty flag for each input in the form to trigger validation

            const inputs = ["email", "phoneNumber", "username", "password", "confirmPassword"];

            $scope.forceValidations(inputs);
        }
    };

    $scope.updateContact = function (flowName) {
        const contactPostData = PersonalContactInfoUpdateUtil.getUpdateContactInfoJson(
            flowName,
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        const validaityOfForm = PersonalContactInfoCustomValidator.validatePersonalInfo(
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode,
            PHONE_NUMBER_PLACEHOLDER
        );
        if (!validaityOfForm.validForm) {
            $scope.accountSetupForm.$valid = false;
            $scope.phoneNum_error = validaityOfForm.errorCode;
        } else {
            $scope.phoneNum_error = "";
        }

        if ($scope.accountSetupForm.$valid) {
            $scope.showSpinner = true;
            accountSetupFactory.updateContact.query(contactPostData).$promise.then(
                function (contactResponse) {
                    onUpdateContactSuccess(contactResponse);
                },
                function (error) {
                    onUpdateContactFailure(error);
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue
            // Manually set the $dirty flag for each input in the form to trigger validation

            const inputs = ["email", "phoneNumber"];

            $scope.forceValidations(inputs);
        }
    };

    $scope.registeUser = function () {
        // Check if the form input is valid
        if ($scope.accountSetupForm.$valid) {
            const registePostData = {
                username: $scope.userInfo.username,
                password: $scope.userInfo.password,
                flowName: $rootScope.flowName
            };
            $scope.showSpinner = true;
            accountSetupFactory.registerUser.query(registePostData).$promise.then(
                function (registeResponse) {
                    onRegisterUserSuccess(registeResponse);
                },
                function (error) {
                    onRegisterUserFailure(error);
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue
            // Manually set the $dirty flag for each input in the form to trigger validation

            const inputs = ["username", "password", "confirmPassword"];

            $scope.forceValidations(inputs);
        }
    };

    function onUpdateContactSuccess(contactResponse) {
        if (contactResponse !== null && contactResponse.status === "successful") {
            $scope.showSpinner = false;
            if (myFlowName !== "registrationFlow") {
                redirectService.redirect(contactResponse, "ALL", "Contact");
            }
        } else {
            $scope.showSpinner = false;
            $scope.saveStatus = contactResponse.status;
        }

        if (myFlowName === "registrationFlow") {
            const registePostData = {
                username: $scope.userInfo.username,
                password: $scope.userInfo.password,
                flowName: myFlowName
            };
            $scope.showSpinner = true;
            accountSetupFactory.registerUser
                .query(registePostData)
                .$promise.then(onRegisterUserSuccess, onRegisterUserFailure);
        }

        //deferred.resolve();
    }

    function onUpdateContactFailure(error) {
        $scope.showSpinner = false;
        if (error.headers("exception") !== null || error.headers("exceptionMessage") !== null) {
            $scope.saveStatusContact = error.data.error.code;
            $scope.saveStatusParams = error.data.error.errors[0];

            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                event_action: "account setup - onUpdateContactFailure",
                event_properties: {
                    login_error_code: error.data.error.code,
                    error_message: error.data.error.errors[0]
                }
            });
        } else {
            if (error.data.data.email !== null) {
                $scope.emailError = error.data.data.email;
            }
            if (error.data.data.phoneNumber !== null) {
                $scope.phoneNumberError = error.data.data.phoneNumber;
            }
        }
        deferred.reject(error);
    }

    /**
     * This method will return the accu code associated with an 'empower restricted plan' if any such plan is returned for the ppt.
     */
    const getEmpowerRestrictedPlanAccuCode = function (data) {
        const method = "getEmpowerRestictedPlanAccuCode";

        const enforceEmpowerRestrictedPlans = $translate.instant("enforceEmpowerRestrictedPlans");
        logger.debug("{0} - enforceEmpowerRestrictedPlans=[{1}]", [
            method,
            enforceEmpowerRestrictedPlans
        ]);

        if (enforceEmpowerRestrictedPlans !== "true") {
            return null;
        }

        if (!data.accessPortalGroups || data.accessPortalGroups.length === 0 || !data.primaryPlan) {
            return null;
        }
        const accessPortalGroups = data.accessPortalGroups;
        const primaryPlan = data.primaryPlan;

        logger.debug("{0} - search primaryPlan : {1} over accessPortalGroups : {2}", [
            method,
            primaryPlan ? JSON.stringify(primaryPlan) : "null",
            accessPortalGroups ? JSON.stringify(accessPortalGroups) : "null"
        ]);

        for (let i = 0; i < accessPortalGroups.length; i++) {
            const accessPortalGroup = accessPortalGroups[i];
            if (accessPortalGroup.accessPortalList) {
                for (let j = 0; j < accessPortalGroup.accessPortalList.length; j++) {
                    const accessPortal = accessPortalGroup.accessPortalList[j];
                    logger.debug("{0} - evaluating accessPortal: {1}", [
                        method,
                        JSON.stringify(accessPortal)
                    ]);

                    //look for a match of primary plan with accessPortalList and return the accu code associated.
                    if (
                        primaryPlan.gaId === accessPortal.gaId &&
                        primaryPlan.indId === accessPortal.indId
                    ) {
                        logger.error("{0} - found restricted plan. returning {1}. ", [
                            method,
                            accessPortalGroup.accu
                        ]);
                        return accessPortalGroup.accu;
                    }
                }
            }
        }

        logger.debug("{0} - returning null", [method]);
        return null;
    };

    const updateCssAccu = function (val) {
        if (val) {
            $rootScope.css.accu = val;
            globalThis.css.accu = val;
        } else {
            $rootScope.css.accu = $window.accu;
            globalThis.css.accu = $window.accu;
        }
    };

    function onRegisterUserSuccess(registeResponse) {
        const method = "onRegisterUserSuccess";
        if (registeResponse !== null && registeResponse.status === "successful") {
            if ($scope.accu.toLowerCase() === "empower") {
                const newAccuCode = getEmpowerRestrictedPlanAccuCode(registeResponse);
                if (
                    newAccuCode &&
                    $window.accu !== newAccuCode &&
                    $window.supportedACCU.folders.indexOf(newAccuCode) >= 0
                ) {
                    logger.error(
                        "{0} - Empower Restricted authentication..changing accu to [{1}]",
                        [method, newAccuCode]
                    );
                    $window.accu = newAccuCode;
                    globalThis.css.accu = newAccuCode;
                    //update tomcat
                    logger.error("{0} - updating accu...", [method]);
                    ReSetACCUService.query({ accu: $window.accu }).$promise.then(function () {
                        //set cookie
                        $cookies.remove("accu", { path: "/" });
                        $cookies.put("accu", $window.accu, { path: "/" });

                        $cookies.remove("accu", { path: "/participant" });
                        $cookies.put("accu", $window.accu, { path: "/participant" });

                        $cookies.remove("clientAccu", { path: "/" });
                        $cookies.put("clientAccu", $window.accu, { path: "/" });

                        $cookies.remove("clientAccu", { path: "/participant" });
                        $cookies.put("clientAccu", $window.accu, { path: "/participant" });

                        // added to resolve SR # 03597037   - cookie used for legacy pptweb quicken download feature
                        $cookies.remove("PM-ACCU-TOKEN", { path: "/" });

                        $scope.accu = AccuCodeService.getAccuCode();
                        updateCssAccu($scope.accu);
                        //to reload customizations..

                        console.log(method + "- re-loading default customizations..", method);
                        DefaultCustomizationRetrService.query().$promise.then(
                            function (defaultData) {
                                console.log(
                                    method + "- default customizations..",
                                    method,
                                    defaultData
                                );

                                console.log(method + "- re-loading preLogin accu data..", method);
                                PreLoginAccuRetrievalService.query({
                                    accu: $scope.accu
                                }).$promise.then(function (preLoginData) {
                                    console.log(
                                        method + "- preLogin accu data..",
                                        method,
                                        preLoginData
                                    );
                                    $scope.accuCustomization = angular.extend(
                                        {},
                                        defaultData,
                                        preLoginData
                                    );
                                    $rootScope.accuSpecificPrimDb =
                                        $scope.accuCustomization.primaryDatabase.preLoginPrimaryDB;

                                    console.log(
                                        method + "- re-loading PreLogin contact info..",
                                        method
                                    );
                                    console.log(
                                        method + "- existing siteContactInfo",
                                        $rootScope.siteContactInfo
                                    );
                                    PreLoginContactInfoService.query({
                                        accu: $scope.accu,
                                        accuSpecificPrimDb: $rootScope.accuSpecificPrimDb,
                                        portalType: "TOLLFREE"
                                    }).$promise.then(function (contactData) {
                                        $rootScope.siteContactInfo = contactData;
                                        console.log(
                                            method + "-  NEW PreLogin contact info..",
                                            method,
                                            contactData
                                        );
                                        $rootScope.$broadcast(
                                            "siteContactInfoUpdated",
                                            $rootScope.siteContactInfo
                                        );
                                        $rootScope.$broadcast(
                                            "loginAuthStatusVerified",
                                            $scope.authenticationStatus
                                        );
                                        $rootScope.$broadcast(
                                            "customizationUpdated",
                                            $scope.accuCustomization
                                        );

                                        logger.error("{0} - Accu code reset to: [{1}]", [
                                            method,
                                            $scope.accu
                                        ]);
                                        //#############################################################################

                                        // $window.location.href = "../../participant/#/mfa/activationCodeDeliveryOptions?accu="+$scope.accu;
                                        redirectService.redirect(registeResponse, "ALL");
                                        //#############################################################################
                                    });
                                });
                            }
                        );
                    });
                    redirectService.redirect(registeResponse, "ALL");
                } else {
                    redirectService.redirect(registeResponse, "ALL");
                }
            } else {
                redirectService.redirect(
                    registeResponse,
                    "ALL",
                    otsRegistrationService.getFormData().context
                );
            }
        } else {
            $scope.saveStatus = registeResponse.status;
        }
        $scope.showSpinner = false;
        deferred.resolve();
    }

    function onRegisterUserFailure(error) {
        if (error.headers("exception") !== null || error.headers("exceptionMessage") !== null) {
            $scope.saveStatus = error.data.error.code;
            $scope.saveStatusParams = error.data.error.errors[0];

            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                event_properties: {
                    login_error_code: error.data.error.code,
                    event_action: "account setup - onRegisterUserFailure",
                    error_message: error.data.error.errors[0]
                }
            });
        } else {
            if (error.data.data.userName !== null) {
                $scope.usernameError = error.data.data.userName;
            }
            if (error.data.data.password !== null) {
                $scope.passwordError = error.data.data.password;
            }
            //$scope.saveStatus = "Could not save contact Information";
        }
        $scope.showSpinner = false;
        deferred.reject(error);
    }

    //------EVENTS-----
    $scope.contactEmailChange = function () {
        eventBus.dispatch(accountSetupEvents.EMAIL_CHANGE, this, {
            email: $scope.contactInfo.email
        });
    };

    $scope.phoneNumChange = function () {
        eventBus.dispatch(accountSetupEvents.PHONE_CHANGE, this, {
            phone: $scope.contactInfo.phoneNumber
        });
    };

    $scope.usernameChange = function () {
        eventBus.dispatch(accountSetupEvents.USRNM_CHANGE, this, {
            username: $scope.userInfo.username
        });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: accountSetupEvents.USRNM_CHANGE,
                payload: {
                    username: $scope.userInfo.username
                }
            }
        });
    };

    $scope.passwordChange = function () {
        eventBus.dispatch(accountSetupEvents.PASS_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: accountSetupEvents.PASS_CHANGE
            }
        });
    };

    $scope.confirmPassChange = function () {
        eventBus.dispatch(accountSetupEvents.CONFIRM_PASS_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: accountSetupEvents.CONFIRM_PASS_CHANGE
            }
        });
    };

    $scope.registerClick = function () {
        eventBus.dispatch(accountSetupEvents.REGISTER, this);
    };

    $scope.continueClick = function () {
        eventBus.dispatch(accountSetupEvents.CONTINUE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: accountSetupEvents.CONTINUE
            }
        });
    };

    $translate([
        "setUpAccountTitle",
        "completeRegistrationInfo",
        "contactInformationTitle",
        "usernamePasswordTitle"
    ]).then(function (translations) {
        angular.extend($scope, {
            translatedData: {
                setUpAccountTitle: translations.setUpAccountTitle,
                completeRegistrationInfo: translations.completeRegistrationInfo,
                contactInformationTitle: translations.contactInformationTitle,
                usernamePasswordTitle: translations.usernamePasswordTitle
            }
        });

        $translate("profile.contactInfoDisclosure")
            .then(function (translation) {
                // Assign the translated value to a scope variable
                $scope.contactInfoDisclosure = translation;
            })
            .catch(function (error) {
                console.error("Error retrieving translation:", error);
            });
    });
};

accountSetupController.$inject = [
    "$cookies",
    "$q",
    "$rootScope",
    "$scope",
    "$state",
    "$translate",
    "$window",
    "AccuCodeService",
    "ContactDetailsFactory",
    "DefaultCustomizationRetrService",
    "PreLoginAccuRetrievalService",
    "PreLoginContactInfoService",
    "PersonalContactInfoUpdateUtil",
    "PersonalContactInfoCustomValidator",
    "ReSetACCUService",
    "accountSetupFactory",
    "eventBus",
    "redirectService",
    "otsRegistrationService"
];
export default accountSetupController;
