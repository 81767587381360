const otsAccountOpenFactory = function ($q, $http) {
    //check if user exists
    const authenticateOTSUserURL = "rest/nonauth/authenticateOtsUser";

    const createShadowAccount = "rest/partialauth/ots/shadowAccount";

    return {
        getExistingUserOTS: function (data, skipExperian, referrerParam) {
            const deferred = $q.defer();

            $http
                .post(
                    __authenticationUrl__ + authenticateOTSUserURL + skipExperian + referrerParam,
                    data
                )
                .success(function (data) {
                    // Pass back the confirmation number
                    deferred.resolve(data);
                })
                .error(function (data) {
                    if (data !== null && data.error !== null) {
                        deferred.reject(data.error);
                    } else {
                        deferred.reject();
                    }
                });
            return deferred.promise;
        },
        createShadowAccountOTS: function (data) {
            const deferred = $q.defer();

            const shadowAccount = {
                phoneNumbers: [
                    {
                        countryCode: 9999,
                        phoneNumber: data,
                        phoneNumberType: "MOBILE"
                    }
                ]
            };
            $http
                .post(__authenticationUrl__ + createShadowAccount, shadowAccount)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (data) {
                    if (data !== null && data.error !== null) {
                        deferred.reject(data.error);
                    } else {
                        deferred.reject();
                    }
                });
            return deferred.promise;
        }
    };
};
otsAccountOpenFactory.$inject = ["$q", "$http", "$log"];
export default otsAccountOpenFactory;
