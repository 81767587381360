import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../../services/translationService";

export default class ChangePersonalContactComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            cancel: this.props.cancel,
            updatePersonalContact: this.props.updatePersonalContact
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    //close modal
    handleClickCancel = () => {
        this.props.cancel("personal_contact_info");
    };

    handleupdatePersonalContact = () => {
        this.props.updatePersonalContact("profile");
    };

    render() {
        if (!this.state.translations) {
            return null;
        }

        return (
            <section>
                REACT WIP
                <div className="modal-header">
                    {/* data-ng-init="retrievePersonalContactInfo()" */}
                    <div className="modal-title">
                        {this.state.translations.profile.login.header.changePersonal}
                    </div>
                </div>
                <div className="modal-body clearfix profile">
                    <div className="modal-well with-background">
                        <form name="personalContactForm" autoComplete="off" noValidate>
                            <p>Here goes the form</p>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={this.handleClickCancel}
                        className="btn btn-link"
                        id="updatePersonalContactId"
                    >
                        {this.state.translations.preference.button.cancel}
                    </button>
                    <button onClick={this.handleupdatePersonalContact} className="btn btn-primary">
                        {this.state.translations.preference.button.save}
                    </button>

                    {/* to do??
                     <span data-ng-hide="showSpinner==true">
                        {{ "preference.button.save" | translate }}
                     </span>
                                             
                    <span data-ng-show="showSpinner==true">
                     
                       <i class="fa fa-circle-o-notch fa-spin"></i>
                            Processing ...
                    </span>
            */}
                </div>
            </section>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

ChangePersonalContactComponent.propTypes = {
    cancel: PropTypes.func,
    updatePersonalContact: PropTypes.func
};
