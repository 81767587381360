import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ControlledFormField from "../../shared/controlledFormField/ControlledFormField";
import { useTranslations } from "../../../queries";
import registerWithGroupPassword from "../../../services/register/registerWithGroupPassword";
import handleRegisterServiceError from "../../../services/register/handleRegisterServiceError";
import useRedirect from "../../../hooks/useRedirect";

export interface RegisterWithGroupPasswordTranslations {
    button: { continue: string };
    groupId: string;
    groupIdRequired: string;
    logon: { [key: string]: string };
    planEnrollmentCode: string;
    planEnrollmentCodeRequired: string;
}

interface RegisterWithGroupPasswordFields {
    groupId: string;
    planEnrollmentCode: string;
}

const DEFAULT_VALUES = {
    groupId: "",
    planEnrollmentCode: ""
};

const FLOW_NAME = "registrationFlow";

const RegisterWithGroupPassword = () => {
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting }
    } = useForm<RegisterWithGroupPasswordFields>({
        defaultValues: DEFAULT_VALUES,
        mode: "onBlur",
        criteriaMode: "all"
    });
    const {
        groupId,
        groupIdRequired,
        logon: errorMessages,
        planEnrollmentCode,
        planEnrollmentCodeRequired,
        button
    } = useTranslations<RegisterWithGroupPasswordTranslations>();
    const [typePlanEnrollmentCode, setTypePlanEnrollmentCode] = useState("number");
    const redirect = useRedirect();

    /**
     * The following defines the validations for each field. Each validation must return undefined
     * if the rule is not violated in order to remove it from the field's error object.
     */
    const GROUP_ID_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? groupIdRequired : undefined)
        }
    };
    const PLAN_ENROLLMENT_CODE_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? planEnrollmentCodeRequired : undefined)
        }
    };

    /**
     * When the Plan Enrollment Code field loses focus, validate the value. If it is valid, set the field type to
     * 'password' to conceal the sensitive information.
     */
    const handleBlurPlanEnrollmentCode = () => {
        setTypePlanEnrollmentCode("password");
    };

    /**
     * When the Plan Enrollment Code field gains focus, set the field type to 'text' so the user can see the value
     * in case they need to make an edit.
     */
    const handleFocusPlanEnrollmentCode = () => {
        setTypePlanEnrollmentCode("text");
    };

    const onSubmit = async (formData: RegisterWithGroupPasswordFields) => {
        const { groupId, planEnrollmentCode } = formData;
        const payload = {
            groupId,
            flowName: FLOW_NAME,
            pin: planEnrollmentCode
        };
        try {
            const { data } = await registerWithGroupPassword(payload);
            redirect(data);
        } catch (err) {
            const message = handleRegisterServiceError(errorMessages, err);
            setError("root", { message });
        }
    };

    return (
        <form data-testid="register-with-group-password" onSubmit={handleSubmit(onSubmit)}>
            {isSubmitting && (
                <div className="loaderBackground">
                    <div className="loader"></div>
                </div>
            )}
            {errors?.root?.message && (
                <div
                    data-testid="register-with-group-password-error"
                    className="error-block margin-bottom-100"
                    aria-live="polite"
                >
                    {errors.root.message}
                </div>
            )}
            <ControlledFormField
                className="col-5"
                control={control}
                id="group-id"
                label={groupId}
                name="groupId"
                rules={GROUP_ID_RULES}
            />
            <ControlledFormField
                className="col-5"
                control={control}
                id="plan-enrollment-code"
                label={planEnrollmentCode}
                name="planEnrollmentCode"
                onBlur={handleBlurPlanEnrollmentCode}
                onFocus={handleFocusPlanEnrollmentCode}
                rules={PLAN_ENROLLMENT_CODE_RULES}
                type={typePlanEnrollmentCode}
            />
            <div className="form-group">
                <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
                >
                    <span>{button.continue.toUpperCase()}</span>
                </button>
            </div>
        </form>
    );
};

export default RegisterWithGroupPassword;
