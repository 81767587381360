const root = "MFAScreen";

export const REMEMBER_DEVICE_AMPLITUDE_PAYLOAD = "Remember device";

const MFAEvents = {
    CLICK: `${root}.click`,
    CTA_BUTTON: `${root}.ctaButton`,
    LINK: `${root}.link`
};

export default MFAEvents;
