import type { AxiosResponse } from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";
import type { RedirectData } from "../redirect/types";

interface VerifyCodePayload {
    rememberDevice: boolean;
    verificationCode: string;
}

const verifyCode = (payload: VerifyCodePayload): Promise<AxiosResponse<RedirectData>> => {
    return executePost(ReferenceData.VERIFY_CODE, "verifyCodeFault", payload);
};

export default verifyCode;
