const registrationFormSubmitTemplate = `
<a class="hidden" ng-click="openOTPNoCodeModalModal();">openModal</a>

<div class="form-group clearfix">
    <button type="submit" ng-click="submitClick()"
        class="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
        ng-disabled="showSpinner==true" id="login-help-submit">
        <div id="frame-container-spinner" class="loaderBackground just-element" ng-if="showSpinner==true">
            <div class="loader"></div>
        </div>

        <span data-ng-hide="showSpinner==true">
            CONTINUE
        </span>

        <span data-ng-show="showSpinner==true">
            <i class="fa fa-circle-o-notch fa-spin"></i>
            Processing ...
        </span>
    </button>
</div>

<div>
    <alternate-site-dialog accu-error="accuError" count-down="30"></alternate-site-dialog>
</div>
`;

export default registrationFormSubmitTemplate;
