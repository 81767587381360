import indIdSelectionTemplate from "./indIdSelectionTemplate";

const IndIdSelectionRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/indId-selection");

    $stateProvider

        // IndId Selection View =================================
        .state("indIdSelection", {
            url: "/indId-selection",
            template: indIdSelectionTemplate,
            controller: "IndIdSelectionController",
            controllerAs: "IndIdSelectionController",
            params: {
                deeplink: null
            },
            data: {
                fullHeight: true,
                title: "IndId Selection"
            }
        });
};

IndIdSelectionRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default IndIdSelectionRoute;
