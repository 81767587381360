const changeAuthorizedDevicesTemplate = `
<div class="modal-header" data-ng-init="retrievePersonalContactInfo()">
    <div class="modal-title" id="modal-title" role="heading" aria-level="2">{{ "profile.authorizedDevices.modal.header" | translate }}</div>
</div>
<div class="modal-body clearfix">
    <div class="modal-well with-background allocation-table-wrapper profile-information">
        <form role="form" name="authorizedDevicesForm" data-ng-init="loadAuthorizedDevices()" class="clearfix"
            autocomplete="off" novalidate>

            <table class="table allocation-fund-table" id="allocation-current-funds-table">
                <thead>
                    <tr>
                        <th>
                            <h4 aria-label="Delete">{{"profile.authorizedDevices.modal.delete" | translate}}</h4>
                        </th>
                        <th class="devices-col-name">
                            <h4>{{"profile.authorizedDevices.modal.deviceName" | translate}}</h4>
                        </th>
                        <th class="text-center">
                            <h4>{{"profile.authorizedDevices.modal.created" | translate}}</h4>
                        </th>
                        <th class="text-right">
                            <h4>{{"profile.authorizedDevices.modal.lastSignIn" | translate}}</h4>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody ng-repeat="device in devices">
                    <tr>
                        <td>
                            <a href="" aria-label="Remove Device {{device.deviceCustomName}}"
                                ng-click="removeDeviceUI(device.deviceFingerPrintHash)"
                                class="btn btn-link allocation-icon">
                                <span class="em-x-bold"></span>
                            </a>
                        </td>
                        <td class="allocation-fund-name-container">
                            <label for="{{device.deviceFingerPrintHash}}"
                                class="sr-only text-lowercase">{{"profile.authorizedDevices.modal.deviceName" |
                                translate}}</label>
                            <input type="text" aria-label="Device {{$index + 1}}: {{device.deviceCustomName}}"
                                class="form-control" id="{{device.deviceFingerPrintHash}}"
                                name="device.deviceFingerPrintHash" data-ng-required="true"
                                data-ng-model="device.deviceCustomName" ng-maxlength="65" />
                        </td>
                        <td class="text-center">
                            {{created_format(device.created)}}
                        </td>
                        <td>
                            {{lastSignIn_format(device.lastSignIn)}}
                        </td>
                    </tr>
                </tbody>
            </table>

        </form>
    </div>
</div>

<div class="modal-footer">
    <button ng-click="cancel('authorized-devices')" class="btn btn-link">{{ "preference.button.cancel" | translate
        }}</button>
    <button ng-click="updateAuthorizedDevices(showSpinner || updateIsAllowed())"
        aria-disabled="{{ showSpinner || !updateIsAllowed() }}" class="btn btn-primary" id="submit">

        <span data-ng-hide="showSpinner==true">
            {{ "preference.button.save" | translate }}
        </span>

        <span data-ng-show="showSpinner==true">

            <i class="fa fa-circle-o-notch fa-spin"></i>
            Processing ...
        </span>
    </button>
</div>
`;
export default changeAuthorizedDevicesTemplate;
