import otpNoCodeController from "./otpNoCodeController";

const otpService = function ($modal) {
    const service = {};
    service.modalInstance = null;

    service.openOTPNoCodeModalModal = function () {
        service.modalInstance = $modal.open({
            template:
                '<pwr-otp-nocode-modal-component options="ctrl.options"></pwr-otp-nocode-modal-component>',
            controller: otpNoCodeController,
            controllerAs: "ctrl",
            size: "md",
            windowClass: "modal-wrapper"
        });
    };

    return service;
};

otpService.$inject = ["$modal"];
export default otpService;
