import ContactDetailsFactory from "core-ui/client/src/app/ContactDetailsFactory";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _get from "lodash/get";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";
import _size from "lodash/size";

import npdiAccountSetupEvents from "./events/NpdiAccountSetupEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const PHONE_NUMBER_PLACEHOLDER = "(###) ###-####";

const npdiAccountSetupController = function (
    $q,
    $rootScope,
    $scope,
    $translate,
    PersonalContactInfoCustomValidator,
    PersonalContactInfoUpdateUtil,
    eventBus,
    npdiFactory,
    redirectService,
    npdiRegistrationService
) {
    const logger = ExternalLogger.getInstance("NpdiAccountSetupController");
    const deferred = $q.defer();

    $scope.submitted = false;
    $scope.pecbypassregEnabled = false;

    npdiFactory.isPECKTMGEnabled.query().$promise.then(
        function (data) {
            if (data !== null) {
                $scope.pecbypassregEnabled = _get(data, "WNPECN");
                npdiRegistrationService.setPecByPass(_get(data, "WNPECN"));
            }
        },
        function (error) {
            logger.error("ERROR ndpiFactory.isPECKTMGEndabled():", error);
            if (error?.data?.status == "FAIL") {
                eventBus.dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                    event_action: "npdi - isPECKTMGEnabled",
                    event_properties: {
                        login_error_code: error?.data?.error?.code,
                        error_message: error?.data?.error?.errors[0]
                    }
                });
            }
        }
    );

    $scope.personalInfo = {
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        ssn: "",
        maritalStatus: ""
    };

    $scope.employmentInfo = {
        currentAnnualIncome: "",
        dateOfHire: ""
    };

    $scope.mailingAddress = {
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipcode: ""
    };

    $scope.stateOptions = {
        code: "",
        description: ""
    };
    $scope.selectedStateValue = {
        code: "",
        description: ""
    };

    $scope.zipcode = "";

    $scope.countryOptions = {
        code: "",
        description: ""
    };
    $scope.selectedCountryValue = {
        code: "",
        description: ""
    };

    $scope.maritalStatusOptions = {
        code: "",
        description: ""
    };

    $scope.genderListOptions = {};

    $scope.selectedGenderValue = "";

    $scope.selectedMaritalStatusValue = {
        code: "",
        description: ""
    };

    $scope.userInfo = {
        username: "",
        password: "",
        confirmPassword: ""
    };

    $scope.cleaveoptions = {
        ssn: {
            blocks: [3, 2, 4],
            uppercase: true,
            delimiters: ["-"]
        }
    };

    $scope.validationStatus = "";
    $scope.validationStatusParams = "";
    $scope.errors = [];

    /**
     * focus manager
     * indexed by fieldName
     * @type {{}}
     */
    $scope.focusMgr = {};

    $scope.forceValidations = function (inputs) {
        inputs.forEach(function (input) {
            $scope.accountSetupForm[input].$touched = true;
            $scope.accountSetupForm[input].$dirty = true;
        });
    };

    /**
     * focus manager
     * indexed by fieldName
     * @type {{}}
     */
    $scope.focusMgr = {};

    $scope.getValidField = function (fieldName) {
        const field = $scope.accountSetupForm[fieldName].$valid;

        return field;
    };

    $scope.isDirty = function (fieldName) {
        const dirty = $scope.accountSetupForm[fieldName].$dirty;

        return dirty;
    };

    $scope.displayFieldMessages = function (fieldName) {
        const field = $scope.accountSetupForm[fieldName];
        if (!field) {
            return true;
        }
        const conditions =
            (field.$invalid && field.$dirty) ||
            (field.$invalid && $scope.accountSetupForm.$submitted) ||
            field.$touched ||
            !field.$pristine;
        return conditions;
    };

    $scope.getFormGroupClass = function (fieldName) {
        const field = $scope.accountSetupForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field.$invalid && field.$dirty && !fieldFocused) {
            status = "has-error";
        }

        if (field.$valid && field.$dirty) {
            status = "has-success";
        }
        return status;
    };

    $scope.isTouched = function (fieldName) {
        return $scope.accountSetupForm[fieldName].$dirty;
    };

    $scope.getFieldError = function (fieldName) {
        return $scope.accountSetupForm[fieldName].$error;
    };

    /**
     * determine the validation error (style) class.
     * different class if one error vs multiples.
     */
    $scope.getValidationErrorClass = function (fieldName) {
        let styleName = "rule-validations";

        if (
            _isUndefined($scope.accountSetupForm) ||
            !fieldName ||
            _isUndefined($scope.accountSetupForm[fieldName])
        ) {
            return styleName;
        }
        const errs = $scope.accountSetupForm[fieldName].$error;
        const errorKeys = _keys(errs);

        const errorCnt = _size(errorKeys);

        //if >1 errors, the style class should be bulleted.
        if (errorCnt > 1) {
            styleName = "form-validation-rule";
        }

        return styleName;
    };

    $scope.removeInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = false;
    };

    $scope.setInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = true;
        $scope.accountSetupForm[event.target.name].$setTouched(true);
        $scope.accountSetupForm[event.target.name].$setDirty(true);
    };

    $scope.loadContactInfo = function () {
        eventBus.dispatch(npdiAccountSetupEvents.CONTINUE, this);

        ContactDetailsFactory.retrieveContactDetails.query(
            {},
            function (data) {
                console.log("   Contact info retrived" + data);
                if (data !== null) {
                    $scope.contactDetails = data;
                    $scope.intlNumber = data.intlNumber;
                    $scope.personalEmailAddress = data.personalEmailAddress;
                    $scope.mobilePhoneNumber = data.domesticMobile;
                    if (data.intlNumber != null) {
                        $scope.intlNumberIdI = $scope.intlNumber;
                        $scope.intlNumberIdD = $scope.intlNumber;
                    }

                    if (data.domesticMobile !== null) {
                        $scope.mobilePhoneNumber = data.domesticMobile;
                        $scope.phoneNumberIdD = data.domesticMobile;
                        $scope.phoneNumberIdI = data.domesticMobile;
                        $scope.selectedCntryPhCodeD = "+" + data.domesticCntryCode.substring(2);
                    }

                    if (data.intCntryCode != null && data.intCntryCode != "")
                        $scope.selectedCntryPhCodeI = "+" + data.intCntryCode.substring(2);
                }
            },
            function (error) {
                if (error.data.status == "FAIL") {
                    $scope.saveStatus = error.data.error.code;
                    $scope.saveStatusParams = error.data.error.errors[0];

                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "npdi - loadContactInfo",
                        event_properties: {
                            login_error_code: error.data.error.code,
                            error_message: error.data.error.errors[0]
                        }
                    });
                }
                logger.error("ERROR - ContactDetailsFactory.retrieveContactDetails: ", error);
            }
        );
    };

    $scope.populateSelectedCntryPhoneCode = function (fieldName) {
        const temp = document.getElementById(fieldName).value;
        if (fieldName === "ContactCountryNameI") {
            $scope.selectedCntryPhCodeI = "+" + temp.substring(2);
        } else if (fieldName === "ContactCountryNameD") {
            $scope.selectedCntryPhCodeD = "+" + temp.substring(2);
        }
    };

    $scope.retrieveContactDetailsDropdown = function () {
        npdiFactory.retrieveContactDetailsDropdown.query(
            {},
            function (data) {
                console.log(npdiFactory, " mask");
                logger.debug(
                    "npdiFactory.retrieveContactDetailsDropdown - Contact info retrived: ",
                    data
                );
                if (data !== null) {
                    $scope.contactDetails = data;
                    $scope.intlNumber = data.intlNumber;
                    $scope.personalEmailAddress = data.personalEmailAddress;
                    $scope.mobilePhoneNumber = data.domesticMobile;
                    if (data.intlNumber != null) {
                        $scope.intlNumberIdI = $scope.intlNumber;
                        $scope.intlNumberIdD = $scope.intlNumber;
                    }

                    if (data.domesticMobile !== null) {
                        $scope.mobilePhoneNumber = data.domesticMobile;
                        $scope.phoneNumberIdD = data.domesticMobile;
                        $scope.phoneNumberIdI = data.domesticMobile;
                        $scope.selectedCntryPhCodeD = "+" + data.domesticCntryCode.substring(2);
                    }

                    if (data.intCntryCode != null && data.intCntryCode != "") {
                        $scope.selectedCntryPhCodeI = "+" + data.intCntryCode.substring(2);
                    }
                }
            },
            function (error) {
                if (error.data.status == "FAIL") {
                    $scope.saveStatus = error.data.error.code;
                    $scope.saveStatusParams = error.data.error.errors[0];

                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "npdi - retrieveContactDetailsDropdown",
                        event_properties: {
                            login_error_code: error.data.error.code,
                            error_message: error.data.error.errors[0]
                        }
                    });
                }
            }
        );
    };

    $scope.setUpAccount = function () {
        // Everything is good, we can continue with registration
        $rootScope.flowName = "npdiAccountSetupFlow";
        const contactPostData = PersonalContactInfoUpdateUtil.getUpdateContactInfoJson(
            $rootScope.flowName,
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        const validaityOfForm = PersonalContactInfoCustomValidator.validatePersonalInfo(
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode,
            PHONE_NUMBER_PLACEHOLDER
        );
        if (!validaityOfForm.validForm) {
            $scope.accountSetupForm.$valid = false;
            $scope.phoneNum_error = validaityOfForm.errorCode;
        } else {
            $scope.phoneNum_error = "";
        }

        const registePostData = {
            username: $scope.userInfo.username,
            password: $scope.userInfo.password,
            flowName: $rootScope.flowName,
            contactInfo: contactPostData
        };
        if (npdiFactory.isLawOfficer) {
            registePostData.isLawOfficer = npdiFactory.isLawOfficer;
        }

        if ($scope.accountSetupForm.$valid) {
            $scope.showSpinner = true;
            setTimeout(function () {
                npdiFactory.addNPDIppt
                    .query(registePostData)
                    .$promise.then(onRegisterUserSuccess, onRegisterUserFailure);
            }, 10);
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue
            // Manually set the $dirty flag for each input in the form to trigger validation
            const inputs = ["email", "phoneNumber", "username", "password", "confirmPassword"];
            $scope.forceValidations(inputs);
        }
    };

    function onRegisterUserSuccess(registeResponse) {
        if (registeResponse !== null && registeResponse.status === "successful") {
            redirectService.redirect(registeResponse, "ALL");
        } else {
            $scope.saveStatus = registeResponse.status;
        }
        $scope.showSpinner = false;
        deferred.resolve();
    }

    function onRegisterUserFailure(error) {
        if (error.headers("exception") !== null || error.headers("exceptionMessage") !== null) {
            $scope.saveStatus = error.data.error.code;
            $scope.saveStatusParams =
                error.data.error?.errors?.length > 0 ? error.data.error.errors[0] : null;

            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                event_action: "npdi - onRegisterUserFailure",
                event_properties: {
                    login_error_code: error.data.error.code,
                    error_message:
                        error.data.error?.errors?.length > 0 ? error.data.error.errors[0] : null
                }
            });
        } else {
            if (error.data.data.userName !== null) {
                $scope.usernameError = error.data.data.userName;
            }
            if (error.data.data.password !== null) {
                $scope.passwordError = error.data.data.password;
            }
            //$scope.saveStatus = "Could not save contact Information";
        }
        $scope.showSpinner = false;
        deferred.reject(error);
    }

    $scope.usernameChange = function () {
        eventBus.dispatch(npdiAccountSetupEvents.USRNM_CHANGE, this, {
            username: $scope.userInfo.username
        });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: npdiAccountSetupEvents.USRNM_CHANGE,
                payload: {
                    username: $scope.userInfo.username
                }
            }
        });
    };

    $scope.passwordChange = function () {
        eventBus.dispatch(npdiAccountSetupEvents.PASS_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: npdiAccountSetupEvents.PASS_CHANGE
            }
        });
    };

    $scope.confirmPassChange = function () {
        eventBus.dispatch(npdiAccountSetupEvents.CONFIRM_PASS_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: npdiAccountSetupEvents.CONFIRM_PASS_CHANGE
            }
        });
    };

    $scope.registerClick = function () {
        eventBus.dispatch(npdiAccountSetupEvents.REGISTER, this);
    };

    $scope.continueClick = function () {
        eventBus.dispatch(npdiAccountSetupEvents.CONTINUE, this);
    };

    $translate([
        "setUpAccountTitleNpdi",
        "completeRegistrationInfo",
        "contactInformationTitle",
        "usernamePasswordTitle"
    ]).then(function (translations) {
        angular.extend($scope, {
            translatedData: {
                setUpAccountTitle: translations.setUpAccountTitleNpdi,
                completeRegistrationInfo: translations.completeRegistrationInfo,
                contactInformationTitle: translations.contactInformationTitle,
                usernamePasswordTitle: translations.usernamePasswordTitle
            }
        });
    });
};

npdiAccountSetupController.$inject = [
    "$q",
    "$rootScope",
    "$scope",
    "$translate",
    "PersonalContactInfoCustomValidator",
    "PersonalContactInfoUpdateUtil",
    "eventBus",
    "npdiFactory",
    "redirectService",
    "npdiRegistrationService"
];
export default npdiAccountSetupController;
