import React, { useState, useReducer, useCallback } from "react";

import {
    authenticateLite,
    authenticatePart,
    onAuthenticateSuccess,
    onAuthenticateFailure
} from "../helpers/authenticate";
import LoginForm from "../LoginForm";
import useAuthenticateReducer, { initialState } from "../useAuthenticateReducer";

// We should be able to use the iframes window context to determine the login domain: ex: "proj10.retirementpartner.com"
const LOGIN_DOMAIN = window.location.origin || "https://participant.empower-retirement.com";

export interface LoginFormContainerNativeProps {
    accuCodeIn: string | null;
    fromIn: string;
    isLoginPartIn: boolean | null | undefined;
}

/**
 * Login Container for react only platform
 *    Also used by drupal iframe login page
 * @param param0
 * @returns
 */
const LoginFormContainerNative = ({
    accuCodeIn,
    fromIn,
    isLoginPartIn
}: LoginFormContainerNativeProps) => {
    const [accuCode, setAccuCode] = useState(accuCodeIn || "");
    const [loggingInState, setLoggingInState] = useState(false);
    const [showLogin, setShowLogin] = useState(true);

    const [authentication, dispatch] = useReducer(useAuthenticateReducer, initialState);

    const getParamValue = () => {
        const isIframeLandingPage = window.location.href.indexOf("iframe") > -1;

        if (!isIframeLandingPage) {
            //      window.location.reload();
        }
        const paramName = "deeplink";
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const deeplinkParam = urlParams.get(paramName);
        return deeplinkParam || "";
    };

    const registerButtonClick = () => {
        setShowLogin(false);
    };

    const openAccountClick = () => {
        setShowLogin(false);
        const href = "/participant/#/articles/MYERIRA/openAnAccount";
        window.location.assign(href);
    };

    /**
     * on click submit
     */
    const handleAuthenticate = useCallback(
        async ({ username, password }) => {
            try {
                const deeplinkParam = getParamValue();

                const authData = isLoginPartIn
                    ? await authenticatePart({
                          accuCode,
                          deeplinkParam,
                          username,
                          password,
                          setLoggingInState,
                          dispatch
                      })
                    : await authenticateLite({
                          accuCode,
                          deeplinkParam,
                          username,
                          password,
                          setLoggingInState,
                          dispatch
                      });

                let href = await onAuthenticateSuccess({
                    accuCode,
                    authData,
                    dispatch,
                    setLoggingInState,
                    setAccuCode
                });

                let appendIframe = "";
                //Redirect to where it needs to go
                if (fromIn === "iframe") {
                    if (href.indexOf("reactindex.html") > -1) {
                        href = href.replace("reactindex.html", "");
                    }
                    appendIframe = "iframe=true";
                }

                let redirectUrl =
                    deeplinkParam && deeplinkParam !== ""
                        ? href + "?noScope=true&deepLinkParam=" + deeplinkParam
                        : href;

                if (fromIn === "iframe") {
                    if (deeplinkParam && deeplinkParam !== "") {
                        redirectUrl = redirectUrl + "&" + appendIframe;
                    } else {
                        redirectUrl = redirectUrl + "?" + appendIframe;
                    }
                }

                //  Next line used for testing locally
                //  redirectUrl = "http://localhost:3005" + redirectUrl;

                window.location.assign(redirectUrl);
            } catch (error: any) {
                const href = onAuthenticateFailure({
                    error: error,
                    dispatch,
                    setLoggingInState,
                    setShowLogin,
                    loginDomain: LOGIN_DOMAIN
                });

                if (href) {
                    //Redirect to where it needs to go
                    window.location.assign(href);
                    //window.top.location.href = href;
                }
            }
        },
        [accuCode, fromIn, isLoginPartIn]
    );

    const updateStyleClass = {
        color: "white"
    };

    return (
        <div data-testid="login-container">
            <h1 style={updateStyleClass}>Test Landing Login page</h1>

            <LoginForm
                accu={accuCode}
                authentication={authentication}
                authenticateInput={handleAuthenticate}
                loggingIn={loggingInState}
                showLoginUpd={showLogin}
                errorCode={authentication.errorCode}
                errorMessageParams={authentication.errorMessageParams}
                registerButtonClickInput={registerButtonClick}
                openAccountClickInput={openAccountClick}
                loginDomain={LOGIN_DOMAIN}
                from={fromIn}
            />
        </div>
    );
};

export default LoginFormContainerNative;
