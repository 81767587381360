const ErrorViewTemplate = `
<div class="modal-header">
    <h4 class="modal-title">Error</h4>
</div>
<div class="modal-body">
    <p ng-bind-html="errorMessage"/>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary pull-right" ng-click="$close(result)">Ok</button>
</div>
`;
export default ErrorViewTemplate;
