/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";

const Input = (props, ref) => {
    const {
        name,
        data,
        label,
        errors,
        placeholder,
        validations,
        size,
        type,
        legend,
        handleChange,
        handleValidation,
        msg,
        handleFocus,
        onKeyDown
    } = props;
    return (
        <div className={["form-group", errors[name] && "has-error", size].join(" ")}>
            <label
                htmlFor={name}
                className="control-label"
                aria-describedby={name == "phone" ? "phone-preffix" : `desc-${name}`}
            >
                {label}
                <span id={`desc-${name}`} className="dob-legend">
                    {legend}
                </span>
            </label>
            {name == "phone" ? (
                <div>
                    <div className="col-xs-2">
                        <span id="phone-preffix" className="phone-preffix">
                            +1
                        </span>
                    </div>
                    <div className="col-xs-10 col-phone-number">
                        <input
                            type={type}
                            className="form-control"
                            id={name}
                            placeholder={placeholder}
                            onFocus={handleFocus || null}
                            onChange={(e) => handleChange(e, ref.current)}
                            onBlur={(e) => handleValidation(e, validations[name], ref)}
                            value={data[name]}
                            onKeyDown={onKeyDown}
                            name={name}
                            aria-required={true}
                            ref={ref}
                            aria-invalid={errors[name]}
                            aria-describedby={errors[name] && `${name}Msg`}
                        />
                        {errors[name] && (
                            <div className="messages">
                                <p aria-live="assertive" className="msg" id={`${name}Msg`}>
                                    {msg[name][errors[name]]}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    <input
                        type={type}
                        className="form-control"
                        id={name}
                        placeholder={placeholder}
                        name={name}
                        onFocus={handleFocus || null}
                        onChange={(e) => handleChange(e, ref.current)}
                        onBlur={(e) => handleValidation(e, validations[name])}
                        value={data[name]}
                        onKeyDown={onKeyDown}
                        ref={ref}
                        aria-required={validations[name].required && true}
                        aria-invalid={errors[name]}
                        aria-describedby={errors[name] && `${name}Msg`}
                    />
                    {errors[name] && (
                        <div className="messages">
                            <p aria-live="assertive" className="msg" id={`${name}Msg`}>
                                {name == "zip"
                                    ? msg[
                                          data.country == "US" || data.country == ""
                                              ? name
                                              : "zipCanada"
                                      ][errors[name]]
                                    : msg[name][errors[name]]}
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

// eslint-disable-next-line react/display-name
export const Select = forwardRef((props, ref) => {
    const {
        name,
        options,
        label,
        legend,
        size,
        errors,
        validations,
        handleChange,
        handleValidation,
        msg
    } = props;
    return (
        <div className={["form-group", errors[name] && "has-error", size].join(" ")}>
            <label htmlFor={name} className="control-label">
                {label}
                <span>{legend}</span>
            </label>
            <div className="custom-select form-control">
                <select
                    name={name}
                    id={name}
                    ref={ref}
                    className="form-control"
                    defaultValue=""
                    onBlur={(e) => handleValidation(e, validations[name])}
                    onChange={handleChange}
                    aria-required={true}
                    aria-invalid={errors[name]}
                    aria-describedby={errors[name] && `${name}Msg`}
                >
                    <option key="blank" disabled value=""></option>
                    {options &&
                        options.map((op) => (
                            <option key={op.code} value={op.code}>
                                {op.name}
                            </option>
                        ))}
                </select>
            </div>
            {errors[name] && (
                <div className="messages">
                    <p aria-live="assertive" className="msg" id={`${name}Msg`}>
                        {msg[name][errors[name]]}
                    </p>
                </div>
            )}
        </div>
    );
});

export default forwardRef(Input);
