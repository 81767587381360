import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

import NpdiLinkAccountsEvents from "./events/NpdiLinkAccountsEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const LinkedAccountStatus = {
    LINKED: true,
    UNLINKED: false
};

const NpdiLinkAccountsController = function (eventBus, $state, $translate, npdiFactory) {
    const vm = this;
    const logger = ExternalLogger.getInstance("npdiLinkAccountsController");

    // Process content from ui-style-guide
    vm.content = {
        title: $translate.instant("linkAccounts.title"),
        instructions: $translate.instant("linkAccounts.instructions"),
        labelUsername: $translate.instant("linkAccounts.labelUsername"),
        labelAccounts: $translate.instant("linkAccounts.labelAccounts"),
        buttonContinue: $translate.instant("linkAccounts.buttonContinue"),
        linkLogIn: $translate.instant("linkAccounts.linkLogIn"),
        linkLoginForMinnesota: $translate.instant("linkAccounts.login.link"),
        msgSuccess: "" // Requires interpolation and will be calculated when the data is available
    };

    // Model data to display in the view
    vm.model = {
        username: "",
        plans: [],
        status: LinkedAccountStatus.UNLINKED
    };

    vm.linkerror = { code: "" };

    /**
     * The request for the participant's plans was successful.
     *
     * @param  {Object} data The NPDI account list data
     */
    vm.onRetrievePlansSuccess = function (data) {
        // Validate username
        if (!data.userName || data.userName.length === 0) {
            logger.error("Username not found for NPDI account linking view");
        } else {
            vm.model.username = data.userName;
        }

        // Validate plans
        if (!data.plans || data.plans.length === 0) {
            logger.error("No plans found for NPDI account linking view");
        } else {
            vm.model.plans = data.plans;
        }
    };

    /**
     * The request for the participant's plans failed.
     *
     * @param {Object} error The XHR error
     */
    vm.onRetrievePlansError = function (error) {
        logger.error("Failed to retrieve NPDI linked plans: ", error);
        $state.go("genericError");
    };

    /**
     * Initilize the view. This function is triggered by the form in the view via `data-ng-init`.
     */
    vm.init = function () {
        npdiFactory.retrievePlansToLink.query(
            {},
            vm.onRetrievePlansSuccess,
            vm.onRetrievePlansError
        );
    };

    vm.getSuccessMessage = function () {
        return $translate.instant("linkAccounts.msgSuccess", { username: vm.model.username });
    };

    /**
     * The request to link accounts was successful. Update the status and display the success message
     */
    vm.submitAccountLinkRequestSuccess = function () {
        vm.model.status = LinkedAccountStatus.LINKED;
        vm.content.msgSuccess = vm.getSuccessMessage();
    };

    /**
     * The request to link accounts failed.
     *
     * @param {Object} error The XHR error
     */
    vm.submitAccountLinkRequestError = function (error) {
        logger.error("Failed to submit request to link NPDI accounts: ", error);
        vm.linkerror.code = error.data.error.code;
        if (error?.data?.status == "FAIL") {
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                event_action: "npdi link accounts - updNPDIPPTEnroll.query failed",
                event_properties: {
                    login_error_code: error.data.error.code,
                    error_message: error.data.error.errors[0]
                }
            });
        }
    };

    /**
     * Submit the request to link accounts.
     */
    vm.submit = function () {
        eventBus.dispatch(NpdiLinkAccountsEvents.CONTINUE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: NpdiLinkAccountsEvents.CONTINUE
            }
        });

        npdiFactory.updNPDIPPTEnroll.query(
            {},
            vm.submitAccountLinkRequestSuccess,
            vm.submitAccountLinkRequestError
        );
    };

    //
    // Convenience methods for the view

    vm.showUsername = function () {
        return vm.model.username.length > 0;
    };

    vm.showAccounts = function () {
        return vm.model.plans.length > 0;
    };

    vm.showLinkAccountsSection = function () {
        return vm.model.status === LinkedAccountStatus.UNLINKED;
    };

    vm.showSuccessMessage = function () {
        return vm.model.status === LinkedAccountStatus.LINKED;
    };
};

NpdiLinkAccountsController.$inject = ["eventBus", "$state", "$translate", "npdiFactory"];
export default NpdiLinkAccountsController;
