import "angular";
import "angular-messages";
import "angular-resource";
import "angular-ui-router";
import MaskDirective from "core-ui/client/src/app/MaskDirective";
import PhoneNumberDirective from "core-ui/client/src/app/PhoneNumberDirective";

import PreLoginAccuRetrievalService from "../authentication/PreLoginAccuRetrievalService";
import PreLoginContactInfoService from "../authentication/PreLoginContactInfoService";
import ReSetACCUService from "../authentication/ReSetACCUService";
import ContactDetailsFactory from "../core/ContactDetailsFactory";
// import validationDirectives from "../core/ValidationDirectives";
import StateInterceptor from "../core/StateInterceptor";

import accountSetupController from "./AccountSetupController";
import accountSetupFactory from "./AccountSetupFactory";
import accountSetupRoute from "./AccountSetupRoute";

// eslint-disable-next-line no-undef
const accountSetup = angular.module("accountSetup", [
    "ngMessages",
    "ngResource",
    "ui.router",
    "validationDirectives",
    MaskDirective.name,
    PhoneNumberDirective.name
]);

accountSetup.factory("StateInterceptor", StateInterceptor);

accountSetup.config([
    "$httpProvider",
    function ($httpProvider) {
        $httpProvider.interceptors.push("StateInterceptor");
    }
]);

accountSetup.controller("accountSetupController", accountSetupController);
accountSetup.factory("accountSetupFactory", accountSetupFactory);
accountSetup.factory("ContactDetailsFactory", ContactDetailsFactory);
accountSetup.factory("PreLoginAccuRetrievalService", PreLoginAccuRetrievalService);
accountSetup.factory("PreLoginContactInfoService", PreLoginContactInfoService);
accountSetup.factory("ReSetACCUService", ReSetACCUService);

accountSetup.config(accountSetupRoute);

export default accountSetup;
