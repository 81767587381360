const IndIdSelectionFactory = function ($resource) {
    const retrieveAllPlansWithIndid =
        "/participant-web-services/rest/partialauth/userInfo/retrieveAllPlansWithIndid";
    const retrievePlansWithIndid =
        "/participant-web-services/rest/partialauth/userInfo/retrievePlansWithIndid";
    const selectIndId = "/participant-web-services/rest/indId/select/:individualId";

    return {
        retrieveAllPlansWithIndid: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrieveAllPlansWithIndid,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        retrievePlansWithIndid: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrievePlansWithIndid,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        selectIndId: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + selectIndId,
            {
                individualId: "@individualId"
            },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

IndIdSelectionFactory.$inject = ["$resource"];
export default IndIdSelectionFactory;
