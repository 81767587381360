import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../services/translationService";

export default class LinkablePlansComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            total: 0
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    handleLinkAccountsFlow = () => {
        //React function, executing an angular external function.
        this.props.openLinkablePlansPage();
    };

    render() {
        if (!this.state.translations) {
            return null;
        }

        return (
            <div className="profile-information work-information ">
                <h2>{this.state.translations.profile.linkableplans.header}</h2>

                <br />

                <div className="row">
                    <span className="col-sm-4 text-right">
                        {this.state.translations.profile.linkableplans.headerSecondary}
                    </span>

                    <div className="col-sm-4 profileLinkablePlanId">
                        <button
                            className="btn-link btn-link-oneid"
                            onClick={this.handleLinkAccountsFlow}
                        >
                            {this.state.translations.profile.linkableplans.ConnectAcctsLabel}
                        </button>
                    </div>
                </div>
                <hr />
            </div>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

LinkablePlansComponent.propTypes = {
    profileInfo: PropTypes.object.isRequired,
    openLinkablePlansPage: PropTypes.func.isRequired
};
