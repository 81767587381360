import registrationPlanIDMessagesTemplate from "./messages/registrationPlanIDMessagesTemplate";
import registrationUniqueIdentifierMessagesTemplate from "./messages/registrationUniqueIdentifierMessagesTemplate";
import registrationErrorMessagesTemplate from "./registrationErrorMessagesTemplate";
import registrationFormSubmitTemplate from "./registrationFormSubmitTemplate";
import registrationFormTemplate from "./registrationFormTemplate";

const registrationAndResetTemplate = `
<div class="form-group has-error" ng-if="autherizationMessage" id="screenReaderAuth" tabindex="0" role="alert" aria-live="assertive">
    <span class="help-block">{{autherizationMessage | translate:autherizationMessageParams}}</span>
</div>



<!-- Screen Reader Error Block -->
<div ng-if="registerForm.$submitted && registerForm.$invalid">
    ${registrationErrorMessagesTemplate}
</div>

<div class="panel-group" ng-if="!accuCustomization.showLoginSetupRecoveryRadio">
    ${registrationFormTemplate}
    ${registrationFormSubmitTemplate}
</div>

<div ng-if="accuCustomization.showLoginSetupRecoveryRadio" class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
    <div class="form-group" ng-class="getFormGroupClass('uniqueIdentifier');">
        <label for="uniqueIdentifierInput" class="control-label">Unique Identifier</label>
        <input type="text" name="uniqueIdentifier" autocomplete="off" id="uniqueIdentifierInput"
            class="form-control col-5" inputmode="numeric" ng-model="individual.uniqueIdentifier"
            data-ng-required="areFieldsRequired.uniqueidFields"
            maxlength="10" reg-ex-input reg-ex="^[0-9]+$" ng-focus="setInputFocus($event)"
            title="Unique identifier must be numeric" ng-blur="removeInputFocus($event);uniqueIdentifierChange();" />
        ${registrationUniqueIdentifierMessagesTemplate}
        <span class="help-block">{{uniqueIdentifierError}}</span>
    </div>
    <div class="form-group" ng-class="getFormGroupClass('planID');">
        <label for="planIDInput" class="control-label">Plan ID</label>
        <input type="text" name="planID" autocomplete="off" id="planIDInput" class="form-control col-5"
            inputmode="numeric" ng-model="individual.planID" data-ng-required="areFieldsRequired.uniqueidFields"
            maxlength="12" reg-ex-input reg-ex="^[0-9]+$" ng-focus="setInputFocus($event)"
            title="Plan ID must be numeric" ng-blur="removeInputFocus($event);planIDChange();" />
        ${registrationPlanIDMessagesTemplate}
        <span class="help-block">{{planIDError}}</span>
    </div>


    <div class="form-group" ng-class="getFormGroupClass('uniqueidDateOfBirth');">
        <label for="uniqueidDateOfBirth" class="control-label">{{"individual.dateOfBirth" | translate}}&nbsp;
            <span class="label-format" aria-hidden="true">MM/DD/YYYY</span>
        </label>
        <pw-beneficiary-date date-id="uniqueidDateOfBirth" date-name="uniqueidDateOfBirth"
            date-title="Format two digit month, two digit day and four digit year"
            date-model="individual.uniqueidDateOfBirth" is-required="areFieldsRequired.uniqueidFields" is-disabled="false"
            date-focus="touchDateOfBirth($event);toggleDateOfBirthFromClick($event);setInputFocus($event)"
            date-blur="handleMaskDateOfBirth($event);removeInputFocus($event);dobChange()" dob-change="dobChange()"
            min-date="getMinimumDateOfBirth()" ui-options="{clearOnBlur:false, allowInvalidValue: true}"
            allow-future-date="true" mask-date="true"
            ng-keydown="toggleDOBfromKeyDown($event)"></pw-beneficiary-date>

            <ng-messages 
                for="getFieldError('uniqueidDateOfBirth');" 
                ng-show="displayFieldMessages('uniqueidDateOfBirth',focusMgr['uniqueidDateOfBirth']);"
                multiple 
                data-ng-class="getValidationErrorClass('uniqueidDateOfBirth');"
            >
                <ng-message when="required">
                    {{ 'dateOfBirthRequired' | translate }}
                </ng-message>
                <ng-message when="valid || pattern">
                    {{ 'dateOfBirthInvalid' | translate }}
                </ng-message>
                <ng-message when="minYear">
                    {{ 'dateOfBirthYearOutOfRange' | translate }}
                </ng-message>
                <ng-message when="futureDateNotAllowed">
                    {{ 'dateOfBirthFutureDateNotAllowed' | translate }}
                </ng-message>
            </ng-messages>
        <span class="help-block">{{uniqueidDateOfBirthError}}</span>
    </div>
    ${registrationFormSubmitTemplate}
</div>
`;
export default registrationAndResetTemplate;
