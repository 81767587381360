import React from "react";
import { useTranslations } from "../../queries";

interface SubmitButtonProps {
    isRegistrationFlow: boolean;
    showSpinner: boolean;
}

interface SubmitButton {
    button: {
        continue: string;
        register: string;
    };
}

const SubmitButton = ({ showSpinner, isRegistrationFlow }: SubmitButtonProps) => {
    const { button }: SubmitButton = useTranslations();
    const buttonText = isRegistrationFlow ? button.register : button.continue;

    return (
        <button
            type="submit"
            id="submit"
            className={`btn btn-primary btn-block btn-lg margin-top-default ${
                showSpinner ? "is-disabled" : ""
            }`}
        >
            {!showSpinner ? (
                <span>{buttonText}</span>
            ) : (
                <span>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    Processing ...
                </span>
            )}
        </button>
    );
};

export default SubmitButton;
