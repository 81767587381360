import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { get as _get } from "lodash";

// in case the fault it thrown before the translations are retrieved, or there is an error in the api
// call to get translations, we default to a standard error message.
export const DEFAULT_MESSAGE =
    "An error has occurred. Please contact a Participant Services Representative if the problem persists.";
export const FAIL = "FAIL";
export const UNKNOWN = "Unknown";

const logErrorObj = (errorObj, faultLabel = "Fault") => {
    const logger = ExternalLogger.getInstance("errorUtils");
    const message = errorObj
        ? `Failed to execute ${errorObj.method} request to ${errorObj.url}. Response code: ${errorObj.httpCode}. Status: ${errorObj.status}. Code: ${errorObj.code}. ${errorObj.errorMessage}`
        : "Error is undefined";

    logger.error("{0}( fault = {1} )", [faultLabel, message]);
};

export const handleFault = (fault, faultLabel = "Fault") => {
    if (_get(fault, "config.data")) {
        fault.requestData = JSON.stringify(fault.config.data);
    }

    logErrorObj(fault, faultLabel);

    return fault;
};
