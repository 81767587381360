const accountRecoveryConfirmTemplate = `
    <pwr-account-recovery-confirmation />
`;
// <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">

//     <header class="contained-form-header">
//         <h1>
//             {{ ::translatedData.loginHelp }}
//         </h1>
//     </header>

//     <div class="inner-container with-padding with-shadow">

//         <form role="form" id="accountRecoveryConfirmForm" name="accountRecoveryConfirmForm" novalidate autocomplete="off">

//             <!-- username disabled-->
//             <div class="form-group">
//                 <label for="username">{{ ::translatedData.username }}</label>
//                 <input  type="text"
//                         class="form-control
//                 form-disabled"
//                         id="username"
//                         name="username"
//                         data-ng-model="userInfo.username"
//                         readonly/>
//             </div><!--//form-group//-->

//             <div>
//                 <div><strong>{{ ::translatedData.confirmationHeader }}</strong></div>
//                 <div>{{ ::translatedData.confirmationContent }}</div>
//                 <br>
//                 <p><a ui-sref="home" ng-click="confirmClick()">{{ ::translatedData.confirmationLink }}</a></p>
//             </div>

//         </form>
//     </div><!--/inner-container with-padding with-shadow/-->
// </div>

export default accountRecoveryConfirmTemplate;
