import accountSelectionTemplate from "./accountSelectionTemplate";

const accountSelectionRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/account-selection");

    $stateProvider

        // Account Selection View =================================
        .state("accountSelection", {
            url: "/account-selection",
            template: accountSelectionTemplate,
            controller: "accountSelectionController",
            data: {
                fullHeight: true,
                title: "Account Selection"
            }
        });
};

accountSelectionRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default accountSelectionRoute;
