import dateUtil from "core-ui/client/src/app/DateUtil";

const isFutureDate = (val, allowFutureDate) => {
    let valid = true;

    if (!allowFutureDate) {
        // Pass through until we get a complete date
        const mmddYYYY = val;

        if (mmddYYYY) {
            // The date is valid if it is not after today
            valid = !dateUtil.isAfter(
                dateUtil.getGenericFormat(mmddYYYY, "MMDDYYYY"),
                dateUtil.getDate()
            );
        }
    }

    return valid;
};

const isBeforeDate = (val) => {
    let valid = true;
    const min = dateUtil.getMinimumDateOfBirth();

    // Pass through until we get a complete date
    const mmddYYYY = val;
    if (mmddYYYY !== null) {
        // The date is valid if it is on or after the minimum date
        valid = dateUtil.isBefore(min, dateUtil.getDateFormatted("YYYY-MM-DD", val));
    }

    return valid;
};

const validDate = (val, allowEmpty) => {
    let valid = true;

    if (val === undefined || (val === "" && allowEmpty)) {
        return true;
    }
    if (val === undefined || val === "") {
        return false;
    }

    const dateValue = val.replace("/", "").replace("/", "");

    if (dateValue.length !== 8) {
        valid = false;
        return valid;
    }

    // Check the validity
    if (dateValue.length > 0) {
        const date = dateUtil.getDateFormatted("MMDDYYYY", val);
        if (dateUtil.isValid(date, "MMDDYYYY", true)) {
            valid = true;
        }
    } else {
        valid = false;
    }

    return valid;
};

export { isBeforeDate, isFutureDate, validDate };
