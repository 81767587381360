const indIdSelectionTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection">
  <header class="contained-form-header margin-top-default">
    <h1>
      {{ ::translatedData.indIdSelectionTitle }}
    </h1>
    <p>{{ ::translatedData.indIdSelectionIntro }}</p>
  </header>

  <div id="statementsDocs-spinner" class="loaderBackground just-element" ng-if="IndIdSelectionController.loading">
    <div class="loader"></div>
  </div>
  <div ng-if="plansMapWithIndId" class="inner-container with-padding with-shadow clearfix">
    <div data-ng-repeat="(key, value) in plansMapWithIndId" class="clearfix">

      <div class="row">
        <div>
          <div class="col-sm-9 col-md-9 col-lg-9">
            <div data-ng-repeat="planWithIndId in value">
              {{planWithIndId.planName}}
            </div>
          </div>

          <div class="col-sm-3 col-md-3 col-lg-3">
            <a class="btn btn-primary" ng-disabled="IndIdSelectionController.disableSelectButton()"
              ng-click="IndIdSelectionController.selectIndId(key)">{{ ::translatedData.buttonView }}</a>
          </div>
        </div>
      </div>

      <hr />
    </div>
  </div>
</div>
`;
export default indIdSelectionTemplate;
