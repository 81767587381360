import React, { createContext, useContext, useMemo, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface QueryParamsContextType {
    queryParams: Record<string, string>;
    setQueryParam: (key: string, value: string | null) => void;
}

const QueryParamContext = createContext<QueryParamsContextType | undefined>(undefined);

interface QueryParamProviderProps {
    children: ReactNode;
}

const IS_REACT = "true";

export const QueryParamProvider: React.FC<QueryParamProviderProps> = ({ children }) => {
    // Get the current location object from the router
    const location = useLocation();
    // Get the navigate function to programmatically navigate
    const navigate = useNavigate();

    // Memoize the query parameters to avoid unnecessary recalculations
    const queryParams = useMemo(() => {
        // Create a URLSearchParams object from the current location's search string
        const searchParams = new URLSearchParams(location.search);
        // Initialize an empty object to store the query parameters
        const params: Record<string, string> = {};
        // Iterate over each entry in the searchParams and add it to the params object
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        // Ensure the constant isReact parameter is always present
        if (!params.isReact) {
            params.isReact = IS_REACT;
            // Add the isReact parameter to the searchParams
            searchParams.set("isReact", IS_REACT);
            // Update the URL with the new searchParams without adding a new entry to the history
            navigate({ search: searchParams.toString() }, { replace: true });
        }
        return params;
    }, [location.search, navigate]);

    // Function to set a query parameter
    const setQueryParam = (key: string, value: string | null) => {
        // Create a URLSearchParams object from the current location's search string
        const searchParams = new URLSearchParams(location.search);
        if (value) {
            // If a value is provided, set the query parameter
            searchParams.set(key, value);
        } else {
            // If no value is provided, delete the query parameter
            searchParams.delete(key);
        }
        // Ensure the isReact parameter is always present
        searchParams.set("isReact", IS_REACT);
        // Update the URL with the new searchParams
        navigate({ search: searchParams.toString() });
    };

    return (
        // Provide the queryParams and setQueryParam function to the context
        <QueryParamContext.Provider value={{ queryParams, setQueryParam }}>
            {children}
        </QueryParamContext.Provider>
    );
};

// Custom hook to use the query parameters context
export const useQueryParams = (): QueryParamsContextType => {
    const context = useContext(QueryParamContext);
    if (!context) {
        throw new Error("useQueryParams must be used within a QueryParamProvider");
    }
    return context;
};
