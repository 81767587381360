import "angular";
import "angular-resource";
import "angular-ui-router";
import profileFactory from "../profile/ProfileFactory.js";

import EConsentController from "./EConsentController.js";
import EConsentFactory from "./EConsentFactory.js";
import EConsentModalController from "./EConsentModalController.js";
import eConsentRoute from "./EConsentRoute.js";

// eslint-disable-next-line no-undef
const eConsent = angular.module("eConsent", ["ngMessages", "ngResource", "ui.router"]);

eConsent.controller("EConsentController", EConsentController);
eConsent.controller("EConsentModalController", EConsentModalController);
eConsent.factory("EConsentFactory", EConsentFactory);
eConsent.factory("profileFactory", profileFactory);
eConsent.config(eConsentRoute);
export default eConsent;
