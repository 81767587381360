const registrationDateOfBirthMessagesTemplate = `
<ng-messages 
    for="getFieldError('dateOfBirth');" 
    ng-show="displayFieldMessages('dateOfBirth',focusMgr['dateOfBirth']);"
    multiple 
    data-ng-class="getValidationErrorClass('dateOfBirth');"
>
    <ng-message when="required">
        {{ 'dateOfBirthRequired' | translate }}
    </ng-message>
    <ng-message when="valid || pattern">
        {{ 'dateOfBirthInvalid' | translate }}
    </ng-message>
    <ng-message when="minYear">
        {{ 'dateOfBirthYearOutOfRange' | translate }}
    </ng-message>
    <ng-message when="futureDateNotAllowed">
        {{ 'dateOfBirthFutureDateNotAllowed' | translate }}
    </ng-message>
</ng-messages>
`;
export default registrationDateOfBirthMessagesTemplate;
