const idProofErrorTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
  <header class="contained-form-header margin-top-default">
    <h1 translate="unableToContinue"></h1>
  </header>

  <div class="inner-container with-padding with-shadow">
    <p ng-bind-html="idProofErrorCode | translate:{phone:phone}" translate-default="{{defaultErrorCode}}"
      translate-values={phone:phone}>
    </p>
  </div>
</div>
`;
export default idProofErrorTemplate;
