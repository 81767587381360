/* eslint-disable */
var registrationFactory = function ($resource) {
    var registerWithoutPinAPi = "rest/nonauth/registration/registrationWithoutPin";
    var registerWithPinAPi = "rest/nonauth/registration/registrationWithPin";
    var idProofPinAPi = "rest/nonauth/registration/idProofingWithPin";

    var registrationSummaryApi = "/participant-web-services/rest/partialauth/registrationSummary";
    var registrationflowAndDestinationURL =
        "/participant-web-services/rest/partialauth/registrationSummary/destination";
    var validateNPDIDataApi = "rest/nonauth/npdi/validateGaIDAndNPDIPin";

    return {
        registerWithoutPin: $resource(
            __authenticationUrl__ + registerWithoutPinAPi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        ),
        registrationSummary: $resource(
            __baseApiUrl__ + registrationSummaryApi,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        registrationflowAndDestination: $resource(
            __baseApiUrl__ + registrationflowAndDestinationURL,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        registerWithPin: $resource(
            __authenticationUrl__ + registerWithPinAPi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        ),

        idProofPinAPi: $resource(
            __authenticationUrl__ + idProofPinAPi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        ),

        validateNPDIData: $resource(
            __authenticationUrl__ + validateNPDIDataApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        )
    };
};

registrationFactory.$inject = ["$resource"];
export default registrationFactory;
