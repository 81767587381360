import React from "react";

import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    const updateStyleClass = {
        color: "white"
    };

    return (
        <div id="error-page">
            <h1 style={updateStyleClass}>Oops!</h1>
            <p style={updateStyleClass}>Sorry, an unexpected error has occurred.</p>
            <p>
                <i style={updateStyleClass}>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}
