const root = {
    LOGIN: "login."
};

const EventBusEvents = {
    BULLETIN_NEXT: root + "bulletin_modal_next_button_click_event",
    BULLETIN_DISMISS: root + "bulletin_modal_dismiss_event",
    ERROR_CODE: root.LOGIN + "error_code",
    FORGOT_USERNAME_PASSWORD: root.LOGIN + "forgot_username_password_clicked",
    HERO_BANNER_CLICKED: "hero_banner_clicked",
    ONETRUST_ACCEPT_COOKIES: "OneTrust_accept_cookies_button_clicked",
    ONETRUST_DO_NOT_SELL_SHARE_INFO:
        "OneTrust_do_not_sell_or_share_my_personal_information_link_clicked",
    ONETRUST_CLOSE_PROMPT_CLICKED: "OneTrust_X_to_close_prompt_clicked",
    OPEN_NEW_ACCOUNT: "preLogin.openNewAccount.click",
    PRELOGIN_BANNER_LOWER_TILES_CLICKED: "prelogin_banner_lower_tiles_clicked",
    REGISTER: root.LOGIN + "register_button_clicked_event",
    SHOW_HIDE_PWD: root.LOGIN + "show_hide_password_button_clicked_event",
    SUBMIT: root.LOGIN + "signin_button_clicked_event",
    VERIFICATION_ERROR_CODE: root.LOGIN + "verify_error_code"
};

export default EventBusEvents;
