import { sentryLoginAppStartMetrics } from "../../utils/sentryMetricsLogging";

const InboundSSOLandingController = function (
    $scope,
    $state,
    $window,
    InboundSSOAuthFactory,
    redirectService,
    ContactDetailsFactory
) {
    $scope.loading = true;
    $scope.contactInfoUpdateRequired = false;

    // Start the app start metrics for the SSO flow
    sentryLoginAppStartMetrics();

    $scope.loadContactInfo = function () {
        ContactDetailsFactory.retrieveContactDetails.query(
            {},
            function (data) {
                console.log("   Contact info retrived" + data);
                if (data !== null) {
                    $scope.contactInfoUpdateRequired = data.contactInfoUpdateRequired;
                }
            },
            function (error) {
                if (error.data.status == "FAIL") {
                    console.log("Error while fetching contact info");
                }
            }
        );
    };

    $scope.ssoData = InboundSSOAuthFactory.query({});
    console.log("ssotype parameter not found continuig regular log in flow");
    $scope.ssoData.$promise.then(
        function (result) {
            $scope.loading = false;
            console.log("contactInfoUpdateRequired-state:" + $scope.ssoData.state);
            if ($scope.ssoData.state === "UPDATE_CONTACT") {
                $state.go("contactUpdate");
            } else {
                redirectService.redirect(result, "ALL", "SSO");
            }
        },
        function (response) {
            $scope.loading = false;
            $window.location.href =
                "/participant/#/login" + "?accu=" + $scope.accu + response.data.error.code;
        }
    );
};

InboundSSOLandingController.$inject = [
    "$scope",
    "$state",
    "$window",
    "InboundSSOAuthFactory",
    "redirectService",
    "ContactDetailsFactory"
];
export default InboundSSOLandingController;
