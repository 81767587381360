import "angular";
import "angular-resource";
import "angular-ui-router";
import "core-ui/client/src/app/SocialSecurityNumberDirective";
import "core-ui/client/src/app/ZIPCodeDirective";
import "core-ui/client/src/app/DateDirective";
import "core-ui/client/src/app/PrintDirective";
import "angular-input-masks";
import npdiAccountSetupController from "./NpdiAccountSetupController";
import npdiFactory from "./NpdiFactory";
import npdiLinkAccountsController from "./NpdiLinkAccountsController";
import npdiRegistrationController from "./NpdiRegistrationController";
import npdiRoute from "./NpdiRoute";

// eslint-disable-next-line no-undef
const npdi = angular.module("npdi", [
    "ngMessages",
    "ngResource",
    "ui.router",
    "validationDirectives",
    "FullHeight",
    "ZIPCodeDirective",
    "DateDirective",
    "SocialSecurityNumberDirective",
    "PrintDirective",
    "ui.utils.masks"
]);

npdi.controller("npdiRegistrationController", npdiRegistrationController);
npdi.controller("npdiAccountSetupController", npdiAccountSetupController);
npdi.controller("npdiLinkAccountsController", npdiLinkAccountsController);
npdi.factory("npdiFactory", npdiFactory);
npdi.config(npdiRoute);

export default npdi;
