const accountRecoveryMultipleUsrsTemplate = `
    <pwr-account-recovery-multiple-user />
`;
export default accountRecoveryMultipleUsrsTemplate;

// `
// <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
// 	<header class="contained-form-header margin-top-default">
//         <h1 data-ng-hide="passwordReset">
//         {{'resetflow.foundusernames' |translate }}
//         </h1>

//         <h1 data-ng-show="passwordReset">
//         {{'resetflow.resettitle' |translate }}
//         </h1>
//     </header>

//     <div class="inner-container with-padding with-shadow">
//         <div data-ng-show="resetStatus !== null" class="form-group has-error">
//             <span class="help-block">{{resetStatus | translate:resetStatusParams}}</span>
//         </div>

//         <!-- Screen Reader Error Block -->
//         <div ng-if="accountRecoveryForm.$submitted && accountRecoveryForm.$invalid">
//             ${accountRecoveryErrorMessagesTemplate}
//         </div>

//         <form role="form" id="accountRecoveryForm" name="accountRecoveryForm" novalidate autocomplete="off">

//             <!-- username disabled-->

// 			<div class="form-group" data-ng-repeat="usrNm in userInfo.usernames" data-ng-hide="passwordReset">
//                 <label for="acUsernameInput">{{ ::translatedData.username }}&nbsp;{{$index + 1}}</label>
//                 <input type="text" class="form-control form-disabled" title="{{ ::translatedData.username }}" id="acUsernameInput" name="usernameInput"
//                        data-ng-model="usrNm" readonly style="text-align:center"/>

//                 <div class="form-group" id="passwordReset" data-ng-show="pwdChangedUsrName!=null && pwdChangedUsrName=== usrNm" style="padding-top:10px">
//                         <p><i class="em-checkbox-icon"></i> {{'resetflow.passwordchanged' |translate }} </p>
//                 </div>

// 				<div class="form-group" id="passwordReset"  data-ng-hide="pwdChangedUsrName!=null && pwdChangedUsrName=== usrNm" style="padding-top:10px">
// 					<a ui-sref="accountRecoveryMultipleUsrs" class="reset-password-toggle" ng-click="passwordHelpClickMultipleUsrNmes(usrNm)">{{'resetflow.resetpwdLink' |translate }}</a>
// 				</div>
//             </div>
//             <!--//form-group//-->

//             <!-- I need help with my password too link-->

//             <!-- password using validationDirectives-->
//             <div class="form-group" data-ng-class="fieldOnBlur('password');" data-ng-show="passwordReset">
//                 <div class="form-group" >
//                     <label for="acUsernameInput">{{ ::translatedData.username }}</label>
//                     <input type="text" class="form-control form-disabled" title="{{ ::translatedData.username }}" id="acUsernameInput" name="usernameInput"
//                     value="{{currentWorkingUsrName}}" readonly style="text-align:center"/>
//                 </div>
//                 <label for="acPasswordInput" class="control-label">
//                     {{ ::translatedData.password }}
//                 </label>
//                 <span class="sr-only" id="passwordRules">Must be eight to sixteen characters. Must include three of these four. {{ ::translatedData.passwordUppercasePattern }}.
//                     {{ ::translatedData.passwordLowercasePattern }}. {{ ::translatedData.passwordNumberPattern }}. {{ ::translatedData.passwordSpecialCharPattern }}. {{ ::translatedData.passwordNotMatchUsername }}.
//                 </span>
//                 <input
//                     type="password"
//                     id="acPasswordInput"
//                     class="form-control password-reset"
//                     name="password"
//                     data-ng-model="userInfo.password"
//                     maxlength="63"
//                     data-ng-required="true"
//                     data-password-validator
//                     data-compare-to-username="currentWorkingUsrName"
//                     ng-blur=" removeInputFocus('password');passwordBlur();"
//                     ng-focus="setInputFocus('password')"
//                     aria-describedby="passwordRules"
//                 />

//                 ${accountRecoveryPasswordMessagesTemplate}

//                 <p data-ng-if="getValidField('password');" class="success">
//                     {{ ::translatedData.passwordAcceptable }}
//                 </p>

//                 <span id="passwordError" class="help-block">{{ passwordError }}</span>
//             </div>
//             <!--//form-group//-->

//             <!-- Re-enter password using validationDirectives-->
//             <div class="form-group" data-ng-class="fieldOnBlur('confirmPassword');" data-ng-show="passwordReset">
//                 <label for="confirmPasswordInput" class="control-label">
//                     {{ ::translatedData.reEnterPassword }}
//                 </label>
//                 <span class="sr-only" id="confirmPasswordRules">{{ ::translatedData.confirmPasswordRequired }}. {{ ::translatedData.confirmPasswordNotMatch }} .
//                 </span>
//                 <input
//                     type="password"
//                     id="confirmPasswordInput"
//                     name="confirmPassword"
//                     maxlength="63"
//                     data-ng-model="userInfo.confirmPassword"
//                     data-ng-required="true"
//                     data-compare-to="userInfo.password"
//                     class="form-control"
//                     ng-focus="setInputFocus('confirmPassword')"
//                     ng-blur="removeInputFocus('confirmPassword');confirmPasswordBlur();"
//                     aria-describedby="confirmPasswordRules"
//                 />

//                 ${accountRecoveryConfirmPasswordMessagesTemplate}

//                 <span id="confirmPasswordError" class="help-block">{{confirmPasswordError}}</span>
//             </div>
//             <!--//form-group//-->
//             <!-- Continue to Account Recovery Confirmation screen. It will be dispalyed only if - "I need help with my password too" link is clicked-->

// 			<div class="row cta investment-button-row" data-ng-show="passwordReset">
//                 <a data-ui-sref="accountRecoveryMultipleUsrs" data-ui-sref-opts="{reload: true}">
//                     <button class="btn btn-link btn-no-style ng-binding">
//                         Cancel
//                     </button>
//                 </a>
//                     <button id="submitButton"
//                     type="submit"
//                     class="btn btn-primary pull-right"
//                     ng-click="resetAccount(userInfo,true)">
//                     {{ 'choosePrimaryPlan.continuebutton' | translate }}
//                 </button>
//             </div>
//             <!--//form-group//-->
//             <div class="row cta investment-button-row" data-ng-hide="passwordReset">
//                 <label for="acUsernameInput">{{ ::translatedData.username }}(s)</label>
//                 <input
//                     ng-if="userInfo.username"
//                     type="text"
//                     class="form-control form-disabled"
//                     title="{{ ::translatedData.username }}"
//                     id="acUsernameInput"
//                     name="usernameInput"
//                     data-ng-model="userInfo.username"
//                     readonly
//                 />
//                 <input
//                     ng-if="userInfo.usernames"
//                     type="text"
//                     class="form-control form-disabled"
//                     title="{{ ::translatedData.username }}"
//                     id="acUsernameInput"
//                     name="usernameInput"
//                     data-ng-model="usrNm in userInfo.usernames"
//                     readonly
//                 />
//                 <br/>
//                 <a data-ui-sref="home">
//                     <button id="submitButton"
//                         type="submit"
//                         class="btn btn-primary pull-right"
//                         ng-click="returnClick()">
//                         RETURN TO LOGIN
//                     </button>
//                 </a>
//             </div>
//         </form>
//     </div>
//     <!--/inner-container with-padding with-shadow/-->
// </div>
// `;
