import React, { useState, useEffect } from "react";

import {
    getOAuthConsentURL,
    rejectOauthConsent,
    acceptOauthConsent
} from "../../../services/oauth/OAuthService";
import { useTranslations } from "../../../queries";
import { QUERY_KEYS } from "../../../queries/constants";
import { useQuery } from "@tanstack/react-query";
import {
    deleteCookie,
    getCookie,
    saveCookie
} from "core-ui/client/react/core/actions/shared/sharedActions";

interface OAuthTranslations {
    oauth: {
        button: {
            authorize: string;
            deny: string;
        };
        description1: string;
        description2: string;
        description3: string;
        header: string;
        link: {
            privacyPolicy: string;
            termsAndconditions: string;
        };
    };
}

const Preconsent = () => {
    const { oauth } = useTranslations<OAuthTranslations>();
    const isOauth = String(getCookie("isOauthLogin")) === "true";
    const [rejectClicked, setRejectClicked] = useState(false);
    const [acceptClicked, setAcceptClicked] = useState(false);

    const {
        data: oauthItem,
        isLoading: oauthItemLoading,
        isError: isGetConsentError
    } = useQuery({
        queryKey: [QUERY_KEYS.GET_OAUTH_CONSENT_URL],
        queryFn: () => getOAuthConsentURL()
    });

    const consentChallenge = oauthItem ? oauthItem?.challenge : "";
    const grantScopeArr = ["offline", "fdx.accounts.read"];
    const consentData = {
        grantAccessTokenAudience: ["null"],
        grantScopes: grantScopeArr,
        challenge: consentChallenge,
        client: oauthItem?.clientName,
        remember: false
    };

    const { data: acceptResponse, isSuccess: acceptItemSuccess } = useQuery({
        queryKey: [QUERY_KEYS.ACCEPT_OAUTH_CONSENT],
        queryFn: () => acceptOauthConsent(consentData),
        enabled: Boolean(consentChallenge && acceptClicked)
    });

    const clearCookieAndRedirect = (redirectUrl: string) => {
        if (isOauth) {
            deleteCookie("isOauthLogin", "/", null);
        }
        window.location.href = redirectUrl;
    };

    if (acceptResponse && acceptItemSuccess) {
        clearCookieAndRedirect(acceptResponse.redirectTo);
    }

    const { data: rejectResponse, isSuccess: rejectItemSuccess } = useQuery({
        queryKey: [QUERY_KEYS.DENY_OAUTH_CONSENT],
        queryFn: () => rejectOauthConsent(consentChallenge),
        enabled: Boolean(consentChallenge && rejectClicked)
    });

    if (rejectResponse && rejectItemSuccess) {
        clearCookieAndRedirect(rejectResponse.redirectTo);
    }

    useEffect(() => {
        // set cookie for article links, hide header and footer
        if (!isOauth) {
            saveCookie("isOauthLogin", "true", "/");
        }
    }, [isOauth]);

    return (
        <div className="preconsentTemplate" data-testid="oauth-preconsent">
            <div className="preconsent-container">
                <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 inner-container with-padding with-shadow">
                    <div>
                        {oauthItemLoading && (
                            <div className="loaderBackground">
                                <div className="loader"></div>
                            </div>
                        )}
                        {oauth && (
                            <div>
                                <h2>
                                    {oauth.description1} {oauthItem?.clientName}
                                </h2>

                                <p>
                                    {oauthItem?.clientName} {oauth.description2}
                                </p>

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg margin-top-default"
                                        disabled={oauthItemLoading || isGetConsentError}
                                        onClick={() => {
                                            setRejectClicked(true);
                                        }}
                                        id="deny"
                                    >
                                        {oauth.button.deny}
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg margin-top-default"
                                        disabled={oauthItemLoading || isGetConsentError}
                                        onClick={() => {
                                            setAcceptClicked(true);
                                        }}
                                        id="accept"
                                    >
                                        {oauth.button.authorize}
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="oauth-links">
                            <p>
                                {oauth.description3}
                                <a href="#/articles/terms" target="_blank">
                                    {oauth.link.termsAndconditions}
                                </a>{" "}
                                and&nbsp;
                                <a href="#/articles/privacy" target="_blank">
                                    {oauth.link.privacyPolicy}
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preconsent;
