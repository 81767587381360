import isVisible from "core-ui/client/src/app/core/util/isVisible";

const PHONE_NUMBER_PLACEHOLDER_DEFAULT = "(___) ___-____";

const PersonalContactInfoCustomValidator = function PersonalContactInfoCustomValidator() {
    return {
        validatePersonalInfo: validatePersonalInfo
    };

    function removeMasking(phoneNumber) {
        return phoneNumber.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
    }

    function validatePersonalInfo(
        emailAddress,
        domesticCntryCd,
        intlCntryCode,
        phoneNumberPlaceholder
    ) {
        let phoneNumber = "";
        let intlNumber = "";
        let cntryCode = "";
        let domesticCntryCode = "";
        let phoneNumberWithNoMasking = "";
        let temp;
        const placeholder = phoneNumberPlaceholder || PHONE_NUMBER_PLACEHOLDER_DEFAULT;

        let formStatusWithErrorCd = {
            validForm: true,
            errorCode: ""
        };

        if (isVisible(document.getElementById("phoneNumberIdD"))) {
            phoneNumber = document.getElementById("phoneNumberIdD").value;
            temp = domesticCntryCd;
            domesticCntryCode = domesticCntryCd.substring(0, 2);
            phoneNumberWithNoMasking = removeMasking(phoneNumber);
        }

        if (isVisible(document.getElementById("phoneNumberIdI"))) {
            // This condition becomes true when the domestic number being changed to INTLNumber
            intlNumber = document.getElementById("phoneNumberIdI").value;
            temp = intlCntryCode;
            cntryCode = temp.substring(0, 2);
        }
        // This condition becomes true when the domestic number being changed to INTLNumber
        if (
            isVisible(document.getElementById("intlNumberIdD")) &&
            document.getElementById("intlNumberIdD").value &&
            domesticCntryCd
        ) {
            temp = domesticCntryCd;
            intlNumber = document.getElementById("intlNumberIdD").value;
            cntryCode = temp.substring(0, 2);
        }

        if (isVisible(document.getElementById("phoneNumberIdI"))) {
            phoneNumber = document.getElementById("phoneNumberIdI").value;
            temp = intlCntryCode;
            domesticCntryCode = intlCntryCode.substring(0, 2);
            phoneNumberWithNoMasking = removeMasking(phoneNumber);
        }
        // This condition becomes true when the domestic number being changed to INTLNumber
        if (
            isVisible(document.getElementById("intlNumberIdI")) &&
            document.getElementById("intlNumberIdI").value &&
            intlCntryCode
        ) {
            temp = intlCntryCode;
            intlNumber = document.getElementById("intlNumberIdI").value;
            cntryCode = temp.substring(0, 2);
        }

        if (intlNumber === "" && (phoneNumber === "" || phoneNumber === placeholder)) {
            formStatusWithErrorCd = {
                validForm: false,
                errorCode: "both_numbers_empty"
            };
        }

        if (
            domesticCntryCode === "US" &&
            cntryCode === "US" &&
            isVisible(document.getElementById("phoneNumberIdD")) &&
            isVisible(document.getElementById("phoneNumberIdI")) &&
            document.getElementById("phoneNumberIdD").value != placeholder &&
            document.getElementById("phoneNumberIdI").value != placeholder
        ) {
            formStatusWithErrorCd = {
                validForm: false,
                errorCode: "both_numbers_domestic"
            };
        }
        if (
            domesticCntryCode != "US" &&
            cntryCode != "US" &&
            isVisible(document.getElementById("intlNumberIdI")) &&
            isVisible(document.getElementById("intlNumberIdD")) &&
            document.getElementById("intlNumberIdI").value != "" &&
            document.getElementById("intlNumberIdD").value != ""
        ) {
            formStatusWithErrorCd = {
                validForm: false,
                errorCode: "both_numbers_INTL"
            };
        }

        if (
            formStatusWithErrorCd.validForm &&
            phoneNumberWithNoMasking !== "" &&
            phoneNumberWithNoMasking !== removeMasking(placeholder)
        ) {
            const pattern = /^\d{10}$/;

            formStatusWithErrorCd = {
                validForm: pattern.test(phoneNumberWithNoMasking),
                errorCode: "21211" // maps to 'Invalid phone number' error message
            };
        }

        return formStatusWithErrorCd;
    }
};
PersonalContactInfoCustomValidator.$inject = ["$resource", "$log"];
export default PersonalContactInfoCustomValidator;
