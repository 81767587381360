import {
    clearSpanishLanguageCookies,
    clearLanguageCookies
} from "../../services/authentication/AuthenticationService";

import { saveCookie } from "../../services/accuCodeService";

const resetLanguageCookies = (currentAccu, logger) => {
    clearSpanishLanguageCookies().then(
        function (data) {
            logger.debug("spanish data: {0} ", [JSON.stringify(data)]);
            logger.debug("Prelogin Spanish cookies have been cleared. {0}", [JSON.stringify(data)]);
            clearLanguageCookies().then(
                function (resp) {
                    logger.debug("Prelogin language cookies have been cleared. {0}", [
                        JSON.stringify(resp)
                    ]);

                    saveCookie("accu", currentAccu, "/", null, null);
                    saveCookie("accu", currentAccu, "/participant", null, null);
                    saveCookie("clientAccu", currentAccu, "/", null, null);
                    saveCookie("clientAccu", currentAccu, "/participant", null, null);
                },
                function (error) {
                    console.error("ERROR - clearLanguageCookies(): ", error);
                }
            );
        },
        function (error) {
            console.error("ERROR - clearSpanishLanguageCookies(): ", error);
        }
    );
};

export default resetLanguageCookies;
