const choosePrimaryPlanTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection">
    <header class="contained-form-header margin-top-default">
        <h1>
            {{ header }}
        </h1>
        <p> {{ intro }} </p>
    </header>
    <div id="statementsDocs-spinner" class="loaderBackground just-element" ng-if="PrimaryPlanSelectionCtrl.loading">
        <div class="loader"></div>
    </div>
    <form class="with-padding with-top-margin margin-bottom-200" role="form" name="primaryPlanSelectionForm" novalidate>
        <div class="inner-container with-padding with-shadow clearfix">
            <div class="form-group panel-group" ng-if="primaryPlanMapWithInIndid">
                <label class="control-label">{{ label }}</label>

                <div class="panel panel-default" data-ng-repeat="(key, value) in primaryPlanMapWithInIndid">
                    <header class="panel-heading form-group" data-ng-class="{'inactive' : true}" id="{{$index}}">
                        <label class="radio-inline panel-title">
                            <input type="radio" value="{{value.indId}}:{{value.dbname}}:{{value.pptwebAccuCode}}"
                                ng-model="PrimaryPlanSelectionCtrl.primaryPlanChoice" />
                            <span style="white-space:normal">
                                {{value.planName}}</span>
                        </label>
                    </header>
                    <!-- panel content -->
                </div>
            </div>

            <div class="row cta investment-button-row margin-bottom-200">
                <button id="submitButton" type="submit" class="btn btn-primary btn-block btn-lg margin-top-default"
                    ng-disabled="(!PrimaryPlanSelectionCtrl.primaryPlanChoice)"
                    ng-click="PrimaryPlanSelectionCtrl.continueClick()">
                    {{ continueButton }}
                </button>
            </div>

            <div class="form-group panel-group" ng-if="showBeAware">
                <label class="control-label">{{ beAwareHeader }}</label>

                <div class="panel panel-default padding-100">{{ beAwareContent }}</div>
            </div>

    </form>
</div>
`;
export default choosePrimaryPlanTemplate;
