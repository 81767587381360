const iraPreAccountOpeningTemplate = `
<div class="col-md-6 col-md-offset-3">
  <pwr-ira-pre-account-openning 
    countryCodes="paoCtl.countryCodes"
    stateCodes="paoCtl.stateCodes"
    loaded="paoCtl.isDataLoaded"
    loading="paoCtl.loading"
    open-existing-retail-message-modal="paoCtl.openExistingRetailMessageModal"
    create-retail-participant="createRetailParticipant"
    isRegistered="paoCtl.isRegistered"
    redirect="paoCtl.redirect"
    service-error="paoCtl.serviceError"
  />
</div>
`;
export default iraPreAccountOpeningTemplate;
