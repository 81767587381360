import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import {
    OAUTH_LOGIN,
    OAUTH_PRECONSENT,
    OAUTH_PREOAUTH_LOGIN,
    ACTIVATION_CODE_DELIVERY_OPTIONS
} from "../../routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../queries/constants";
import { TranslationsServiceProvider } from "../../providers/TranslationsServiceProvider";
import DeliveryOptions from "../../components/mfa/deliveryOptions/DeliveryOptions"; //"./deliveryOptions/DeliveryOptions";
import OAuthContainer from "./container/OAuthContainer";
import Preconsent from "./preconsent/Preconsent";
import PreOauthLogin from "./preOauthLogin/PreOauthLogin";

/**
 * TODO: DELETE THIS COMPONENT WHEN ANGULAR IS REMOVED.
 * This is a temporary component that provides access to react router, react query, and the translations hook
 * while still allowing the new MFA components to be embedded in the Angular application.
 * Also temporarily pass the redirectService from the Angular app until that has been converted.
 */
const OAuth = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: DEFAULT_STALE_TIME
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <TranslationsServiceProvider>
                <HashRouter>
                    <Routes>
                        <Route path={OAUTH_LOGIN} element={<OAuthContainer />}></Route>
                        <Route path={OAUTH_PRECONSENT} element={<Preconsent />}></Route>
                        <Route path={OAUTH_PREOAUTH_LOGIN} element={<PreOauthLogin />}></Route>
                        <Route
                            path={ACTIVATION_CODE_DELIVERY_OPTIONS}
                            element={<DeliveryOptions />}
                        ></Route>
                    </Routes>
                </HashRouter>
            </TranslationsServiceProvider>
        </QueryClientProvider>
    );
};

export default OAuth;
