import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import _findIndex from "lodash/findIndex";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";
import _pull from "lodash/pull";

import profileEvents from "./events/ProfileEvents";

const HomeAddressController = function (
    $injector,
    $scope,
    $state,
    $timeout,
    HomeAddressFactory,
    LanguageCodeFactory,
    eventBus,
    profileFactory
) {
    console.log("hello from homeaddresscontroller");
    const logger = ExternalLogger.getInstance("HomeAddressController");

    $scope.submitted = false;

    $scope.stline1 = "";
    $scope.stline2 = "";
    $scope.city = "";

    $scope.stateOptions = {
        code: "",
        description: ""
    };
    $scope.selectedStateValue = {
        code: "",
        description: ""
    };

    $scope.zipcode = "";

    $scope.countryOptions = {
        code: "",
        description: ""
    };
    $scope.selectedCountryValue = {
        code: "",
        description: ""
    };

    $scope.validationStatus = "";
    $scope.validationStatusParams = "";
    $scope.errors = [];

    $scope.focusMgr = {};

    $scope.removeInputFocus = function (event) {
        $timeout(function () {
            $scope.focusMgr[event.target.name] = false;
        });
    };

    $scope.setInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = true;
        $scope.homeAddressForm[event.target.name].$setTouched(true);
        $scope.homeAddressForm[event.target.name].$setDirty(true);
    };

    $scope.displayFieldMessages = function (fieldName, suppressFocused) {
        if (_isUndefined(suppressFocused)) {
            suppressFocused = false;
        }

        if (_isUndefined($scope.homeAddressForm)) {
            return "";
        }
        const field = $scope.homeAddressForm[fieldName];
        if (_isUndefined($scope.homeAddressForm) || !field) {
            return true;
        }
        const conditions =
            (field.$invalid && field.$dirty && !suppressFocused) ||
            (field.$touched && !suppressFocused) ||
            (!field.$pristine && !suppressFocused);

        return conditions;
    };

    LanguageCodeFactory.retrieveLanguageCode.query({}, (data) => {
        $scope.isPae = data && data.paeUser === "true";
    });

    if (
        profileFactory.getFirstLineMailing() !== null &&
        profileFactory.getFirstLineMailing() !== ""
    ) {
        $scope.stline1 = profileFactory.getFirstLineMailing();
    }
    if (
        profileFactory.getScndLineMailing() !== null &&
        profileFactory.getScndLineMailing() !== ""
    ) {
        $scope.stline2 = profileFactory.getScndLineMailing();
    }
    if (profileFactory.getCity() !== null && profileFactory.getCity() !== "") {
        $scope.city = profileFactory.getCity();
    }
    if (profileFactory.getState() !== null && profileFactory.getState() !== "") {
        $scope.selectedStateValue.code = profileFactory.getState();
    }
    if (profileFactory.getZipcode() !== null && profileFactory.getZipcode() !== "") {
        $scope.zipcode = profileFactory.getZipcode();
    }
    if (profileFactory.getCountry() !== null && profileFactory.getCountry() !== "") {
        $scope.selectedCountryValue.code = profileFactory.getCountry();
    }

    $scope.fieldOnBlur = function (fieldName) {
        const field = $scope.homeAddressForm[fieldName];
        return typeof field !== "undefined" && field.$touched && field.$invalid ? "has-error" : "";
    };

    $scope.getFormGroupClass = function (fieldName) {
        if (_isUndefined($scope.homeAddressForm)) {
            return "";
        }
        const field = $scope.homeAddressForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field && field.$invalid && field.$dirty && !fieldFocused) {
            status = "has-error";
        }

        if (field && field.$valid && field.$dirty) {
            status = "has-success";
        }
        return status;
    };

    $scope.getValidField = function (fieldName) {
        const field = $scope.homeAddressForm[fieldName];
        return !!(typeof field !== "undefined" && field.$valid);
    };

    $scope.isDirty = function (fieldName) {
        const field = $scope.homeAddressForm[fieldName];
        return !!(typeof field !== "undefined" && field.$dirty);
    };

    $scope.displayFieldError = function (fieldName) {
        const field = $scope.homeAddressForm[fieldName];
        return !!(
            (typeof field !== "undefined" && field.$invalid && field.$dirty) ||
            (field.$invalid && $scope.homeAddressForm.$submitted)
        );
    };

    $scope.getFieldError = function (fieldName) {
        if (
            _isUndefined($scope.homeAddressForm) ||
            !fieldName ||
            _isUndefined($scope.homeAddressForm[fieldName])
        ) {
            return "";
        }
        return $scope.homeAddressForm[fieldName].$error;
    };

    /**
     * determine the validation error (style) class.
     * different class if one error vs multiples.
     */
    $scope.getValidationErrorClass = function (fieldName) {
        let styleName = "rule-validations";

        if (
            _isUndefined($scope.homeAddressForm) ||
            !fieldName ||
            _isUndefined($scope.homeAddressForm[fieldName])
        ) {
            return styleName;
        }
        const errs = $scope.homeAddressForm[fieldName].$error;
        const errorKeys = _keys(errs);

        if (fieldName === "zipcode") {
            _pull(errorKeys, "mask");
        }

        const errorCnt = errorKeys.length;

        // if >1 errors, the style class should be bulleted.
        if (errorCnt > 1) {
            styleName = "form-validation-rule";
        }

        return styleName;
    };

    $scope.addressPostDataReady = false;
    $scope.loadHomeAddress = function () {
        const method = "loadHomeAddress()";

        const addressPostData = {
            stline1: $scope.stline1,
            stline2: $scope.stline2,
            city: $scope.city,
            state: $scope.selectedStateValue.code,
            zipcode: $scope.zipcode,
            country: $scope.selectedCountryValue.code
        };

        HomeAddressFactory.retrieveHomeAddress.query(
            addressPostData,
            function (data) {
                logger.debug("{0} - Home Address retrieved: {1}", [
                    method,
                    data ? JSON.stringify(data) : "null"
                ]);
                eventBus.dispatch(profileEvents.OPEN_CHANGE_HOME_ADDRESS_MODAL, this);

                if (data !== null) {
                    if (data.firstLineMailing !== null) {
                        $scope.stline1 = data.firstLineMailing;
                    }
                    if (data.scndLineMailing !== null) {
                        $scope.stline2 = data.scndLineMailing;
                    }
                    if (data.city !== null) {
                        $scope.city = data.city;
                    }
                    if (data.states !== null) {
                        $scope.stateOptions = normalizeRecords(data.states);
                    }
                    if (data.stateCode !== null) {
                        $scope.selectedStateValue = getCodeValue(
                            $scope.stateOptions,
                            data.stateCode
                        );
                    }
                    if (data.zipCode !== null) {
                        $scope.zipcode = data.zipCode;
                    }
                    if (data.countries !== null) {
                        $scope.countryOptions = normalizeRecords(data.countries);
                    }
                    if (data.country !== null) {
                        $scope.selectedCountryValue = getCodeValue(
                            $scope.countryOptions,
                            data.country
                        );
                    }
                }
            },
            function (error) {
                const $modalInstance = $injector.get("$modalInstance");
                $modalInstance.dismiss("cancel");
                logger.error("ERROR - HomeAddressFactory.retrieveHomeAddress: ", error);
            }
        );
    };

    const normalizeRecords = function (records) {
        const normalizedRecords = [];

        // eslint-disable-next-line no-undef
        angular.forEach(records, function (record) {
            if (record.code !== null && record.description !== null) {
                normalizedRecords.push({
                    code: record.code,
                    description: record.description.toProperCase()
                });
            }
        });
        $scope.addressPostDataReady = true;

        return normalizedRecords;
    };

    String.prototype.toProperCase = function () {
        return this.toLowerCase().replace(/(^[a-z]| [a-z]|-[a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    };

    const getCodeValue = function (source, code) {
        if (typeof source === "undefined" || typeof code === "undefined") {
            return null;
        }

        const index = _findIndex(source, { code: code });

        return index >= 0 ? source[index] : null;
    };

    $scope.clearZipcode = function () {
        $scope.zipcode = "";
    };

    $scope.filterZipCode = function (val) {
        if (!val || val === "") {
            return val;
        }

        //special case for trailing dash
        const stubPattern = /^\d{5}-$/;

        if (stubPattern.test(val)) {
            return val.substring(0, 5);
        }

        return val;
    };

    $scope.zipcodeUpdated = function () {
        const method = "zipcodeUpdated()";
        // eslint-disable-next-line no-undef
        $scope.zipcode = $scope.filterZipCode(document.getElementById("zipcodeId").value);
        logger.debug("{0} : {1}", [method, $scope.zipcode]);
    };

    $scope.updateHomeAddress = function (_, valid) {
        if (($scope.homeAddressForm && $scope.homeAddressForm.$valid) || valid) {
            const updateHomeAddressData = {
                stline1: $scope.stline1,
                stline2: $scope.stline2,
                city: $scope.city,
                state: $scope.selectedStateValue.code,
                zipcode: $scope.zipcode,
                country: $scope.selectedCountryValue.code
            };

            HomeAddressFactory.updateHomeAddress.query(
                updateHomeAddressData,
                function () {
                    const $modalInstance = $injector.get("$modalInstance");
                    $modalInstance.dismiss("cancel");
                    $state.go(
                        "profile",
                        {},
                        {
                            reload: true
                        }
                    );
                },
                function (response) {
                    if (
                        response.headers("exception") !== null ||
                        response.headers("exceptionMessage") !== null
                    ) {
                        $scope.saveStatus = response.data.error.code;
                        $scope.saveStatusParams = response.data.error.errors[0];
                    }
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue

            // Manually set the $dirty flag for each input in the form to trigger validation
            //eventBus.dispatch(profileEvents.UPDATE_HOME_ADDRESS_INVALID, this);
            const inputs = ["stline1", "stline2", "city", "state", "zipcode", "country"];

            if ($scope.homeAddressForm) {
                inputs.forEach(function (input) {
                    $scope.homeAddressForm[input].$touched = true;
                    $scope.homeAddressForm[input].$dirty = true;
                });
            }
        }
    };

    /**
     *  Close modal window
     **/
    $scope.cancel = function () {
        const $modalInstance = $injector.get("$modalInstance");
        eventBus.dispatch(profileEvents.CANCEL_HOME_ADDRESS, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: profileEvents.CANCEL_HOME_ADDRESS
            }
        });
        $modalInstance.dismiss("cancel");
    };
};

HomeAddressController.$inject = [
    "$injector",
    "$scope",
    "$state",
    "$timeout",
    "HomeAddressFactory",
    "LanguageCodeFactory",
    "eventBus",
    "profileFactory"
];
export default HomeAddressController;
