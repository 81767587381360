import { useQuery } from "@tanstack/react-query";
import { getPreLoginAccuRetrieval } from "../../services/accuCodeService";

const useAccuCustomization = (accu: string | undefined) => {
    return useQuery({
        queryKey: ["accu-customization"],
        queryFn: async () => {
            const data = await getPreLoginAccuRetrieval(accu);
            return data;
        },
        enabled: Boolean(accu)
    });
};

export default useAccuCustomization;
