const AlternateSiteDialogDirectiveViewTemplate = `
<div class="modal bulletins" ng-class="{show: accuError.showDialog}">
    <div class="modal-dialog bulletin" ng-if="accuError.showDialog" ng-init="init()">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ "error.alternate_site.accu.dialog.title" | translate }}</h4>
            </div>
            <div class="modal-body">
                <p>{{ "error.alternate_site.accu.dialog.description.message" | translate:translate }}</p>
                <a ng-click="redirectToCorrectAccuSite(accuError)">{{ accuError.redirectAccuUrl }}</a>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-link" ng-click="redirectToCorrectAccuSite(accuError)">
                    <a>{{ "error.alternate_site.accu.dialog.redirect.link.message" | translate }}</a>
                </button>
            </div>
        </div>
    </div>
</div>
`;
export default AlternateSiteDialogDirectiveViewTemplate;
