const noContactInfoOnFileWithSecurityTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
    <header class="contained-form-header margin-top-default">
    <h1>
      {{ "error.nocontactInfoWithSecurityTitle" | translate }}
    </h1>
  </header>
  <div class="inner-container with-padding with-shadow">
    <p>{{ "nocontactInfoWithSecurity" | translate }}</p>
  </div>
</div>
`;
export default noContactInfoOnFileWithSecurityTemplate;
