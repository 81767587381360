/**
 *
 * @name app.mfa.events.MFAEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for mfa section
 */

"use strict";

const root = "MFAEvent.";

const MFAEvents = {
    CONTACT_REDIRECT: root + "redirect_to_contact_info_from_mfa_link_clicked_event",
    REMEMBER_DEVICE: root + "remember_device_checkbox_clicked_event",
    NO_CODE: root + "no_code_link_clicked_event",
    SIGN_IN: root + "sign_in_button_clicked_event",
    CODE_CHANGE: root + "activation_code_input_changed_event",
    CODE_DELIVERY_CHANGE: root + "activation_code_delivery_format_change_event",
    VERIFY_CODE: root + "verify_activation_code_clicked_event",
    VERIFY_CODE_LOGIN_HELP: root + "verify_activation_code_for_login_help_clicked_event",
    SEND_CODE: root + "send_code_button_clicked_event",
    LOGIN_VERIFY_ERROR_CODE: root + "login_verify_error_code",
    MFA_DID_NOT_RECEIVE_CODE: "MFA_didn't_receive_the_code_link_clicked"
};

export default MFAEvents;
