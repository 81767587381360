import registrationSSNMessagesTemplate from "./messages/registrationSSNMessagesTemplate";
import registrationZipCodeMessagesTemplate from "./messages/registrationZipCodeMessagesTemplate";

const registrationFormTemplate = `
<div ng-class="{'panel-body': accuCustomization.showLoginSetupRecoveryRadio}">
    <div class="form-group" ng-class="getFormGroupClass('ssn');">
        <label for="ssnInput" class="control-label">{{"individual.ssn" | translate}}</label>
        <input type="text" class="form-control col-5" id="ssnInput" name="ssn" autocomplete="off"
            inputmode="numeric" ng-model="individual.ssn"
            ng-model-options="{ updateOn: 'blur', allowInvalid: true }" data-ng-required="areFieldsRequired.ssnFields"
            ng-blur="handleMaskSSN($event);removeInputFocus($event);ssnInputChange()"
            ng-focus="toggleSSNfromMaskedClick($event);setInputFocus($event)"
            title="Social Security must be numeric and must be nine digits" cleave="cleaveoptions.ssn"
            ng-keydown="toggleSSNfromMaskedKeyDown($event)" pw-social-security-number />
        ${registrationSSNMessagesTemplate}
        <span class="help-block">{{ssnError}}</span>
    </div>

    <!-- zipCode-->
    <div class="form-group" ng-class="getFormGroupClass('zipCode');">
        <label for="zipCodeInput" class="control-label">{{"individual.zipCode" | translate}}</label>
        <input type="text" name="zipCode" autocomplete="off" id="zipCodeInput" class="form-control col-5"
            inputmode="numeric" ng-model="individual.zipCode" data-ng-required="areFieldsRequired.ssnFields"
            maxlength="10" reg-ex-input reg-ex="^[-a-zA-Z0-9 ]+$" data-zipcode-alpha-numeric-validator
            ng-focus="setInputFocus($event)" title="ZIP/POSTAL must be numeric"
            ng-blur="removeInputFocus($event);zipChange();" />
        ${registrationZipCodeMessagesTemplate}
        <span class="help-block">{{zipcodeError}}</span>
    </div>


    <div class="form-group" ng-class="getFormGroupClass('ssnDateOfBirth');">
        <label for="ssnDateOfBirth" class="control-label">{{"individual.dateOfBirth" | translate}}&nbsp;
            <span class="label-format" aria-hidden="true">MM/DD/YYYY</span>
        </label>
                
        <pw-beneficiary-date 
            date-id="ssnDateOfBirth" 
            date-name="ssnDateOfBirth"
            date-title="Format two digit month, two digit day and four digit year"
            date-model="individual.ssnDateOfBirth" 
            is-required="areFieldsRequired.ssnFields" 
            is-disabled="false"
            date-focus="touchDateOfBirth($event);toggleDateOfBirthFromClick($event);setInputFocus($event)"
            date-blur="handleMaskDateOfBirth($event);removeInputFocus($event);dobChange()" 
            dob-change="dobChange()"
            min-date="getMinimumDateOfBirth()" 
            ui-options="{clearOnBlur:false, allowInvalidValue: true}"
            allow-future-date="true" 
            mask-date="true"
            ng-keydown="toggleDOBfromKeyDown($event)"
        >
        </pw-beneficiary-date>
        <ng-messages 
            for="getFieldError('ssnDateOfBirth');" 
            ng-show="displayFieldMessages('ssnDateOfBirth',focusMgr['ssnDateOfBirth']);"
            multiple 
            data-ng-class="getValidationErrorClass('ssnDateOfBirth');"
        >
            <ng-message when="required">
                {{ 'dateOfBirthRequired' | translate }}
            </ng-message>
            <ng-message when="valid || pattern">
                {{ 'dateOfBirthInvalid' | translate }}
            </ng-message>
            <ng-message when="minYear">
                {{ 'dateOfBirthYearOutOfRange' | translate }}
            </ng-message>
            <ng-message when="futureDateNotAllowed">
                {{ 'dateOfBirthFutureDateNotAllowed' | translate }}
            </ng-message>
        </ng-messages>
        <span class="help-block">{{ssnDateOfBirthError}}</span>
    </div>
</div>
`;

export default registrationFormTemplate;
