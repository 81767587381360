const noContactInfoModalTemplate = `
<div 
	class="modal-content" 
	role="dialog" 
	tabindex="0" 
	aria-hidden="false"
	data-ng-init="LinkablePlansSelectionController.dataForNoContactInfoModal()
">
	<div class="modal-header">
		<div class="block-title-linking" data-ng-repeat="planValue in plansFromCurrentContext">
			<b>{{planValue.planName}}</b>
		</div>
	</div>
	<div class="modal-body clearfix">
		<div class="modal-well with-background">

			<p translate="noContactInfoModal.intro"
				translate-values="{phoneNum:plansFromCurrentContext[0].planContactPhoneNumber}"
				data-ng-if="plansFromCurrentContext.length===1"></p>
			<p translate="noContactInfoModal.introWithMorePlans"
				translate-values="{phoneNum:plansFromCurrentContext[0].planContactPhoneNumber}"
				data-ng-if="plansFromCurrentContext.length>1"></p>
		</div>
	</div>
	<div class="modal-footer"><button type="button" class="btn btn-primary margin-top-default oneid-button-large"
			ng-click="LinkablePlansSelectionController.cancelModal()">
			{{ 'noContactInfoModal.ok' | translate }}
		</button>
	</div>
</div>
`;
export default noContactInfoModalTemplate;
