const investmentInformationResultsViewTemplate = `
<div ng-if="!ctrl.loading">
    <div class="internal-breadcrumbs">
        <ol class="breadcrumb">
            <li><a href="#/login">{{::ctrl.content.breadcrumbHome}}</a></li>
            <li class="active">{{::ctrl.content.breadcrumbCurrent}}</li>
        </ol>
    </div>

    
    <div class="marketing-content inner-container with-padding investment-information-container">
        
        <h1 class="marketing-content-primary-header">{{::ctrl.content.title}}</h1>
        
        <h2 ng-if="ctrl.planName"><strong>{{::ctrl.planName}}</strong></h2>
        
        <p>{{::ctrl.content.introduction}}</p>
        
        <p ng-if="ctrl.showNoInvestmentsMessage()">{{::ctrl.content.noInvestmentsFoundMessage}}</p>
        
        <div class="margin-top-100 panel" ng-if="ctrl.overviewRecords.length > 0">
            <h2>{{::ctrl.content.overviewHeading}}</h2>
            <p>{{::ctrl.content.overviewDescription}}</p>
            <ul class="list-group flush red-arrow">
                <li>
                    <button type="button"
                            class="btn btn-link padding-0"
                            data-ng-click="ctrl.showOverview = !ctrl.showOverview"
                            aria-controls="overviewRecords">
                        {{::ctrl.content.overviewToggleLink}}
                    </button>
                </li>
            </ul>
            <div data-ng-if="ctrl.showOverview"
                id="overviewRecords"
                role="region"
                aria-live="polite"
                aria-expanded="true">
                <div class="panel-body padding-top-0 padding-bottom-0">
                    <div ng-repeat="assetClass in ctrl.overviewRecords track by assetClass.assetClassName">
                        <h3>{{assetClass.assetClassName}}</h3>
                        <ul class="list-group flush red-arrow">
                            <li ng-repeat="fund in assetClass.funds">
                                <a href="{{fund.url}}" target="_blank">{{::fund.fundLegalName}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="margin-top-100 panel" ng-if="ctrl.performanceRecords.length > 0">
            <h2>{{::ctrl.content.performanceHeading}}</h2>
            <p>{{::ctrl.content.performanceDescription}}</p>
            <ul class="list-group flush red-arrow">
                <li>
                    <button type="button"
                            class="btn btn-link padding-0"
                            data-ng-click="ctrl.showPerformance = !ctrl.showPerformance"
                            aria-controls="performanceRecords">
                        {{::ctrl.content.performanceToggleLink}}
                    </button>
                </li>
            </ul>
            <div data-ng-if="ctrl.showPerformance"
                id="performanceRecords"
                role="region"
                aria-live="polite"
                aria-expanded="true">
                <div class="panel-body padding-top-0 padding-bottom-0">
                    <div ng-repeat="assetClass in ctrl.performanceRecords track by assetClass.assetClassName">
                        <ul class="list-group flush red-arrow">
                            <li ng-repeat="fund in assetClass.funds">
                                <a href="{{fund.url}}" target="_blank">{{::ctrl.content.performanceDocumentLink}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="margin-top-100 margin-bottom-0 panel" ng-if="ctrl.prospectusRecords.length > 0">
            <h2>{{::ctrl.content.prospectusesHeading}}</h2>
            <p>{{::ctrl.content.prospectusesDescription}}</p>
            <ul class="list-group flush red-arrow">
                <li>
                    <button type="button"
                            class="btn btn-link padding-0"
                            data-ng-click="ctrl.showProspectus = !ctrl.showProspectus"
                            aria-controls="prospectusRecords">
                        {{::ctrl.content.prospectusesToggleLink}}
                    </button>
                </li>
            </ul>
            <div data-ng-if="ctrl.showProspectus"
                id="prospectusRecords"
                role="region"
                aria-live="polite"
                aria-expanded="true">
                <div class="panel-body padding-top-0 padding-bottom-0">
                    <div ng-repeat="assetClass in ctrl.prospectusRecords track by assetClass.assetClassName">
                        <h3>{{assetClass.assetClassName}}</h3>
                        <ul class="list-group flush red-arrow">
                            <li ng-repeat="fund in assetClass.funds">
                                <a ng-if="fund.displayProspectusInd === 'Y'" href="{{fund.url}}" target="_blank">{{::fund.fundLegalName}}</a>
                                <div ng-if="fund.displayProspectusInd !== 'Y'">{{::fund.fundLegalName}}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
`;
export default investmentInformationResultsViewTemplate;
