import React from "react";

import PropTypes from "prop-types";

export default class ValidationMessagesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: this.props.errors,
            inValid: this.props.inValid,
            individualTransisions: this.props.individualTransisions || false
        };
    }

    render() {
        if (this.state.individualTransisions) {
            return (
                <div className="form-validation-rule react-message-validator">
                    {this.state.inValid
                        ? this.state.errors.map((error, indx) => (
                              <p
                                  className={"help-block " + error.status}
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${error.txt}-${indx}`}
                              >
                                  {error.txt}
                              </p>
                          ))
                        : null}
                </div>
            );
        } else {
            return (
                <div className="form-validation-rule react-message-validator">
                    {this.state.inValid
                        ? this.state.errors.map((error) => (
                              <p className="help-block" key={error}>
                                  {error}
                              </p>
                          ))
                        : null}
                </div>
            );
        }
    }

    componentDidUpdate(nextProps) {
        this.setState({
            errors: nextProps.errors,
            inValid: nextProps.inValid,
            individualTransisions: nextProps.individualTransisions || false
        });
    }
}

ValidationMessagesComponent.propTypes = {
    errors: PropTypes.array.isRequired,
    inValid: PropTypes.bool.isRequired,
    individualTransisions: PropTypes.bool
};
