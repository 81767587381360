import EventBus from "core-ui/client/src/app/core/eventbus/EventBus";

interface AmplitudeEventData {
    event_properties: Record<string, string | number>;
    event_type: string;
}

const sendAmplitudeEvent = (data: AmplitudeEventData) => {
    const eventBus = new EventBus(window, document);

    return eventBus.dispatchAmplitude(data);
};

export default sendAmplitudeEvent;
