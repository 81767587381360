const confirmPasswordTemplate = `
<div data-ng-show="saveStatus !== null" class="form-group has-error">
    <span class="help-block" id="notMatchPasswordRecordsError"> {{  saveStatus | translate:saveStatusParams}}
    </span>
</div>

<div class="form-group" data-ng-class="getFormGroupClass('password');">
    <label for="passwordId" class="control-label">
        {{ "profile.login.label.passwordCurrent" | translate }}
    </label>
    <input type="password"
           class="form-control"
           id="passwordId"
           name="password"
           data-ng-model="profile.password"
           maxlength="63"
           data-ng-required="true"
           ng-focus="setInputFocus($event)"
           ng-blur="removeInputFocus($event);changeCurrentPasswordInput();"
           aria-describedby="notMatchPasswordRecordsError passwordRules"
           />

    <ng-messages id="passwordRules" for="getFieldError('password');" ng-if="displayFieldMessages('password');" class="rule-validations">
        <ng-message when="required || isDirty('password')">
            <span ng-class="{'text-muted': focusMgr['password']}">{{"passwordRequired"  | translate }}</span>
        </ng-message>
    </ng-messages>
    <span class="help-block">{{passwordError}}</span>
</div>

<div class="form-group" data-ng-class="getFormGroupClass('newPassword');">
    <label for="newPasswordId" class="control-label">
        {{ "profile.login.label.passwordNew" | translate }}
    </label>
    <span class="sr-only" id="passwordRules">Must be eight to sixteen characters. Must include three of these four. {{"passwordUppercasePattern"| translate }}. {{"passwordLowercasePattern"| translate }}. {{"passwordNumberPattern"| translate }}. {{"passwordSpecialCharPattern"| translate }}. {{"passwordNotMatchUsername" | translate }}. </span>
    <span class="sr-only" hidden id="newPasswordRulesId">
        {{"newPasswordRequired" | translate }}.
        {{"newPasswordShoudNotMatch" | translate}}.
        {{"passwordMinLength" | translate }}.
        {{"passwordCondition0"| translate }}.
        {{"passwordUppercasePattern" | translate }}
        {{"passwordLowercasePattern" | translate }}
        {{"passwordNumberPattern" | translate }}
        {{"passwordSpecialCharPattern" | translate }}
    </span>
    <input type="password"
           class="form-control"
           id="newPasswordId"
           data-ng-model="profile.newPassword"
           data-match-to="profile.password"
           name="newPassword"
            maxlength="63"

           data-ng-required="true"
           data-password-validator
           data-compare-to-username="profile.username"
           ng-focus="setInputFocus($event)"
           ng-blur="removeInputFocus($event);changeNewPasswordInput();"
           aria-describedby="newPasswordRulesId"
           />

    <ng-messages
            for="getFieldError('newPassword');"
            ng-if="displayFieldMessages('newPassword');"
            multiple
            data-ng-class="getValidationErrorClass('newPassword');"
            ng-class="{'text-muted': focusMgr['newPassword']}"
           >
        <ng-message when="required || isDirty('newPassword')" class="form-validation-rule">
            {{"newPasswordRequired" | translate }}
        </ng-message>

        <ng-message when="matchTo || !required || isDirty('newPassword')" class="form-validation-rule">
             {{"newPasswordShoudNotMatch" | translate}}
        </ng-message>

        <ng-message when="minLength || maxLength || isDirty('newPassword')" class="form-validation-rule">
            {{"passwordMinLength" | translate }}
        </ng-message>

         <ng-message when="meetCondition0 || isDirty('newPassword')" class="form-validation-rule">
            {{"passwordCondition0"| translate }}
        </ng-message>

        <ng-message when="meetCondition1 || isDirty('newPassword')" class="form-validation-rule">
            {{"passwordCondition1"| translate }}
        </ng-message>

        <ng-message when="meetCondition2 || isDirty('newPassword')" class="form-validation-rule">
            {{"passwordCondition2"| translate }}
        </ng-message>
    </ng-messages>

        <div class="row">
            <div class="col-sm-1">
            </div>
                <div class="col-sm-11">
<!-- another set of password error messages -->
        <ng-messages
            for="getFieldError('newPassword');"
            ng-if="displayFieldMessages('newPassword');"
            multiple
            data-ng-class="getValidationErrorClass('newPassword');"
            ng-class="{'text-muted': focusMgr['newPassword']}">

                    <ng-message when="uppercasePattern || isDirty('newPassword')" class="form-validation-rule">
                        {{"passwordUppercasePattern" | translate }}
                    </ng-message>

                    <ng-message when="lowercasePattern || isDirty('newPassword')" class="form-validation-rule">
                        {{"passwordLowercasePattern" | translate }}
                    </ng-message>

                    <ng-message when="numberPattern || isDirty('newPassword')" class="form-validation-rule">
                        {{"passwordNumberPattern" | translate }}
                    </ng-message>

                    <ng-message when="specialCharPattern || isDirty('newPassword')" class="form-validation-rule">
                        {{"passwordSpecialCharPattern" | translate }}
                    </ng-message>
                </div>
           </ng-messages>

      </div>

<!-- another set of password error messages -->
    <ng-messages
        role="alert"
        for="getFieldError('newPassword');"
        ng-if="displayFieldMessages('newPassword');"
        multiple
        data-ng-class="getValidationErrorClass('newPassword');"
        ng-class="{'text-muted': focusMgr['newPassword']}">
          <ng-message when="compareToUsername" class="form-validation-rule">
            {{"passwordNotMatchUsername"  | translate }}
          </ng-message>

    </ng-messages>

    <p data-ng-if="getValidField('newPassword');" class="success" role="alert">
        {{"passwordAcceptable" | translate}}
    </p>
</div>

<div class="form-group" data-ng-class="getFormGroupClass('confirmPassword');">
    <label for="confirmPasswordId" class="control-label">
        {{ "profile.login.label.passwordConfirm" | translate }}
    </label>
    <span class="sr-only" id="confirmPasswordRules">{{"confirmPasswordRequired"| translate }}. {{"confirmPasswordNotMatch"| translate }}.
    </span>
    <input type="password"
           class="form-control"
           id="confirmPasswordId"
           data-ng-model="profile.confirmPassword"
           data-compare-to="profile.newPassword"
           data-ng-required="true"
           name="confirmPassword"
           maxlength="63"
           data-password-validator
           ng-focus="setInputFocus($event)"
           ng-blur="removeInputFocus($event);changeNewConfirmPasswordInput();"
           aria-describedby="confirmPasswordRulesId"
           />

    <ng-messages
            for="getFieldError('confirmPassword');"
            ng-if="displayFieldMessages('confirmPassword');"
            multiple
            data-ng-class="getValidationErrorClass('confirmPassword');"
            ng-class="{'text-muted': focusMgr['confirmPassword']}"
            id="confirmPasswordRulesId">

        <ng-message when="required" class="form-validation-rule">
             {{"confirmPasswordRequired"  | translate}}
        </ng-message>

        <ng-message when="compareTo" class="form-validation-rule">
             {{"confirmPasswordNotMatch"  | translate}}

        </ng-message>
        <ng-message when="!(compareTo)" class="form-validation-rule">
             {{"confirmPasswordValid"  | translate}}
        </ng-message>
    </ng-messages>
</div>
`;
export default confirmPasswordTemplate;
