import registrationPinMessagesTemplate from "./messages/registrationPinMessagesTemplate";
import registrationSSNMessagesTemplate from "./messages/registrationSSNMessagesTemplate";
import regErrorTemplate from "./regErrorTemplate";

const registrationWithPinTemplate = `
<form name="registerForm" ng-submit="registerWithPin(individual)" autocomplete="off" novalidate>

    <div class="form-group has-error" ng-show="autherizationMessage != null" id="screenReaderAuth">
        <span class="help-block">{{autherizationMessage | translate:autherizationMessageParams}}</span>
    </div>

    <!-- Screen Reader Error Block -->
    <div ng-if="registerForm.$submitted && registerForm.$invalid">
        ${regErrorTemplate}
    </div>

    <div class="form-group" ng-class="getFormGroupClass('ssn');">
        <label for="ssnInput" class="control-label">{{"individual.ssn" | translate}}</label>
        <input type="text" class="form-control col-5" id="ssnInput" name="ssn" autocomplete="off" inputmode="numeric"
            ng-model="individual.ssn" ng-model-options="{ updateOn: 'blur', allowInvalid: true }" ng-required="true"
            ng-blur="handleMaskSSN($event);removeInputFocus($event)"
            ng-focus="toggleSSNfromMaskedClick($event);setInputFocus($event)"
            title="Social Security must be numeric and must be nine digits" cleave="cleaveoptions.ssn"
            ng-keydown="toggleSSNfromMaskedKeyDown($event)" pw-social-security-number />

        ${registrationSSNMessagesTemplate}
        <span class="help-block">{{ssnError}}</span>
    </div>

    <!-- PIN using validationDirectives and mask-->
    <div class="form-group" ng-class="getFormGroupClass('pin');">
        <label class="control-label" for="pinInput">{{"individual.pin" | translate}}</label>
        <input type="password" class="form-control col-5" id="pinInput" name="pin" autocomplete="new-password"
            pattern="[0-9]*" inputmode="numeric" ng-required="true" pin-validator pin-length-validator maxlength="16"
            mask-restrict="accept" mask-clean="true" ng-model="individual.pin" ng-focus="setInputFocus($event)"
            ng-blur="removeInputFocus($event);changePin()"
            title="Pin must be numeric and pin must be between four and sixteen digits">
        <!--ng-model-options="{ updateOn: 'blur', allowInvalid: true }"-->
        ${registrationPinMessagesTemplate}
        <span class="help-block">{{pinError}}</span>
    </div>



    <div class="form-group clearfix">
        <button type="submit" class="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
            ng-disabled="showSpinner==true" id="register-pin-submit">


            <div id="frame-container-spinner" class="loaderBackground just-element" ng-if="showSpinner==true">
                <div class="loader"></div>
            </div>

            <span data-ng-hide="showSpinner==true">
                CONTINUE
            </span>

            <span data-ng-show="showSpinner==true">
                <i class="fa fa-circle-o-notch fa-spin"></i>
                Processing ...
            </span>
        </button>
    </div>
</form>

<div>
    <alternate-site-dialog accu-error="accuError" count-down="30"></alternate-site-dialog>
</div>
`;
export default registrationWithPinTemplate;
