import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

import communicationPreferencesTemplate from "../eConsent/communicationPreferencesTemplate";
import EConsentModalController from "../eConsent/EConsentModalController";
import eConsentTemplate from "../eConsent/eConsentTemplate";
import setAriaLabelledByToElement from "../utils/accessibilityHelpers";

import AuthorizedDevicesController from "./AuthorizedDevicesController";
import changeAuthorizedDevicesTemplate from "./changeAuthorizedDevicesTemplate";
import changeHomeAddressTemplate from "./changeHomeAddressTemplate";
import changePasswordTemplate from "./changePasswordTemplate";
import changePersonalContactTemplate from "./changePersonalContactTemplate";
import changeUsernameTemplate from "./changeUsernameTemplate";
import ProfileEvents from "./events/ProfileEvents";
import HomeAddressController from "./HomeAddressController";
import ProfileContactInfoModalController from "./ProfileContactInfoModalController";
import ProfileCredentialsController from "./ProfileCredentialsController";

let modalInstance;
const ProfileContactInfoController = function (
    $injector,
    $modal,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $window,
    ContactDetailsFactory,
    LanguageCodeFactory,
    InternationalPhoneNumUtil,
    PersonalContactInfoCustomValidator,
    PersonalContactInfoUpdateUtil,
    beneficiaryService,
    eventBus,
    profileFactory,
    profileService
) {
    // ViewModel replaces $scope
    const vm = this;
    const logger = ExternalLogger.getInstance("ProfileContactInfoController");

    vm.plans = [];
    vm.hasBeneficiaries = false;
    $scope.showSpinner = false;
    $scope.dataLoaded = false;
    $rootScope.showNonAuthHeader = null; //set to null so authenticated-header displays
    $scope.statusCode = "";
    $scope.pinAuthCode = "";
    $scope.personalEmailAddress = "";
    $scope.mobilePhoneNumber = "";
    $scope.workEmailAddress = "";
    $scope.workPhoneNumber = "";
    $scope.firstName = "";
    $scope.lastName = "";
    $scope.userName = "";
    $scope.gaId = "";
    $scope.indId = "";
    $scope.email = {};
    $scope.profileInfo = {};

    $scope.relativeBaseURL = $window.location.href.substring(
        0,
        $window.location.href.indexOf("/#/")
    );
    $scope.domainURL = $scope.relativeBaseURL;

    $scope.emailError = "";
    $scope.phoneNumerError = "";
    //var added for employement status check
    $scope.empStatus = null;

    $scope.stline1 = "";
    $scope.stline2 = "";
    $scope.city = "";
    $scope.states = {};
    $scope.zipcode = "";
    $scope.countries = {};

    $scope.beneficiaries = {};
    $scope.eConsentDetails = {};

    $scope.isAddrUpdateAllowed = "";

    $scope.isUserNameUpdateAllowed = true;
    vm.isSubjectToFeva = false;
    retriveContactInfor();

    $scope.secondaryPlans = true;
    $scope.togglePlans = function () {
        $scope.secondaryPlans = $scope.secondaryPlans === false;
    };

    // Authorized Devices
    $scope.authorizedDevicesLoaded = false;
    $scope.authorizedDevices = [];
    vm.isPae = false;
    $scope.events = ProfileEvents;
    $scope.eventBus = eventBus;

    retrieveAuthorizedDevices();

    function addAriaLabelledByToProfileModal() {
        setAriaLabelledByToElement("modal-wrapper", "modal-title");
    }

    LanguageCodeFactory.retrieveLanguageCode.query({}, (data) => {
        vm.isPae = data && data.paeUser === "true";
    });

    $scope.getFormGroupClass = function (fieldName) {
        const field = $scope.personalContactForm[fieldName];
        let status = "";

        if (field.$invalid && field.$dirty) {
            status = "has-error";
        }

        return status;
    };

    $scope.fieldOnBlur = function (fieldName) {
        const field = $scope.accountSetupForm[fieldName];
        let status = "";

        if (field.$touched && field.$invalid) {
            status = "has-error";
        }
        return status;
    };

    $scope.getValidField = function (fieldName) {
        const field = $scope.personalContactForm[fieldName].$valid;

        return field;
    };

    $scope.isDirty = function (fieldName) {
        const dirty = $scope.personalContactForm[fieldName].$dirty;

        return dirty;
    };

    $scope.displayFieldError = function (fieldName) {
        const field = $scope.personalContactForm[fieldName];
        let displayFieldError = false;

        if (field.$invalid && field.$dirty) {
            displayFieldError = true;
        }

        if (field.$invalid && $scope.personalContactForm.$submitted) {
            displayFieldError = true;
        }

        return displayFieldError;
    };

    $scope.getFieldError = function (fieldName) {
        return $scope.personalContactForm[fieldName].$error;
    };

    /**
     * Use change Username template for modal
     **/
    $scope.getTemplateUsername = function () {
        return changeUsernameTemplate;
    };

    /**
     * Use change Password template for modal
     **/
    $scope.getTemplatePassword = function () {
        return changePasswordTemplate;
    };

    /**
     * Use communication preferences template for modal
     **/
    $scope.getTemplateCommunicationPreferences = function () {
        return communicationPreferencesTemplate;
    };

    /**
     * Use personal contact template for modal
     **/
    $scope.getTemplatePersonalContact = function () {
        return changePersonalContactTemplate;
    };

    /**
     * Open modal
     **/
    $scope.openModal = function openModal(template) {
        modalInstance = $modal.open({
            template: template,
            controller: ProfileCredentialsController,
            size: "md",
            windowClass: "modal-wrapper"
        });
        modalInstance.rendered.then(addAriaLabelledByToProfileModal);
    };

    $scope.retrievePersonalContactInfo = function retrievePersonalContactInfo() {
        $scope.showSpinner = false;
        ContactDetailsFactory.retrieveContactDetails.query().$promise.then(
            function (data) {
                $scope.contactDetails = data;
                $scope.intlNumber = data.intlNumber;
                if (data.intCntryCode != null && data.intCntryCode != "")
                    $scope.selectedCntryPhCodeI = "+" + data.intCntryCode.substring(2);
                if (data.domesticCntryCode != null && data.domesticCntryCode != "")
                    $scope.selectedCntryPhCodeD = "+" + data.domesticCntryCode.substring(2);
            },

            function (error) {
                logger.error("ERROR - ContactDetailsFactory.retrieveContactDetails: ", error);
            }
        );
    };

    $scope.populateSelectedCntryPhoneCode = function (fieldName) {
        // eslint-disable-next-line no-undef
        const temp = document.getElementById(fieldName).value;
        if (fieldName === "ContactCountryNameI")
            $scope.selectedCntryPhCodeI = "+" + temp.substring(2);
        else if (fieldName === "ContactCountryNameD")
            $scope.selectedCntryPhCodeD = "+" + temp.substring(2);
    };

    /**
     * Open modal
     **/
    $scope.openContactModal = function () {
        modalInstance = $modal.open({
            templateUrl: "profile/" + $scope.personalContact + ".html",
            controller: ProfileContactInfoModalController,
            controllerAs: "ctrl",
            size: "md",
            windowClass: "modal-wrapper"
        });
        modalInstance.rendered.then(addAriaLabelledByToProfileModal);
    };

    $scope.openModalTemplateHomeAddress = function () {
        $scope.openHomeAddressModal();
    };

    $scope.openLinkablePlansPage = function () {
        $state.go("linkablePlansProfile");
    };

    $scope.openModalTemplateUsername = function () {
        $scope.openModal($scope.getTemplateUsername());
    };

    $scope.openModalTemplatePassword = function () {
        $scope.openModal($scope.getTemplatePassword());
    };

    $scope.openModalTemplateCommunicationPreferences = function () {
        $scope.openEConsentModal($scope.getTemplateCommunicationPreferences());
    };

    $scope.openModalTemplateAuthorizedDevices = function () {
        $scope.openAuthorizedDevicesModal();
    };

    /**
     * Open modal
     **/
    $scope.openHomeAddressModal = function () {
        modalInstance = $modal.open({
            template: changeHomeAddressTemplate,
            controller: HomeAddressController,
            controllerAs: "ctrl",
            size: "md",
            windowClass: "modal-wrapper"
        });
    };

    /**
     * Open modal
     **/
    $scope.openEConsentModal = function () {
        modalInstance = $modal.open({
            template: eConsentTemplate,
            controller: EConsentModalController,
            controllerAs: "ctrl",
            size: "lg",
            windowClass: "modal-wrapper"
        });
        modalInstance.rendered.then(addAriaLabelledByToProfileModal);
    };

    /**
     * Open Authorized Devices modal
     **/
    $scope.openAuthorizedDevicesModal = function () {
        modalInstance = $modal.open({
            template: changeAuthorizedDevicesTemplate,
            controller: AuthorizedDevicesController,
            controllerAs: "ctrl",
            size: "lg",
            windowClass: "modal-wrapper"
        });
        modalInstance.rendered.then(addAriaLabelledByToProfileModal);
    };

    /**
     *  Close modal window
     **/
    $scope.cancel = function () {
        modalInstance.dismiss("cancel");
    };

    function retrieveAuthorizedDevices() {
        profileFactory.retrieveAuthorizedDevices.query({}, function (data) {
            if (data !== null && data.status == "SUCCESSFUL") {
                if (data.data) {
                    $scope.authorizedDevices = data.data;
                    profileFactory.setAuthorizedDevices(data.data);
                }
                $scope.authorizedDevicesLoaded = true;
            }
        });
    }

    function retriveContactInfor() {
        $scope.mobilePhoneNumber = null;
        $scope.intlFormattedNbr = null;

        profileFactory.retriveContactInfor.query(
            {},
            function (data) {
                if (data !== null && data.status === "successful") {
                    $scope.profileInfo = data.data;

                    if (data.data.statusCode !== null) {
                        $scope.statusCode = data.data.statusCode;
                    }

                    if (data.data.pinAuthCode !== null) {
                        $timeout(function () {
                            $scope.pinAuthCode = data.data.pinAuthCode;
                        });
                    }

                    if (data.data.userName !== null) {
                        $scope.userName = data.data.userName;
                        profileFactory.setUsername(data.data.userName);
                    }

                    if (
                        data.data.isUserNameUpdateAllowed != null &&
                        data.data.isUserNameUpdateAllowed != "undefined"
                    ) {
                        $scope.isUserNameUpdateAllowed = data.data.isUserNameUpdateAllowed;
                    }

                    if (data.data.firstName !== null) {
                        $scope.firstName = data.data.firstName;
                    }

                    $scope.intlFormattedNbr = data.data.intlFormattedNbr;
                    if ($scope.intlFormattedNbr == null && data.data.intlNumber != null) {
                        $scope.intlFormattedNbr = InternationalPhoneNumUtil.formatToE164(
                            data.data.intlNumber,
                            data.data.intlCntryCode
                        );
                        $scope.profileInfo.intlFormattedNbr = $scope.intlFormattedNbr;
                    }

                    if (data.data.intlNumber != null) {
                        $scope.intlNumberIdI = $scope.intlNumber;
                        $scope.intlNumberIdD = $scope.intlNumber;
                    }

                    if (data.data.lastName !== null) {
                        $scope.lastName = data.data.lastName;
                    }

                    if (data.data.email !== null) {
                        $scope.personalEmailAddress = data.data.email;
                    }

                    if (data.data.phoneNumber !== null && data.data.phoneNumber != "") {
                        $scope.mobilePhoneNumber = data.data.phoneNumber;
                        $scope.phoneNumberIdD = $scope.mobilePhoneNumber;
                        $scope.phoneNumberIdI = $scope.mobilePhoneNumber;
                        $scope.selectedCntryPhCodeD =
                            "+" + data.data.domesticCntryCode.substring(2);
                    }
                    if (data.data.workPhoneNumber !== null) {
                        $scope.workPhoneNumber = data.data.workPhoneNumber;
                    }

                    if (data.data.workEmail !== null) {
                        $scope.workEmailAddress = data.data.workEmail;
                    }
                    if (data.data.firstLineMailing !== null) {
                        $scope.stline1 = data.data.firstLineMailing;
                        profileFactory.setFirstLineMailing(data.data.firstLineMailing);
                    }
                    if (data.data.scndLineMailing !== null) {
                        $scope.stline2 = data.data.scndLineMailing;
                        profileFactory.setScndLineMailing(data.data.scndLineMailing);
                    }
                    if (data.data.city !== null) {
                        $scope.city = data.data.city;
                        profileFactory.setCity(data.data.city);
                    }
                    if (data.data.state !== null) {
                        $scope.state = data.data.state;
                        profileFactory.setState(data.data.state);
                    }
                    if (data.data.country !== null) {
                        $scope.country = data.data.country;
                        profileFactory.setCountry(data.data.country);
                    }
                    if (data.data.zipcode !== null) {
                        $scope.zipcode = data.data.zipcode;
                        profileFactory.setZipcode(data.data.zipcode);
                    }
                    if (data.data.isAddrUpdateAllowed !== null) {
                        $scope.isAddrUpdateAllowed = data.data.isAddrUpdateAllowed;
                    }
                    if (data.data.eConsentDetails && data.data.eConsentDetails.length > 0) {
                        $scope.eConsentDetails = data.data.eConsentDetails;
                        profileFactory.setEConsentDetails(data.data.eConsentDetails);
                        $scope.allowedToOverwrite = "true";
                    }
                    if (data.data.displayChangeCommPrefsButton !== null) {
                        $scope.displayChangeCommPrefsButton =
                            data.data.displayChangeCommPrefsButton;
                    }

                    $scope.indId = data.data.indid;
                    $scope.econsentemailAddress = data.data.econsentemailAddress;
                    profileFactory.setEconsentEmail(data.data.econsentemailAddress);

                    profileFactory.setSecondaryEmail(data.data.secondaryEmailAddress);

                    //get Employment status code
                    if (data.data.employmentLoaReasonCode !== null) {
                        $scope.empStatus = data.data.employmentLoaReasonCode;
                    }

                    profileFactory.setSourcepage("profile");
                    $scope.sourcepage = "profile";

                    vm.loadPlansWithBeneficiaries();
                    vm.isPlanSubjectToFeva();
                    //State that will auto open the modal
                    if ($state.includes("communicationModal")) {
                        $scope.openEConsentModal($scope.getTemplateCommunicationPreferences());
                    }
                }
                $scope.dataLoaded = true;
            },
            function (error) {
                console.error("profileFactory.retriveContactInfor - ERROR - ", error);
            }
        );
    }

    /**
     * There may be multiple plans, so we need to load all plans for the participant then get beneficiaries
     * for each plan.
     *
     * The beneficiaries will be in plans[i].beneficiaries
     */
    vm.loadPlansWithBeneficiaries = function () {
        profileService
            .loadPlansWithBeneficiaries($scope.indId)
            .then(onLoadPlansWithBeneficiariesSuccess, onLoadPlansWithBeneficiariesError);

        function onLoadPlansWithBeneficiariesSuccess(plans) {
            vm.plans = plans;

            // Check if there are any beneficiaries on any of the plans. If not, we hide them on the profile page
            for (let i = 0, n = plans.length; i < n; i++) {
                if (
                    plans[i].beneficiaries &&
                    ((plans[i].beneficiaries.Primary &&
                        plans[i].beneficiaries.Primary.length > 0) ||
                        (plans[i].beneficiaries.Contingent &&
                            plans[i].beneficiaries.Contingent.length > 0))
                ) {
                    vm.hasBeneficiaries = true;
                    break;
                }
            }
        }

        function onLoadPlansWithBeneficiariesError() {
            // We don't want to prevent the participant from seeing other information about his or her profile,
            // so we just won't show the beneficiaries section of the profile page.
            vm.hasBeneficiaries = false; // Probably unnecessary, but included for good measure.
        }
    };

    $scope.getBeneficiaryName = function (beneficiary) {
        return beneficiaryService.getBeneficiaryName(beneficiary);
    };

    $scope.updatePersonalContact = function (flowName) {
        const updatePersonalContactData = PersonalContactInfoUpdateUtil.getUpdateContactInfoJson(
            flowName,
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        const validaityOfForm = PersonalContactInfoCustomValidator.validatePersonalInfo(
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        if (!validaityOfForm.validForm) {
            $scope.personalContactForm.$valid = false;
            $scope.phoneNum_error = validaityOfForm.errorCode;
        }

        if ($scope.personalContactForm.$valid) {
            // if (true) {
            $scope.showSpinner = true;
            profileFactory.updatePersonalContact.query(updatePersonalContactData).$promise.then(
                function () {
                    if (modalInstance) {
                        // `modalInstance` is not set from the direct access to /participant/#/change-personal-contact
                        modalInstance.dismiss("cancel");
                    }

                    $state.go(
                        "profile",
                        {},
                        {
                            reload: true
                        }
                    );
                    $scope.showSpinner = false;
                },

                function (response) {
                    $scope.showSpinner = false;

                    if (response === "44403") {
                        modalInstance.dismiss("cancel");
                    }

                    if (
                        response.headers("exception") !== null ||
                        response.headers("exceptionMessage") !== null
                    ) {
                        $scope.saveStatusContact = response.data.error.code;

                        $scope.saveStatusParams = response.data.error.errors[0];
                    } else {
                        if (response.data.data.email !== null) {
                            $scope.emailError = response.data.data.email;
                        }
                        if (response.data.data.phoneNumber !== null) {
                            $scope.phoneNumberError = response.data.data.phoneNumber;
                        }
                    }
                }
            );
        } else {
            return false;
        }
    };

    vm.isPlanSubjectToFeva = function () {
        const groupService = $injector.get("groupService");
        groupService.isAnyGroupSubjectToFEVA().then(function (response) {
            vm.isSubjectToFeva = response;
        });
    };
};

ProfileContactInfoController.$inject = [
    "$injector",
    "$modal",
    "$rootScope",
    "$scope",
    "$state",
    "$timeout",
    "$window",
    "ContactDetailsFactory",
    "LanguageCodeFactory",
    "InternationalPhoneNumUtil",
    "PersonalContactInfoCustomValidator",
    "PersonalContactInfoUpdateUtil",
    "beneficiaryService",
    "eventBus",
    "profileFactory",
    "profileService"
];
export default ProfileContactInfoController;
