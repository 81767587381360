const otsAccountRegistrationPersonalDetailsForm = `
<div class="col-md-6 col-md-offset-4" style="display:flex;justify-content:flex-end">
  <pwr-ots-account-personal-details-form
    stateCodes="stateCodes"
    loaded="otsCtl.isDataLoaded"
    loading="otsCtl.loading"
    redirect="otsCtl.redirect"
    service-error="otsCtl.serviceError",
    open-existing-retail-message-modal="openExistingRetailMessageModal"
    is-registered="isRegistered"
    form-data="formData"
    handle-personal-details-form-submit="handlePersonalDetailsFormSubmit"
    />
</div>
`;
export default otsAccountRegistrationPersonalDetailsForm;
