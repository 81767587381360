const inboundSSOSpinnerTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection">
  <header class="contained-form-header margin-top-default">
    <h1>
      {{ ::translatedData.indIdSelectionTitle }}
    </h1>
    <p>{{ ::translatedData.indIdSelectionIntro }}</p>
  </header>
  <pwr-sso-loader-component></pwr-sso-loader-component>
</div>
`;
export default inboundSSOSpinnerTemplate;
