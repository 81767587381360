import StringUtil from "core-ui/client/src/app/StringUtil";

interface RegisterServiceError {
    data: {
        error: {
            code: string;
            errors?: { attempts: string; code: string }[];
            message?: string;
            status: string;
        };
    };
}

function isRegisterServiceError(resp: any): resp is RegisterServiceError {
    return resp?.data?.error;
}

const handleRegisterServiceError = (errorMessages: { [key: string]: string }, err: unknown) => {
    if (isRegisterServiceError(err)) {
        const { code: errorCode, errors } = err.data.error;
        let message = errorMessages[errorCode];
        if (message && errors && errors.length > 0) {
            message = StringUtil.supplant(message, errors[0], /\{\{(.+)\}\}/g);
        }
        return message;
    }
    return "";
};

export default handleRegisterServiceError;
