import type { AxiosResponse } from "axios";
import ReferenceData from "../../../constants/ReferenceData";
import { executePost } from "../../../utils/appUtils";
import { RedirectData } from "../../redirect/types";

interface RegisterWithoutPinPayload {
    accu: string | undefined;
    dateOfBirth: string;
    flowName: string;
    isIDProofing: string;
    lastName?: string;
    metrixPlanId?: string;
    ssn?: string;
    zipcode?: string;
}

const registerWithoutPin = (
    payload: RegisterWithoutPinPayload
): Promise<AxiosResponse<RedirectData>> => {
    return executePost(ReferenceData.REGISTER_WITHOUT_PIN, "registerWithoutPinFault", payload);
};

export default registerWithoutPin;
