/**
 *
 * @name app.accountSetup.events.NpdiLinkAccountsEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for NPDI account linking
 */

const root = "NpdiLinkAccountsEvent.";

const NpdiLinkAccountsEvents = {
    CONTINUE: root + "continue_button_clicked_event"
};

export default NpdiLinkAccountsEvents;
