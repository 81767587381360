const ContactDetailsFactory = function ($resource) {
    return {
        retrieveContactDetails: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + "rest/partialauth/retrieveContactDetails",
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        )
    };
};

ContactDetailsFactory.$inject = ["$resource", "$window"];
export default ContactDetailsFactory;
