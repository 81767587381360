import deepLinkTemplate from "./deepLinkTemplate";

const deepLinkRoute = function ($stateProvider) {
    $stateProvider.state("d", {
        url: "/d/:featureName/:gaId",
        template: deepLinkTemplate,
        controller: "deepLinkController",
        data: {
            fullHeight: true,
            title: "Deeplink"
        },

        params: {
            featureName: {
                value: "",
                squash: true
            },
            gaId: {
                value: "",
                squash: true
            }
        }
    });
};

deepLinkRoute.$inject = ["$stateProvider"];
export default deepLinkRoute;
