const OneIdMfaFactory = function ($resource) {
    const validateOneIdMFACode = "rest/partialauth/mfa/verifycodeByIndIdDbName";

    return {
        validateOneIdMFACode: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + validateOneIdMFACode,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        getDeliveryOptions: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + "rest/partialauth/mfa/deliveryOptionsByIndId/:indId/:dbName",
            {
                indId: "@indId",
                dbName: "@dbName"
            },
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        createAndDeliverActivationCodePOST: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ +
                "rest/partialauth/mfa/createAndDeliverActivationCodePostByIndId",
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

OneIdMfaFactory.$inject = ["$resource"];
export default OneIdMfaFactory;
