import type { AxiosResponse } from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";
import type { RedirectData } from "../redirect/types";

interface VerifyCodeByIndIdDbNamePayload {
    indIdDbName: string;
    verificationCode: string;
}

const verifyCodeByIndIdDbName = (
    payload: VerifyCodeByIndIdDbNamePayload
): Promise<AxiosResponse<RedirectData>> => {
    return executePost(
        ReferenceData.VERIFY_CODE_BY_IND_ID_DB_NAME,
        "verifyCodeByIndIdDbNameFault",
        payload
    );
};

export default verifyCodeByIndIdDbName;
