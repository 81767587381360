import React from "react";

import PropTypes from "prop-types";
import { PatternFormat } from "react-number-format";

import TranslationService from "../../services/translationService";

export default class WorkEmailAddressComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            total: 0
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    render() {
        if (!this.state.translations) {
            return null;
        }

        return (
            <div className="profile-information work-information">
                <h2>{this.state.translations.profile.work.header}</h2>

                <p>{this.state.translations.profile.work.headerSecondary}</p>

                <div className="row">
                    <strong className="col-sm-4 text-right">
                        {this.state.translations.profile.work.emailAddress}
                    </strong>
                    <div className="col-sm-8" id="workEmailAddress">
                        {this.props.profileInfo.workEmail}
                    </div>
                </div>

                <div className="row">
                    <strong className="col-sm-4 text-right">
                        {this.state.translations.profile.work.phoneNumber}
                    </strong>
                    <div className="col-sm-8" id="workPhoneNumber">
                        <PatternFormat
                            value={this.props.profileInfo.workPhoneNumber}
                            format="(###) ###-####"
                            mask="_"
                            displayType={"text"}
                        />
                    </div>
                </div>

                <hr />
            </div>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

WorkEmailAddressComponent.propTypes = {
    profileInfo: PropTypes.object.isRequired
};
