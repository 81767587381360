import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

const idProofController = function (
    $rootScope,
    $scope,
    $state,
    $stateParams,
    idProofFactory,
    redirectService,
    otsAccountOpenFactory,
    otsRegistrationService
) {
    const vm = this;
    const logger = ExternalLogger.getInstance("idProofController");
    if ($rootScope.siteContactInfo && $rootScope.siteContactInfo.phone) {
        $scope.phone = $rootScope.siteContactInfo.phone;
    } else {
        $scope.phone = "1-855-756-4738";
    }
    $rootScope.$state = $state;
    $scope.selectedAnswerValue = false;
    vm.loading = true;
    $scope.flowName = $rootScope.flowName;
    $scope.isOtsUser = $stateParams.flowName === "OTS";
    $scope.idProofingQuestions = null;
    $scope.selectedIDProofOptions = [];
    if ($stateParams.errorCode) {
        $scope.idProofErrorCode = $stateParams.errorCode;
    }

    $scope.submitIDProofAnswers = function () {
        logger.debug(" submitIDProofAnswers");
        if ($scope.idProofingQueryForm.$valid) {
            $scope.showSpinner = true;
            const idProofSelDataObj = {
                idProofData: {
                    idProofSelData: $scope.selectedIDProofOptions
                }
            };
            logger.debug("IDProof Object: " + JSON.stringify(idProofSelDataObj));
            idProofFactory.submitIDProofAnswers.query(
                idProofSelDataObj,
                function (data) {
                    $scope.showSpinner = false;

                    if (data.status == "fail" && data.errorCode != null) {
                        if ($scope.isOtsUser) {
                            $state.go("idProofError", { errorCode: "idProofOTS" });
                        } else {
                            $state.go("mailPin", { optOut: "optOut-Error" });
                        }
                    } else {
                        if (data.flowName === "otsAccountSetup") {
                            //Get session data
                            const formData = otsRegistrationService.getFormData();

                            // IF OTS Call Shadowaccount if successfull redirect otherwise throw error
                            otsAccountOpenFactory
                                .createShadowAccountOTS(formData.phoneNumber)
                                .then(
                                    //Get data and redirect
                                    function () {
                                        redirectService.redirect(data, "ALL", formData.context);
                                    }
                                )
                                .catch(function () {
                                    $state.go("idProofError", { errorCode: "idProofOTS" });
                                });
                        } else {
                            redirectService.redirect(data, "ALL");
                        }
                    }
                },
                function (error) {
                    $scope.showSpinner = false;
                    logger.error("ERROR - idProofFactory.submitIDProofAnswers(): ", error);
                }
            );
        }
    };

    $scope.handleIDProofOptionSelection = function (
        idProofingQuestion,
        selectedAnswer,
        selectedAnswerValue
    ) {
        $scope.idProofingQuestion = idProofingQuestion;
        $scope.selectedAnswer = selectedAnswer;
        $scope.origOptions = $scope.idProofingQuestion.options;

        // eslint-disable-next-line no-undef
        angular.forEach($scope.origOptions, function (value) {
            if ($scope.idProofingQuestion.multiSelect === true) {
                if (value.id === $scope.selectedAnswer.id) {
                    value.selected = selectedAnswerValue;
                }
            } else {
                value.selected = value.id === $scope.selectedAnswer.id;
            }
        });
        const index = $scope.selectedIDProofOptions.indexOf(idProofingQuestion);
        if (index != -1) {
            $scope.selectedIDProofOptions.splice(index, 1);
        }

        $scope.selectedIDProofOptions.push(idProofingQuestion);
    };

    $scope.retrieveIDProofQueries = function () {
        idProofFactory.retrieveIDProofQueries.query(
            {},
            //success
            function (data, headers) {
                vm.loading = true;

                if (data !== null && data.status == "successful") {
                    $scope.idProofingQuestions = data.idProofingQuestions;
                } else if (data.status == "fail") {
                    vm.loading = false;
                    $state.go("idProofError", { errorCode: data.errorCode });
                } else if (data.state != null) {
                    $rootScope.contactNumberIdProof = data.contactNumber;

                    redirectService.redirect(data, "ALL");
                }

                if (headers("exception") !== null) {
                    vm.loading = false;
                    $state.go("mailPin", { optOut: "optOut-Error" });
                } else {
                    vm.loading = false;
                }
            },
            function (response) {
                vm.loading = false;
                if (response.data.status == "FAIL") {
                    $state.go("idProofError", { errorCode: response.data.error.code });
                }
            }
        ); //End of query function
    };

    $scope.onOptOutClick = function () {
        $state.go("mailPin", { optOut: "optOut-Query" });
    };
};

idProofController.$inject = [
    "$rootScope",
    "$scope",
    "$state",
    "$stateParams",
    "idProofFactory",
    "redirectService",
    "otsAccountOpenFactory",
    "otsRegistrationService"
];
export default idProofController;
