const deepLinkTemplate = `
<div>
  <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2" ng-if="deepLinkErrorCode!=null">
    <header class="contained-form-header margin-top-default">
    <h1>
      {{ "error.headingTitle" | translate }}
    </h1>
  </header>
  <div class="inner-container with-padding with-shadow" ng-if="deepLinkErrorCode!=null">
    <p>{{deepLinkErrorCode}}</p>
  </div>
</div>
`;
export default deepLinkTemplate;
