import type { AxiosResponse } from "axios";
import { executePost } from "../../../../utils/appUtils";

const optOutWithExpiredOTPAndReqPin = (isOptOutParams): Promise<AxiosResponse> => {
    const optOutWithExpiredOTPAPi = `/participant-web-services/rest/partialauth/otpExpiredRequestPin/${isOptOutParams.optOut}`;
    return executePost(optOutWithExpiredOTPAPi, "optOutWithExpiredOTPAndReqPinFault", {
        ...isOptOutParams
    });
};
export default optOutWithExpiredOTPAndReqPin;
