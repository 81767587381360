import { useQuery } from "@tanstack/react-query";
import { setAccuService } from "../../services/accuCodeService";
import { QUERY_KEYS } from "../constants";

const useAccuService = (accuValue: string | undefined, isSSOLogin: boolean) => {
    return useQuery({
        queryKey: [QUERY_KEYS.SET_ACCU_SERVICE, accuValue],
        queryFn: () => setAccuService(accuValue),
        enabled: !!accuValue && !isSSOLogin
    });
};

export default useAccuService;
