import React from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import PropTypes from "prop-types";

import TranslationService from "../../services/translationService";

export default class EConsentComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            allowedToOverwrite: this.props.allowedToOverwrite,
            displayChangeCommPrefsButton: this.props.displayChangeCommPrefsButton,
            openModalTemplateCommunicationPreferences:
                this.props.openModalTemplateCommunicationPreferences,
            econsentemailAddress: this.props.econsentemailAddress,
            eConsentDetails: this.props.eConsentDetails,
            secondaryPlans: false
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    handleOpenEConsentModal = () => {
        //React function, executing an angular external function.
        this.props.openModalTemplateCommunicationPreferences();
    };

    handleTogglePlans = (event) => {
        event.preventDefault();
        const value = this.state.secondaryPlans;

        this.setState({
            secondaryPlans: !value
        });
    };

    handleKeydownOpenEConsentModal = (event) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: () => this.handleOpenEConsentModal()
        });
    };

    render() {
        if (this.state.allowedToOverwrite === "true" && this.state.translations) {
            return (
                <section>
                    <div className="profile-information">
                        <div className="profile-information">
                            <div className="row">
                                <h2 className="col-md-12 margin-top-0">
                                    {" "}
                                    {this.state.translations.preference.eDelivery.headline}{" "}
                                </h2>

                                {this.state.displayChangeCommPrefsButton == true && (
                                    <div className="btn-container">
                                        <button
                                            onClick={this.handleOpenEConsentModal}
                                            className="btn btn-primary"
                                            aria-label={
                                                this.state.translations.profile.eDelivery
                                                    .buttonAriaLabel
                                            }
                                            onKeyDown={this.handleKeydownOpenEConsentModal}
                                        >
                                            {this.state.translations.preference.button.change}
                                        </button>
                                    </div>
                                )}
                            </div>

                            {this.state.eConsentDetails &&
                                this.state.eConsentDetails
                                    .sort(function (a, b) {
                                        return a.planName.localeCompare(b.planName);
                                    })
                                    .map((eConsentDetail) => (
                                        /*PrimaryPlan*/
                                        <section key={eConsentDetail.planName}>
                                            {eConsentDetail.primaryPlan && (
                                                <div className="row">
                                                    <div className="col-sm-12 margin-bottom-100">
                                                        {eConsentDetail.planName}
                                                        {this.state.eConsentDetails.length > 1 && (
                                                            <span>
                                                                {" "}
                                                                (
                                                                <button
                                                                    className="btn-link no-underline no-padding"
                                                                    onClick={this.handleTogglePlans}
                                                                >
                                                                    {`${
                                                                        this.state.secondaryPlans
                                                                            ? "less"
                                                                            : "more"
                                                                    } plans`}
                                                                </button>
                                                                ){" "}
                                                            </span>
                                                        )}
                                                    </div>

                                                    <strong className="col-sm-4 text-right">
                                                        {
                                                            this.state.translations.preference
                                                                .eDelivery.label.delivery
                                                        }
                                                    </strong>

                                                    <div className="col-sm-8">
                                                        {eConsentDetail.eDeliveryOption ===
                                                            "NO_CONSENT_ANY_ELECTRONIC" &&
                                                            eConsentDetail.pptElectronicOptionsOnly && (
                                                                <div>
                                                                    {
                                                                        this.state.translations
                                                                            .preference.eDelivery
                                                                            .deliveryMethod.option6
                                                                    }
                                                                </div>
                                                            )}

                                                        {eConsentDetail.eDeliveryOption ===
                                                            "NO_CONSENT_ANY_ELECTRONIC" &&
                                                            !eConsentDetail.pptElectronicOptionsOnly && (
                                                                <div>
                                                                    {
                                                                        this.state.translations
                                                                            .preference.eDelivery
                                                                            .deliveryMethod.option5
                                                                    }
                                                                </div>
                                                            )}

                                                        {eConsentDetail.eDeliveryOption ===
                                                            "NO_CONSENT_ALL_MAIL" && (
                                                            <div>
                                                                {
                                                                    this.state.translations
                                                                        .preference.eDelivery
                                                                        .deliveryMethod.option6
                                                                }
                                                            </div>
                                                        )}

                                                        {eConsentDetail.eDeliveryOption ===
                                                            "YES_CONSENT_ANY_ELECTRONIC" && (
                                                            <div>
                                                                {
                                                                    this.state.translations
                                                                        .preference.eDelivery
                                                                        .deliveryMethod.option5
                                                                }
                                                            </div>
                                                        )}

                                                        {eConsentDetail.eDeliveryOption ===
                                                            "YES_CONSENT_ALL_MAIL" && (
                                                            <div>
                                                                {
                                                                    this.state.translations
                                                                        .preference.eDelivery
                                                                        .deliveryMethod.option6
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {!eConsentDetail.primaryPlan &&
                                                this.state.secondaryPlans && (
                                                    <div className="row">
                                                        <div className="col-sm-12  margin-bottom-100">
                                                            {eConsentDetail.planName}
                                                        </div>

                                                        <strong className="col-sm-4 text-right">
                                                            {
                                                                this.state.translations.preference
                                                                    .eDelivery.label.delivery
                                                            }
                                                        </strong>

                                                        <div className="col-sm-8">
                                                            {eConsentDetail.eDeliveryOption ===
                                                                "NO_CONSENT_ANY_ELECTRONIC" &&
                                                                eConsentDetail.pptElectronicOptionsOnly && (
                                                                    <div>
                                                                        {
                                                                            this.state.translations
                                                                                .preference
                                                                                .eDelivery
                                                                                .deliveryMethod
                                                                                .option6
                                                                        }
                                                                    </div>
                                                                )}

                                                            {eConsentDetail.eDeliveryOption ===
                                                                "NO_CONSENT_ANY_ELECTRONIC" &&
                                                                !eConsentDetail.pptElectronicOptionsOnly && (
                                                                    <div>
                                                                        {
                                                                            this.state.translations
                                                                                .preference
                                                                                .eDelivery
                                                                                .deliveryMethod
                                                                                .option5
                                                                        }
                                                                    </div>
                                                                )}

                                                            {eConsentDetail.eDeliveryOption ===
                                                                "NO_CONSENT_ALL_MAIL" && (
                                                                <div>
                                                                    {
                                                                        this.state.translations
                                                                            .preference.eDelivery
                                                                            .deliveryMethod.option6
                                                                    }
                                                                </div>
                                                            )}

                                                            {eConsentDetail.eDeliveryOption ===
                                                                "NO_CONSENT_ALL_MAIL" && (
                                                                <div>
                                                                    {
                                                                        this.state.translations
                                                                            .preference.eDelivery
                                                                            .deliveryMethod.option6
                                                                    }
                                                                </div>
                                                            )}

                                                            {eConsentDetail.eDeliveryOption ===
                                                                "YES_CONSENT_ANY_ELECTRONIC" && (
                                                                <div>
                                                                    {
                                                                        this.state.translations
                                                                            .preference.eDelivery
                                                                            .deliveryMethod.option5
                                                                    }
                                                                </div>
                                                            )}

                                                            {eConsentDetail.eDeliveryOption ===
                                                                "YES_CONSENT_ALL_MAIL" && (
                                                                <div>
                                                                    {
                                                                        this.state.translations
                                                                            .preference.eDelivery
                                                                            .deliveryMethod.option6
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                        </section>
                                    ))}

                            {this.state.econsentemailAddress && (
                                <div className="row">
                                    <strong className="col-sm-4 text-right">
                                        {this.state.translations.preference.eDelivery.label.email}
                                    </strong>
                                    <div className="col-sm-4">
                                        {this.state.econsentemailAddress}
                                    </div>
                                </div>
                            )}

                            <hr />
                        </div>
                    </div>
                </section>
            );
        } else {
            return null;
        }
    }

    componentDidMount() {
        this.getTranslations();
    }
}

EConsentComponent.propTypes = {
    allowedToOverwrite: PropTypes.string,
    displayChangeCommPrefsButton: PropTypes.bool,
    openModalTemplateCommunicationPreferences: PropTypes.func,
    econsentemailAddress: PropTypes.string,
    eConsentDetails: PropTypes.array
};
