const idProofFactory = function ($resource) {
    const retrieveIDProofQueriesApi =
        "/participant-web-services/rest/partialauth/idProof/retrieveIDProofingData";

    const submitIDProofAnswersApi =
        "/participant-web-services/rest/partialauth/idProof/submitIDProofData";

    const requestPinApi = "/participant-web-services/rest/partialauth/requestPin/:optOut";

    const optOutModalAndReqPinApi =
        "/participant-web-services/rest/partialauth/optOutModalRequestPin/:optOut";

    const optOutQueryAndReqPinApi =
        "/participant-web-services/rest/partialauth/optOutQueryRequestPin/:optOut";

    const optOutErrorAndReqPinApi =
        "/participant-web-services/rest/partialauth/optOutErrorRequestPin/:optOut";

    const optExpiredReqPinApi =
        "/participant-web-services/rest/partialauth/otpExpiredRequestPin/:optOut";

    const optInConsentApi =
        "/participant-web-services/rest/partialauth/idProof/optInConsent/WNIDPQ";

    return {
        retrieveIDProofQueries: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrieveIDProofQueriesApi,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        submitIDProofAnswers: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + submitIDProofAnswersApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        requestPin: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + requestPinApi,
            { optOut: "@optOut" },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        optOutModalAndReqPin: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + optOutModalAndReqPinApi,
            { optOut: "@optOut" },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        optOutWithExpiredOTPAndReqPin: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + optExpiredReqPinApi,
            { optOut: "@optOut" },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        optOutErrorAndReqPin: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + optOutErrorAndReqPinApi,
            { optOut: "@optOut" },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        optOutQueryAndReqPin: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + optOutQueryAndReqPinApi,
            { optOut: "@optOut" },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        optInConsent: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + optInConsentApi,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        )
    };
};

idProofFactory.$inject = ["$resource"];
export default idProofFactory;
