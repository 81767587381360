import React from "react";
import VerificationCodeEntry from "./verificationCodeEntry/VerificationCodeEntry";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ACTIVATION_CODE_DELIVERY_OPTIONS, VERIFY_CODE } from "../../routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../queries/constants";
import { TranslationsServiceProvider } from "../../providers/TranslationsServiceProvider";
import DeliveryOptions from "./deliveryOptions/DeliveryOptions";

interface MFAProps {
    $location: { path: (path: string) => void };
    $scope: { $apply: () => void; $root: { featureName: string } };
    linkingContext?: string;
    redirectService: {
        redirect: (data: { destinationUrl?: string; state: string }, key: string) => void;
    };
}

/**
 * TODO: DELETE THIS COMPONENT WHEN ANGULAR IS REMOVED.
 * This is a temporary component that provides access to react router, react query, and the translations hook
 * while still allowing the new MFA components to be embedded in the Angular application.
 * Also temporarily pass the redirectService from the Angular app until that has been converted.
 */
const MFA = ({ redirectService: { redirect }, $location, $scope, linkingContext }: MFAProps) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: DEFAULT_STALE_TIME
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <TranslationsServiceProvider>
                <HashRouter>
                    <Routes>
                        <Route
                            path={ACTIVATION_CODE_DELIVERY_OPTIONS}
                            element={
                                <DeliveryOptions
                                    legacyRedirect={redirect}
                                    linkingContext={linkingContext}
                                    location={$location}
                                    scope={$scope}
                                />
                            }
                        ></Route>
                        <Route
                            path={VERIFY_CODE}
                            element={
                                <VerificationCodeEntry
                                    legacyRedirect={redirect}
                                    linkingContext={linkingContext}
                                />
                            }
                        />
                    </Routes>
                </HashRouter>
            </TranslationsServiceProvider>
        </QueryClientProvider>
    );
};

export default MFA;
