export enum AuthActionTypes {
    FAILED_AUTHENTICATION = "FAILED_AUTHENTICATION",
    INVALID_AUTHENTICATION = "INVALID_AUTHENTICATION",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    PROCESSING_AUTHENTICATION = "PROCESSING_AUTHENTICATION",
    RESET_AUTHENTICATION_STATE = "RESET_AUTHENTICATION_STATE",
    SET_AUTHENTICATION_ERROR_CODE = "SET_AUTHENTICATION_ERROR_CODE",
    SET_AUTHENTICATION_ERROR_MESSAGE_PARAMS = "SET_AUTHENTICATION_ERROR_MESSAGE_PARAMS"
}

interface LoginSuccessAction {
    payload: Omit<AuthenticationState, "errorMessageParams" | "errorCode" | "processing">;
    type: AuthActionTypes.LOGIN_SUCCESS;
}

interface ResetAuthenticationAction {
    type: AuthActionTypes.RESET_AUTHENTICATION_STATE;
}

interface InvalidAuthenticationAction {
    type: AuthActionTypes.INVALID_AUTHENTICATION;
}

interface ProcessingAuthenticationAction {
    type: AuthActionTypes.PROCESSING_AUTHENTICATION;
}

interface FailedAuthenticationAction {
    type: AuthActionTypes.FAILED_AUTHENTICATION;
}

interface SetAuthenticationErrorCodeAction {
    payload: AuthenticationState;
    type: AuthActionTypes.SET_AUTHENTICATION_ERROR_CODE;
}

interface SetAuthenticationErrorMessageParamsAction {
    payload: { errorMessageParams: ErrorMessageParams };
    type: AuthActionTypes.SET_AUTHENTICATION_ERROR_MESSAGE_PARAMS;
}

export type AuthAction =
    | ResetAuthenticationAction
    | InvalidAuthenticationAction
    | ProcessingAuthenticationAction
    | LoginSuccessAction
    | FailedAuthenticationAction
    | SetAuthenticationErrorCodeAction
    | SetAuthenticationErrorMessageParamsAction;

export interface ErrorMessageParams {
    ALTERNATIVE_LOGN_URL?: string;
    EndLockTime?: string;
    attempts?: string;
    code: string;
}

interface AuthenticationState {
    errorCode?: string;
    errorMessage?: string;
    errorMessageParams?: ErrorMessageParams;
    flowName?: string;
    processing?: "failed" | "pending" | "loginsuccess" | "";
    userId?: string;
}

export const initialState: AuthenticationState = {
    processing: "",
    errorMessage: "",
    errorCode: "",
    errorMessageParams: {
        code: "",
        attempts: ""
    },
    userId: "",
    flowName: ""
};

const useAuthenticateReducer = (state = initialState, action: AuthAction): AuthenticationState => {
    switch (action.type) {
        case AuthActionTypes.RESET_AUTHENTICATION_STATE:
            return initialState;
        case AuthActionTypes.PROCESSING_AUTHENTICATION:
            return {
                ...state,
                processing: "pending"
            };
        case AuthActionTypes.INVALID_AUTHENTICATION:
            return {
                ...state,
                errorMessage: "missingInput",
                errorCode: "missingInput",
                processing: "failed"
            };
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                processing: "loginsuccess",
                ...action.payload
            };
        case AuthActionTypes.FAILED_AUTHENTICATION:
            return {
                ...state,
                processing: "failed"
            };
        case AuthActionTypes.SET_AUTHENTICATION_ERROR_CODE:
            return {
                ...state,
                ...action.payload
            };

        case AuthActionTypes.SET_AUTHENTICATION_ERROR_MESSAGE_PARAMS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default useAuthenticateReducer;
