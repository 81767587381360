import findNodeData from "../findNodeData";
import { CustomNode } from "../../types";

const findNodeType = ({
    domNode,
    tagType
}: {
    domNode: CustomNode;
    tagType: Element["nodeName"];
}) => {
    if (!domNode || !tagType) {
        return "";
    }

    const data = findNodeData({ domNode, tagType });

    if (!data) {
        return "";
    }

    const dataString = String(data);

    if (dataString.includes("accuCustomization")) {
        if (dataString.includes("preLoginBanner")) {
            return "banner";
        }
        if (dataString.includes("preLoginArticleOne")) {
            return "articleOne";
        }
        if (dataString.includes("preLoginArticleTwo")) {
            return "articleTwo";
        }
        if (dataString.includes("preLoginArticleThree")) {
            return "articleThree";
        }
        if (dataString.includes("global")) {
            return "global";
        }
    }

    return "";
};

export default findNodeType;
