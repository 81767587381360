import React from "react";
import AccountRecoveryErrorLabel from "./AccountRecoveryErrorLabel";

interface ConfirmPasswordErrorMessagesProps {
    errorsBag: object;
    label: string;
    translations: TranslationsConfirmPassword;
    wasFocus?: boolean;
}

interface TranslationsConfirmPassword {
    [key: string]: string | object;
    confirmPasswordNotMatch: string;
    confirmPasswordRequired: string;
}

const ConfirmPasswordErrorMessages = ({
    label,
    errorsBag,
    translations,
    wasFocus
}: ConfirmPasswordErrorMessagesProps) => {
    const errorBag = errorsBag[label];
    if (!errorBag) {
        return "";
    }
    return (
        <div className={`rule-validations ${wasFocus ? "rule-validations-danger" : ""}`}>
            <AccountRecoveryErrorLabel
                error={translations.confirmPasswordRequired}
                completed={errorBag.required}
            />
            <AccountRecoveryErrorLabel
                error={translations.confirmPasswordNotMatch}
                completed={errorBag.match}
            />
        </div>
    );
};

export default ConfirmPasswordErrorMessages;
