import investmentInformationFormViewTemplate from "./InvestmentInformationFormViewTemplate";
import investmentInformationResultsViewTemplate from "./InvestmentInformationResultsViewTemplate";

const InvestmentInformationRoutes = function ($stateProvider) {
    $stateProvider

        .state("investmentInformation", {
            url: "/investmentInformation",
            template: investmentInformationFormViewTemplate,
            controller: "investmentInformationFormController",
            controllerAs: "ctrl",
            data: {
                fullHeight: true
            }
        })

        .state("investmentInformationResults", {
            url: "/investmentInformation/:groupId",
            template: investmentInformationResultsViewTemplate,
            controller: "investmentInformationResultsController",
            controllerAs: "ctrl"
        });
};

InvestmentInformationRoutes.$inject = ["$stateProvider"];
export default InvestmentInformationRoutes;
