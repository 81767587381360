const registrationStreetAddressMessagesTemplate = `
<ng-messages 
    for="getFieldError('streetAddress');"
    ng-show="displayFieldMessages('streetAddress', focusMgr['streetAddress']);"
    multiple
    data-ng-class="getValidationErrorClass('streetAddress');"
>
    <ng-message when="required">
        {{"streetAddressRequired" | translate}}
    </ng-message>
    <ng-message when="addressPattern">
        {{"streetAddressInvalid"  | translate }}
    </ng-message>
</ng-messages>
`;
export default registrationStreetAddressMessagesTemplate;
