import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../services/translationService";

export default class BeneficiariesEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            plans: this.props.plans,
            pinAuthCode: this.props.pinAuthCode
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    handelGetBeneficiaryName = (value) => {
        return this.props.getBeneficiaryName(value);
    };

    render() {
        if (!this.state.translations) {
            return null;
        }

        return (
            <div className="profile-beneficiaries">
                <h2 className="margin-top-0">
                    {this.state.translations.profile.beneficiaries.headline}
                </h2>

                {this.state.plans &&
                    this.state.plans.map((plan) => (
                        <section key={plan.planName}>
                            {plan.beneficiaries.Primary.length +
                                plan.beneficiaries.Contingent.length >
                                0 && (
                                <div className="profile-information beneficiaries-information">
                                    <div className="row">
                                        <h3 className="col-md-12 margin-top-0">{plan.planName}</h3>

                                        {this.state.pinAuthCode === "U" &&
                                            plan.beneUAccess === "true" && (
                                                <div className="btn-container">
                                                    <a
                                                        href={
                                                            "/participant/accounts/#/account/" +
                                                            plan.indId +
                                                            "/" +
                                                            plan.gaId +
                                                            "/beneficiary"
                                                        }
                                                        className="btn btn-primary"
                                                    >
                                                        {
                                                            this.state.translations.preference
                                                                .button.changeBeneficiaries
                                                        }
                                                    </a>
                                                </div>
                                            )}
                                    </div>

                                    {plan.beneficiaries.Primary &&
                                        plan.beneficiaries.Primary.length > 0 && (
                                            <div className="row beneficiaries-primary">
                                                <strong className="col-sm-4 text-right">
                                                    {
                                                        this.state.translations.profile
                                                            .beneficiaries.label.primary
                                                    }
                                                </strong>

                                                <div className="col-sm-4">
                                                    <table>
                                                        <tbody>
                                                            {plan.beneficiaries.Primary &&
                                                                plan.beneficiaries.Primary.map(
                                                                    (primary) => (
                                                                        <tr
                                                                            key={
                                                                                primary.beneficiaryFirstName
                                                                                    ? primary.beneficiaryFirstName
                                                                                    : primary.beneficiaryLastName
                                                                            }
                                                                        >
                                                                            <td>
                                                                                {
                                                                                    primary.allocationPercent
                                                                                }
                                                                                %
                                                                            </td>
                                                                            <td>
                                                                                {this.handelGetBeneficiaryName(
                                                                                    primary
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                    {plan.beneficiaries.Contingent &&
                                        plan.beneficiaries.Contingent.length > 0 && (
                                            <div className="row beneficiaries-contingent">
                                                <strong className="col-sm-4 text-right">
                                                    {
                                                        this.state.translations.profile
                                                            .beneficiaries.label.contingent
                                                    }
                                                </strong>

                                                <div className="col-sm-4">
                                                    <table>
                                                        <tbody>
                                                            {plan.beneficiaries.Contingent &&
                                                                plan.beneficiaries.Contingent.map(
                                                                    (contingent) => (
                                                                        <tr
                                                                            key={
                                                                                contingent.beneficiaryFirstName
                                                                                    ? contingent.beneficiaryFirstName
                                                                                    : contingent.beneficiaryLastName
                                                                            }
                                                                        >
                                                                            <td>
                                                                                {
                                                                                    contingent.allocationPercent
                                                                                }
                                                                                %
                                                                            </td>
                                                                            <td>
                                                                                {this.handelGetBeneficiaryName(
                                                                                    contingent
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )}
                        </section>
                    ))}
                <hr />
            </div>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

BeneficiariesEditComponent.propTypes = {
    plans: PropTypes.array.isRequired,
    pinAuthCode: PropTypes.string.isRequired,
    getBeneficiaryName: PropTypes.func.isRequired
};
