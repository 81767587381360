import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

import idProofEvents from "./events/IDProofEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const otpVerificationCodeEntryController = function (
    $scope,
    $injector,
    eventBus,
    $stateParams,
    redirectService,
    otsAccountOpenFactory,
    otsRegistrationService
) {
    const vm = this;
    const $state = $injector.get("$state");
    const logger = ExternalLogger.getInstance("otpVerificationCodeEntryController");

    $scope.isOtsUser = $stateParams.flowName === "OTS";
    vm.options = {
        onConfirm: function (phoneNumber, data) {
            logger.debug("otpVerificationCodeEntryController - onConfirmClick - [{0}]", [
                phoneNumber ? phoneNumber : null
            ]);
            eventBus.dispatch(idProofEvents.OTP_AGREE_ENTER_CODE, this);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: idProofEvents.OTP_AGREE_ENTER_CODE
                }
            });
            const params = {
                phoneNumber: phoneNumber ? phoneNumber : null
            };
            if (data.flowName === "otsAccountSetup") {
                //Get session data
                const formData = otsRegistrationService.getFormData();

                // IF OTS Call Shadowaccount if successfull redirect otherwise throw error
                otsAccountOpenFactory
                    .createShadowAccountOTS(phoneNumber)
                    .then(
                        //Get data and redirect
                        function () {
                            redirectService.redirect(data, "ALL", formData.context);
                        }
                    )
                    .catch(function () {
                        $state.go("idProofError", { errorCode: "idProofOTS" });
                    });
            } else {
                $state.go("accountSetup", params);
            }
        },

        onOtpError: function (state) {
            logger.debug("state - [{0}]", [state ? state : null]);
            const params = {
                optOut: "optOut-Modal"
            };
            if ($scope.isOtsUser) {
                $state.go("idProofError", { errorCode: "idProofOTS" });
            } else {
                $state.go("mailPin", params);
            }
        },
        phoneNumber: null
    };

    vm.phoneNumber = null;
    vm.getPhoneNumber = function () {
        if ($state.params && $state.params.phoneNumber) {
            const phoneNumberParam = $state.params.phoneNumber;
            vm.phoneNumber = phoneNumberParam.replace(/\D+/g, "");
        }

        return vm.phoneNumber;
    };

    const otpService = $injector.get("otpService");
    vm.openOTPNoCodeModal = otpService.openOTPNoCodeModalModal;

    vm.init = function () {
        const method = "init()";
        const phoneNumber = vm.getPhoneNumber();
        if (phoneNumber) {
            vm.options.phoneNumber = phoneNumber;
            logger.debug("otpVerificationCodeEntryController - {0} - phoneNumber provided: [{1}]", [
                method,
                phoneNumber
            ]);
        } else {
            logger.debug("otpVerificationCodeEntryController - {0} - phoneNumber NOT provided.", [
                method
            ]);
        }
    };

    vm.init();
};

otpVerificationCodeEntryController.$inject = [
    "$scope",
    "$injector",
    "eventBus",
    "$stateParams",
    "redirectService",
    "otsAccountOpenFactory",
    "otsRegistrationService"
];
export default otpVerificationCodeEntryController;
