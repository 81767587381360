const SetAccuService = function ($resource) {
    return $resource(
        __baseApiUrl__ + "/participant-web-services/rest/nonauth/setAccu/:accu",
        { accu: "@accu" },
        {
            query: {
                method: "GET",
                isArray: false
            }
        }
    );
};

SetAccuService.$inject = ["$resource", "$window"];
export default SetAccuService;
