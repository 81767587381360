import React from "react";

import AccountRecoveryMultipleUser from "../AccountRecoveryMultipleUser";
import RecoveryWrapper, { RecoveryWrapperProps } from "./RecoveryWrapper";

const AccountRecoveryMultipleUserContainer = ({
    redirectService,
    reactFlow
}: Omit<RecoveryWrapperProps, "wrapElement">) => {
    return (
        <RecoveryWrapper
            reactFlow={reactFlow}
            redirectService={redirectService}
            wrapElement={(redirect) => <AccountRecoveryMultipleUser redirect={redirect} />}
        />
    );
};
export default AccountRecoveryMultipleUserContainer;
