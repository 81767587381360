import "angular";
import "angular-resource";
import "angular-ui-router";
import StateInterceptor from "../core/StateInterceptor";

import idProofConsentController from "./IDProofConsentController";
import idProofController from "./IDProofController";
import idProofFactory from "./IDProofFactory.js";
import idProofRouteStates from "./IDProofRoute";
import mailPinController from "./MailPinController";
import otpIdentityVerificationController from "./otpIdentityVerificationController";
import otpNoCodeController from "./otpNoCodeController";
import otpService from "./otpService";
import otpVerificationCodeEntryController from "./otpVerificationCodeEntryController";

// eslint-disable-next-line no-undef
const idProof = angular.module("idProof", ["ngResource", "ui.router", "focusDirectives"]);

idProof.factory("StateInterceptor", StateInterceptor);
idProof.config([
    "$httpProvider",
    function ($httpProvider) {
        $httpProvider.interceptors.push("StateInterceptor");
    }
]);

idProof.controller("idProofController", idProofController);
idProof.controller("mailPinController", mailPinController);
idProof.controller("idProofConsentController", idProofConsentController);
idProof.controller("otpNoCodeController", otpNoCodeController);
idProof.controller("otpIdentityVerificationController", otpIdentityVerificationController);
idProof.controller("otpVerificationCodeEntryController", otpVerificationCodeEntryController);
idProof.service("otpService", otpService);
idProof.config(idProofRouteStates);
idProof.factory("idProofFactory", idProofFactory);

export default idProof;
