import { executePost } from "../../../../utils/appUtils";
import ReferenceData from "../../../../constants/ReferenceData";

const submitIDProofAnswers = async (idProofSelDataObj) => {
    try {
        const response = await executePost(
            ReferenceData.SUBMIT_IDPROOF_QUESTIONS,
            "submitIdProofQuestionsAccountFault",
            { ...idProofSelDataObj }
        );
        return response.data;
    } catch (error) {
        throw new Error("Failed to fetch ID proofing questions");
    }
};
export default submitIDProofAnswers;
