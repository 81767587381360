import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _ from "lodash";

import ErrorViewTemplate from "../error/ErrorViewTemplate";

import registrationEvents from "./events/RegistrationEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import EDeliveryService from "core-ui/client/src/app/core/eDelivery/EDeliveryService";

const registrationSummaryController = function (
    $injector,
    $modal,
    $q,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $translate,
    dataService,
    deepLinkFactory,
    eventBus,
    beneficiaryTypeService,
    beneficiaryService,
    redirectService,
    registrationFactory,
    TransactionAccessRetrService
) {
    const logger = ExternalLogger.getInstance("RegistrationSummaryController");
    const vm = this;
    vm.summary = null;
    vm.beneficiaries = null;
    vm.beneficiariesTypes = null;
    vm.model = null;
    vm.isDataLoaded = false;

    vm.allowEDelivery = false;
    vm.selectedEmail = null;
    vm.consentInd = null;
    vm.translate = {
        eDeliveryError: $translate.instant("preference.eDelivery.errorMessage")
    };

    function retriveRegistrationSummaryData() {
        registrationFactory.registrationSummary.query(
            function (data) {
                if (data !== null) {
                    vm.summary = data;
                    vm.groupedInvestmentOptions = !_.isEmpty(vm.summary.investmentOptions)
                        ? _.groupBy(vm.summary.investmentOptions, "groupingCode")
                        : null;
                    vm.groupingCodes = Object.keys(vm.groupedInvestmentOptions).length;
                }
            },
            function (error) {
                logger.error("retrieve registration summary data  failed", error);
                if (error?.data?.status == "FAIL") {
                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "registration - registrationSummary.query failed",
                        event_properties: {
                            login_error_code: error?.data?.error?.code,
                            error_message: error?.data?.error?.errors[0]
                        }
                    });
                }
            }
        );
    }

    $scope.init = function () {
        const deferred = $q.defer();
        const primaryPlanService = $injector.get("primaryPlanService");
        // String to pass to EventBus.Dispatch on registration success
        const PPGASTRING = "Primary Plan Success Info";
        setTimeout(function () {
            primaryPlanService
                .getPrimaryPlan()
                .then(onGetPrimaryPlanSuccess, onGetPrimaryPlanFailure);
        }, 10);

        function onGetPrimaryPlanSuccess(plan) {
            if (plan.indId && plan.gaId && plan.indId !== undefined && plan.gaId !== undefined) {
                retriveRegistrationSummaryData();

                //ONLY fetch benens if benes are enabled
                const txnCodeParams = {
                    txnCode: "WNBEN",
                    individualId: plan.indId,
                    groupId: plan.gaId
                };

                if ($rootScope.featureName !== undefined) {
                    $rootScope.deeplinkCust.gaId = plan.gaId;
                }

                //send planId and individualID to analytics on get Primary plan success
                eventBus.dispatch(PPGASTRING, this, {
                    gaId: plan.gaId,
                    indId: plan.indId
                });

                eventBus.dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.VIEW_PAGE,
                    event_properties: {
                        selection: PPGASTRING,
                        gaId: plan.gaId,
                        indId: plan.indId
                    }
                });

                TransactionAccessRetrService.queryWithAccount(
                    txnCodeParams,
                    function (response) {
                        if (response && response.WNBEN && response.WNBEN !== "false") {
                            dataService.getBeneficiaries(plan.gaId).then(function (beneficiaries) {
                                vm.beneficiaries = beneficiaries;
                                vm.beneficiaries.qjsaVerificationRequired = false;
                            });
                            dataService.getBeneficiaryTypes(plan.gaId).then(function (beneTypes) {
                                vm.beneficiariesTypes = beneTypes;
                                beneficiaryTypeService.setTypes(vm.beneficiariesTypes);
                            });
                        }
                    },
                    function (error) {
                        //Error with transaction access call
                        logger.error("transaction authoriziation retrieval error", error);
                        if (error?.data?.status == "FAIL") {
                            eventBus.dispatchAmplitude({
                                event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                                event_action:
                                    "registration - transaction access.queryWithAccount failed",
                                event_properties: {
                                    login_error_code: error?.data?.error?.code,
                                    error_message: error?.data?.error?.errors[0]
                                }
                            });
                        }
                    }
                );
                vm.loadCommunicationPreferences(plan.gaId);
            }

            deferred.resolve();
        }

        function onGetPrimaryPlanFailure(error) {
            if (error?.data?.status == "FAIL") {
                eventBus.dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                    event_action: "registration - getPrimaryPlan failed",
                    event_properties: {
                        login_error_code: error?.data?.error?.code,
                        error_message: error?.data?.error?.errors[0]
                    }
                });
            }
            deferred.reject(error);
        }

        return deferred.promise;
    };

    /**
     * Load the participant's communication preferences.
     *
     * @param  {String}  gaId The participant's group id
     * @return {Promise}      A promise to load the participant's current communication preferences into the
     *                        e-delivery service and update the flag to show the communication preferences section
     */
    vm.loadCommunicationPreferences = async (gaId) => {
        const eDeliveryResponse = await EDeliveryService().initialize(gaId);
        vm.allowEDelivery =
            eDeliveryResponse.allowedToOverwrite === "true" && eDeliveryResponse.statusCode == "A";
        if (vm.allowEDelivery) {
            vm.selectedEmail = eDeliveryResponse.econsentemailAddress;
            vm.consentInd = !!eDeliveryResponse.eDeliveryOption;
            vm.gaId = gaId;
            vm.eDeliveryInitData = eDeliveryResponse;
        }
    };

    /*to get the bene types*/
    $scope.getTypeOrRelationshipDisplay = function (beneficiaries) {
        return beneficiaryService.getTypeOrRelationshipDisplay(
            beneficiaries,
            beneficiaryTypeService.getTypes()
        );
    };

    vm.newSubmitEDeliveryAndNavigate = (event, selectedEmail, consentInd) => {
        vm.selectedEmail = selectedEmail;
        vm.consentInd = consentInd;
    };

    /**
     * When the "Continue to My Account" link is clicked, we need to submit the e-delivery preference before moving
     * on to My Account.
     *
     * @param {Object} $event The click event that triggered this function
     */
    vm.submitEDelivery = async () => {
        const updateParticipantEConsent = await EDeliveryService().updateParticipantEConsent(
            "registrationFlow",
            vm.gaId,
            vm.selectedEmail,
            vm.consentInd,
            eventBus
        );

        if (updateParticipantEConsent) {
            vm.continueToMyAccount();
        } else {
            // There was a problem saving the communication preference. Display an modal dialog and continue
            // with navigation when it is dismissed.
            const modal = $modal.open({
                template: ErrorViewTemplate,
                size: "md",
                resolve: {
                    errorMessage: function () {
                        return vm.translate.eDeliveryError;
                    }
                },
                controller: [
                    "$scope",
                    "errorMessage",
                    function ($scope, errorMessage) {
                        $scope.errorMessage = errorMessage;
                    }
                ]
            });

            modal.result.then(function () {
                vm.continueToMyAccount();
            });
        }
    };

    /**
     * Complete the "Continue to My Account" workflow after the [optional] e-delivery preference has been updated.
     */
    vm.continueToMyAccount = function () {
        if ($rootScope.regData !== undefined && $rootScope.regData !== null) {
            $rootScope.regData.destinationUrl = $rootScope.regData.postRegSummaryUrl;
        }
        // set flag for deeplink featureName
        // if this flag is true than redirect to deeplink
        if ($rootScope.featureName !== undefined) {
            const handleError = () => {
                redirectService.redirect({
                    destinationUrl: "participant/accounts/#/",
                    flowName: "deepLink"
                });
            };
            const jsonPostForDest = {
                authTxnCodes: $rootScope.deeplinkCust.authTxnCodes,
                deepLinkedUrl: $rootScope.deeplinkCust.url,
                gaID: $rootScope.deeplinkCust.gaId,
                targetTxnCode: $rootScope.deeplinkCust.menuAccessCode
            };

            deepLinkFactory.getDestination.query(
                jsonPostForDest,
                function (data) {
                    if (data.deepLinkEligible) {
                        redirectService.redirect(data, "ALL", "RegistrationSummary");
                    } else {
                        handleError();
                    }
                },
                function () {
                    handleError();
                }
            );
        } else {
            registrationFactory.registrationflowAndDestination.query(
                function (data) {
                    if (data !== null) {
                        redirectService.redirect(data, "ALL", "RegistrationSummary");
                    }
                },
                function (response) {
                    if (response.headers("exception") !== null) {
                        $scope.registrationSummaryMessage = response.data.error.code;
                        $scope.registrationSummaryMessageParams = response.data.error.errors[0];
                    }
                }
            );
        }
    };

    function onInitSuccess() {
        vm.isDataLoaded = true;
    }

    function onInitFailure() {
        // TODO: Refactor this to use logger
        console.error(" registration summary initiation failed !  ");

        vm.isDataLoaded = true;
    }

    $scope.init().then(onInitSuccess, onInitFailure);

    //----EVENTS------
    $scope.printClick = function () {
        eventBus.dispatch(registrationEvents.PRINT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: registrationEvents.PRINT
            }
        });
    };
    $scope.otherContribToggle = function (val) {
        eventBus.dispatch(registrationEvents.OTHER_CONTRIB_TOGGLE, this, {
            value: val
        });
    };

    $scope.agreeClick = function () {
        eventBus.dispatch(registrationEvents.AGREE, this);
    };
};

registrationSummaryController.$inject = [
    "$injector",
    "$modal",
    "$q",
    "$rootScope",
    "$scope",
    "$state",
    "$timeout",
    "$translate",
    "dataService",
    "deepLinkFactory",
    "eventBus",
    "beneficiaryTypeService",
    "beneficiaryService",
    "redirectService",
    "registrationFactory",
    "TransactionAccessRetrService"
];
export default registrationSummaryController;
