import "angular";
import "angular-resource";
import "angular-ui-router";
import "core-ui/client/src/app/MaskDirective";
import "core-ui/client/src/app/PhoneNumberDirective";
import "../core/ValidationDirectives";
import PlanFactory from "core-ui/client/src/app/PlanFactory";

import ContactDetailsFactory from "../core/ContactDetailsFactory";
import InternationalPhoneNumUtil from "../core/InternationalPhoneNumUtil";
import PersonalContactInfoCustomValidator from "../core/PersonalContactInfoCustomValidator";
import PersonalContactInfoUpdateUtil from "../core/PersonalContactInfoUpdateUtil";
import EConsentController from "../eConsent/EConsentController";
import EConsentFactory from "../eConsent/EConsentFactory";
import EConsentModalController from "../eConsent/EConsentModalController";

import authorizedDevicesController from "./AuthorizedDevicesController";
import HomeAddressController from "./HomeAddressController";
import HomeAddressFactory from "./HomeAddressFactory";
import phoneFormatter from "./PhoneFormatter";
import profileChangePasswordController from "./ProfileChangePasswordController";
import ProfileContactInfoController from "./ProfileContactInfoController";
import ProfileCredentialsController from "./ProfileCredentialsController";
import profileFactory from "./ProfileFactory";
import profileRoute from "./ProfileRoute";
import ProfileService from "./ProfileService";

// eslint-disable-next-line no-undef
const profile = angular.module("profile", [
    "ngMessages",
    "ngResource",
    "ui.router",
    "validationDirectives",
    "MaskDirective",
    "PhoneNumberDirective"
]);

profile.controller("ProfileContactInfoController", ProfileContactInfoController);
profile.controller("EConsentController", EConsentController);
profile.controller("EConsentModalController", EConsentModalController);
profile.controller("HomeAddressController", HomeAddressController);
profile.controller("ProfileCredentialsController", ProfileCredentialsController);
profile.controller("profileChangePasswordController", profileChangePasswordController);
profile.controller("authorizedDevicesController", authorizedDevicesController);
profile.filter("phoneFormatter", phoneFormatter);
profile.factory("EConsentFactory", EConsentFactory);
profile.factory("profileFactory", profileFactory);
profile.factory("HomeAddressFactory", HomeAddressFactory);
profile.service("profileService", ProfileService);
profile.factory("planFactory", PlanFactory);
profile.factory("ContactDetailsFactory", ContactDetailsFactory);
profile.factory("InternationalPhoneNumUtil", InternationalPhoneNumUtil);
profile.service("PersonalContactInfoUpdateUtil", PersonalContactInfoUpdateUtil);
profile.service("PersonalContactInfoCustomValidator", PersonalContactInfoCustomValidator);
profile.config(profileRoute);

export default profile;
