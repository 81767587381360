/**
 *
 * @name app.accountSelection.events.AccountSelectionEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for account selection pages
 */
"use strict";

const root = "AccountSelectionEvent.";

const AccountSelectionEvents = {
    VIEW: root + "view_link_clicked_event",
    NEXT_GEN_VIEW: root + "next_gen_view_link_clicked_event"
};

export default AccountSelectionEvents;
