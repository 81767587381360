import allFlowsContactInfoUpdateTemplate from "../core/allFlowsContactInfoUpdateTemplate";

const changePersonalContactTemplate = `
<!-- React Component
<pwr-change-personal-contact-component
  cancel="cancel"
  updatePersonalContact="updatePersonalContact"
>
</pwr-change-personal-contact-component>
-->

<div class="modal-header" data-ng-init="retrievePersonalContactInfo()">
    <div class="modal-title" id="modal-title" role="heading" aria-level="2">{{ "profile.personal.header.changePersonal" | translate }}</div>
</div>
<div class="modal-body clearfix">
    <div class="modal-well with-background">
        <form role="form" name="personalContactForm"  class="clearfix" autocomplete="off" novalidate>
            ${allFlowsContactInfoUpdateTemplate}
        </form>
    </div>
</div>

<div class="modal-footer">
    <button ng-click="cancel('personal_contact_info')" class="btn btn-link">{{ "preference.button.cancel" | translate }}</button>
    <button ng-click="updatePersonalContact('profile')" class="btn btn-primary" id="submit" ng-disabled="showSpinner || !updateIsAllowed()">

		 <span data-ng-hide="showSpinner==true">
		    {{ "preference.button.save" | translate }}
		 </span>
		                         
		<span data-ng-show="showSpinner==true">
            <i class="fa fa-circle-o-notch fa-spin"></i>
            Processing ...
		</span>
  </button>
</div>
`;
export default changePersonalContactTemplate;
