const InboundSSOAuthFactory = function ($resource, $window) {
    let urlToRedirect = "rest/nonauth/inBoundSSOValidation";
    const query = $window?.location?.href?.split("#")[1]?.split("?")[1];
    const params = Object.fromEntries(new URLSearchParams(query));
    const paramValue = params?.ssoType;
    if (paramValue) {
        console.log("ssotype parameter detected redirecting to inBoundSSOAsPAEAuth");
        urlToRedirect = "rest/nonauth/inBoundSSOAsPAEAuth";
    }

    return $resource(
        __authenticationUrl__ + urlToRedirect,
        {},
        {
            query: {
                method: "POST",
                isArray: false
            }
        }
    );
};

InboundSSOAuthFactory.$inject = ["$resource", "$window"];
export default InboundSSOAuthFactory;
