import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

const mailPinController = function ($rootScope, $scope, $state, $stateParams, idProofFactory) {
    const logger = ExternalLogger.getInstance("mailPinController");
    const vm = this;
    $rootScope.$state = $state;
    $scope.heading = "";
    vm.loading = true;
    logger.debug("Inside controller");
    if ($stateParams.optOut === "optOut-Modal" || $stateParams.optOut === "optOut-Query") {
        $scope.heading = "pinRequestedHeding";
        logger.debug($stateParams.optOut);
    } else if ($stateParams.optOut === "otp-Expired") {
        $scope.heading = "otpExpiredHeading";
    } else {
        $scope.heading = "mailPinDefaultHeading";
    }

    $scope.phone = $rootScope?.siteContactInfo?.phone || "1-855-756-4738";

    $scope.isOptOutParams = {
        optOut: $stateParams.optOut
    };

    if ($stateParams.optOut === "optOut-Modal") {
        idProofFactory.optOutModalAndReqPin.query(
            $scope.isOptOutParams,
            //success
            function (data, headers) {
                vm.loading = true;
                if (data !== null && data.status == "successful") {
                    vm.loading = false;
                } else if (!data.success) {
                    vm.loading = false;
                    $state.go("idProofError", { errorCode: "defaultErrorCode" });
                }

                if (headers("exception") !== null) {
                    vm.loading = false;
                } else {
                    vm.loading = false;
                }
            },
            function () {
                vm.loading = false;
            }
        ); //End of query function
    } else if ($stateParams.optOut === "otp-Expired") {
        idProofFactory.optOutWithExpiredOTPAndReqPin.query(
            $scope.isOptOutParams,
            //success
            function (data, headers) {
                vm.loading = true;
                if (data !== null && data.status == "successful") {
                    vm.loading = false;
                } else if (!data.success) {
                    vm.loading = false;
                    $state.go("idProofError", { errorCode: "defaultErrorCode" });
                }

                if (headers("exception") !== null) {
                    vm.loading = false;
                } else {
                    vm.loading = false;
                }
            },
            function () {
                vm.loading = false;
            }
        ); //End of query function
    } else if ($stateParams.optOut === "optOut-Query") {
        idProofFactory.optOutQueryAndReqPin.query(
            $scope.isOptOutParams,
            //success
            function (data, headers) {
                vm.loading = true;
                if (data !== null && data.status == "successful") {
                    vm.loading = false;
                } else if (!data.success) {
                    vm.loading = false;
                    $state.go("idProofError", { errorCode: "defaultErrorCode" });
                }

                if (headers("exception") !== null) {
                    vm.loading = false;
                } else {
                    vm.loading = false;
                }
            },
            function () {
                vm.loading = false;
            }
        ); //End of query function
    } else if ($stateParams.optOut === "optOut-Error") {
        idProofFactory.optOutErrorAndReqPin.query(
            $scope.isOptOutParams,
            //success
            function (data, headers) {
                vm.loading = true;
                if (data !== null && data.status == "successful") {
                    vm.loading = false;
                } else if (!data.success) {
                    vm.loading = false;
                    $state.go("idProofError", { errorCode: "defaultErrorCode" });
                }

                if (headers("exception") !== null) {
                    vm.loading = false;
                } else {
                    vm.loading = false;
                }
            },
            function () {
                vm.loading = false;
            }
        ); //End of query function
    }
};

mailPinController.$inject = ["$rootScope", "$scope", "$state", "$stateParams", "idProofFactory"];
export default mailPinController;
