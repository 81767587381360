import React, { useState, useEffect } from "react";

import TranslationService from "../../services/translationService";

import RegistrationContext from "./container/RegistrationContext";
const Registration = () => {
    const [translations, setTranslations] = useState();
    const [hide] = useState(true);
    useEffect(() => {
        TranslationService.getTranslations((resp) => {
            setTranslations(resp.button.continue);
        });
    }, []);

    const showContinuation = (continuing) => {
        const fragment = (
            <React.Fragment>
                <span hidden={!hide}>{continuing}</span>
                <span hidden={hide}>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    Processing ...
                </span>
            </React.Fragment>
        );
        return fragment;
    };

    const getNextPage = () => {
        console.log("testing");
    };

    const getRegistration = () => {
        const continuing =
            translations && translations.button && translations.button.continue
                ? translations.button.continue
                : "Continue";
        const fragment = (
            <RegistrationContext.Provider value={RegistrationContext}>
                <div className="form-group clearfix">
                    <button
                        type="submit"
                        id="submit"
                        className="btn btn-primary btn-block btn-lg margin-top-default"
                        onClick={getNextPage()}
                    >
                        {showContinuation(continuing)}
                    </button>
                </div>
            </RegistrationContext.Provider>
        );
        return fragment;
    };

    return getRegistration();
};
export default Registration;
