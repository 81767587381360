import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import FormUtil from "core-ui/client/src/app/FormUtil";

/** Note:
 * Even if you think this code is really simillar to "EConsentModalController.js", we can not combine both in one controller, due to "$modalInstance"
 * that needs to be initiated in a previous screen to trigger the modal
 **/
const EConsentController = function (
    $rootScope,
    $scope,
    $state,
    EConsentFactory,
    profileFactory,
    redirectService
) {
    /** * Logger. */
    const logger = ExternalLogger.getInstance("EConsentController");

    $scope.submitted = false;
    $scope.primaryPlanT = false;
    $scope.email = {};
    $scope.eConsentDetails = {};
    $scope.sourcepage = $rootScope.flowName ? $rootScope.flowName : "mfa";

    // When Communication Preferences (aka eConsent) is displayed on the 180 day page, it reuses the modal from
    // the participant profile view. We want to add padding around the view for the standalone page but not the
    // modal, so here we check the fullHeight data parameter set in ./EConsentRoute.js and only add the padding
    // class for that page.
    $scope.isStandalonePage = $state.current.data && $state.current.data.fullHeight === true;

    retriveContactInfor();

    function retriveContactInfor() {
        profileFactory.retriveContactInfor.query(
            {},
            function (data) {
                if (data !== null && data.status == "successful") {
                    if (data.data.eConsentDetails && data.data.eConsentDetails.length > 0) {
                        // Default checkbox to checked if eDeliveryOption is 'NO_CONSENT_ANY_ELECTRONIC'
                        // eslint-disable-next-line no-undef
                        angular.forEach(data.data.eConsentDetails, function (detail) {
                            detail.pptConsentForElectronic = true;
                        });

                        $scope.eConsentDetails = data.data.eConsentDetails;
                        profileFactory.setEConsentDetails(data.data.eConsentDetails);
                        $scope.allowedToOverwrite = "true";
                    }

                    if (
                        data.data.taxStatementEConsentDetails &&
                        data.data.taxStatementEConsentDetails.length > 0
                    ) {
                        $scope.taxStatementEConsentDetails = data.data.taxStatementEConsentDetails;
                        profileFactory.setTaxStatementEConsentDetails(
                            data.data.taxStatementEConsentDetails
                        );
                        $scope.allowedToOverwrite = "true";
                    }

                    $scope.econsentemailAddress = data.data.econsentemailAddress;
                    profileFactory.setEconsentEmail(data.data.econsentemailAddress);
                    profileFactory.setSecondaryEmail(data.data.secondaryEmailAddress);

                    if (
                        profileFactory.getEconsentEmail() !== null &&
                        profileFactory.getEconsentEmail() !== ""
                    ) {
                        $scope.email.primary = profileFactory.getEconsentEmail();
                    }

                    if (
                        profileFactory.getSecondaryEmail() !== null &&
                        profileFactory.getSecondaryEmail() !== ""
                    ) {
                        $scope.email.secondary = profileFactory.getSecondaryEmail();
                    }

                    $scope.eConsent = {
                        emailchoice:
                            profileFactory.getEconsentEmail() || profileFactory.getSecondaryEmail(),
                        eConsentDetails: profileFactory.getEConsentDetails(),
                        taxStatementEConsentDetails: profileFactory.getTaxStatementEConsentDetails()
                    };
                }
            },

            function (error) {
                console.error("profileFactory.retriveContactInfor - ERROR: ", error);
            }
        );
    }

    /**
     *
     */
    $scope.filterInquiryOnlyPlans = function (details) {
        const arr = [];
        if (details) {
            // eslint-disable-next-line no-undef
            angular.forEach(details, function (detail) {
                if (detail.pinAuthCode === "U") {
                    arr.push(detail);
                }
            });
        }
        return arr;
    };

    $scope.updatePPTConsent = function () {
        logger.debug(" submitting eConsentDetails {0}", [
            JSON.stringify($scope.eConsent.eConsentDetails)
        ]);

        if ($scope.eConsentForm["email"]) {
            $scope.eConsentForm["email"].$setDirty();
        }

        if ($scope.eConsentForm.$valid) {
            $scope.showSpinner = true;

            // DEFC-15975 Continue the previous behavior of allowing the participant to submit their communication
            // preference when pinAuthCode is "i" for a plan on the 180 day communication preferences page only.
            // Ideally we would not even display the 180 day communication preferences page if all plans have a
            // pinAuthCode of "i" to be consistent with the profile behavior, but that will require additional work
            // that has not yet been scheduled.
            const filteredDetails =
                $state.current.name === "changeCommPref"
                    ? $scope.eConsent.eConsentDetails
                    : $scope.filterInquiryOnlyPlans($scope.eConsent.eConsentDetails);

            const updateEConsentParams = {
                email: $scope.eConsent.emailchoice || "",
                eConsentDetails: filteredDetails,
                taxStatementEConsentDetails: $scope.eConsent.taxStatementEConsentDetails,
                flowName: $scope.sourcepage
            };

            logger.debug("..submitting eConsentParams={0}", [JSON.stringify(updateEConsentParams)]);

            EConsentFactory.updatePPTEConsent.query(
                updateEConsentParams,
                function (data) {
                    $scope.showSpinner = false;
                    redirectService.redirect(data, "ALL", "econsent");
                },

                function (error) {
                    /*if  (error ==='44403') {
                        $modalInstance.dismiss('cancel');
                    }*/
                    $scope.showSpinner = false;

                    $scope.saveStatus = error.data.error.code;
                    if (error.data.error.code === "ERROR_CODE_PRIMARY_PLAN_T") {
                        $scope.primaryPlanT = true;
                    }
                    $scope.saveStatusParams = error.data.error.errors[0];
                }
            );
        }
    };

    /**
     *  Close modal window
     **/
    $scope.cancel = function () {
        logger.debug("does this click creds");
        //  $modalInstance.dismiss('cancel');
        $scope.eConsentForm.pptConsentForElectronic.$rollbackViewValue();
    };

    /**
     *  Return the name errors, of a field in a form
     **/
    $scope.getFieldError = function (fieldName) {
        return FormUtil.getFieldError($scope.eConsentForm, fieldName);
    };

    /**
     *  Return true if field is dirty
     **/
    $scope.isDirty = function (fieldName) {
        return FormUtil.isDirty($scope.eConsentForm, fieldName);
    };

    /**
     *  Return has-error if field is dirty and has any error
     **/
    $scope.getFormGroupClass = function (fieldName) {
        return FormUtil.getFormGroupClass($scope.eConsentForm, fieldName);
    };

    /**
     *  Each time the user change the e-delivery checks,
        this will detec if any of them is true, then will set mustHaveEmail as true
     **/
    $scope.updateEConsentDetail = function () {
        $scope.mustHaveEmail = false;

        angular.forEach($scope.taxStatementEConsentDetails, function (detail) {
            if (detail.pptConsentForElectronic === true) {
                $scope.mustHaveEmail = true;
            }
        });

        // eslint-disable-next-line no-undef
        angular.forEach($scope.eConsentDetails, function (detail) {
            if (detail.pptConsentForElectronic === true) {
                $scope.mustHaveEmail = true;
            }
        });
    };
    //Run first time to detect preloaded values
    $scope.updateEConsentDetail();
};

EConsentController.$inject = [
    "$rootScope",
    "$scope",
    "$state",
    "EConsentFactory",
    "profileFactory",
    "redirectService"
];
export default EConsentController;
