const accountRecoveryFactory = function ($resource) {
    const resetAccountAPi = "rest/partialauth/resetPassword";

    const getUsernameUrl = "rest/partialauth/getUsername";

    return {
        getUsername: $resource(
            __authenticationUrl__ + getUsernameUrl,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        resetAccount: $resource(
            __authenticationUrl__ + resetAccountAPi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: { "Content-Type": "application/json" }
                }
            }
        )
    }; //End of Return
};

accountRecoveryFactory.$inject = ["$resource", "$window"];
export default accountRecoveryFactory;
