/**
 *
 * @name app.profile.events.ProfileEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for profile
 */
const root = "ProfileEvent.";

const ProfileEvents = {
    CANCEL: root + "profile_change_cancel_button_clicked_event",
    UPDATE_PASS_VALID: root + "update_password_clicked_event",
    UPDATE_PASS_INVALID: root + "update_password_clicked_with_invalid_password_event",
    UPDATE_USERNAME_VALID: root + "update_username_clicked_event",
    UPDATE_USERNAME_INVALID: root + "update_username_clicked_with_invalid_username_event",

    FORCE_UPDATE_PASS_VALID: root + "force_update_password_clicked_event",
    FORCE_UPDATE_PASS_INVALID: root + "force_update_password_clicked_with_invalid_password_event",
    FORCE_CANCEL: root + "force_update_password_cancel_link_clicked_event",
    FORCE_CHANGE_CURRENT_PASS_INPUT:
        root + "force_update_change_current_password_input_field_event",
    FORCE_CHANGE_NEW_PASS_INPUT: root + "force_update_change_new_password_input_field_event",
    FORCE_CHANGE_NEW_CONFIRM_PASS_INPUT:
        root + "force_update_change_confirm_new_password_input_field_event",

    CHANGE_EMAIL: root + "contact_info_change_email_event",
    CHANGE_NUMBER: root + "contact_info_change_number_event",
    UPDATE_PERSONAL_CONTACT: root + "update_personal_contact_info_clicked_event",
    UPDATE_PERSONAL_CONTACT_INVALID:
        root + "update_personal_contact_info_clicked_invalid_info_event",
    OPEN_CONTACT_MODAL: root + "open_contact_change_modal_button_clicked_event",
    OPEN_CHANGE_MODAL: root + "open_change_credentials_modal_button_clicked_event",
    OPEN_E_CONSENT: root + "open_e_consent_modal_button_clicked_event",
    BENEFICIARIES_LINK: root + "beneficiaries_change_link_clicked_event",

    CHANGE_USERNAME_INPUT: root + "change_username_input_event",
    CHANGE_CURRENT_PASS_INPUT: root + "change_current_password_input_field_event",
    CHANGE_NEW_PASS_INPUT: root + "change_new_password_input_field_event",
    CHANGE_NEW_CONFIRM_PASS_INPUT: root + "change_confirm_new_password_input_field_event",

    EMAIL_CHOICE_CHANGE: root + "e_consent_email_choice_change_event",
    SECURITY_LINK_CLICK: root + "security_doc_link_clicked_event",
    E_DELIVERY_CLICK: root + "e_delivery_option_checkbox_clicked_event",
    E_CONSENT_SUBMIT: root + "e_consent_submit_clicked_event",
    E_CONSENT_SUBMIT_INVALID: root + "e_consent_submit_clicked_invalid_info_event",

    OPEN_CHANGE_HOME_ADDRESS_MODAL: root + "open_change_home_address_modal_button_clicked_event",
    CANCEL_HOME_ADDRESS: root + "change_home_address_cancel_button_clicked_event",

    MORE_DEVICES_EXPAND: root + "profile_more_devices_expand",
    LESS_DEVICES_COLLAPSE: root + "profile_less_devices_collapse",
    CHANGE_UPDATE_AUTHORIZED_DEVICES: root + "profile_change_update_authorized_devices",
    DELETE_AUTHORIZED_DEVICE: root + "profile_x_delete_authorized_device",
    CANCEL_UPDATE_AUTHORIZED_DEVICES: root + "profile_cancel_update_authorized_devices",
    SAVE_UPDATE_AUTHORIZED_DEVICES: root + "profile_save_update_authorized_devices"
};

export default ProfileEvents;
