const PAESSOAuthFactory = function ($resource) {
    return $resource(
        // eslint-disable-next-line no-undef
        __authenticationUrl__ + "rest/nonauth/authenticate",
        {
            SSO_TOKEN: "@SSO_TOKEN",
            PAEUser: "@PAEUser",
            accu: "@accu",
            username: "@username"
        },
        {
            query: {
                method: "GET",
                isArray: false
            }
        }
    );
};

PAESSOAuthFactory.$inject = ["$resource", "$location", "$window"];
export default PAESSOAuthFactory;
