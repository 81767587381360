const LinkedConfirmationFactory = function ($resource) {
    const retrieveNextDestination =
        "/participant-web-services/rest/partialauth/oneId/retrieveNextDestination";

    return {
        retrieveNextDestination: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrieveNextDestination,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        )
    };
};

LinkedConfirmationFactory.$inject = ["$resource"];
export default LinkedConfirmationFactory;
