import ReferenceData from "../../../constants/ReferenceData";
import { executeGet } from "../../../utils/appUtils";

interface StateCode {
    code: string;
    name: string;
}
type StateCodeResponseData = StateCode[];
type StateCodeSuccessResponse = StateCodeResponseData | undefined;

const retrieveStateCode = () => {
    const stateCodeSuccess = (results): StateCodeSuccessResponse => {
        if (results?.data) {
            return results.data;
        }
    };

    return executeGet(ReferenceData.GET_STATE_CODE, "retrieveStateCodeFault")
        .then(stateCodeSuccess)
        .catch((error: unknown) => {
            throw error;
        });
};

export default retrieveStateCode;
