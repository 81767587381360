import AccessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";

import mfaEvents from "../mfa/events/MFAEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const OneIdMfaController = function (
    $scope,
    $location,
    $window,
    PrimaryPlanSelectionFactory,
    redirectService,
    eventBus,
    $translate,
    $cookies,
    SetACCUService,
    OneIdMfaFactory,
    accountService,
    $state,
    profileFactory,
    LinkedConfirmationFactory,
    DefaultCustomizationRetrService,
    PreLoginAccuRetrievalService,
    ReSetACCUService,
    $q,
    AccuCodeService,
    $rootScope,
    PreLoginContactInfoService,
    $timeout
) {
    const vm = this;
    vm.loading = true;
    vm.error = false;

    $scope.mfa = {};
    $scope.activationCodeModel = {};
    $scope.internationalPhoneMessage = null;
    $scope.emptyDeliveryOptionsMessage = null;

    vm.noCodeReceived = function () {
        vm.loading = true;
    };

    vm.dataForOneIdMFAValidateCode = function () {
        const plansFromCurrentContext = [];
        // eslint-disable-next-line no-undef
        angular.forEach($rootScope.plansMapWithIndIdDbName, function (values, key) {
            if (key === $rootScope.linkingContext) {
                // eslint-disable-next-line no-undef
                angular.forEach(values, function (value) {
                    plansFromCurrentContext.push(value);
                });
            }
        });
        $rootScope.plansFromCurrentContext = plansFromCurrentContext;
    };
    vm.dataForOneIdMFAValidateCode();

    vm.getFormGroupClass = function (fieldName) {
        const field = $scope.oneIdVerificationForm[fieldName];
        let status = "";

        if (field.$invalid && field.$dirty) {
            status = "has-error";
        }

        if (field.$invalid && $scope.oneIdVerificationForm.$submitted) {
            status = "has-error";
        }

        return status;
    };

    vm.displayFieldError = function (fieldName) {
        const field = $scope.oneIdVerificationForm[fieldName];
        let displayFieldError = false;

        if (field.$invalid && field.$dirty) {
            displayFieldError = true;
        }

        if (field.$invalid && $scope.oneIdVerificationForm.$submitted) {
            displayFieldError = true;
        }

        return displayFieldError;
    };

    vm.getFieldError = function (fieldName) {
        return $scope.oneIdVerificationForm[fieldName].$error;
    };

    vm.validateOneIdMFACode = function () {
        $scope.showSpinner = true;
        // Check if the form input is valid
        if ($scope.oneIdVerificationForm.$valid) {
            const verificationData = {
                verificationCode: $scope.verification.verificationCode,
                indIdDbName: $rootScope.linkingContext
            };

            OneIdMfaFactory.validateOneIdMFACode.query(
                verificationData,

                function (data) {
                    $scope.showSpinner = true;
                    if (data.errors === false) {
                        $scope.mfa.errorMessage = "";
                        $state.go("linkablePlans");
                    } else {
                        $scope.verificationMessage = data.activationCode;
                        $scope.showSpinner = true;
                    }
                },
                function (response) {
                    $scope.showSpinner = false;
                    $scope.mfa.errorMessage = "";
                    $scope.verificationMessage = "";

                    if (response.headers("exception") !== null) {
                        $scope.mfa.errorMessage = response.data.error.code;
                        $scope.mfa.errorMessageParams = response.data.error.errors[0];
                    } else {
                        $scope.mfa.errorMessage = "99999";
                    }
                }
            );
        } else {
            $scope.showSpinner = false;
            // focus the error message for screen readers
            AccessibilityUtil.focusElement("screenReader");
        }
    };

    vm.getDeliveryOptions = function () {
        const firstPlan = getFirstPlanFromCurrentContext();
        const getDeliveryOptionsParam = { indId: firstPlan.indId, dbName: firstPlan.dbname };
        OneIdMfaFactory.getDeliveryOptions.query(
            getDeliveryOptionsParam,
            //success
            function (data, headers) {
                console.log("OneIdMfaController getDeliveryOptions success");
                console.log(data);
                $scope.authentication.flowName = data.flowName;
                $scope.mfa = data;
                $scope.mfa.errorMessage = null;

                let hasIntlPhoneNumber = false;
                if (headers("exception") !== null) {
                    $scope.mfa.errorMessage = headers("exception");
                } else {
                    if (data.destination == "emptyDeliveryOptions") {
                        $scope.emptyDeliveryOptionsMessage =
                            "We are unable to complete your request. If you require immediate account access please contact a Participant Services Representative.";
                        $state.go("oneIdMfaActivationCode");
                    } else if (!data.intlPhoneSupportAllowed) {
                        for (let i = 0; i < data.deliverySet.length; i++) {
                            if (data.deliverySet[i].deliveryPhoneType === "IntlPhoneNumber") {
                                hasIntlPhoneNumber = true;
                                $scope.internationalPhoneMessage =
                                    "International phone number support is currently unavailable.";
                                $state.go("oneIdMfaActivationCode");
                            }
                        }
                        if (!hasIntlPhoneNumber) {
                            data.destination = "mfa";
                        }
                    } else {
                        data.destination = "mfa";
                    }
                    $scope.mfa.dataLoaded = true;
                    //set focus on deliveryOption element
                    $timeout(function () {
                        document.getElementById("deliveryOption").focus();
                    }, 200);
                }
            }, //When it fails
            function (response) {
                $scope.mfa.errorMessage = "";
                if (response.headers("exception") !== null) {
                    $scope.mfa.errorMessage = response.data.error.code;
                    $scope.mfa.errorMessageParams = response.data.error.errors[0];
                } else {
                    $scope.mfa.errorMessage = "99999";
                }
            }
        ); //End of query function
    }; //End of getDeliveryOptions function

    vm.verifyCode = function () {
        eventBus.dispatch(mfaEvents.VERIFY_CODE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.VERIFY_CODE
            }
        });
        vm.loading = true;
        $state.go("oneIdMfaEnterCode");
    };

    vm.createAndDeliverActivationCodePOST = function () {
        // based on MFAController, with modifications
        eventBus.dispatch(mfaEvents.SEND_CODE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.SEND_CODE
            }
        });
        $scope.showSpinner = true;

        const firstPlan = getFirstPlanFromCurrentContext();
        const mfaRequestData = {
            accu: $scope.accu,
            deliveryOption: $scope.activationCodeModel.deliveryOption,
            indid: firstPlan.indId,
            dbName: firstPlan.dbname,
            gaId: firstPlan.gaId
        };

        if ($scope.activationCodeModel.deliveryOption === "none") {
            $scope.mfa.errorMessage = "ActivationCodeDeliveryMissingInputException";
            handleScreenReader();
            $scope.showSpinner = false;
        } else {
            OneIdMfaFactory.createAndDeliverActivationCodePOST.query(
                mfaRequestData,
                // success
                function (data, headers) {
                    $scope.showSpinner = false;

                    if (headers("exception") !== null) {
                        $scope.mfa.errorMessage = headers("exception");
                        handleScreenReader();
                    } else {
                        $state.go("oneIdMfaEnterCode");
                    }
                }, // When it fails
                function (response) {
                    $scope.showSpinner = false;
                    if (response.headers("exception") !== null) {
                        $scope.mfa.errorMessage = response.data.error.code;
                        $scope.mfa.errorMessageParams = response.data.error.errors[0];
                        handleScreenReader();
                    } else {
                        $scope.mfa.errorMessage = "99999";
                        handleScreenReader();
                    }
                }
            ); // End of query function
        }
    }; // End of createAndSendActivationCodePOST function

    const handleScreenReader = function () {
        AccessibilityUtil.focusElement("screenReader");
    };

    const getFirstPlanFromCurrentContext = function () {
        const plansFromCurrentContext = $rootScope.plansFromCurrentContext;

        if (plansFromCurrentContext === null || plansFromCurrentContext.length == 0) return null;

        const firstPlan = plansFromCurrentContext[0];
        return firstPlan;
    };

    // focus the input with an error
    vm.focusOnErrorInput = function (e, elementId) {
        AccessibilityUtil.focusOnErrorInput(e, elementId);
    };
};

OneIdMfaController.$inject = [
    "$scope",
    "$location",
    "$window",
    "PrimaryPlanSelectionFactory",
    "redirectService",
    "eventBus",
    "$translate",
    "$cookies",
    "SetACCUService",
    "OneIdMfaFactory",
    "accountService",
    "$state",
    "profileFactory",
    "LinkedConfirmationFactory",
    "DefaultCustomizationRetrService",
    "PreLoginAccuRetrievalService",
    "ReSetACCUService",
    "$q",
    "AccuCodeService",
    "$rootScope",
    "PreLoginContactInfoService",
    "$timeout",
    "mfaFactory"
];
export default OneIdMfaController;
