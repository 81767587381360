import axios from "axios";
import LogService from "core-ui/client/src/app/core/logger/LogService";
import TranslatedJsonService from "core-ui/client/src/app/TranslatedJsonService";

import getCookie from "../../utils/getCookie";

class TranslationService {
    static getTranslations = (callback) => {
        if (!TranslationService.translations) {
            const accu = getCookie("accu") || "Empower";
            return axios
                .get(new TranslatedJsonService().getUrl(accu))
                .then((res) => {
                    const json = res.data;
                    TranslationService.translations = json;

                    if (callback) {
                        callback(json);
                    }

                    return json;
                })
                .catch((error) => {
                    LogService.getLogger().error("TranslationService", error);
                });
        } else {
            if (callback) {
                callback(TranslationService.translations);
            }

            return TranslationService.translations;
        }
    };

    /*
    This will be used when the translated value is already known and you need to replace values within it.
    */
    static translate = (translateText, translateValues) => {
        return TranslationService.translateMaster(translateText, translateValues, false);
    };

    /*
    This is needed when the value to be translated is a variable and you need to use the eval function and 
    you need to replace value(s) within it.
    */
    static translateWithEval = (translateText, translateValues) => {
        return TranslationService.translateMaster(translateText, translateValues, true);
    };

    /*
    This is the master piece of code that all above functions will call.
    */
    static translateMaster = (translateText, translateValues) => {
        let translation = translateText;

        //Determine if there are values that need to be replace within the translated value
        if (translateValues && translateValues.length > 2) {
            //determines all values that will be used as replacements, based on spilting on double comma(single will break proper sentences)
            const array = translateValues.split(",,");
            //loop through all translatedValues
            for (let i = 0; i < array.length; i++) {
                const index = array[i].indexOf(":");

                if (index > 0) {
                    //determimine key value pairs and replace placeholders with the values
                    const key = array[i].substring(0, index).trim();
                    const value = array[i].substring(index + 1);
                    translation = translation.replace("{{" + key + "}}", value);
                }
            }
        }

        return translation;
    };
}

export default TranslationService;
