const preAccountOpeningFactory = function ($resource) {
    const retrieveCountryCode = "rest/nonauth/util/countryCodes";
    const retrieveStateCode = "rest/nonauth/util/stateCodes";
    const createRetailParticipant = "rest/nonauth/registration/createRetailParticipant";

    return {
        retrieveCountryCode: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + retrieveCountryCode,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true
                }
            }
        ),
        retrieveStateCode: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + retrieveStateCode,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true
                }
            }
        ),
        createRetailParticipant: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + createRetailParticipant,
            { data: "@body" },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

preAccountOpeningFactory.$inject = ["$resource"];
export default preAccountOpeningFactory;
