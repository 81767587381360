import React from "react";
import useSSOLoaderLogic from "./useSSOLoaderLogic";
import { useTranslations } from "../../queries";
interface ISSOLoaderProps {
    loadingTime?: number;
}
interface IQueryLoader {
    ssoLoader: {
        phrases: { [key: string]: string };
    };
}
/**
 * @example <SSOLoader loadingTime={20} />
 * @param loadingTime {number} is the amount of time the loading-bar will take to reach 100%
 * @returns
 */
const DEFAULT_PHRASES = [
    "You're just moment away from your future...",
    "We're creating your complete financial picture...",
    "Get ready for your financial future..."
];

const SSOLoader = ({ loadingTime = 15 }: ISSOLoaderProps) => {
    const phrasesObject = useTranslations<IQueryLoader>("ssoLoader.phrases");
    const phrases = phrasesObject ? (Object.values(phrasesObject) as string[]) : DEFAULT_PHRASES;
    const { loadingPercentage, phrasesIndex } = useSSOLoaderLogic({
        loadingTime,
        phrases
    });

    return (
        <>
            <div className="sr-only" aria-live="assertive">
                {phrases.map((phrase, i) =>
                    i === phrasesIndex ? (
                        <p key={phrase} className="sso-phrase">
                            {phrase}
                        </p>
                    ) : (
                        ""
                    )
                )}
            </div>
            <div className="sso-loader" data-testid="SSOLoader">
                <div className="sso-bar">
                    <span
                        className="sso-loading"
                        style={{
                            width: `${loadingPercentage}%`,
                            transitionDuration: `15s`
                        }}
                    ></span>
                </div>
                {phrases.map((phrase, i) => (
                    <p
                        key={phrase}
                        className="sso-phrase"
                        style={{
                            opacity: i === phrasesIndex ? 100 : 0,
                            marginTop: i === phrasesIndex ? "80px" : "0px"
                        }}
                    >
                        {phrase}
                    </p>
                ))}
            </div>
        </>
    );
};

export default SSOLoader;
