import { getEligibilityLite } from "../../authentication/AuthenticationService";
import { RedirectData } from "../types";

const DASHBOARD_URL = "participant/home/#/dashboard/retirement-income";
const ACCOUNTS_OVERVIEW_URL = "participant/accounts/#/overview";
const LEGACY_NPDI_URL = "legacyNpdiBegin.do?accu=GwrsWR";

const getExternalRedirectUrl = async (data: RedirectData, featureName?: string, gaId?: string) => {
    try {
        if (data.destinationUrl === "d") {
            let url = `participant/${window.location.search}#/d`;
            if (featureName) {
                url += `/${featureName}`;
            }
            if (gaId) {
                url += `/${gaId}`;
            }
            return url;
        } else if (data.destinationUrl === "liatLanding") {
            const { homePageEligibility } = await getEligibilityLite();
            if (homePageEligibility) {
                return DASHBOARD_URL;
            }
            return ACCOUNTS_OVERVIEW_URL;
        } else if (data.destinationUrl === "NPDI_LEGACY_REDIRECT") {
            return __iframeUrl__ ? `${__iframeUrl__}/${LEGACY_NPDI_URL}` : LEGACY_NPDI_URL;
        }
        return data.destinationUrl;
    } catch (error) {
        console.error("Error getting external redirect URL", error);
        throw error;
    }
};

export default getExternalRedirectUrl;
