import React, { useEffect, useState } from "react";
import LogService from "core-ui/client/src/app/core/logger/LogService";
import MaskedInput from "react-text-mask";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslations } from "../../../queries";
import { sendOneTimePasswordCode } from "../../../services/idProof/otp/OneTimePasswordService";
import EventBus from "core-ui/client/src/app/core/eventbus/EventBus";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import IDProofEvents from "./events/IdProofEvents";
import {
    IDPROOF_WITH_QUERY,
    IDPROOF_WITH_MAILPIN,
    IDPROOF_WITH_PIN,
    IDPROOF_CODE_ENTRY,
    IDPROOF_ERROR
} from "../../../routes";

interface IdentityVerificationTranslations {
    oneTimePassword: {
        agreeButton: string;
        agreeButtonProcessing: string;
        identityVerification: {
            agreeButton: string;
            agreeButtonProcessing: string;
            errors: {
                phoneNumberInvalid: string;
                phoneNumberRequired: string;
            };
            header: string;
            message: {
                paragraph1: string;
                paragraph2: string;
                paragraph3: string;
            };
            phoneNumber: string;
            skipButton: string;
            subHeader: string;
        };
    };
}
interface Props {
    state: { go: (path: string) => void };
}
const IdentityVerificationComponent = ({ state }: Props) => {
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
    const [serviceError, setServiceError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOtsUser, setIsOtsUser] = useState<boolean>(false);
    const { oneTimePassword } = useTranslations<IdentityVerificationTranslations>();
    const navigate = useNavigate();
    const location = useLocation();
    const flowName = location?.state?.flowName;

    useEffect(() => {
        if (flowName !== null) {
            setIsOtsUser(flowName === "OTS");
        }
    }, [flowName]);
    const eventBus = new EventBus(window, document);

    const isEmpty = (str: string | null | undefined): boolean => {
        return !str || str.replace(/\s/g, "") === "";
    };

    const isInvalidPhoneNumberPattern = (str: string | null | undefined): boolean => {
        if (!str) return true;
        const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        return !phoneNumberPattern.test(str);
    };

    const validatePhoneNumber = (value: string) => {
        setPhoneNumber(value);
        if (isEmpty(value)) {
            setPhoneNumberError(oneTimePassword?.identityVerification?.errors?.phoneNumberRequired);
            return;
        }
        if (isInvalidPhoneNumberPattern(value)) {
            setPhoneNumberError(oneTimePassword?.identityVerification?.errors?.phoneNumberInvalid);
            return;
        }
        setPhoneNumberError(null);
    };

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        LogService.getLogger().debug(`handlePhoneNumberChange [${phoneNumber}] [${value}]`);
        if (!isInvalidPhoneNumberPattern(value) || !!phoneNumber) {
            validatePhoneNumber(value);
        }
    };

    const handlePhoneNumberBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;
        LogService.getLogger().debug(`handlePhoneNumberBlur [${phoneNumber}] [${value}]`);
        validatePhoneNumber(value);
    };

    const renderPhoneNumberErrorMessage = () => {
        if (phoneNumberError) {
            return (
                <div className="form-group has-error" id="screenReader">
                    <span id="helpBlock" className="help-block">
                        {phoneNumberError}
                    </span>
                </div>
            );
        }
    };

    const renderServiceErrorMessage = () => {
        if (serviceError) {
            return (
                <div className="alert alert-warning margin-top-100">
                    <p>{serviceError}</p>
                </div>
            );
        }
    };

    const isFormValid = (): boolean => {
        return !!phoneNumber && !phoneNumberError;
    };

    const renderSubmitButton = () => {
        let className = "btn btn-primary";
        let disabled = false;
        let label = oneTimePassword?.identityVerification?.agreeButton;

        if (isFormValid()) {
            if (isLoading) {
                disabled = true;
                label = oneTimePassword?.identityVerification?.agreeButtonProcessing;
                className += " pw-loader disabled-loading";
            }
        } else {
            disabled = true;
        }

        return (
            <button
                className={className}
                id="idverify-submit"
                disabled={disabled}
                onClick={handleSubmit}
            >
                {label}
            </button>
        );
    };

    const handleError = (data, error) => {
        LogService.getLogger().error(
            `handleError. data=[${data ? JSON.stringify(data) : "null"}] error: ${
                error ? JSON.stringify(error) : "null"
            }`
        );
        setIsLoading(false);

        const errorCode = data?.errorCode || error?.response?.data?.error?.code;
        navigate(IDPROOF_ERROR, { state: { errorCode: errorCode }, replace: true });
    };

    const onConfirm = (phoneNumber, data) => {
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: IDProofEvents.OTP_AGREE_SEND_CODE
            }
        });
        const params = {
            phoneNumber: phoneNumber ? phoneNumber : null,
            flowName: flowName ? flowName : null
        };
        if (data.state === "ID_PROOFING_QUERIES") {
            if (state) {
                state.go("idProofQueries");
            } else {
                navigate(IDPROOF_WITH_QUERY, { state: params, replace: true });
            }
        } else if (data.state === "MAIL_PIN") {
            if (state) {
                state.go("mailPin");
            } else {
                navigate(IDPROOF_WITH_MAILPIN, { replace: true });
            }
        } else if (data.state === "ID_PROOFING_PIN_STATE") {
            if (state) {
                state.go("idProofWithPin");
            } else {
                navigate(IDPROOF_WITH_PIN, { replace: true });
            }
        } else if (data.state === "ID_PROOFING_OTP_VERIFY_CODE") {
            if (state) {
                state.go("otpIdentityVerificationCodeEntry");
            } else {
                navigate(IDPROOF_CODE_ENTRY, { state: params, replace: true });
            }
        } else {
            if (state) {
                state.go("idProofError");
            } else {
                navigate(IDPROOF_ERROR, { state: { errorCode: data.errorCode }, replace: true });
            }
        }
    };
    const handleSuccess = (data) => {
        LogService.getLogger().error(
            `handleSuccess. data=[${data ? JSON.stringify(data) : "null"}]`
        );
        setIsLoading(false);
        onConfirm(phoneNumber, data);
    };

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setServiceError(null);
        setIsLoading(true);
        validatePhoneNumber(phoneNumber!);

        if (isFormValid()) {
            sendOneTimePasswordCode(
                { value: phoneNumber!.replace(/\D+/g, "") },
                handleSuccess,
                handleError
            );
        } else {
            setIsLoading(false);
        }
    };

    const onCancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: IDProofEvents.OTP_SKIP_MAIL_PIN
            }
        });
        navigate(IDPROOF_WITH_MAILPIN, { replace: true, state: { optOut: "optOut-Modal" } });
    };

    const getFieldClass = () => {
        return `form-group${phoneNumberError ? " has-error" : ""}`;
    };
    return (
        <div className="container row">
            <div className="row">
                <div
                    className="registration col-xs-8 col-xs-offset-4"
                    data-testid="identity-verification-component"
                >
                    <header className="contained-form-header">
                        <h1>{oneTimePassword?.identityVerification?.header}</h1>
                        <p>{oneTimePassword?.identityVerification?.subHeader}</p>
                    </header>
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: oneTimePassword?.identityVerification?.message
                                        ?.paragraph1
                                }}
                            ></div>
                            {isOtsUser ? (
                                <div
                                    className="margin-top-100"
                                    dangerouslySetInnerHTML={{
                                        __html: oneTimePassword?.identityVerification?.message
                                            ?.paragraph2
                                    }}
                                ></div>
                            ) : (
                                <div
                                    className="margin-top-100"
                                    dangerouslySetInnerHTML={{
                                        __html: oneTimePassword?.identityVerification?.message
                                            ?.paragraph3
                                    }}
                                ></div>
                            )}
                            <div className="margin-top-200">
                                <div className={getFieldClass()}>
                                    <label className="control-label" htmlFor="phoneNumber">
                                        {oneTimePassword?.identityVerification?.phoneNumber}
                                    </label>
                                    <MaskedInput
                                        mask={[
                                            "(",
                                            /[1-9]/,
                                            /\d/,
                                            /\d/,
                                            ")",
                                            " ",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            "-",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/
                                        ]}
                                        className="form-control col-12"
                                        placeholder="(###) ###-####"
                                        guide={false}
                                        id="phoneNumber"
                                        onBlur={handlePhoneNumberBlur}
                                        onChange={handlePhoneNumberChange}
                                        autoComplete="off"
                                    />
                                </div>
                                {renderPhoneNumberErrorMessage()}
                                {renderServiceErrorMessage()}
                            </div>
                            <div className="row margin-top-200">
                                <div className="col-md-6 col-sm-12">
                                    {isOtsUser && (
                                        <button
                                            className="btn btn-primary"
                                            onClick={onCancelClick}
                                            id="idverify-cancel"
                                        >
                                            {oneTimePassword?.identityVerification?.skipButton}
                                        </button>
                                    )}
                                </div>
                                <div className="col-md-6 col-sm-12 text-right">
                                    {renderSubmitButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IdentityVerificationComponent;
