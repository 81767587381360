import React, { useState, useEffect } from "react";

import { useTranslations } from "../../../queries";

interface OAuthTranslations {
    oauthPrelogin: {
        button: {
            cancel: string;
            prelogin: string;
        };
        description1: string;
        description2: string;
        description3: string;
        header: string;
    };
}

const PreOauthLogin = () => {
    const { oauthPrelogin } = useTranslations<OAuthTranslations>();
    const [showSpinner, setShowSpinner] = useState(false);

    /**
     *   Write isOauth cookie to hold prelogin state to hide header and footer
     */
    useEffect(() => {
        const isOauth = String(sessionStorage.getItem("isOauth")) === "true";
        if (!isOauth) {
            sessionStorage.setItem("isOauth", "true");
        }

        setShowSpinner(false);
    }, []);

    return (
        <div className="preOauthLoginTemplate" data-testid="oauth-prelogin">
            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 inner-container with-padding with-shadow">
                <form name="getUrlForm-form">
                    <h1>{oauthPrelogin.header}</h1>

                    <div>
                        <p>{oauthPrelogin.description1}</p>
                        <p>{oauthPrelogin.description2}</p>
                        <p>{oauthPrelogin.description3}</p>
                    </div>

                    <div className="form-group">
                        <button
                            type="submit"
                            className="btn btn-primary btn-lg margin-top-default"
                            disabled={showSpinner}
                            onClick={() => {
                                console.log("login clicked");
                            }}
                            id="prelogin"
                        >
                            {oauthPrelogin.button.prelogin}
                        </button>
                    </div>
                    <div className="form-group">
                        <a href="/participant/#/oauth/preoauthlogin" id="cancel">
                            {oauthPrelogin.button.cancel}
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PreOauthLogin;
