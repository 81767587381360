import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { TranslationsServiceProvider } from "../../providers/TranslationsServiceProvider";
import SSOLoader from "./SSOLoader";
const client = new QueryClient();
const QueryClientWrapper = () => {
    return (
        <QueryClientProvider client={client}>
            <TranslationsServiceProvider>
                <SSOLoader />
            </TranslationsServiceProvider>
        </QueryClientProvider>
    );
};
export default QueryClientWrapper;
