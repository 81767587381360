const extractKey = (prefix: string, text: string): string => {
    return String(text).replace(prefix, "").replaceAll(" ", "");
};

const getCustomizationValue = (
    prefix: string,
    customization: Record<string, any>,
    updatedText: string
): string => {
    const key = extractKey(prefix, updatedText);
    return customization[key];
};
export type TagType = "banner" | "articleOne" | "articleTwo" | "articleThree" | "global" | "";

interface UpdateAccuCustomizationProps {
    accuCustomization: Record<string, any>;
    subType?: string;
    type: string;
    updatedText: string;
}

const updateAccuCustomization = ({
    type,
    updatedText,
    subType,
    accuCustomization
}: UpdateAccuCustomizationProps) => {
    let customizedAccuText = updatedText;
    switch (type) {
        case "banner":
            customizedAccuText = getCustomizationValue(
                "preLoginBanner.",
                accuCustomization.preLoginBanner,
                updatedText
            );

            break;
        case "articleOne":
            customizedAccuText = getCustomizationValue(
                "preLoginArticleOne.",
                accuCustomization.preLoginArticleOne,
                customizedAccuText
            );

            break;
        case "articleTwo":
            customizedAccuText = getCustomizationValue(
                "preLoginArticleTwo.",
                accuCustomization.preLoginArticleTwo,
                customizedAccuText
            );

            break;
        case "articleThree":
            customizedAccuText = getCustomizationValue(
                "preLoginArticleThree.",
                accuCustomization.preLoginArticleThree,
                customizedAccuText
            );

            break;
        case "global":
            if (subType !== undefined) {
                customizedAccuText = getCustomizationValue(
                    "global.text.",
                    accuCustomization.global[subType],
                    customizedAccuText
                );
            }
            break;
        default:
            break;
    }

    return customizedAccuText;
};

export default updateAccuCustomization;
