const authenticationTemplate = `
<div ng-if="accuCustomization.preLoginBodyTemplate" ng-include="getPreLoginBodyTemplate()"></div>

<div class="modal bulletins" ng-class="{show: bulletins.length}">
    <div aria-describedby="bullet-info" aria-labelledby="bullet-title" role="dialog" aria-modal="true" class="modal-dialog bulletin" ng-repeat="bulletin in bulletins" ng-if="$first">
        <div tabindex="0" class="modal-content" ng-id="bulletin.bulletinId">
            <div class="modal-header">
                <h4 ng-if="bulletin.subject" id="bullet-title" class="modal-title" ng-bind="bulletin.subject"></h4>
                <h5 ng-if="bulletin.subSubject" ng-bind="bulletin.subSubject"></h5>
            </div>
            <div class="modal-body">
                <p id="bullet-info" ng-bind-html="trustAsHtml(bulletin.information)"></p>
            </div>
            <div class="modal-footer">
                <button tabindex="0" type="button" class="btn btn-link btn-dismiss" data-dismiss="modal" ng-if="!$last" ng-click="bulletinNext(bulletin)">
                    {{ "bulletin.button.next" | translate }}
                </button>
                <button tabindex="0" type="button" class="btn btn-link btn-dismiss" data-dismiss="modal" ng-if="$last" ng-click="bulletinDismiss(bulletin)">
                    {{ "bulletin.button.dismiss" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div>
    <alternate-site-dialog accu-error="accuError" count-down="30"></alternate-site-dialog>
</div>
`;
export default authenticationTemplate;
