const PreLoginAccuRetrievalService = function ($resource) {
    return $resource(
        __styleEnv__ + "customizations/:accu/:accu:suffixPath",
        {
            suffixPath: "AccountsCustomization.json",
            accu: "@accu"
        },
        {
            query: {
                method: "GET",
                isArray: false
            }
        }
    );
};

PreLoginAccuRetrievalService.$inject = ["$resource"];
export default PreLoginAccuRetrievalService;
