const NPDIRegistrationService = function () {
    let pecByPass = false;

    this.getPecByPass = function () {
        return pecByPass;
    };

    this.setPecByPass = function (data) {
        pecByPass = data;
    };
};

export default NPDIRegistrationService;
