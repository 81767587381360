import axios from "axios";

type AllCredential = {
    name: string;
    status: string;
};

type SPData = {
    allCredentials: AllCredential[];
    credentials: string[];
    verificationOptions: string[];
};

type SPHeader = {
    SP_HEADER_VERSION: number;
    accountsMetaData: string[];
    authLevel: string;
    betaTester: boolean;
    csrf: string;
    developer: boolean;
    deviceName: string;
    isDelegate: boolean;
    personId: number;
    qualifiedLead: boolean;
    status: string;
    success: boolean;
    userGuid: string;
    userStage: string;
    username: string;
};

type RedwoodAuthenticateTokenResponseData = {
    data: {
        spData: SPData;
        spHeader: SPHeader;
    };
};

const redwoodAuthenticateToken = async (url: string, idToken: string) => {
    const formData = new FormData();
    formData.append("idToken", idToken);
    const response = await axios.post<RedwoodAuthenticateTokenResponseData["data"]>(
        url + "/api/credential/authenticateToken",
        formData,
        {
            withCredentials: true
        }
    );
    return response;
};

export default redwoodAuthenticateToken;
