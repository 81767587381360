import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../services/translationService";

export default class ProfileBreadcrumbComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            url: this.props.url
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    render() {
        if (!this.state.translations) {
            return null;
        }
        return (
            <div className="col-md-12 internal-breadcrumbs">
                <ol className="breadcrumb">
                    <li>
                        <a href={this.props.url + "/accounts/#/landing"}>
                            {" "}
                            {this.state.translations.profile.profileBreadcrumb.home}
                        </a>
                    </li>
                    <li className="active">
                        {this.state.translations.profile.profileBreadcrumb.profile}
                    </li>
                </ol>
            </div>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

ProfileBreadcrumbComponent.propTypes = {
    url: PropTypes.string.isRequired
};
