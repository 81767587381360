const npdiLinkAccountsTemplate = `
<div class="col-sm-8 col-sm-offset-2 col-xs-12">
    <header class="contained-form-header">
        <h1>{{::ctrl.content.title}}</h1>
    </header>
    <div id="content-container">
        <div class="inner-container with-padding with-shadow">
		
		 <div class="form-group has-error" ng-if="ctrl.linkerror.code!==''" id="screenReaderAuth" tabindex="0">
			<span class="help-block">{{ctrl.linkerror.code | translate:ctrl.linkerror.code}}</span>
		 </div>

            <form name="npdiForm" autocomplete="off" novalidate role="form" data-ng-init="ctrl.init()" data-ng-submit="ctrl.submit()" ng-if="ctrl.showLinkAccountsSection()">
                <p>{{::ctrl.content.instructions}}</p>
                
                <p ng-if="ctrl.showUsername()"><strong>{{::ctrl.content.labelUsername}}: </strong>{{ctrl.model.username}}</p>
                
                <div ng-if="ctrl.showAccounts()" class="margin-top-200">
                    <h2>{{ctrl.content.labelAccounts}}</h2>
                    
                    <ul class="account-listing list-unstyled">
                        <li ng-repeat="plan in ctrl.model.plans">
                            <span class="plan">{{plan.groupName}}</span>
                        </li>
                    </ul>
                </div>
            
                <button type="submit" class="btn btn-primary btn-lg btn-block margin-top-default">{{::ctrl.content.buttonContinue}}</button>
            </form>
            
            <div  ng-if="ctrl.showSuccessMessage()">
                <p class="margin-bottom-0"><i class="em-checkbox-icon"></i> <span ng-if="accu!='MinnesotaWR'">{{ctrl.content.msgSuccess}}</span>
				<a ui-sref="home" ng-if="accu!='MinnesotaWR'">{{::ctrl.content.linkLogIn}}</a>
				 <span ng-bind-html="'accountExists.accounts.linked' | translate" ng-if="accu === 'MinnesotaWR'"> 
				 </span>
            </div>
        </div>
    </div>
</div>
`;
export default npdiLinkAccountsTemplate;
