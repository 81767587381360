import React, { Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NpdiForm from "./NpdiForm";
import { NPDI_BASE } from "../../routes";

const NpdiContainer = () => {
    const showBase = useLocation().pathname === NPDI_BASE;

    return (
        <Fragment>
            {showBase && <NpdiForm />}
            <div id="outlet">
                <Outlet />
            </div>
        </Fragment>
    );
};

export default NpdiContainer;
