const idProofConsentTemplate = `
<div class="modal-content">
    <div class="modal-header">
        <div class="modal-title">We found you!</div>
    </div>
    <div class="modal-body clearfix">
        <div class="modal-well with-background">
            <p 
                translate="{{idProofConsent}}"
                translate-default="By clicking, “I agree, continue” you authorize us to verify your identity with information available from public and proprietary data sources.  If you do not wish to continue with this service, click “Skip, mail PIN” and a registration PIN will be mailed to your address on record."
            >{{"idProofConsent" | translate}}</p>
        </div>
    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-cancel" ng-if="!isOTSUser" ng-click="idProofConsentCtrl.onCancelClick()">
            Skip, mail PIN
        </button>
        <button type="submit" class="btn btn-primary" ng-click="idProofConsentCtrl.onConfirmClick()">
            I agree, continue
        </button>
    </div>
</div>
`;
export default idProofConsentTemplate;
