const accountSelectionTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection">
  <header class="contained-form-header margin-top-default">
    <h1>
      {{ ::translatedData.accountSelectionTitle }}
    </h1>
    <p>{{ ::translatedData.accountSelectionIntro }}</p>
  </header>

  <div 
    data-ng-show="accessPortalGroupedByAccu.length>0" 
    class="inner-container with-padding with-shadow clearfix"
  >
    <div 
      data-ng-repeat="accessPortalGroup in accessPortalGroupedByAccu" 
      class="clearfix"
    >

      <div class="row">
        <div class="col-sm-9">
          <div data-ng-repeat="accessPortal in accessPortalGroup.accessPortalList">
            {{accessPortal.planName}}
          </div>
        </div>

        <div class="col-sm-3">

          <ng-if ng-if="accessPortalGroup.accessPortalList[0].portal.indexOf('#')>0" >
            <a  id ={{accessPortalGroup[0].accuCode}} class="btn btn-primary"  ng-click="redirectToNextGenView(accessPortalGroup.accessPortalList[0].accuCode)">{{ ::translatedData.buttonView }}</a>
          </ng-if>
          <ng-if ng-if="accessPortalGroup.accessPortalList[0].portal.indexOf('#')<0" >
            <a href="" class="btn btn-primary" id ={{accessPortalGroup[0].accuCode}}  ng-click="redirectLegSSOPortal(accessPortalGroup.accessPortalList[0] )"> {{ ::translatedData.buttonView }}</a>
          </ng-if>

        </div>
      </div>

      <hr />
    </div>
  </div>
</div>
`;
export default accountSelectionTemplate;
