const planDetailTemplate = `
<div class="row"  ng-show="vm.summary !== null && vm.summary.planName !== null && vm.summary.planName !== ''">
    <div class="col-sm-10">
        <h3 class="margin-top-0">
            {{ "summary.pageTitle" | translate }}
        </h3>
    </div>
    
    <div class="col-sm-2">

        <button type="button"
        class="btn-link btn-print pull-right no-right-padding"
        print-section
        print-element-id="beneficiaryConfirmation"
        ng-click="printClick()">
        <i class="fa fa-solid fa-print margin-right-default" style="font-style: normal;"></i>
        {{ "button.print" | translate }}
    </button>
</div>
</div>

<div class="details-section" ng-if="vm.summary !== null && vm.summary.planName !== null && vm.summary.planName !== ''">
    <table class="table-details">
        <tbody>
            <tr>
                <th><strong>{{ "summary.details.plan" | translate }}</strong></th>
                <td>{{vm.summary.planName}}</td>
            </tr>


            <tr ng-if="vm.summary.contributionRateList != null && vm.summary.contributionRateList.length>0">
                <th><strong>{{ "summary.details.contributionRate" | translate }}</strong></th>
                <td> 
                    <div ng-repeat="contributionRate in vm.summary.contributionRateList"> 
                        {{contributionRate}}
                    </div>
                </td>
            </tr>

            <tr ng-if="vm.summary.investmentOptions.length > 0">
                <th>{{ "summary.details.investmentOptions" | translate }}</th>
                <td>      
                    <div ng-repeat="(groupingCode, investmentOptions) in vm.groupedInvestmentOptions">
                        <dl>
                            <dt ng-if="vm.groupingCodes > 0 && groupingCode !== ''">{{groupingCode}}</dt>
                            <dd ng-repeat="investmentOption in investmentOptions">{{investmentOption.allocationPercentage}}% {{ investmentOption.name }}</dd>
                        </dl>
                    </div>
                </td> 
            </tr>
        </tbody>
    </table>
</div>
`;
export default planDetailTemplate;
