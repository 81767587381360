export const ACCOUNT_RECOVERY = "account-recovery";
export const ACCOUNT_RECOVERY_MULTIPLE_USERS = "account-recovery-multipleUsrs";
export const ACCOUNT_RECOVERY_CONFIRMATION = "account-recovery-confirm";
export const INVALID_ROUTE = "invalidRoute";
export const HELLO_WORLD = "helloworld";
export const LOGIN = "login";
export const LOGIN_WITH_ACCU = "login?accu=";
export const LOGIN_HELP_WITH_ACCU = "loginHelp/*";
export const MFA_ACTIVATION_CODE_DELIVERY_OPTIONS = "activationCodeDeliveryOptions";
export const MFA_VERIFY_CODE = "verifyCode";
export const NPDI = "npdi/*";
export const NPDI_BASE = "/npdi";
export const NPDI_ACCOUNT_EXISTS = "accountExists";
export const NPDI_LINK_ACCOUNTS = "linkAccounts";
export const NPDI_ACCOUNT_SETUP = "accountSetup";
export const NPDI_ERROR = "error";
export const REGISTER = "register/*";
export const ROUTE_SIGNIN = "signin";
export const SIGNIN_WITH_ACCU = "signin?accu=";
export const ACCOUNT_OPEN_DETAILS = "/accountOpenDetails";
export const ACCOUNT_OPEN_PERSONAL_DETAILS = "/accountOpenPersonalDetails";
export const IDPROOF_BASE = "/idProof";
export const IDPROOF_WITH_PIN = IDPROOF_BASE + "/idProofWithPin";
export const IDPROOF_WITH_QUERY = IDPROOF_BASE + "/idProofquery";
export const IDPROOF_WITH_MAILPIN = IDPROOF_BASE + "/mailpin";
export const IDPROOF_ERROR = IDPROOF_BASE + "/error";
export const ACTIVATION_CODE_DELIVERY_OPTIONS = "/activationCodeDeliveryOptions";
export const VERIFY_CODE = "/verifyCode";
export const CONTACT_UPDATE = "/contact-update";
export const REGISTER_INFO = "/register-info";
export const ONE_ID_DELIVERY_OPTIONS = "/oneIdMfaActivationCode";
export const ONE_ID_VERIFY_CODE = "/oneIdMfaEnterCode";
export const IDPROOF_OTP_VERIFY = IDPROOF_BASE + "/otp-verify";
export const IDPROOF_CODE_ENTRY = IDPROOF_BASE + "/otp-verify-code";
export const ACCOUNT_SETUP = "/accountSetup";
export const OAUTH_LOGIN = "oauth/login";
export const OAUTH_LOGIN_WITH_ACCU = "oauth/login?accu=";
export const OAUTH_PRECONSENT = "oauth/preconsent";
export const OAUTH_PREOAUTH_LOGIN = "oauth/preoauthlogin";
