/**
 *
 * @name app.IndIdSelection.events.IndIdSelectionEvents
 * @copyright 2018 Empower Retirement
 * @description
 *
 *  These are the events for IndId selection pages
 */
const root = "OneIdEvents.";

const OneIdEvents = {
    VIEW: root + "choose_Primary_plan_Cont_clicked",
    CHOOSE_PRIMARY_PLAN_CONTD_CLIECKED: root + "choose_Primary_plan_back_clicked",
    SKIP_LINKING: root + "skip_linking_clicked",
    CONTINUE_LINKING: root + "continue_linking",
    CONFIRMATION_CONTD_CLICK: root + "oneid_confirmation_contd_clicked",
    IS_MFA_NEEDED: root + "oneid_mfa_needed_check"
};

export default OneIdEvents;
