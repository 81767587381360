import reEnrollTemplate from "./reEnrollTemplate";

const reenrollRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/re-enroll");

    $stateProvider.state("re-enroll", {
        url: "/re-enroll/:indId/:gaId/:postUrl",
        controller: "reEnrollController as ctrl",
        template: reEnrollTemplate,
        params: {
            indId: null,
            gaId: null,
            postUrl: { value: "landing", squash: true }
        },
        resolve: {
            indId: [
                "$stateParams",
                function ($stateParams) {
                    return $stateParams.indId;
                }
            ],
            gaId: [
                "$stateParams",
                function ($stateParams) {
                    return $stateParams.gaId;
                }
            ],
            postUrl: [
                "$stateParams",
                function ($stateParams) {
                    return $stateParams.postUrl;
                }
            ]
        }
    });
};

reenrollRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default reenrollRoute;
