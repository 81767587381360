const beneficiaryTemplate = `
<div class="beneficiaries ng-scope"
    ng-if="vm.beneficiaries.Primary.length > 0 || vm.beneficiaries.Contingent.length > 0">
    <div class="row">
        <div class="col-sm-8">
            <h2 class="ng-binding"> {{ "summary.beneficiaries.title" | translate }}</h2>
        </div>
    </div>

    <div ui-view class="ng-scope">

        <div ui-view class="ng-scope">
            <div class="beneficiary-list ng-scope"
                ng-if="vm.beneficiaries.Primary.length > 0 || vm.beneficiaries.Contingent.length > 0">

                <div class="beneficiaries-wrap ng-isolate-scope" ng-if="vm.beneficiaries.Primary.length > 0 ">
                    <table class="beneficiaries primary-beneficiaries table">
                        <thead>
                            <tr ng-if="vm.beneficiaries.Primary.length > 0">
                                <th class="col-xs-5 col-sm-6 ng-binding">
                                    Primary
                                </th>
                                <th class="col-xs-3 col-sm-4 ng-binding">Type</th>
                                <th class="col-xs-4 col-sm-2 ng-binding">Allocation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-repeat="bene in vm.beneficiaries.Primary">
                                <td class="beneficiary-name ng-scope"> {{bene.beneficiaryFirstName}}
                                    {{bene.beneficiaryLastName}}
                                </td>
                                <td class="beneficiary-type ng-scope">
                                    {{getTypeOrRelationshipDisplay(bene.beneficiaryType);}}
                                </td>
                                <td class="beneficiary-allocation ng-scope"> {{bene.allocationPercent}} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="beneficiaries-wrap ng-isolate-scope" ng-if="vm.beneficiaries.Contingent.length > 0">
                    <table class="beneficiaries contingent-beneficiaries table">
                        <thead>
                            <tr ng-if="vm.beneficiaries.Contingent.length > 0">
                                <th class="col-xs-5 col-sm-6 ng-binding">
                                    CONTINGENT
                                </th>
                                <th class="col-xs-3 col-sm-4 ng-binding">Type</th>
                                <th class="col-xs-4 col-sm-2 ng-binding">Allocation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-repeat="bene  in  vm.beneficiaries.Contingent">
                                <td> {{bene.beneficiaryFirstName}} {{bene.beneficiaryLastName}}
                                </td>
                                <td> {{getTypeOrRelationshipDisplay(bene.beneficiaryType);}}
                                </td>
                                <td> {{bene.allocationPercent}} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
`;
export default beneficiaryTemplate;
