import React from "react";
const RegistrationState = {};
const getContext = () => {
    const RegistrationStateContext = React.createContext(RegistrationState);
    return RegistrationStateContext;
};

const RegistrationContext = getContext();

export default RegistrationContext;
