const contactUsTemplate = `
<div class="alert alert-danger" data-ng-if="errors.length > 0">
	<p data-ng-repeat="error in errors">{{error}}</p>
</div>

<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
	<header class="contained-form-header">
		<h1>
			Setup your Contact Information
		</h1>

	</header>

	<form role="form" id="contactUs-form" name="contactUs-form">

		<h2>{{"contactInformationTitle" | translate}}</h2>

		<!-- email using validationDirectives-->
		<div class="form-group" data-ng-class="getFormGroupClass('email');">
			<label for="emailInput">{{"contactInfo.email" | translate}}</label>
			<!-- <span class="help-block">{{emailError}}</span> -->
			<input type="email"
				   class="form-control"
				   id="emailInput"
				   name="email"
				   placeholder='{{"contactInfo.emailHolder" | translate}}'
				   data-ng-model="vm.contactInfo.email"
				   data-ng-required="true"
				   data-email-validator
				   ng-blur="emailChange()"/>
			<ng-messages for="getFieldError('email');" ng-if="isDirty('email');" style="color:maroon">
				<ng-message when="required">Email is required</ng-message>
				<ng-message when="emailFormat">Please enter a valid email address.</ng-message>
			</ng-messages>
			<div ng-if="getValidField('email');" style="color:green">Valid Email</div>
		</div>

		<!-- phoneNumber using validationDirectives-->
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" data-ng-class="getFormGroupClass('phoneNumber');">
					<label for="phoneNumberInput">{{"contactInfo.phoneNumber" | translate}}</label>
					<!-- <span class="help-block">{{phoneNumberError}}</span> -->
					<input type="number"
						   min="0"
						   inputmode="numeric"
						   pattern="[0-9]*"
						   class="form-control"
						   id="phoneNumberInput"
						   name="phoneNumber"
						   data-ng-model="vm.contactInfo.phoneNumber"
						   placeholder='{{"contactInfo.phoneNumberHolder" | translate}}'
						   data-ng-required="true"
						   ng-blur="phoneChange()"
						   data-phone-number-validator/>
					<ng-messages for="getFieldError('phoneNumber');" ng-if="isDirty('phoneNumber');" style="color:maroon">
						<ng-message when="required">Phone Number is required</ng-message>
						<ng-message when="phoneNumberDigits">Please enter a ten digit phone number</ng-message>
					</ng-messages>
					<div ng-if="getValidField('phoneNumber');" style="color:green">Valid Phone Number</div>
				</div><!--//form-group//  -->
			</div><!--//col-md-6//  -->
		</div><!--//row//  -->



		<div class="form-group clearfix">

			<input type="button" class="btn btn-primary btn-block btn-lg margin-top-default" value="Continue" ng-click="contactContinue()"/>

		</div>
	</form>
</div>
`;
export default contactUsTemplate;
