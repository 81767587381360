import React from "react";
import type { FieldErrorsImpl } from "react-hook-form";
import type { RegisterFieldErrors } from "./types";

interface UsernameErrorsProps {
    errors: FieldErrorsImpl<RegisterFieldErrors>;
    highlightErrors: boolean;
}

const UsernameErrors = ({ errors, highlightErrors }: UsernameErrorsProps) => {
    if (!errors.username?.types) return;

    const {
        required,
        hasNoSpace,
        lengthRequirement,
        hasNumber,
        hasThreeLetters,
        hasAllowedCharactersUsername
    } = errors.username.types;

    return (
        <ul className={highlightErrors ? "field-errors" : ""} data-testid="username-errors">
            {required && <li data-testid="username-required">{required}</li>}
            {hasNoSpace && <li data-testid="username-no-space">{hasNoSpace}</li>}
            {lengthRequirement && <li data-testid="username-min-length">{lengthRequirement}</li>}
            {hasNumber && <li data-testid="username-number-pattern">{hasNumber}</li>}
            {hasThreeLetters && <li data-testid="username-letter-pattern">{hasThreeLetters}</li>}
            {hasAllowedCharactersUsername && (
                <li data-testid="username-special-char">{hasAllowedCharactersUsername}</li>
            )}
        </ul>
    );
};

export default UsernameErrors;
