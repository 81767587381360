/**
 *
 * @name app.authentication.events.AuthenticationEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for authentication
 */

"use strict";

const root = "AuthenticationEvent.";

const AuthenticationEvents = {
    BULLETIN_NEXT: root + "bulletin_modal_next_button_click_event",
    BULLETIN_DISMISS: root + "bulletin_modal_dismiss_event",

    USERNAME: root + "username_input_changed_event",
    PASSWORD: root + "password_input_changed_event",
    FORGOT_PASSWORD: root + "forgot_password_link_click_event",
    SUBMIT: root + "username_and_password_submit_button_click_event",
    REGISTER: root + "register_link_clicked_event",

    CONTACT_EMAIL: root + "contact_us_user_email_input_change_event",
    CONTACT_PHONE: root + "contact_us_user_phone_number_change_event",
    CONTACT_CONTINUE: root + "contact_use_continue_button_click_event",
    SHOW_HIDE_PWD: root + "show_hide_password_button_click_event"
};

/**
 * Publish constructor array.
 */
export default AuthenticationEvents;
