import formatTimingPrefix from "./formatTimingPrefix";
import getLoginAppStartTime from "./getLoginAppStartTime";
import sendAmplitudeEvent from "./sendAmplitudeEvent";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const LOGIN_BUTTON_CLICK = "loginButtonClick";

const sentryLoginClickMetrics = () => {
    const timingPrefix = formatTimingPrefix();

    const eventName = `${timingPrefix}${LOGIN_BUTTON_CLICK}`;

    const loginButtonClickExists = sessionStorage.getItem(eventName);

    if (loginButtonClickExists) {
        // Remove old and set with new value
        sessionStorage.removeItem(eventName);
    }

    //user clicks login button
    const loginButtonClick = new Date().getTime();
    const loginAppStartTime = getLoginAppStartTime();

    if (loginAppStartTime) {
        // Measure the time from app loading to clicking login button
        const loginButtonClickDelta = String(
            loginButtonClick - Number(loginAppStartTime.timestamp)
        );

        const { hash } = window.location;

        const eventPayload = JSON.stringify({
            timestamp: loginButtonClick,
            hash
        });

        sessionStorage.setItem(timingPrefix + "loginButtonClick", eventPayload);
        sessionStorage.setItem(timingPrefix + "loginButtonClickDelta", loginButtonClickDelta);

        sendAmplitudeEvent({
            event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
            event_properties: {
                name: "loginButtonClickDelta",
                timeToLoad: loginButtonClickDelta
            }
        });
    }
};

export default sentryLoginClickMetrics;
