const registrationZipCodeMessagesTemplate = `
<ng-messages 
    for="getFieldError('zipCode');"
    ng-show="displayFieldMessages('zipCode', focusMgr['zipCode']);"
    multiple
    data-ng-class="getValidationErrorClass('zipCode');"
>
    <ng-message when="required">
        {{"zipcodeRequired" | translate }}
    </ng-message>
    <ng-message when="zipcodeAlphaNumeric">
        {{ "zipcodeInvalidSpecialChar" | translate }}
    </ng-message>
</ng-messages>
`;
export default registrationZipCodeMessagesTemplate;
