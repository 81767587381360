import "angular";
import "angular-resource";
import "angular-ui-router";
import accountRecoveryController from "./AccountRecoveryController";
import accountRecoveryFactory from "./AccountRecoveryFactory";
import accountRecoveryRoute from "./AccountRecoveryRoute";

// eslint-disable-next-line no-undef
const accountRecovery = angular.module("accountRecovery", [
    "ngMessages",
    "focusDirectives",
    "ngResource",
    "ui.router",
    "validationDirectives"
]);

accountRecovery.controller("accountRecoveryController", accountRecoveryController);
accountRecovery.factory("accountRecoveryFactory", accountRecoveryFactory);
accountRecovery.config(accountRecoveryRoute);

export default accountRecovery;
