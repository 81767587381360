import registrationDateOfBirthMessagesTemplate from "./messages/registrationDateOfBirthMessagesTemplate";
import registrationLastNameMessagesTemplate from "./messages/registrationLastNameMessagesTemplate";
import registrationPinMessagesTemplate from "./messages/registrationPinMessagesTemplate";
import registrationSSNMessagesTemplate from "./messages/registrationSSNMessagesTemplate";
import registrationStreetAddressMessagesTemplate from "./messages/registrationStreetAddressMessagesTemplate";
import registrationZipCodeMessagesTemplate from "./messages/registrationZipCodeMessagesTemplate";

const registrationErrorMessagesTemplate = `
<a href="#" id="screenReader" ng-click="enableScreenReaderErrors($event)" class="sr-only">There were {{registerForm.$error.required.length}} errors in the information you submitted. Review errors.</a>
<div id="screenReaderErrorMessages" class="sr-only">
  <!-- SSN -->
  <a ng-if="registerForm.ssn.$invalid" href="#" ng-click="focusOnErrorInput($event, 'ssnInput')">${registrationSSNMessagesTemplate} Focus invalid input.</a>
  <!-- Zip Code -->
  <a ng-if="registerForm.zipCode.$invalid" href="#" ng-click="focusOnErrorInput($event, 'zipCodeInput')">${registrationZipCodeMessagesTemplate} Focus invalid input.</a>
  <!-- Last Name -->
  <a ng-if="registerForm.ssnLastName.$invalid" href="#" ng-click="focusOnErrorInput($event, 'ssnLastNameInput')">${registrationLastNameMessagesTemplate} Focus invalid input.</a>
  <a ng-if="registerForm.uniqueidLastName.$invalid" href="#" ng-click="focusOnErrorInput($event, 'uniqueidLastNameInput')">${registrationLastNameMessagesTemplate} Focus invalid input.</a>
  <!-- Date of Birth -->
  <a ng-if="registerForm.ssnDateOfBirth.$invalid" href="#" ng-click="focusOnErrorInput($event, 'ssnDateOfBirth')">${registrationDateOfBirthMessagesTemplate} Focus invalid input.</a>
  <a ng-if="registerForm.uniqueidDateOfBirth.$invalid" href="#" ng-click="focusOnErrorInput($event, 'uniqueidDateOfBirth')">${registrationDateOfBirthMessagesTemplate} Focus invalid input.</a>
  <!-- Street Address -->
  <a ng-if="registerForm.streetAddress.$invalid" href="#" ng-click="focusOnErrorInput($event, 'streetAddressInput')">${registrationStreetAddressMessagesTemplate} Focus invalid input.</a>
  <!-- PIN -->
  <a ng-if="registerForm.pin.$invalid" href="#" ng-click="focusOnErrorInput($event, 'pinInput')">${registrationPinMessagesTemplate} Focus invalid input.</a>
</div>
`;
export default registrationErrorMessagesTemplate;
