const npdiAccountExistsTemplate = `
<div class="alert alert-danger" data-ng-if="errors.length > 0">
    <p data-ng-repeat="error in errors">{{error}}</p>
</div>

<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
    <header class="contained-form-header">
        <h1>
            {{ "accountExists.title" | translate }}
        </h1>
    </header>
    
    <div class="inner-container with-padding with-shadow">    
        <p ng-bind-html="'accountExists.please.login' | translate"> </p>
    </div>
</div>
`;
export default npdiAccountExistsTemplate;
