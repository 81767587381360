import "angular";

/**
 * The module that will contain our auto focus directives.
 *
 * @type {*|module}
 */
// eslint-disable-next-line no-undef
const focusDirectives = angular.module("focusDirectives", []);

const autoFocus = function () {
    "use strict";
    return {
        restrict: "A",
        require: "?regEx",
        scope: {},
        replace: false,

        link: {
            pre: function preLink() {
                //console.debug('prelink called');
                // this fails since the element hasn't rendered
                //element[0].focus();
            },
            post: function postLink(scope, element) {
                //console.debug('postlink called');
                // this succeeds since the element has been rendered
                element[0].focus();
            }
        }
    };
};

focusDirectives.directive("autoFocus", autoFocus);
export default focusDirectives;
