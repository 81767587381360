import ReferenceData from "../../../constants/ReferenceData";
import { executePost } from "../../../utils/appUtils";
import type { AxiosResponse } from "axios";

const getShadowAccountDetails = (data) => ({
    phoneNumbers: [
        {
            countryCode: 9999,
            phoneNumber: data,
            phoneNumberType: "MOBILE"
        }
    ]
});

const createShadowAccountOTS = (phoneNumber = "1-855-756-4738"): Promise<AxiosResponse> => {
    const shadowAccount = getShadowAccountDetails(phoneNumber);

    return executePost(
        ReferenceData.CREATE_SHADOW_ACCOUNT_OTS,
        "createShadowAccountFault",
        shadowAccount
    );
};

export default createShadowAccountOTS;
