const PreLoginBulletinService = function ($resource) {
    return $resource(
        __baseApiUrl__ + "/participant-web-services/rest/nonauth/bulletins/site",
        { accu: "@accu", primaryDBFlag: "@primaryDBFlag" },
        {
            query: {
                method: "GET",
                isArray: true
            }
        }
    );
};

PreLoginBulletinService.$inject = ["$resource", "$window"];
export default PreLoginBulletinService;
