import registrationAndResetTemplate from "../registration/registrationAndResetTemplate";

const loginHelpTemplate = `
<div class="registration col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">

  <header class="contained-form-header margin-top-default">
      <h1>{{ "loginHelp" | translate }}
      <span class="registration-progress">(1 of 4)</span>
      </h1>
      <p>{{ "enterResetInfo" | translate}}</p>
  </header>

  <div class="inner-container with-padding with-shadow">
    <form role="form" name="registerForm" ng-submit="registerWithoutPin('loginHelp')" autocomplete="off" novalidate aria-live="assertive">
      ${registrationAndResetTemplate}
    </form>
   </div><!--/inner-container with-padding with-shadow/-->
</div><!--//registration//-->
`;
export default loginHelpTemplate;
