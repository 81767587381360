import { useState, useEffect } from "react";

import DateUtil from "core-ui/client/src/app/DateUtil";
import { PhoneNumberUtil, AsYouTypeFormatter } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

const error = {
    LIMIT: "limit",
    INVALID: "invalid",
    EMPTY: "empty",
    FUTURE: "future",
    COUNTRY: "country"
};

export const useForm = (config = null) => {
    const [data, setData] = useState(config.formFields || {});
    const [curRef, setCurRef] = useState({ ref: null, cursor: null });

    const [errors, setErrors] = useState({});
    const [isBackSpace, setIsBackSpace] = useState(false);

    useEffect(() => {
        if (curRef.ref && curRef.cursor) {
            if (curRef.cursor != curRef.ref.value.length) {
                if (isBackSpace) {
                    curRef.ref.selectionStart = curRef.cursor;
                    curRef.ref.selectionEnd = curRef.cursor;
                } else {
                    curRef.ref.selectionStart = curRef.ref.value.length;
                    curRef.ref.selectionEnd = curRef.ref.value.length;
                }
            }

            setCurRef({ ...curRef, cursor: null });
            setIsBackSpace(false);
        }
    }, [curRef, data, isBackSpace]);

    const handleChange = (val, ref) => {
        let value = val.target.value;
        const name = val.target.name;
        const type = val.target.type;
        const len = value.length;
        const cursor_start = val.target.selectionStart;

        setCurRef({ ref: ref, cursor: null });
        if (name === "lastname" && value.length > 0) {
            // Show limit error on fly
            if (value.length > 35) {
                setErrors({ ...errors, [name]: error.LIMIT });
            } else {
                const cow_errors = errors;
                delete cow_errors[name];
                setErrors({ ...cow_errors });
            }
        }
        // Inputs validation on the fly
        if (name === "ssn" && value.length > 0) {
            value = value.replace(/[^0-9]/gi, "");
            const validated = onFly(value, [3, 2, 4], "-", 9);
            if (validated) {
                value = validated;
            } else {
                return;
            }
        }
        if (name == "dateOfBirth" && value.length > 0) {
            // mimicking 4 point auth behavior
            value = value.replace(/[^a-z0-9]/gi, "");
            const init_len = value.length;
            value = value.replace(/[^0-9]/gi, "");

            if (value.length == 0) {
                if (init_len > 2) {
                    value = "/";
                }
                if (init_len > 4 && init_len > 2) {
                    value = "//";
                }
            } else {
                // pattern formatting
                const validated = onFly(value, [2, 2, 4], "/", 8);
                if (validated) {
                    value = validated;
                } else {
                    return;
                }
            }
        }
        if (name == "phone") {
            const validated = phoneOnFly(value, 10, data.country);
            if (validated || validated === "") {
                value = validated;
            } else {
                return;
            }
        }
        if (name == "zip") {
            const validated = zipOnFly(value, 9, data.country);
            if (validated || validated === "") {
                value = validated;
            } else {
                return;
            }
        }
        if (type == "select-one") {
            if (value !== "") {
                // No errors
                const cow_errors = errors;
                delete cow_errors[name];
                setErrors({ ...cow_errors });
            }
        }

        // fix caret position
        setCurRef({
            ref: ref,
            cursor: cursor_start,
            modified: value.length != len
        });

        setData({
            ...data,
            [name]: value
        });
        return value;
    };

    const handleValidation = (e, validations, byName) => {
        let name;
        let val;

        if (e) {
            // Field's rules validation
            name = e.target.name;
            val = e.target.value;
        } else {
            name = byName.name;
            val = byName.value;
        }

        // onSubmit validations
        if (e === null) {
            if (!validations) return;
        }

        if (name === "ssn" && e) {
            // Swipping masked/unmasked values
            const masked = ssnMasked(val);
            setData({ ...data, [name]: masked, [`ssnMasked`]: val });
        }

        // General validations
        // eslint-disable-next-line no-prototype-builtins
        if (validations.hasOwnProperty("required")) {
            if (isEmpty(val)) {
                if (e) {
                    setErrors({ ...errors, [name]: error.EMPTY });
                    return;
                } else {
                    return { [name]: error.EMPTY };
                }
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (validations.hasOwnProperty("characters")) {
            if (isAlphaInvalid(val, validations.characters)) {
                if (e) {
                    setErrors({ ...errors, [name]: error.INVALID });
                    return;
                } else {
                    return { [name]: error.INVALID };
                }
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (validations.hasOwnProperty("limit")) {
            if (isLimit(val, validations.limit)) {
                if (e) {
                    setErrors({ ...errors, [name]: error.LIMIT });
                    return;
                } else {
                    return { [name]: error.LIMIT };
                }
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (validations.hasOwnProperty("minlimit")) {
            if (isMinLimit(val, validations.minlimit)) {
                if (e) {
                    setErrors({ ...errors, [name]: error.INVALID });
                    return;
                } else {
                    return { [name]: error.INVALID };
                }
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (validations.hasOwnProperty("date")) {
            const isValid = isValidDate(val);
            if (isValid.valid === false) {
                if (e) {
                    setErrors({ ...errors, [name]: isValid.error });
                    return;
                } else {
                    return { [name]: isValid.error };
                }
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (validations.hasOwnProperty("email")) {
            if (!isValidEmail(val)) {
                if (e) {
                    setErrors({ ...errors, [name]: error.INVALID });
                    return;
                } else {
                    return { [name]: error.INVALID };
                }
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (validations.hasOwnProperty("phone")) {
            const validation = isValidPhone(val, data.country);
            if (!validation.isValid) {
                if (e) {
                    setErrors({ ...errors, [name]: validation.error });
                    return;
                } else {
                    return { [name]: validation.error };
                }
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (validations.hasOwnProperty("zip")) {
            const validations = isValidZip(val, data.country || "US");
            // eslint-disable-next-line no-prototype-builtins
            if (validations.hasOwnProperty("error")) {
                if (e) {
                    setErrors({ ...errors, [name]: validations.error });
                    return;
                } else {
                    return { [name]: validations.error };
                }
            }
        }

        // No errors
        const cow_errors = errors;
        delete cow_errors[name];
        return setErrors({ ...cow_errors });
    };

    const onFly = (value, pattern, delimiter, limit) => {
        const curr_value = String(value)
            .replaceAll(" ", "")
            .replaceAll(delimiter, "")
            .split("")
            .slice(0, limit);
        const input_arr = [];

        // Only digits & minlimit
        const isNumeric = /^\d+$/i.test(curr_value.join(""));
        if (curr_value.length > limit || isNumeric == false) return null;

        // pattern
        pattern.map((block) => {
            input_arr.push(curr_value.splice(0, block));
        });

        // flat by delimiter
        return input_arr
            .filter((e) => e.length > 0)
            .map((block) => block.join(""))
            .join(delimiter);
    };

    const phoneOnFly = (phone, limit, country) => {
        if (phone === "") {
            return phone;
        }
        const curr_value = String(phone)
            .replace(/-|\(|\)|\s/gi, "")
            .split("");

        if (curr_value[0] == "1") {
            curr_value.shift();
        }

        const isNumeric = /^\d+$/i.test(curr_value.join(""));
        if (curr_value.length > limit || isNumeric == false) return null;

        // Formatting phone nuber by country
        const formatter = new AsYouTypeFormatter(country || "US");
        formatter.clear();
        let formatted = "";

        const num = curr_value.join("");
        for (const c of num) {
            formatted = formatter.inputDigit(c);
        }

        return formatted;
    };

    const zipOnFly = (zip, limit, country) => {
        if (zip == "") return zip;
        if (country == "CA") {
            zip = zip.replace(/[^a-z0-9]/gi, "");
            zip = zip.slice(0, limit + 1);

            return zip;
        } else {
            // US Zip
            // Remove dash
            zip = zip.replace(/[^0-9]/gi, "");
            if (zip.slice(-1) == "-") return zip.slice(0, -1);
            // Ss numeric
            const curr_zip = zip.replace("-", "").slice(0, limit);
            const isValid = isAlphaInvalid(curr_zip, "numeric");
            if (!isValid) return false;

            // Add dash after 5 characters
            const addDash = (str) => str.slice(0, 5) + "-" + str.slice(5);

            // Return with dash
            if (curr_zip.length > 5) {
                return addDash(curr_zip);
            }

            return zip;
        }
    };

    const handleFocus = (e) => {
        const name = e.target.name;

        if (name == "ssn") {
            // Swipping masked/unmasked values
            const normal = data["ssnMasked"];
            setData({ ...data, [name]: normal });
        }
    };

    const handleKeyDown = (e) => {
        // BackSpace - track caret position
        if (e.keyCode === 8) {
            setIsBackSpace(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let local_errors = {};
        for (const [name, value] of Object.entries(data)) {
            const current_error = handleValidation(null, null, { name, value }, null);
            local_errors = { ...local_errors, ...current_error };
        }

        setErrors({ ...errors, ...local_errors });

        if (Object.keys(local_errors).length === 0) {
            return true;
        }
        return false;
    };

    return {
        data,
        handleChange,
        handleSubmit,
        handleValidation,
        handleFocus,
        handleKeyDown,
        errors
    };
};

// Utility functions
const isEmpty = (str) => str.length === 0;
const isAlphaInvalid = (str, type) => {
    if (type === "alpha") {
        // only letters
        return /[^a-z\s]/i.test(str);
    } else if (type == "alphanumeric") {
        return !/[^a-z0-9\s]/i.test(str);
    }
    if (type == "numeric") {
        return /^\d+$/i.test(str);
    } else if (type === "address") {
        // custom allowed characters
        return /[^a-z0-9#/&;-\s]/i.test(str);
    } else if (type === "city") {
        return /[^a-z‘\s]/i.test(str);
    }
};
const isLimit = (str, limit) => str.length > limit;
const isMinLimit = (str, minlimit) => {
    return str.length < minlimit;
};
const ssnMasked = (value) =>
    value
        .split("")
        .map((e, i) => (e != "-" && i < 7 ? "●" : e))
        .join("");
const isValidDate = (date) => {
    // Format -> MM/DD/YYYY
    const curr_date = DateUtil.getDateFormatted("MM/DD/YYYY", date);
    const minimum_date = DateUtil.getDate("1849-12-31");
    const max_date = DateUtil.getDate();

    const isFormatValid = curr_date.isValid();
    const isAfterMinumum = curr_date.isAfter(minimum_date);
    const isFuture = curr_date.isAfter(max_date);

    const isValid = isFormatValid && isAfterMinumum && !isFuture;

    if (isValid) {
        return { valid: true, error: null };
    } else if (!isFormatValid || !isAfterMinumum) {
        return { valid: false, error: error.INVALID };
    } else if (isFuture) {
        return { valid: false, error: error.FUTURE };
    }
};

const isValidEmail = (email) => {
    const isValid = emailValidator(email);
    return isValid;
};

const isValidPhone = (phone, country) => {
    const phone_trim = String(phone)
        .replace(/-|\(|\)|\s/gi, "")
        .split("");

    if (phone_trim.length < 10) {
        return { isValid: false, error: error.INVALID };
    }
    // Validate phone number by selected country
    const number = phoneUtil.parseAndKeepRawInput(phone, country || "US");
    return {
        isValid: phoneUtil.isValidNumberForRegion(number, country || "US"),
        error: error.COUNTRY
    };
};

const isValidZip = (zip, country) => {
    if (country == "US") {
        return isZipUsValid(zip);
    } else {
        return isZipCaValid(zip);
    }
};

const isZipUsValid = (zip) => {
    // Zip US rules
    const isValid = {};
    const curr_zip = zip.replaceAll("-", "");

    const isNumeric = /^\d+$/i.test(curr_zip);
    const isLimitValid = curr_zip.length == 5 || curr_zip.length == 9;

    if (!isNumeric) {
        isValid.error = error.INVALID;
        return isValid;
    }
    if (!isLimitValid) {
        isValid.error = error.LIMIT;
        return isValid;
    }

    return isValid;
};

const isZipCaValid = (zip) => {
    // Zip CA rules
    const isValid = {};

    const isAlphaNumeric = isAlphaInvalid(zip, "alphanumeric");

    const isLimitValid = zip.length <= 10;

    if (!isAlphaNumeric) {
        isValid.error = error.INVALID;
        return isValid;
    }
    if (!isLimitValid) {
        isValid.error = error.LIMIT;
        return isValid;
    }

    return isValid;
};

const emailValidator = function (email) {
    const tester =
        /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) return false;

    const emailParts = email.split("@");

    if (emailParts.length !== 2) return false;

    const account = emailParts[0];
    const address = emailParts[1];

    if (account.length > 64) return false;
    else if (address.length > 255) return false;

    const domainParts = address.split(".");
    if (
        domainParts.some(function (part) {
            return part.length > 63;
        })
    )
        return false;

    if (!tester.test(email)) return false;

    return true;
};
