import iraConsentTemplate from "./IraConsentTemplate";

const iraConsentRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/iraTerms");

    $stateProvider.state("iraTerms", {
        url: "/iraTerms/:indId/:gaId",
        controller: "iraConsentController as ctrl",
        template: iraConsentTemplate,
        params: {
            indId: null,
            gaId: null
        },
        resolve: {
            indId: [
                "$stateParams",
                function ($stateParams) {
                    return $stateParams.indId;
                }
            ],
            gaId: [
                "$stateParams",
                function ($stateParams) {
                    return $stateParams.gaId;
                }
            ]
        }
    });
};

iraConsentRoute.$inject = ["$stateProvider", "$urlRouterProvider"];

export default iraConsentRoute;
