const changeUsernameTemplate = `
<!-- React Component
<pwr-change-username-component 
    cancel = "cancel"
    updateUserName = "updateUserName"
    componentValue = "profile.username"

></pwr-change-username-component>
-->

<div class="modal-header">
    <div class="modal-title" id="modal-title" role="heading" aria-level="2">
        {{ "profile.login.header.changeUsername" | translate }}
    </div>
</div>
<div class="modal-body clearfix profile">
    <div class="modal-well with-background">
        <form role="form" name="credentialForm" autocomplete="off" novalidate>

            <div data-ng-show="saveStatus !== null" class="form-group has-error">
                <span class="help-block" id="notMatchUsernameRecordsError"> {{ saveStatus | translate:saveStatusParams}}
                </span>
            </div>

            <div class="form-group" data-ng-class="getFormGroupClass('username')">
                <label for="usernameId" class="control-label">{{ "profile.login.label.username" | translate }}</label>
                <span class="sr-only" id="userNameRules">{{"usernameNoSpace"| translate }}. {{"usernameMinLength"|
                    translate }}. {{"usernameLetterPattern"| translate }}. {{"usernameNumberPattern"| translate }}.
                </span>
                <input type="text" class="form-control" id="usernameId" name="username" data-ng-required="true"
                    data-ng-model="profile.username" maxlength="63" data-username-validator
                    ng-focus="setInputFocus($event);" ng-blur="removeInputFocus($event);changeUsernameInput()"
                    aria-describedby="userNameRules usernameError notMatchUsernameRecordsError" />
                <ng-messages for="getFieldError('username');" data-ng-if="displayFieldMessages('username');" multiple
                    data-ng-class="getValidationErrorClass('username');"
                    ng-class="{'text-muted': !focusMgr['username']}">
                    <ng-message when="required || isTouched('username')">
                        {{"usernameRequired" | translate }}
                    </ng-message>

                    <ng-message when="usernameNoSpace || isTouched('username')" class="form-validation-rule">
                        {{"usernameNoSpace" | translate }}
                    </ng-message>

                    <ng-message when="minLength || isTouched('username')" class="form-validation-rule">
                        {{"usernameMinLength" | translate }}
                    </ng-message>

                    <ng-message when="letterPattern" class="form-validation-rule">
                        {{"usernameLetterPattern" | translate }}
                    </ng-message>

                    <ng-message when="usernameNumberPattern || isTouched('username')" class="form-validation-rule">
                        {{"usernameNumberPattern" | translate }}
                    </ng-message>

                    <ng-message when="allowedSpecialCharactersPattern || isTouched('username')"
                        class="form-validation-rule">
                        {{"usernameAllowedSpecialCharacters" | translate }}
                    </ng-message>
                </ng-messages>

                <p data-ng-if="getValidField('username');" class="success">
                    {{"usernameSuccess" | translate}}
                </p>
                <span id="usernameError" class="help-block">{{usernameError}}</span>
            </div>
        </form>
    </div>
</div>

<div class="modal-footer">
    <button ng-click="cancel('username')" class="btn btn-link" id="updateUserNameId">{{ "preference.button.cancel" |
        translate }}</button>
    <button ng-click="updateUserName('profile')" class="btn btn-primary" id="submit" ng-disabled="showSpinner==true">
        <span data-ng-hide="showSpinner==true">
            {{ "preference.button.save" | translate }}
        </span>
        <span data-ng-show="showSpinner==true">
            <i class="fa fa-circle-o-notch fa-spin"></i>
            Processing ...
        </span>
    </button>

</div>
`;
export default changeUsernameTemplate;
