import formatTimingPrefix from "./formatTimingPrefix";

type LoginAppStartTime = {
    hash: string;
    timestamp: number;
} | null;

const getLoginAppStartTime = (): LoginAppStartTime => {
    const timingPrefix = formatTimingPrefix();
    const loginAppStart = sessionStorage.getItem(`${timingPrefix}loginAppStart`);

    if (loginAppStart) {
        return JSON.parse(loginAppStart);
    }

    return null;
};

export default getLoginAppStartTime;
