/* eslint-disable react/prop-types */
import React from "react";

import LogService from "core-ui/client/src/app/core/logger/LogService";
import _ from "lodash";

import {
    IraConsent,
    IraConsentAcceptTermsAndConditions
} from "../../services/iraConsentService/IraConsentService";
import TranslationService from "../../services/translationService";

class IraConsentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            needAllocations: false,
            needBeneficiaries: false,
            individualId: props.individualId,
            groupId: props.groupId,
            planName: null,
            translations: null
        };
    }

    /**
     * load the translations.
     */
    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    handleSuccess = (data) => {
        const currentGroupId = this.state.groupId;
        let currentPlanName = null;
        _.forEach(data.accessPortalGroups, function (val) {
            if (val.accu == "MYERIRA") {
                _.forEach(val.accessPortalList, function (val1) {
                    if (val1.gaId == currentGroupId) {
                        currentPlanName = val1.planName;
                    }
                });
            }
        });

        this.setState({
            isLoading: false,
            needBeneficiaries: data.needBeneficiaries,
            needAllocations: data.needAllocations,
            planName: currentPlanName
        });
    };

    handleError = (data) => {
        LogService.getLogger().error(
            "handleError. data=[" + (data ? JSON.stringify(data) : "null") + "]"
        );
        this.setState({ isLoading: false });
    };

    acceptTermsAndConditions = (event) => {
        event.preventDefault();
        IraConsentAcceptTermsAndConditions(
            {
                individualId: this.state.individualId,
                groupId: this.state.groupId
            },
            this.handleAcceptTermsSuccess,
            this.handleAcceptTermsFailure
        );
    };

    handleAcceptTermsSuccess = (data) => {
        console.log("handleAcceptTermsSuccess", data);
        this.props.goToNextLocation(data);
    };

    handleAcceptTermsFailure = (data, error) => {
        console.log("handleAcceptTermsFailure", data, error);
    };

    getLink = () => {
        const groupArray = this.state.groupId?.split("-");
        if (groupArray && groupArray[1] === "01") {
            return "/static/MYERIRA/pdf/ownerAcknowledgement_traditional.pdf";
        }
        return "/static/MYERIRA/pdf/ownerAcknowledgement_roth.pdf";
    };

    render() {
        if (!this.state.translations) {
            return null;
        } else if (this.state.isLoading) {
            return (
                <div id="frame-container-spinner" className="loaderBackground just-element">
                    <div className="loader"></div>
                </div>
            );
        } else {
            return (
                <div
                    className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration"
                    data-testid="ira-consent-component"
                >
                    <header className="contained-form-header">
                        <h1>{this.state.translations?.iraConsent?.iraTermsHeading}</h1>
                        <p>
                            {this.state.translations?.iraConsent?.acceptTerms}
                            <b>{this.state.planName}</b>
                            {this.state.translations?.iraConsent?.acceptTerms2}
                        </p>
                    </header>

                    <div className="inner-container with-padding with-shadow">
                        <form id="iraConsent-form" name="iraConsentForm" className="clearfix">
                            <div>{this.state.translations?.iraConsent?.acceptIraSubHeading}</div>
                            <div className="row margin-top-100">
                                <a
                                    href={this.getLink()}
                                    target="_blank"
                                    className="btn btn-primary pull-left"
                                    rel="noreferrer"
                                >
                                    {this.state.translations?.iraConsent?.reviewTermsAndConditions}
                                </a>
                                <button
                                    onClick={this.acceptTermsAndConditions}
                                    className="btn btn-primary pull-right"
                                >
                                    {this.state.translations?.iraConsent?.acceptTermsAndConditions}
                                </button>
                            </div>

                            {(this.state.needBeneficiaries || this.state.needAllocations) && (
                                <div className="margin-top-100">
                                    {this.state.translations?.iraConsent?.iraThingsToBeCompleted}
                                </div>
                            )}

                            <ul className="list-group" style={{ padding: "1.2em" }}>
                                {this.state.needBeneficiaries ? (
                                    <li> {this.state.translations?.iraConsent?.beneficiaries} </li>
                                ) : null}
                                {this.state.needAllocations ? (
                                    <li> {this.state.translations?.iraConsent?.investments} </li>
                                ) : null}
                            </ul>
                        </form>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        this.getTranslations();
        IraConsent(
            { individualId: this.state.individualId, groupId: this.state.groupId },
            this.handleSuccess,
            this.handleError
        );
    }
}

export default IraConsentComponent;
