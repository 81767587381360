import type { AxiosResponse } from "axios";
import { RedirectData } from "../../redirect/types";
import { executePost } from "../../../utils/appUtils";
import ReferenceData from "../../../constants/ReferenceData";

interface RegisterWithPinPayload {
    accu: string | undefined;
    flowName: string;
    isIDProofing: string;
    pin: string;
    ssn: string;
}

const registerWithPin = (payload: RegisterWithPinPayload): Promise<AxiosResponse<RedirectData>> => {
    return executePost(ReferenceData.REGISTER_WITH_PIN, "registerWithPinFault", payload);
};

export default registerWithPin;
