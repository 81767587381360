const root = "IDProofEvent.";

const IDProofEvents = {
    CONFIRM_CONSENT: root + "consent_confirm_clicked_event",
    OTP_NO_CODE_CONTINUE: root + "oneTimePassword_NoCode_continue_clicked_event",
    OTP_NO_CODE_CANCEL: root + "oneTimePassword_NoCode_cancel_clicked_event",
    OTP_SKIP_MAIL_PIN: root + "oneTimePassword_skip_mail_pin_clicked_event",
    OTP_AGREE_SEND_CODE: root + "oneTimePassword_agree_send_code_clicked_event",
    OTP_AGREE_ENTER_CODE: root + "oneTimePassword_agree_enter_code_clicked_event"
};

export default IDProofEvents;
