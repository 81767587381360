const validationCount = function (value) {
    const numberPattern = /\d{1}/;
    const lowercasePattern = /^(?=.*[a-z]){1}/;
    const uppercasePattern = /^(?=.*[A-Z]){1}/;
    const specialCharPattern = /^(?=.*[=$@$!#%*?&+#._-]){1}/;

    let counter = 0;
    if (lowercasePattern.test(value)) {
        counter++;
    }
    if (uppercasePattern.test(value)) {
        counter++;
    }
    if (numberPattern.test(value)) {
        counter++;
    }
    if (specialCharPattern.test(value)) {
        counter++;
    }

    return counter;
};

const minLengthPattern = /^[\s\S]{8,}$/;
const maxLengthPattern = /^[\s\S]{0,63}$/;
const numberPattern = /\d{1}/;
const lowercasePattern = /^(?=.*[a-z]){1}/;
const uppercasePattern = /^(?=.*[A-Z]){1}/;
const specialCharPattern = /^(?=.*[=$@$!#%*?&+#._-]){1}/;
const errorsBag = {} as any;

export const validatePassword = (value: string) => {
    const count = validationCount(value);
    errorsBag.required = value.length > 0;
    errorsBag.minLength = minLengthPattern.test(value);
    errorsBag.maxLength = maxLengthPattern.test(value);
    errorsBag.numberPattern = count >= 3 ? true : numberPattern.test(value);
    errorsBag.lowercasePattern = count >= 3 ? true : lowercasePattern.test(value);
    errorsBag.uppercasePattern = count >= 3 ? true : uppercasePattern.test(value);
    errorsBag.specialCharPattern = count >= 3 ? true : specialCharPattern.test(value);
    errorsBag.meetCondition0 = !(count === 0);
    errorsBag.meetCondition1 = !(count === 1);
    errorsBag.meetCondition2 = !(count === 2);
    return errorsBag;
};
