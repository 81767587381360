const existingRetailMessageTemplate = `
<div class="pao-existing-modal">
    <div class="modal-header">
        <div class="modal-title">{{ "iraPreAccount.existingRetailModal.title" | translate }}</div>
    </div>
    <div class="modal-body clearfix" id="myModal" data-backdrop="static">
        <p>{{ 'iraPreAccount.existingRetailModal.body' | translate }}</p>
        <div class="pull-right margin-bottom-100 margin-top-100">
            <a
                href="/participant/#/login?accu=MYERIRA"
                title="{{'iraPreAccount.existingRetailModal.link' | translate }}"
            >
                {{'iraPreAccount.existingRetailModal.link' | translate }}
            </a>
        </div>
    </div>
</div>
`;
export default existingRetailMessageTemplate;
