import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../services/translationService";

export default class HomeMailingAddressComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            country: this.props.country,
            zipcode: this.props.zipcode,
            city: this.props.city,
            state: this.props.state,
            stline1: this.props.stline1,
            stline2: this.props.stline2,
            isAddrUpdateAllowed: this.props.isAddrUpdateAllowed,
            pinAuthCode: this.props.pinAuthCode
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    handleClick = () => {
        //React function, executing an angular external function.
        this.props.openHomeAddressModal();
    };

    render() {
        if (!this.state.translations) {
            return null;
        }

        return (
            <div className="profile-information home-information">
                <h2>{this.state.translations.profile.home.header}</h2>

                {this.state.isAddrUpdateAllowed && this.state.pinAuthCode === "U" && (
                    <div
                        className="btn-container"
                        data-ng-show="isAddrUpdateAllowed && pinAuthCode==='U'"
                    >
                        <button
                            onClick={this.handleClick}
                            className="btn btn-primary"
                            id="updateHomeAddressId"
                        >
                            {this.state.translations.preference.button.changeHomeAddress}
                        </button>
                    </div>
                )}

                <div className="row">
                    <strong className="col-sm-4 text-right">
                        {this.state.translations.profile.home.physicalAddress}
                    </strong>
                    <div className="col-sm-4">
                        <div>{this.state.stline1}</div>
                        {this.state.stline2 !== "" && <div>{this.state.stline2}</div>}

                        {(this.state.country === "US" || this.state.country === "CA") && (
                            <div>
                                {this.state.city}&nbsp; {this.state.state}&nbsp;{" "}
                                {this.state.zipcode}
                            </div>
                        )}

                        {this.state.country !== "US" && this.state.country !== "CA" && (
                            <div>
                                {this.state.city}&nbsp; {this.state.zipcode}
                            </div>
                        )}

                        <div>{this.state.country}</div>
                    </div>
                </div>
                <hr />
            </div>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

HomeMailingAddressComponent.propTypes = {
    country: PropTypes.string,
    zipcode: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    stline1: PropTypes.string,
    stline2: PropTypes.string,
    isAddrUpdateAllowed: PropTypes.bool,
    pinAuthCode: PropTypes.string,
    openHomeAddressModal: PropTypes.func
};
