import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import FormUtil from "core-ui/client/src/app/FormUtil";
import _forEach from "lodash/forEach";
import _values from "lodash/values";
import InvestmentInformationEvents from "./events/investmentInformationEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const InvestmentInformationFormController = function ($scope, $state, $translate, eventBus) {
    const vm = this;
    const logger = ExternalLogger.getInstance("InvestmentInformationFormController");

    vm.loading = true;
    vm.groupId = "";

    //
    // Get custom content from ui-style-guide

    // Store the content in the controller so we can bind it once in the view for performance reasons.
    // The content starts out as the translateId for the content we want from ui-style-guide customizations.
    vm.content = {
        title: "investmentInformation.form.title",
        subtitle: "investmentInformation.form.subtitle",
        labelGroupId: "investmentInformation.form.labelGroupId",
        buttonContinue: "investmentInformation.form.buttonContinue",
        errorGroupIdRequired: "investmentInformation.form.errorGroupIdRequired",
        errorGroupIdInvalid: "investmentInformation.form.errorGroupIdInvalid"
    };

    /**
     * Initialize the content by loading it from the custom JSON we get from ui-style-guide. If the translation is
     * successful, we map the translated content back to our content object. If it fails, we note it in the log and
     * redirect to the generic error page. This prevents the user from seeing translateIds in place of the content.
     *
     * @return {Promise} A promise to resolve the translated content
     */
    function initializeContent() {
        return $translate(_values(vm.content)).then(
            function onTranslationSuccess(translations) {
                _forEach(vm.content, function (value, key) {
                    vm.content[key] = translations[value];
                });
            },
            function onTranslationFailure() {
                logger.error("Failed to translate content values via ui-style-guide.");
                $state.go("genericError");
            }
        );
    }

    /**
     * Initialize our data for the view.
     *
     * The translate service is asynchronous, so it works out better to wait for the translations to complete before
     * rendering the values so the translate IDs don't display to the user. We accomplish this with the loading flag.
     */
    function init() {
        initializeContent().then(function () {
            vm.loading = false;
        });
    }

    vm.getFormGroupClass = function (fieldName) {
        return FormUtil.getFormGroupClass($scope.investmentInformationForm, fieldName);
    };

    vm.getFieldError = function (fieldName) {
        return FormUtil.getFieldError($scope.investmentInformationForm, fieldName);
    };

    vm.displayFieldError = function (fieldName) {
        return FormUtil.displayFieldError($scope.investmentInformationForm, fieldName);
    };

    $scope.handleFundInformationButtonClicked = function () {
        eventBus.dispatch(InvestmentInformationEvents.CONTINUE_FUND_INFORMATION_CLICKED, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: InvestmentInformationEvents.CONTINUE_FUND_INFORMATION_CLICKED
            }
        });
    };
    vm.submitForm = function submitForm() {
        if (!FormUtil.isFormValid($scope.investmentInformationForm)) {
            return false;
        }

        $state.go("investmentInformationResults", { groupId: vm.groupId });
    };

    init();
};

InvestmentInformationFormController.$inject = ["$scope", "$state", "$translate", "eventBus"];
export default InvestmentInformationFormController;
