const indexTemplate = `
<div class="registration col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2" screen-size>

    <header class="contained-form-header margin-top-default">
        <h1>{{ "lookupAccount" | translate }}</h1>
        <p>{{ "enterAccountInfo" | translate}}</p>
    </header>

    <!-- Nav tabs -->

    <ul ng-if="!accuCustomization.showLoginSetupRecoveryRadio" class="nav nav-tabs hidden-xs" role="tablist" id="registrationTabs">
        <li role="tab" id="registrationWithoutPinTab" class="register-tab active" tabindex="0">
            <a id="formRegistrationWithoutPinTab" ng-click="activateTab($event)" ui-sref="register.withoutPin">{{
                "tabs.noPin" | translate}}</a>
        </li>
        <li role="tab" id="registrationWithPinTab" class="register-tab"  tabindex="0">
            <a id="formRegistrationPinTab" ng-click="activateTab($event)" ui-sref="register.withPin">{{ "tabs.havePin" |
                translate}}</a>
        </li>
        <li role="tab" id="registrationWithGroupPasswordTab" class="register-tab"  tabindex="0">
            <a id="formRegistrationWithGroup" ng-click="activateTab($event)" ui-sref="register.withGroupPassword">{{
                "tabs.groupPassword" | translate}}</a>
        </li>
    </ul>
    <div class="tab-content hidden-xs" ng-if="!mobile">
        <div role="tabpanel" class="tab-pane active" id="registrationContent">
            <ui-view></ui-view>
        </div>
        <!--//.tab-pane//-->
    </div>
    <!--//tab-content//-->

    <accordion ng-if="mobile" class="nav nav-tabs visible-xs-block mobile-registration" role="tablist"
        close-others="true" id="registrationAccordion">
        <accordion-group ng-init="accordion.isWithoutPinOpen = true" is-open="accordion.isWithoutPinOpen"
            id="registrationWithoutPinPanel">
            <accordion-heading><a ng-click="includes('register.withoutPin')" ui-sref="register.withoutPin">{{
                    "tabs.noPin" | translate}}</a></accordion-heading>
            <div ng-if="includes('register.withoutPin')">
                <ui-view></ui-view>
            </div>
        </accordion-group>

        <accordion-group is-open="accordion.isWithPinOpen" id="registrationWithPinPanel">
            <accordion-heading><a ng-click="includes('register.withPin')" ui-sref="register.withPin">{{ "tabs.havePin" |
                    translate}}</a></accordion-heading>
            <div ng-if="includes('register.withPin')">
                <ui-view></ui-view>
            </div>
        </accordion-group>
        <accordion-group is-open="accordion.isGroupOpen" id="registrationWithGroupPasswordPanel">
            <accordion-heading><a ng-click="includes('register.withGroupPassword')"
                    ui-sref="register.withGroupPassword">{{ "tabs.groupPassword" | translate}}</a></accordion-heading>
            <div ng-if="includes('register.withGroupPassword')">
                <ui-view></ui-view>
            </div>
        </accordion-group>
    </accordion>


</div>
<!--//registration//-->
`;
export default indexTemplate;
