import React from "react";
import { useTranslations } from "../../../queries";
import RegisterWithoutPin from "../../register/registerWithoutPin/RegisterWithoutPin";

interface LoginHelpTranslations {
    enterResetInfo: string;
    loginHelp: string;
}

const FLOW_NAME = "loginHelp";

const LoginHelp = () => {
    const { loginHelp, enterResetInfo } = useTranslations<LoginHelpTranslations>();

    return (
        <div className="container row">
            <div className="row">
                <div className="registration col-xs-6 col-xs-offset-6">
                    <header className="contained-form-header margin-top-default">
                        <h1>
                            {loginHelp}
                            <span className="registration-progress">(1 of 4)</span>
                        </h1>
                        <p>{enterResetInfo}</p>
                    </header>

                    <div className="inner-container with-padding with-shadow">
                        <RegisterWithoutPin flowName={FLOW_NAME} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginHelp;
