import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";
import AccessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { sentryVerifyCodeSignIn } from "../utils/sentryMetricsLogging";

import mfaEvents from "./events/MFAEvents";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";

const mfaVerifyCodeController = function (
    $cookies,
    $rootScope,
    $scope,
    $window,
    $timeout,
    AccuCodeService,
    DefaultCustomizationRetrService,
    PreLoginAccuRetrievalService,
    ReSetACCUService,
    PreLoginContactInfoService,
    eventBus,
    mfaFactory,
    redirectService,
    otsRegistrationService
) {
    const logger = ExternalLogger.getInstance("mfaVerifyCodeController");
    $scope.mfa = {};
    $scope.verificationMessage = "";
    $scope.verification = {
        verificationCode: "",
        rememberDevice: ""
    };
    $scope.submitted = false;
    $scope.formOnError = false;
    $scope.showFormDefaultError = false;
    const STATUS_500 = "500";
    const STATUS_0000 = "0000";
    const STATUS_9999 = "9999";

    const callAmplitudeEvent = function (eventBusPayload) {
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
            selection: mfaEvents.LOGIN_VERIFY_ERROR_CODE,
            payload: eventBusPayload
        });
    };

    $scope.displayFieldError = function (fieldName) {
        const field = $scope.verficationForm[fieldName];
        let displayFieldError = false;

        if (field.$invalid && field.$dirty) {
            displayFieldError = true;
        }

        if (field.$invalid && $scope.verficationForm.$submitted) {
            displayFieldError = true;
        }

        return displayFieldError;
    };

    $scope.getFieldError = function (fieldName) {
        return $scope.verficationForm[fieldName].$error;
    };

    /**
     * Checks wheter form is valid.
     * @returns returns true if the form has been touched, is invalid and has been submitted. False otherwise
     */
    $scope.formIsInvalid = function () {
        return (
            $scope.verficationForm.$invalid &
            ($scope.verficationForm.$dirty || $scope.verficationForm.$submitted)
        );
    };

    /**
     * Checks wheter form contains any error.
     * @returns true if form has error, false otherwise
     */
    $scope.formHasError = function (onSubmit = false) {
        $scope.formOnError =
            $scope.formIsInvalid() || !!$scope.verificationMessage || !!$scope.mfa.errorMessage;
        if ($scope.formOnError) {
            $scope.showFormDefaultError =
                $scope.formIsInvalid() && !$scope.verificationMessage && !$scope.mfa.errorMessage;
            if (onSubmit) {
                $timeout(function () {
                    // focus the input with an error
                    AccessibilityUtil.focusElement("verification_codeInput");
                }, 500);
            }
        } else {
            $scope.showFormDefaultError = false;
        }
        return $scope.formOnError;
    };

    /**
     * Get form classes.
     * @returns by default 'form-group', on error 'form-group has-error'
     */
    $scope.getFormGroupClass = function () {
        return "form-group" + ($scope.formOnError ? " has-error" : "");
    };

    const updateCssAccu = function (val) {
        if (val) {
            $rootScope.css.accu = val;
            // eslint-disable-next-line no-undef
            globalThis.css.accu = val;
        } else {
            $rootScope.css.accu = $window.accu;
            // eslint-disable-next-line no-undef
            globalThis.css.accu = $window.accu;
        }
    };
    $scope.mfaNoCodeClick = function () {
        eventBus.dispatch(mfaEvents.MFA_DID_NOT_RECEIVE_CODE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.MFA_DID_NOT_RECEIVE_CODE
            }
        });
    };
    $scope.verifyCode = function () {
        // tracks when the Proceed button is clicked on the MFA screen
        localStorage.removeItem(PerformanceTrackingKeys.PT_MFA_CLICKED);
        localStorage.setItem(PerformanceTrackingKeys.PT_MFA_CLICKED, new Date().getTime());

        const flow = otsRegistrationService.getFormData().context || "MFA";
        const method = "mfaVerifyCodeController verifyCode";
        eventBus.dispatch(mfaEvents.SIGN_IN, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.SIGN_IN
            }
        });
        $scope.showSpinner = true;
        // Check if the form input is valid
        if ($scope.verficationForm.$valid) {
            if (
                $scope.verification.rememberDevice === undefined ||
                $scope.verification.rememberDevice === ""
            ) {
                $scope.verification.rememberDevice = "false";
            }

            // capture the timestamp once the code has been validated
            sentryVerifyCodeSignIn();

            const verificationData = {
                verificationCode: $scope.verification.verificationCode,
                rememberDevice: $scope.verification.rememberDevice,
                flowName: $rootScope.flowName
            };

            mfaFactory.verifyCode
                .query(
                    verificationData,

                    function (data) {
                        $scope.showSpinner = true;
                        if (data.errors === false) {
                            $scope.mfa.errorMessage = "";
                            if (data.primaryPlan && data.primaryPlan.accuCode) {
                                const newAccuCode = data.primaryPlan.accuCode;
                                //set gaId
                                if (!$rootScope.gaId) {
                                    $rootScope.gaId = data.primaryPlan.gaId;
                                }
                                if (
                                    newAccuCode &&
                                    $window.accu !== newAccuCode &&
                                    $window.supportedACCU.folders.indexOf(newAccuCode) >= 0
                                ) {
                                    logger.error(
                                        "{0} - Empower Restricted authentication..changing accu to [{1}]",
                                        [method, newAccuCode]
                                    );
                                    $window.accu = newAccuCode;
                                    global.accu = newAccuCode;

                                    //update tomcat
                                    logger.error("{0} - updating accu...", [method]);
                                    ReSetACCUService.query({ accu: $window.accu }).$promise.then(
                                        function () {
                                            //set cookie
                                            $cookies.remove("accu", { path: "/" });
                                            $cookies.put("accu", $window.accu, { path: "/" });
                                            $cookies.remove("accu", { path: "/participant" });
                                            $cookies.put("accu", $window.accu, {
                                                path: "/participant"
                                            });
                                            $cookies.remove("clientAccu", { path: "/" });
                                            $cookies.put("clientAccu", $window.accu, { path: "/" });
                                            $cookies.remove("clientAccu", { path: "/participant" });
                                            $cookies.put("clientAccu", $window.accu, {
                                                path: "/participant"
                                            });

                                            // added to resolve SR # 03597037   - cookie used for legacy pptweb quicken download feature
                                            $cookies.remove("PM-ACCU-TOKEN", { path: "/" });

                                            $scope.accu = AccuCodeService.getAccuCode();

                                            updateCssAccu($scope.accu);
                                            //to reload customizations..

                                            console.log(
                                                method + "- re-loading default customizations..",
                                                method
                                            );
                                            DefaultCustomizationRetrService.query().$promise.then(
                                                function (defaultData) {
                                                    console.log(
                                                        method + "- default customizations..",
                                                        method,
                                                        defaultData
                                                    );

                                                    console.log(
                                                        method +
                                                            "- re-loading preLogin accu data..",
                                                        method
                                                    );
                                                    PreLoginAccuRetrievalService.query({
                                                        accu: $scope.accu
                                                    }).$promise.then(function (preLoginData) {
                                                        console.log(
                                                            method + "- preLogin accu data..",
                                                            method,
                                                            preLoginData
                                                        );
                                                        // eslint-disable-next-line no-undef
                                                        $scope.accuCustomization = angular.extend(
                                                            {},
                                                            defaultData,
                                                            preLoginData
                                                        );
                                                        $rootScope.accuSpecificPrimDb =
                                                            $scope.accuCustomization.primaryDatabase.preLoginPrimaryDB;

                                                        console.log(
                                                            method +
                                                                "- re-loading PreLogin contact info..",
                                                            method
                                                        );
                                                        console.log(
                                                            method + "- existing siteContactInfo",
                                                            $rootScope.siteContactInfo
                                                        );
                                                        PreLoginContactInfoService.query({
                                                            accu: $scope.accu,
                                                            accuSpecificPrimDb:
                                                                $rootScope.accuSpecificPrimDb,
                                                            portalType: "TOLLFREE"
                                                        }).$promise.then(function (contactData) {
                                                            $rootScope.siteContactInfo =
                                                                contactData;
                                                            console.log(
                                                                method +
                                                                    "-  NEW PreLogin contact info..",
                                                                method,
                                                                contactData
                                                            );
                                                            $rootScope.$broadcast(
                                                                "siteContactInfoUpdated",
                                                                $rootScope.siteContactInfo
                                                            );
                                                            $rootScope.$broadcast(
                                                                "loginAuthStatusVerified",
                                                                $scope.authenticationStatus
                                                            );
                                                            $rootScope.$broadcast(
                                                                "customizationUpdated",
                                                                $scope.accuCustomization
                                                            );

                                                            logger.error(
                                                                "{0} - Accu code reset to: [{1}]",
                                                                [method, $scope.accu]
                                                            );
                                                            //#############################################################################
                                                            // $window.location.href = "../../participant/#/mfa/activationCodeDeliveryOptions?accu="+$scope.accu;
                                                            redirectService.redirect(
                                                                data,
                                                                "ALL",
                                                                flow
                                                            );
                                                            //#############################################################################
                                                        });
                                                    });
                                                }
                                            );
                                        }
                                    );
                                } else {
                                    redirectService.redirect(data, "ALL", flow);
                                }
                            } else {
                                redirectService.redirect(data, "ALL", flow);
                            }
                        } else {
                            $scope.verificationMessage = data.activationCode;
                            $scope.showSpinner = true;
                            const eventBusPayload = {
                                error_code: data.activationCode
                            };

                            callAmplitudeEvent(eventBusPayload);
                        }
                    },
                    function (response) {
                        $scope.showSpinner = false;
                        $scope.mfa.errorMessage = "";
                        $scope.verificationMessage = "";

                        if (response.headers("exception") !== null) {
                            $scope.mfa.errorMessage =
                                response.data.error.code === STATUS_500
                                    ? STATUS_0000
                                    : response.data.error.code;
                            $scope.mfa.errorMessageParams = response.data.error.errors[0];
                            const eventBusPayload = {
                                error_code: response.data.error.code
                            };

                            callAmplitudeEvent(eventBusPayload);
                        } else {
                            $scope.mfa.errorMessage = STATUS_9999;
                            const eventBusPayload = {
                                error_code: STATUS_9999
                            };

                            callAmplitudeEvent(eventBusPayload);
                        }
                    }
                )
                .$promise.finally(function () {
                    // Check for errors
                    $scope.formHasError(true);
                });
        } else {
            $scope.showSpinner = false;
            // Check for errors
            $scope.formHasError(true);
            const eventBusPayload = {
                error_code: "invalid_input_value"
            };

            callAmplitudeEvent(eventBusPayload);
        }
    };

    // focus the input with an error
    $scope.focusOnErrorInput = function (e, elementId) {
        AccessibilityUtil.focusOnErrorInput(e, elementId);
    };
};

mfaVerifyCodeController.$inject = [
    "$cookies",
    "$rootScope",
    "$scope",
    "$window",
    "$timeout",
    "AccuCodeService",
    "DefaultCustomizationRetrService",
    "PreLoginAccuRetrievalService",
    "ReSetACCUService",
    "PreLoginContactInfoService",
    "eventBus",
    "mfaFactory",
    "redirectService",
    "otsRegistrationService"
];
export default mfaVerifyCodeController;
