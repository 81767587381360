const mfaFactory = function ($resource) {
    const createAndDeliverActivationCodeApi =
        "rest/partialauth/mfa/createAndDeliverActivationCode/:deliveryOption";
    const createAndDeliverActivationCodePOSTApi =
        "rest/partialauth/mfa/createAndDeliverActivationCode";
    const verificationCodeApi = "rest/partialauth/mfa/verifycode";

    return {
        createAndDeliverActivationCode: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + createAndDeliverActivationCodeApi,
            {},
            {
                deliveryOption: "@deliveryOption",
                accu: "@accu"
            },
            {
                query: {
                    method: "GET",
                    isArray: false,
                    responseType: "json"
                }
            }
        ),

        createAndDeliverActivationCodePOST: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + createAndDeliverActivationCodePOSTApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        getDeliveryOptions: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + "rest/partialauth/mfa/deliveryOptions",
            {
                flowName: "@flowName"
            },
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        verifyCode: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + verificationCodeApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

mfaFactory.$inject = ["$resource"];
export default mfaFactory;
