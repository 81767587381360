import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { PatternFormat } from "react-number-format";

import TranslationService from "../../services/translationService";

const PersonalContactInfoComponent = ({ profileInfo, onClickFunction }) => {
    const [translations, setTranslations] = useState(null);

    useEffect(() => {
        TranslationService.getTranslations((json) => {
            setTranslations(json);
        });
    }, []);

    const renderPhoneField = (id, phone) => {
        return (
            <div className="row">
                <div id={"mobile-work-contact-" + id} className="col-sm-4">
                    <strong>{translations?.profile?.personal[id]}</strong>
                </div>
                <div className="col-sm-8" id={id}>
                    <PatternFormat
                        value={phone}
                        format="(###) ###-####"
                        mask="_"
                        displayType={"text"}
                    />
                </div>
            </div>
        );
    };

    if (!translations) {
        return null;
    }

    return (
        <div className="profile-information personal-information">
            <h2> {translations?.profile?.personal?.header?.personalInformation} </h2>

            <div className="row">
                <strong className="col-sm-4 text-right">
                    {translations?.profile?.personal?.emailAddress}
                </strong>
                <div className="col-sm-8" id="personalEmailAddress">
                    {profileInfo?.email}
                </div>

                {profileInfo?.pinAuthCode === "U" && (
                    <div className="btn-container">
                        <button
                            className="btn btn-primary"
                            id="updateContactInfoId"
                            onClick={onClickFunction}
                        >
                            {translations?.preference?.button?.changeContactInformation}
                        </button>
                    </div>
                )}
            </div>
            {profileInfo?.phoneNumber && renderPhoneField("mobileNumber", profileInfo?.phoneNumber)}
            {profileInfo?.home && renderPhoneField("home", profileInfo?.home)}
            {profileInfo?.intlFormattedNbr && (
                <div className="row">
                    <strong className="col-sm-4 text-right">
                        {translations?.profile?.personal?.intlNumber}
                    </strong>
                    <div className="col-sm-8" id="intlNumber">
                        {profileInfo?.intlFormattedNbr}
                    </div>
                </div>
            )}
            <hr />
        </div>
    );
};

PersonalContactInfoComponent.propTypes = {
    profileInfo: PropTypes.object.isRequired,
    onClickFunction: PropTypes.func
};

export default PersonalContactInfoComponent;
