import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _forEach from "lodash/forEach";
import _pickBy from "lodash/pickBy";
import _values from "lodash/values";

const InvestmentInformationResultsController = function (
    $translate,
    $stateParams,
    $state,
    investmentInformationService
) {
    const vm = this;
    const logger = ExternalLogger.getInstance("InvestmentInformationResultsController");

    vm.DocumentTypes = {
        FundOverview: "FUNDOV",
        FundPerformance: "IOAG",
        OnlineProspectus: "PROSP"
    };

    vm.groupId = $stateParams.groupId;
    vm.planName = "";
    vm.overviewRecords = [];
    vm.performanceRecords = [];
    vm.prospectusRecords = [];

    vm.showOverview = false;
    vm.showPerformance = true;
    vm.showProspectus = false;
    vm.loading = true;

    //
    // Get custom content from ui-style-guide

    // Store the content in the controller so we can bind it once in the view for performance reasons.
    // The content starts out as the translateId for the content we want from ui-style-guide customizations.
    vm.content = {
        breadcrumbHome: "investmentInformation.results.breadcrumb.home",
        breadcrumbCurrent: "investmentInformation.results.breadcrumb.current",
        title: "investmentInformation.results.title",
        introduction: "investmentInformation.results.introduction",
        overviewHeading: "investmentInformation.results.overview.heading",
        overviewDescription: "investmentInformation.results.overview.description",
        overviewToggleLink: "investmentInformation.results.overview.toggleLink",
        performanceHeading: "investmentInformation.results.performance.heading",
        performanceDescription: "investmentInformation.results.performance.description",
        performanceToggleLink: "investmentInformation.results.performance.toggleLink",
        performanceDocumentLink: "",
        prospectusesHeading: "investmentInformation.results.prospectuses.heading",
        prospectusesDescription: "investmentInformation.results.prospectuses.description",
        prospectusesToggleLink: "investmentInformation.results.prospectuses.toggleLink",
        noInvestmentsFoundMessage: "investmentInformation.results.noInvestmentsFoundMessage"
    };

    /**
     * Initialize the content by loading it from the custom JSON we get from ui-style-guide. If the translation is
     * successful, we map the translated content back to our content object. If it fails, we note it in the log and
     * redirect to the generic error page. This prevents the user from seeing translateIds in place of the content.
     *
     * @return {Promise} A promise to resolve the translated content
     */
    function initializeContent() {
        // Get the content we can translate immediately without needing to interpolate
        const content = _pickBy(vm.content, function (translateId) {
            return translateId.length > 0;
        });

        return $translate(_values(content)).then(
            function onTranslationSuccess(translations) {
                _forEach(content, function (value, key) {
                    vm.content[key] = translations[value];
                });
            },
            function onTranslationFailure() {
                logger.error("Failed to translate content values via ui-style-guide.");
                $state.go("genericError");
            }
        );
    }

    /**
     * The "no investments found" message should be displayed if there are no investment information records.
     *
     * @return {Boolean} True if there are no records to display; false otherwise
     */
    vm.showNoInvestmentsMessage = function () {
        return (
            vm.overviewRecords.length === 0 &&
            vm.performanceRecords.length === 0 &&
            vm.prospectusRecords.length === 0
        );
    };

    /**
     * Check if the value is a non-empty array. This is pretty basic, but I'm adding a function for it to clean up the
     * places it is used here. We could create an array utility in core-ui if this would be useful in other places.
     *
     * @param  {Mixed}   x The source value to validate
     * @return {Boolean}   True if the source is a non-empty array; False otherwise
     */
    vm.isNonEmptyArray = function (x) {
        return Array.isArray(x) && x.length > 0;
    };

    /**
     * Get the investment information data.
     *
     * @param  {String}  groupId The Group ID for which we want to retrieve investment information
     * @return {Promise}         A promise to resolve the investment information
     */
    vm.getInvestmentInformation = function (groupId) {
        return investmentInformationService.query({ groupId: groupId }).$promise;
    };

    /**
     * Clean the records by filtering out records without a name specified or without funds, then filter out funds
     * that are missing a name and URL and fully qualify the fund document URL.
     *
     * @param  {Array}   records            The records to clean (these will be an array of asset class objects)
     * @param  {Boolean} allowEmptyFundName True to allow records without a name (used for performance doc that uses the plan name)
     * @return {Array}                      The cleaned records
     */
    vm.cleanRecords = function (records, allowEmptyFundName) {
        return records
            .filter((record) => record.assetClassName && vm.isNonEmptyArray(record.funds))
            .map((record) => {
                return {
                    assetClassName: record.assetClassName,
                    funds: record?.funds?.filter((fund) => {
                        if (allowEmptyFundName) {
                            return fund?.url?.length > 0;
                        }

                        return fund.fundLegalName && fund.url;
                    })
                };
            })
            .filter((record) => record.funds.length > 0);
    };

    /**
     * Parse the response and map it to our data structure
     *
     * @param  {Object} data The response from the service
     */
    vm.parseResponse = function (data) {
        if (data) {
            // Overview documents
            if (vm.isNonEmptyArray(data[vm.DocumentTypes.FundOverview])) {
                vm.overviewRecords = vm.cleanRecords(data[vm.DocumentTypes.FundOverview], false);
            }

            // Performance documents
            if (vm.isNonEmptyArray(data[vm.DocumentTypes.FundPerformance])) {
                vm.performanceRecords = vm.cleanRecords(
                    data[vm.DocumentTypes.FundPerformance],
                    true
                );

                if (vm.performanceRecords.length > 0) {
                    vm.planName = vm.performanceRecords[0].assetClassName;
                    vm.content.performanceDocumentLink = $translate.instant(
                        "investmentInformation.results.performance.documentLink",
                        { planName: vm.planName }
                    );
                }
            }

            // Prospectus documents
            if (vm.isNonEmptyArray(data[vm.DocumentTypes.OnlineProspectus])) {
                vm.prospectusRecords = vm
                    .cleanRecords(data[vm.DocumentTypes.OnlineProspectus], false)
                    .filter((record) =>
                        record.funds.some((fund) => fund.displayProspectusInd === "Y")
                    );
            }
        }
    };

    /**
     * Initialize the Investment Information results view. We should have a Group ID from the previous page, passed
     * in via $stateParams by the ui-router. We need to load the investment documents for that Group, if valid.
     *
     * If the Group ID was not valid or did not return any documents, we will display a message that there are no
     * documents to display.
     */
    function init() {
        // Kick off the fetch before we get the custom content
        const fetchPromise = vm.getInvestmentInformation(vm.groupId);

        initializeContent().then(function () {
            fetchPromise
                .then(
                    function success(data) {
                        vm.parseResponse(data);
                    },
                    function error(err) {
                        logger.error(
                            "Failed to get investment information for Group ID " + vm.groupId,
                            err
                        );
                    }
                )
                .finally(function () {
                    // Disable the loading indicator and display the page regardless of the API response. If the Group ID
                    // was invalid, we display the "No investments found" message rather than a validation message.
                    vm.loading = false;
                });
        });
    }

    init();
};

InvestmentInformationResultsController.$inject = [
    "$translate",
    "$stateParams",
    "$state",
    "investmentInformationService"
];
export default InvestmentInformationResultsController;
