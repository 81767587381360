const npdiPayStubInfoTemplate = `
<!-- Paygroup -->
<div class="row">
  <div ng-hide="stateAgency.value === 'N'" class="form-group col-xs-12 col-sm-4" data-ng-class="getFormGroupClass('payGroupId');">
      <label for="payGroupIdInput" class="control-label">{{ "npdiForm.employmentInfo.payGroupId" | translate }}</label>
      <input type="text"
      name="payGroupId"
      id="payGroupIdInput"
      class="form-control"
      reg-ex-input
      reg-ex="^[a-zA-Z0-9]+$"
      data-ng-minlength="3"
      maxlength="3"
      ng-blur="removeInputFocus($event);"
        ng-focus="setInputFocus($event)"
      data-ng-model="ctPec.payGroupId"
      data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
      data-ng-required="stateAgency.value === 'Y' || stateAgency.value === ''">
      <ng-messages  for="getFieldError('payGroupId');"
            ng-if="displayFieldMessages('payGroupId',focusMgr['payGroupId']);"
            multiple
            data-ng-class="getValidationErrorClass('payGroupId');">
            <ng-message when="minlength" role=alert>
                {{"npdiForm.payGroupInvalidLength"  | translate }}
            </ng-message>
            <ng-message when="required">
                <span role=alert>{{"npdiForm.payGroupRequired" | translate }}</span>
            </ng-message>
      </ng-messages>
  </div>
  <!-- Employee ID -->
 
    <div ng-hide="stateAgency.value === 'N'" class="form-group col-xs-12 col-sm-4" data-ng-class="getFormGroupClass('employeeId');">
      <label for="employeeIdInput" class="control-label">{{ "npdiForm.employmentInfo.employeeId" | translate }}</label>
      <input  type="text"
      name="employeeId"
      id="employeeIdInput"
      class="form-control"
      reg-ex-input
      reg-ex="^[0-9]+$"
      data-ng-minlength="6"
      maxlength="6"
      ng-blur="removeInputFocus($event);"
        ng-focus="setInputFocus($event)"
      data-ng-model="ctPec.employeeId"
      data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
      data-ng-required="stateAgency.value === 'Y' || stateAgency.value === ''">
      <ng-messages  for="getFieldError('employeeId');"
              ng-if="displayFieldMessages('employeeId',focusMgr['employeeId']);"
              multiple
              data-ng-class="getValidationErrorClass('employeeId');">
              <ng-message when="minlength" role=alert>
                  {{"npdiForm.employeeIdInvalidLength"  | translate }}
              </ng-message>
              <ng-message when="required">
                <span role=alert>{{"npdiForm.employeeIdRequired" | translate }}</span>
            </ng-message>
      </ng-messages>
    </div>
    <!-- Employee RCD #/Employer Name -->
    <div class="form-group col-xs-12 col-sm-4" data-ng-class="getFormGroupClass(stateAgency.value === 'N' ? 'employerName' : 'employeeRCD');">
      <label for="divisionInput" class="control-label">
        {{ stateAgency.value === 'N' ? "npdiForm.employmentInfo.employerName" : "npdiForm.employmentInfo.employeeRCD" | translate }}
      </label>
        <input type="text" 
          ng-model="thisScope.selectedDivCodesValue" 
          placeholder="" 
          uib-typeahead="division as division.name for division in divisionCodesOptions | filter:{name:$viewValue}"
          typeahead-popup-template-url="customPopupTemplate.html" 
          class="form-control"
          typeahead-min-length="0"
          typeahead-select-on-blur="true"
          name="{{stateAgency.value === 'N' ? 'employerName' : 'employeeRCD'}}"
          id="divisionInput"
          data-ng-required="true"
          ng-focus="setInputFocus($event);"                     
          ng-blur="removeInputFocus($event);"
          typeahead-editable="false"
          typeahead-show-on-focus
          typeahead-select-on-exact="true"/>
        <input aria-label="cancel" type="button" ng-show="selectedDivCodesValue" ng-click="clearValues()" value="x" class="bootstrap-selectize-input-clear-button state-agency-clear-button"/>
        <ng-messages for="getFieldError(stateAgency.value === 'N' ? 'employerName' : 'employeeRCD');"
            ng-if="displayFieldMessages(stateAgency.value === 'N' ? 'employerName' : 'employeeRCD',focusMgr[stateAgency.value === 'N' ? 'employerName' : 'employeeRCD']);"
            class="rule-validations">
            <ng-message when="required">
                <span role=alert>{{ stateAgency.value === 'N' ? "npdiForm.employerNameRequired" : "npdiForm.employeeRcdRequired" | translate }}</span>
            </ng-message>
            <ng-message when="editable">
                <span role=alert>{{ stateAgency.value === 'N' ? "npdiForm.employerNameNotFound" : "npdiForm.employeeRcdNotFound" | translate }}</span>
            </ng-message>
        </ng-messages>
    </div>
  </div>
`;

export default npdiPayStubInfoTemplate;
