import registrationPinMessagesTemplate from "../registration/messages/registrationPinMessagesTemplate";
import registrationErrorMessagesTemplate from "../registration/registrationErrorMessagesTemplate";

const idProofingWithPinFlowTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
    <div id="frame-container-spinner" class="loaderBackground just-element" ng-if="idProofCtrl.loading">
        <div class="loader"></div>
    </div>
    <form name="registerForm" ng-submit="idProofWithPin(individual)" autocomplete="off" novalidate>

        <div class="form-group has-error" ng-show="autherizationMessage != null" id="screenReaderAuth" tabindex="0">
            <span class="help-block">{{autherizationMessage | translate:autherizationMessageParams}}</span>
        </div>

        <!-- Screen Reader Error Block -->
        <div ng-if="registerForm.$submitted && registerForm.$invalid">${registrationErrorMessagesTemplate}</div>

        <header class="contained-form-header">
            <h1>
                Enter your PIN
            </h1>
        </header>

        <div class="inner-container with-padding with-shadow">

            <p>Please enter the PIN you received. If you have not received your PIN, please call {{contactNoForIDProof}}
                to request another one. </p>

            <!-- PIN using validationDirectives and mask-->
            <div class="form-group" ng-class="getFormGroupClass('pin');">
                <label class="control-label" for="pinInput">{{"individual.pin" | translate}}</label>
                <input type="password" class="form-control col-5" id="pinInput" name="pin" pattern="[0-9]*"
                    inputmode="numeric" ng-required="true" pin-validator pin-length-validator maxlength="16"
                    mask-restrict="accept" mask-clean="true" ng-model="individual.pin" ng-focus="setInputFocus($event)"
                    ng-blur="removeInputFocus($event);changePin()"
                    title="Pin must be numeric and pin must be between four and sixteen digits">
                <!--ng-model-options="{ updateOn: 'blur', allowInvalid: true }"-->
                
                ${registrationPinMessagesTemplate}
                
                <span class="help-block">{{pinError}}</span>
            </div>

            <div class="form-group clearfix">


                <button type="submit" class="btn btn-primary btn-lg btn-block margin-top-default"
                    ng-disabled="showSpinner==true" id="submit">
                    <span data-ng-hide="showSpinner==true">
                        Continue
                    </span>

                    <span data-ng-show="showSpinner==true">
                        <i class="fa fa-circle-o-notch fa-spin"></i>
                        Processing ...
                    </span>

                </button>
            </div>

        </div>
    </form>
</div>
`;
export default idProofingWithPinFlowTemplate;
