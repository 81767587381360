import ReferenceData from "../../../constants/ReferenceData";
import { executePost } from "../../../utils/appUtils";
import type { AxiosResponse } from "axios";

interface ExistingUser {
    context: string;
    destinationUrl: string;
    errors: boolean;
    flowName: string;
    message: string;
    state: string;
}
const getExistingUserOTS = (data, skipExperian, referrerParam): Promise<ExistingUser> => {
    const modifiedUrl = `${ReferenceData.GET_EXISTING_USER_OTS}${skipExperian}${referrerParam}`;
    return executePost(modifiedUrl, "getExistingUserFault", data).then(
        (response: AxiosResponse) => {
            const existingUser: ExistingUser = {
                context: response.data.context,
                destinationUrl: response.data.destinationUrl,
                errors: response.data.errors,
                flowName: response.data.flowName,
                message: response.data.message,
                state: response.data.state
            };
            return existingUser;
        }
    );
};

export default getExistingUserOTS;
