import "angular";
import "angular-resource";
import "angular-ui-router";
import accountSelectionController from "./AccountSelectionController";
import accountSelectionFactory from "./AccountSelectionFactory";
import accountSelectionRoute from "./AccountSelectionRoute";

// eslint-disable-next-line no-undef
const accountSelection = angular.module("accountSelection", ["ngResource", "ui.router"]);

accountSelection.controller("accountSelectionController", accountSelectionController);
accountSelection.factory("accountSelectionFactory", accountSelectionFactory);
accountSelection.config(accountSelectionRoute);

export default accountSelection;
