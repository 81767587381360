/* eslint-disable */
globalThis.integratedEligibility = {
    csrf: null,
    eligibilityLoaded: false,
    homePageEligibility: false,
    integrated: true,
    postOriginUrl: null,
    showCommunicationPreferences: false,
    showLIAT: false,
    showWidgets: false,
    success: false,
    samlToken: undefined,
    samlTokenError: undefined,
    samlPosted: false,
    userGuid: null,
    integratedCSS: "",
    integratedJS: "",
    isRetail: false,
    hasRetailAccount: false,
    isEmulator: false,
    setEmulator: false,
    deminAccountForWithdrawalLanding: false,
    notifications: []
};
