import DateUtil from "core-ui/client/src/app/DateUtil";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _get from "lodash/get";
import _isUndefined from "lodash/isUndefined";

const ReEnrollController = function (
    $injector,
    $controller,
    $cookies,
    $scope,
    $state,
    $stateParams,
    $translate,
    $window,
    AuthenticationStatusService,
    LanguageCodeFactory,
    TransactionAccessRetrService,
    redirectService,
    reEnrollFactory
) {
    const logger = ExternalLogger.getInstance("ReEnrollController");
    const accountService = $injector.get("accountService");
    const ENROLLMENT_STATES = {
        DEFAULT: "default",
        ACKNOWLEDGE: "acknowledge",
        CONFIRMATION: "confirmation"
    };

    const ctrl = this;
    let postEnrollUrl;
    let individualId;
    let groupId;

    let postEnrollData = null;
    const $rootScope = $injector.get("$rootScope");
    const apiDateFormat = "DD-MMM-YYYY";

    ////////////////////////////     PRIVATE METHODS    ///////////////////////////////

    /**
     * Prepares a clean scope and vars for the re-enrollment view
     */
    const initScope = function () {
        postEnrollUrl = "";
        individualId = "";
        groupId = "";

        ctrl.viewState = ENROLLMENT_STATES.DEFAULT;
        ctrl.eventId = "";
        ctrl.startDate = "";
        ctrl.endDate = "";
        ctrl.todaysDate = new Date();
        ctrl.effectiveDate = new Date();
        ctrl.allocTypeCode = "";
        ctrl.investmentFromList = [];
        ctrl.investmentToList = [];
        ctrl.translatedData = {};
        ctrl.isOptIn = false;
        ctrl.planName = "";
        ctrl.showLearn = false;
    };

    const localize = function () {
        $translate([
            "reEnroll.labels.print",
            "reEnroll.labels.reEnrollmentDetails",
            "reEnroll.labels.importantChanges",
            "reEnroll.labels.acknowledgement",
            "reEnroll.labels.confirmation",
            "reEnroll.labels.investments",
            "reEnroll.labels.from",
            "reEnroll.labels.to",
            "reEnroll.labels.noThanks",
            "reEnroll.labels.iAgree",
            "reEnroll.labels.continue",
            "reEnroll.content.importantChangesP1",
            "reEnroll.content.importantChangesP2",
            "reEnroll.content.otherAccountRenrollment",
            "reEnroll.content.importantChangesP3Pre",
            "reEnroll.content.importantChangesP3Post",
            "reEnroll.content.importantChangesP3PostALLOC",
            "reEnroll.content.importantChangesP3PostASSET",
            "reEnroll.content.importantChangesP3PostBOTH",
            "reEnroll.content.preConfirmMsg",
            "reEnroll.content.confirmationPrefix",
            "reEnroll.content.confirmationNumber",
            "reEnroll.content.acknowledgementPrefix",
            "reEnroll.content.acknowledgementSuffix",
            "reEnroll.content.acknowledgeSummaryMsgPrefix",
            "reEnroll.content.acknowledgeSummaryMsgSuffix"
        ]).then(
            function (translations) {
                // eslint-disable-next-line no-undef
                angular.extend(ctrl.translatedData, {
                    reEnrollmentDetailsLabel: translations["reEnroll.labels.reEnrollmentDetails"],
                    printLabel: translations["reEnroll.labels.print"],
                    fromLabel: translations["reEnroll.labels.from"],
                    toLabel: translations["reEnroll.labels.to"],
                    investmentsLabel: translations["reEnroll.labels.investments"],
                    importantChangesLabel: translations["reEnroll.labels.importantChanges"],
                    acknowledgementLabel: translations["reEnroll.labels.acknowledgement"],
                    confirmationLabel: translations["reEnroll.labels.confirmation"],
                    noThanks: translations["reEnroll.labels.noThanks"],
                    iAgree: translations["reEnroll.labels.iAgree"],
                    continue: translations["reEnroll.labels.continue"],
                    importantChangesP1: translations["reEnroll.content.importantChangesP1"],
                    importantChangesP2: translations["reEnroll.content.importantChangesP2"],
                    importantChangesP3Pre: translations["reEnroll.content.importantChangesP3Pre"],
                    otherAccountRenrollment:
                        translations["reEnroll.content.otherAccountRenrollment"],
                    importantChangesP3PostALLOC:
                        translations["reEnroll.content.importantChangesP3PostALLOC"],
                    importantChangesP3PostASSET:
                        translations["reEnroll.content.importantChangesP3PostASSET"],
                    importantChangesP3PostBOTH:
                        translations["reEnroll.content.importantChangesP3PostBOTH"],
                    preConfirmMsg: translations["reEnroll.content.preConfirmMsg"],
                    confirmationPrefix: translations["reEnroll.content.confirmationPrefix"],
                    confirmationNumber: translations["reEnroll.content.confirmationNumber"],
                    acknowledgementPrefix: translations["reEnroll.content.acknowledgementPrefix"],
                    acknowledgementSuffix: translations["reEnroll.content.acknowledgementSuffix"],
                    acknowledgeSummaryMsgPrefix:
                        translations["reEnroll.content.acknowledgeSummaryMsgPrefix"],
                    acknowledgeSummaryMsgSuffix:
                        translations["reEnroll.content.acknowledgeSummaryMsgSuffix"]
                });
            },
            function (fault) {
                logger.error(fault);
            }
        );
    };

    ctrl.getTypeCodeSuffix = function () {
        return ctrl.translatedData["importantChangesP3Post" + ctrl.allocTypeCode || "BOTH"];
    };

    const FooterController = $controller("FooterController as FooterController", {
        $scope: $scope
    });

    ctrl.selectedLang = $rootScope.siteContactInfo.trnsltLabel;

    ctrl.openFooterModal = function (modalSrc) {
        FooterController.openFooterModal(modalSrc);
    };

    function processLanguagePreference() {
        const method = "processLanguagePreference";
        const https = "https://";
        const http = "http://";
        const toLangCode = "es";
        const period = ".";
        const currentUrl = $window.location.href;
        let newUrl = currentUrl;
        $translate(["footer.languagePreference.supportLanguagePreference"]).then(
            function (translations) {
                const supportLanguagePreference =
                    translations["footer.languagePreference.supportLanguagePreference"];
                logger.debug("{0} - supportLanguagePreference=[{1}]", [
                    method,
                    supportLanguagePreference
                ]);

                if (supportLanguagePreference !== "true") {
                    return null;
                }

                accountService.setActiveGroupAccount().then(function () {
                    const activeGroupAccount = accountService.getActiveGroupAccount();
                    const txnCodeParamWithGroup = {
                        txnCode: "WNSPWB,WNLANG",
                        individualId: activeGroupAccount.individualId,
                        groupId: activeGroupAccount.groupId
                    };

                    TransactionAccessRetrService.queryWithAccount(
                        txnCodeParamWithGroup,
                        function (response) {
                            $rootScope.trnsltWbTrnCode =
                                response.WNSPWB && response.WNSPWB === "true";
                            $rootScope.langUpdTrnCode =
                                response.WNLANG && response.WNLANG === "true";

                            if ($rootScope.langUpdTrnCode || $rootScope.trnsltWbTrnCode) {
                                if ($rootScope.langUpdTrnCode) {
                                    $scope.languageMessageLabel =
                                        "footer.customerSupport.pstLoginLangDescriptionWithTrnslt";
                                    $rootScope.siteContactInfo.trnsltLabel = $translate.instant(
                                        "footer.languagePreference.linkSpanish"
                                    );
                                }
                                LanguageCodeFactory.retrieveLanguageCode.query(
                                    {},
                                    function (data) {
                                        if (data !== null && data.languageCode) {
                                            $rootScope.siteContactInfo.selectedLanguage =
                                                data.languageCode;
                                            if (
                                                $rootScope.langUpdTrnCode &&
                                                data.languageCode === "SPN"
                                            ) {
                                                $rootScope.siteContactInfo.trnsltLabel =
                                                    $translate.instant(
                                                        "footer.languagePreference.linkEnglish"
                                                    );
                                            }
                                            const isPaeUser =
                                                data.paeUser === "true" ||
                                                (data.paeUser === "false" ? false : data.paeUser);
                                            if (
                                                $rootScope.trnsltWbTrnCode &&
                                                data.languageCode === "SPN" &&
                                                !isPaeUser
                                            ) {
                                                logger.debug("Redirect to Spanish site...");
                                                if (
                                                    currentUrl.indexOf(https + toLangCode) < 0 &&
                                                    currentUrl.indexOf(http + toLangCode) < 0 &&
                                                    currentUrl.indexOf("participant") >= 0 &&
                                                    currentUrl.indexOf("login") < 0
                                                ) {
                                                    AuthenticationStatusService.clearSpanishLanguageCookies().then(
                                                        function (resp) {
                                                            console.log(
                                                                "SSO Spanish cookies have been cleared.",
                                                                resp
                                                            );
                                                            LanguageCodeFactory.setLanguageCookies.query(
                                                                {},
                                                                function (data) {
                                                                    console.log(
                                                                        "SSO Cloned cookies for Spanish site",
                                                                        data
                                                                    );
                                                                    newUrl = newUrl.replace(
                                                                        https,
                                                                        https + toLangCode + period
                                                                    );
                                                                    newUrl = newUrl.replace(
                                                                        http,
                                                                        http + toLangCode + period
                                                                    );
                                                                    console.log(
                                                                        "Redirect to Spanish site..."
                                                                    );
                                                                    $window.location.href = newUrl;
                                                                },
                                                                function (error) {
                                                                    logger.error(
                                                                        "Failed to clone the cookie for Spanish site",
                                                                        error
                                                                    );
                                                                }
                                                            );
                                                        },
                                                        function (error) {
                                                            logger.error(
                                                                "SSO Failed to clearSpanishLanguageCookies",
                                                                error
                                                            );
                                                        }
                                                    );
                                                }
                                            }
                                        } else {
                                            logger.error(
                                                "Failed to get language code of gaId=%s, indId=%s",
                                                activeGroupAccount.groupId,
                                                activeGroupAccount.individualId,
                                                data
                                            );
                                        }
                                    },
                                    function (error) {
                                        logger.error(
                                            "Failed to get language code of gaId=%s, indId=%s",
                                            activeGroupAccount.groupId,
                                            activeGroupAccount.individualId,
                                            error
                                        );
                                    }
                                );
                                if (!$rootScope.siteContactInfo.selectedLanguage) {
                                    $rootScope.siteContactInfo.selectedLanguage = "ENG";
                                }
                            }
                            if (
                                !$rootScope.trnsltWbTrnCode &&
                                (currentUrl.indexOf(https + toLangCode) >= 0 ||
                                    currentUrl.indexOf(http + toLangCode) >= 0)
                            ) {
                                newUrl = currentUrl.replace(https + toLangCode + period, https);
                                newUrl = newUrl.replace(http + toLangCode + period, http);
                                console.log("Redirect to English site...");
                                $window.location.href = newUrl;
                            }
                        },
                        function (error) {
                            logger.error(
                                "Failed to check transaction access for WNSPWB, WNLANG of gaId=%s, indId=%s",
                                activeGroupAccount.groupId,
                                activeGroupAccount.individualId,
                                error
                            );
                        }
                    );
                });
            }
        );
    }

    /**
     * Initializes the view
     */
    const init = function () {
        initScope();

        groupId = $stateParams.gaId;
        individualId = $stateParams.indId;
        postEnrollData = { destinationUrl: $stateParams.postUrl || "landing" };

        const getPlanResetPartFundsSuccess = function (result) {
            ctrl.planName = result.planName;
            if (displayReEnroll(result)) {
                logger.debug("init()- result: " + result);
                // adding ".replace(/-/g, '')" to strip weird "-" in Firefox browser
                ctrl.startDate = DateUtil.getDateFormatted("MM/DD/YYYY", result.startDate).replace(
                    /-/g,
                    ""
                );
                ctrl.endDate = DateUtil.getDateFormatted("MM/DD/YYYY", result.endDate).replace(
                    /-/g,
                    ""
                );
                ctrl.effectiveDate = DateUtil.getDateFormatted(
                    "MM/DD/YYYY",
                    result.effDate
                ).replace(/-/g, "");
                ctrl.allocTypeCode = result.assetAllocTypeCode;
                ctrl.investmentFromList = result.fromFunds;
                ctrl.investmentToList = result.toFunds;
                ctrl.managedAccountsProgramName = result.managedAccountsProgramName;
            } else {
                postEnrollUrl = result.postEnrollUrl;
                navigateToPostUrl();
            }
        }.bind(this);

        const getPlanResetPartFundsFailure = function (fault) {
            logger.error("getPlanResetPartFunds:" + fault);
        }.bind(this);

        if (groupId && individualId) {
            reEnrollFactory
                .getPlanResetPartFunds(individualId, groupId)
                .then(getPlanResetPartFundsSuccess, getPlanResetPartFundsFailure);
        } else {
            $state.go("home");
        }

        showLearnMore();
        processLanguagePreference();
    };

    /**
     * Examines the api result to see if the business rules governing whether to show the re-enroll view is met
     *
     * @param result
     * @returns {*|boolean}
     */
    const displayReEnroll = function (result) {
        const hasResult = !_isUndefined(result);
        const eligible = _get(result, "eligible", false);

        const postURL = _get(result, "postEnrollUrl", postEnrollUrl);
        const display = hasResult && eligible;

        postEnrollUrl = postURL;

        logger.debug("displayReEnroll() - display: " + display);

        return display;
    };

    /**
     * Currently the only possible options for navigating away are to the LIAT or ACCOUNTS apps hence the
     * use of $window
     *
     */
    const navigateToPostUrl = function () {
        logger.debug("navigateToPostUrl() - navigating to " + postEnrollUrl);

        postEnrollData.destinationUrl = postEnrollUrl;
        redirectService.redirect(postEnrollData, postEnrollUrl);
    };

    ////////////////////////////    PUBLIC METHODS    //////////////////////////////////

    /**
     * Determines whether to show the investments table if the alloc type code is ASSET
     *
     * @returns {boolean}
     */
    ctrl.displayInvestmentTable = function () {
        return ctrl.allocTypeCode === "ASSET";
    };

    /**
     * Handle the opt out anchor click on the initial view
     */
    ctrl.optOut = function () {
        const apiSuccess = function (result) {
            if (result && result.eventId && result.postEnrollUrl) {
                ctrl.eventId = result.eventId;
                postEnrollUrl = result.postEnrollUrl;
                ctrl.setState(ENROLLMENT_STATES.CONFIRMATION);
            } else {
                // what to do?
            }
        }.bind(this);

        const apiFailure = function (fault) {
            logger.error("Opt Out Failure:" + fault);
        }.bind(this);

        reEnrollFactory.optOutReset(individualId, groupId).then(apiSuccess, apiFailure);
    };

    /**
     * Handle the I agree button on the initial view
     */
    ctrl.optIn = function () {
        const apiSuccess = function (result) {
            if (result && result.eventId && result.postEnrollUrl) {
                ctrl.eventId = result.eventId;
                postEnrollUrl = result.postEnrollUrl;
                ctrl.isOptIn = true;
                ctrl.setState(ENROLLMENT_STATES.ACKNOWLEDGE);
            } else {
                // what to do?
            }
        }.bind(this);

        const apiFailure = function (fault) {
            logger.error("Opt In Failure:" + fault);
        }.bind(this);

        reEnrollFactory.optInReset(individualId, groupId).then(apiSuccess, apiFailure);
    };

    // TODO: delete from here
    ctrl.removeCookie = function () {
        $cookies.remove("reEnrollOptIn");
    };
    // TODO: to here

    /**
     * Helper method to determine of the account list should be visible (it should appear in default and acknowledgement states)
     * @returns {boolean}
     */
    ctrl.showAccountList = function () {
        const isConfirmation = ctrl.viewState === ENROLLMENT_STATES.CONFIRMATION;
        return ctrl.viewState && !isConfirmation;
    };

    /**
     * Helper method to determine of the continue button should be visible, despite it operating differently in different states
     *
     * @returns {boolean}
     */
    ctrl.showContinue = function () {
        return ctrl.viewState !== ENROLLMENT_STATES.DEFAULT;
    };

    /**
     * Helper method to determine if the current state is the default state
     * @returns {boolean}
     */
    ctrl.isDefaultState = function () {
        return ctrl.viewState === ENROLLMENT_STATES.DEFAULT;
    };

    /**
     * Helper method to determine if the current state is the acknowledgement state
     * @returns {boolean}
     */
    ctrl.isAcknowledgeState = function () {
        return ctrl.viewState === ENROLLMENT_STATES.ACKNOWLEDGE;
    };

    /**
     * * Helper method to determine if the current state is the confirmation state
     * @returns {boolean}
     */
    ctrl.isConfirmState = function () {
        return ctrl.viewState === ENROLLMENT_STATES.CONFIRMATION;
    };

    /**
     * Handles the continue button clicks
     */
    ctrl.continue = function () {
        switch (ctrl.viewState) {
            case ENROLLMENT_STATES.ACKNOWLEDGE:
                $cookies.put("reEnrollOptIn", "true", {
                    expires: new Date(ctrl.endDate),
                    path: "/"
                });
                navigateToPostUrl();
                break;
            case ENROLLMENT_STATES.CONFIRMATION:
                navigateToPostUrl();
                break;
            case ENROLLMENT_STATES.DEFAULT:
            default:
                break;
        }
    };

    const showLearnMore = function () {
        const apiSuccess = function (result) {
            const statements = result.data;
            const exist = statements.find((statement) => {
                const effDate = DateUtil.getDateFormatted(apiDateFormat, statement.effdate);
                const now = DateUtil.getDate();
                const diffDays = now.diff(effDate, "days");

                if (statement.dmtyCode === "PFUDCP" && diffDays <= 90) {
                    const pdfUrl = (
                        __baseApiUrl__ +
                        `/participant-web-services/rest/document/getEcmDocument/documentId/${statement.ecmGuid}/docCategory/ParticipantStatement/objectStore/${statement.ecmRepository}`
                    ).replace(/[\u200B-\u200D\uFEFF]/g, "");
                    ctrl.showLearnLink = pdfUrl;

                    return true;
                } else {
                    return false;
                }
            });

            ctrl.showLearn = !!exist;
        }.bind(this);

        const apiFailure = function (fault) {
            logger.error("Opt In Failure:" + fault);
            ctrl.showLearn = false;
        }.bind(this);

        reEnrollFactory.getStatements(individualId, groupId).then(apiSuccess, apiFailure);
    };

    /**
     * Sets the view state
     * @param state
     */
    ctrl.setState = function (state) {
        ctrl.viewState = state;
    };

    ctrl.showPrint = function () {
        return (
            ctrl.viewState === ENROLLMENT_STATES.CONFIRMATION ||
            ctrl.viewState === ENROLLMENT_STATES.ACKNOWLEDGE
        );
    };

    ctrl.print = function () {
        $window.print();
    };

    /**
     *  Initialize the controller
     */
    init();
    localize();
};

ReEnrollController.$inject = [
    "$injector",
    "$controller",
    "$cookies",
    "$scope",
    "$state",
    "$stateParams",
    "$translate",
    "$window",
    "AuthenticationStatusService",
    "LanguageCodeFactory",
    "TransactionAccessRetrService",
    "redirectService",
    "reEnrollFactory"
];
export default ReEnrollController;
