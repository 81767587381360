const registrationGroupPasswordTemplate = `
<form name="registerForm" ng-submit="registerGroupPassword('registration')" autocomplete="off" novalidate role="form">

<div class="form-group has-error" ng-if="autherizationMessage" id="screenReaderAuth" tabindex="0">
    <span class="help-block">{{autherizationMessage | translate:autherizationMessageParams}}</span>
</div>

    <div class="form-group" ng-class="getFormGroupClass('groupId');">
        <label class="control-label" for="groupIdInput">{{"groupId" | translate}}</label>
        <input type="text"
               class="form-control col-5"
               inputmode="numeric"
               id="groupIdInput"
               name="groupId"
               ng-model="groupId"
               ng-required="true"
               ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
               ng-focus="setInputFocus($event);"
               ng-blur="removeInputFocus($event);"
               autocomplete="new-password"
               />

          <ng-messages
              for="getFieldError('groupId');"
              ng-show="displayFieldMessages('groupId', focusMgr['groupId']);"
              class="rule-validations">
                <ng-message when="required">
                  <span>{{"groupIdRequired" | translate }}</span>
                </ng-message>
        </ng-messages>
    </div>

    <div class="form-group" ng-class="getFormGroupClass('planEnrollmentCode');">
        <label class="control-label" for="planEnrollmentCodeInput">{{"planEnrollmentCode" | translate}}</label>
        <input type="password"
               class="form-control col-5"
               id="planEnrollmentCodeInput"
               name="planEnrollmentCode"
               ng-model="planEnrollmentCode"
               ng-required="true"
               ng-focus="setInputFocus($event);"
               ng-blur="removeInputFocus($event);"
               ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
               autocomplete="new-password"
               />

        <ng-messages
            for="getFieldError('planEnrollmentCode');"
            ng-show="displayFieldMessages('planEnrollmentCode', focusMgr['planEnrollmentCode']);"
            class="rule-validations">
              <ng-message when="required">
                  <span>{{"planEnrollmentCodeRequired" | translate }}</span>
              </ng-message>
        </ng-messages>
    </div>


    <div class="form-group clearfix">
        <button type="submit"
                class="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
                id="register-group-submit" ng-click="handlePlanEnrollmentCodeSubmit()">
                     <span data-ng-hide="showSpinner">CONTINUE</span>
                     <span data-ng-show="showSpinner">
                       <i class="fa fa-circle-o-notch fa-spin"></i>
                         PROCESSING ...
                    </span>
        </button>
    </div>
</form>
`;
export default registrationGroupPasswordTemplate;
