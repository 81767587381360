import React from "react";
import { useTranslations } from "../../queries";
import AccountRecoveryErrorLabel from "./AccountRecoveryErrorLabel";

const errorToTranslation = {
    minLength: "passwordMinLength",
    meetCondition0: "passwordCondition0",
    meetCondition1: "passwordCondition1",
    meetCondition2: "passwordCondition2",
    uppercasePattern: "passwordUppercasePattern",
    lowercasePattern: "passwordLowercasePattern",
    numberPattern: "passwordNumberPattern",
    specialCharPattern: "passwordSpecialCharPattern",
    notMatchUsername: "passwordNotMatchUsername",
    required: "passwordRequired"
} as const;

const conditions = ["minLength", "meetCondition0", "meetCondition1", "meetCondition2"];
const passwordRequierements = [
    "uppercasePattern",
    "lowercasePattern",
    "numberPattern",
    "specialCharPattern"
];

interface PasswordErrorMessagesProps {
    errorsBag: object;
    label: string;
    wasFocus?: boolean;
}

type TranslationValues = (typeof errorToTranslation)[keyof typeof errorToTranslation];

type TrasnlationsPasswordErrorMessages = {
    [key in TranslationValues]: string;
};

const PasswordErrorMessages = ({ label, errorsBag, wasFocus }: PasswordErrorMessagesProps) => {
    const translations = useTranslations<TrasnlationsPasswordErrorMessages>("accountSetup");
    const errorBag = errorsBag[label];

    if (!errorBag) {
        return "";
    }

    return (
        <div
            className={`rule-validations ${wasFocus ? "rule-validations-danger" : ""}`}
            aria-live="assertive"
        >
            <AccountRecoveryErrorLabel
                formValidationStyle={false}
                error={translations.passwordRequired}
                completed={errorBag.required}
            />
            {conditions.map((key) => (
                <AccountRecoveryErrorLabel
                    key={key}
                    error={translations[errorToTranslation[key]]}
                    completed={errorBag[key]}
                />
            ))}
            {!!conditions.length && (
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-11" aria-live="assertive">
                        {passwordRequierements.map((key) => (
                            <AccountRecoveryErrorLabel
                                key={key}
                                completed={errorBag[key]}
                                error={translations[errorToTranslation[key]]}
                            />
                        ))}
                    </div>
                </div>
            )}
            <AccountRecoveryErrorLabel
                error="notMatchUsername"
                completed={errorBag.notMatchUsername}
            />
        </div>
    );
};

export default PasswordErrorMessages;
