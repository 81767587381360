import AccessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import _isUndefined from "lodash/isUndefined";

import accountRecoveryEvents from "./events/AccountRecoveryEvents";

const accountRecoveryController = function (
    $scope,
    $state,
    accountRecoveryFactory,
    eventBus,
    $translate
) {
    $scope.resetStatus = "";
    $scope.resetStatusParams = "";
    $scope.errors = [];

    $scope.usernameError = "";
    $scope.passwordError = "";
    $scope.confirmPasswordError = "";
    $scope.showSpinner = false;

    $scope.forceValidations = function (inputs) {
        inputs.forEach(function (input) {
            $scope.accountRecoveryForm[input].$touched = true;
            $scope.accountRecoveryForm[input].$dirty = true;
        });
    };

    $scope.userInfo = {
        username: "",
        password: "",
        confirmPassword: ""
    };

    $scope.focusMgr = {};

    $scope.usernameFocus = false;
    $scope.passwordFocus = false;

    loadUsername();

    $scope.getFormGroupClass = function (fieldName) {
        const field = $scope.accountRecoveryForm[fieldName];
        let status = "";

        if (field.$invalid && field.$dirty) {
            status = "has-error";
        }

        return status;
    };

    $scope.fieldOnBlur = function (fieldName) {
        const field = $scope.accountRecoveryForm[fieldName];
        let status = "";

        if (field.$touched && field.$invalid) {
            status = "has-error";
        }
        return status;
    };

    $scope.getValidField = function (fieldName) {
        const field = $scope.accountRecoveryForm[fieldName].$valid;

        return field;
    };

    $scope.isDirty = function (fieldName) {
        const dirty = $scope.accountRecoveryForm[fieldName].$dirty;

        return dirty;
    };

    $scope.getFieldError = function (fieldName) {
        return $scope.accountRecoveryForm[fieldName].$error;
    };

    $scope.displayFieldMessages = function (fieldName, suppressFocused) {
        if (_isUndefined(suppressFocused)) {
            suppressFocused = false;
        }

        if (_isUndefined($scope.accountRecoveryForm)) {
            return "";
        }
        const field = $scope.accountRecoveryForm[fieldName];
        if (_isUndefined($scope.accountRecoveryForm) || !field) {
            return true;
        }
        const conditions =
            (field.$invalid && field.$dirty && !suppressFocused) ||
            (field.$touched && !suppressFocused) ||
            (!field.$pristine && !suppressFocused);

        return conditions;
    };

    $scope.displayFieldError = function (fieldName) {
        const field = $scope.accountRecoveryForm[fieldName];
        let displayFieldError = false;

        if (field.$invalid && field.$dirty) {
            displayFieldError = true;
        }

        if (field.$invalid && $scope.accountRecoveryForm.$submitted) {
            displayFieldError = true;
        }

        return displayFieldError;
    };

    function loadUsername() {
        accountRecoveryFactory.getUsername.query().$promise.then(function (data) {
            console.log("   Username retrived" + data);
            if (data !== null && data.status == "successful") {
                if (data.username !== null) {
                    $scope.userInfo.username = data.username;
                    $scope.userInfo.usernames = data.userNames;
                }
            }
        });
    }

    $scope.resetAccount = function (mutltipleUserFlow) {
        eventBus.dispatch(accountRecoveryEvents.CONFIRM_CLICK, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_LINK,
            event_properties: {
                selection: accountRecoveryEvents.CONFIRM_CLICK
            }
        });
        $scope.showSpinner = true;
        // Check if the form input is valid
        let resetQueryData = null;
        if ($scope.accountRecoveryForm.$valid) {
            if (mutltipleUserFlow) {
                resetQueryData = {
                    userName: $scope.currentWorkingUsrName,
                    password: $scope.userInfo.password,
                    confirmPassword: $scope.userInfo.confirmPassword
                };
            } else {
                resetQueryData = {
                    userName: $scope.userInfo.username,
                    password: $scope.userInfo.password,
                    confirmPassword: $scope.userInfo.confirmPassword
                };
            }

            accountRecoveryFactory.resetAccount.query(resetQueryData).$promise.then(
                function (data) {
                    $scope.showSpinner = false;
                    if (data !== null && data.status === "successful") {
                        if (!mutltipleUserFlow) {
                            $state.go("accountRecoveryConfirm");
                        } else {
                            $scope.passwordReset = false;
                            $scope.pwdChangedUsrName = $scope.currentWorkingUsrName;
                        }
                    } else {
                        $scope.resetStatus = "Could not reset Account Information";
                    }
                },
                function (error) {
                    $scope.showSpinner = false;
                    if (
                        error.headers("exception") !== null ||
                        error.headers("exceptionMessage") !== null
                    ) {
                        $scope.resetStatus = error.data.error.code;
                        $scope.resetStatusParams = error.data.error.errors[0];

                        const eventBusPayload = {
                            error_code: $scope.resetStatus
                        };

                        dispatchAmplitude({
                            eventType: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                            selection: accountRecoveryEvents.LOGIN_ERROR_CODE,
                            payload: eventBusPayload
                        });
                    } else {
                        if (error.data.data.username !== null) {
                            $scope.usernameError = error.data.data.username;
                        }
                        if (error.data.data.password !== null) {
                            $scope.passwordError = error.data.data.password;
                        }
                        if (error.data.data.confirmPassword !== null) {
                            $scope.confirmPasswordError = error.data.data.confirmPassword;
                        }
                        $scope.resetStatus = "Could not reset Account Information";
                    }
                }
            );
        } else {
            $scope.showSpinner = false;
            // Some input is not valid, so we need to prompt the participant to fix the issue
            // Manually set the $dirty flag for each input in the form to trigger validation

            const inputs = ["password", "confirmPassword"];

            inputs.forEach(function (input) {
                $scope.accountRecoveryForm[input].$touched = true;
                $scope.accountRecoveryForm[input].$dirty = true;
            });

            //set the focus on the screen reader error block to prompt the participant to fix the issue
            AccessibilityUtil.focusElement("screenReader");
        }
    };

    // focus the input with an error
    $scope.focusOnErrorInput = function (e, elementId) {
        AccessibilityUtil.focusOnErrorInput(e, elementId);
    };

    // focus on the first error in the screen reader error block
    $scope.enableScreenReaderErrors = function (e) {
        AccessibilityUtil.enableScreenReaderErrors(e);
    };

    $scope.setInputFocus = function (fieldName) {
        $scope.focusMgr[fieldName] = true;
        switch (fieldName) {
            case "username":
                //$scope.usernameFocus === false ? $scope.usernameFocus = true : $scope.usernameFocus = false;
                $scope.usernameFocus = !!$scope.usernameFocus;
                break;
            case "password":
                //$scope.passwordFocus === false ? $scope.passwordFocus = true : $scope.passwordFocus = false;
                $scope.passwordFocus = !!$scope.passwordFocus;
                break;
        }
    };

    $scope.removeInputFocus = function (fieldName) {
        $scope.focusMgr[fieldName] = false;
    };

    //-----EVENTS------
    $scope.confirmClick = function () {
        eventBus.dispatch(accountRecoveryEvents.CONFIRM_CLICK, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_LINK,
            event_properties: {
                selection: accountRecoveryEvents.CONFIRM_CLICK
            }
        });
    };
    $scope.passwordHelpClick = function () {
        $scope.passwordReset = !$scope.passwordReset;
        eventBus.dispatch(accountRecoveryEvents.PASSWORD_RESET, this, {
            passwordReset: $scope.passwordReset
        });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_LINK,
            event_properties: {
                selection: accountRecoveryEvents.PASSWORD_RESET,
                payload: {
                    passwordReset: $scope.passwordReset
                }
            }
        });
    };

    $scope.passwordHelpClickMultipleUsrNmes = function (usrName) {
        $scope.currentWorkingUsrName = usrName;
        $scope.passwordReset = !$scope.passwordReset;
        eventBus.dispatch(accountRecoveryEvents.PASSWORD_RESET, this, {
            passwordReset: $scope.passwordReset
        });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_LINK,
            event_properties: {
                selection: accountRecoveryEvents.PASSWORD_RESET,
                payload: {
                    passwordReset: $scope.passwordReset
                }
            }
        });
    };
    $scope.passwordBlur = function () {
        eventBus.dispatch(accountRecoveryEvents.PASSWORD_BLUR, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: accountRecoveryEvents.PASSWORD_BLUR
            }
        });
    };
    $scope.confirmPasswordBlur = function () {
        eventBus.dispatch(accountRecoveryEvents.CONFIRM_PASSWORD_BLUR, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: accountRecoveryEvents.CONFIRM_PASSWORD_BLUR
            }
        });
    };
    $scope.continueClick = function () {
        eventBus.dispatch(accountRecoveryEvents.CONTINUE_CLICK, this);
    };
    $scope.returnClick = function () {
        eventBus.dispatch(accountRecoveryEvents.RETURN_CLICK, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: accountRecoveryEvents.RETURN_CLICK
            }
        });
    };
    $scope.noCodeClick = function () {
        eventBus.dispatch(accountRecoveryEvents.NO_CODE, this);
    };
    $scope.codeSubmit = function () {
        eventBus.dispatch(accountRecoveryEvents.CODE_SUBMIT, this);
    };
    $scope.activationCodeChange = function (option) {
        if (option.startsWith("sms")) {
            $scope.activationCodeModel.alert = "show";
        } else {
            $scope.activationCodeModel.alert = "no";
        }
        eventBus.dispatch(accountRecoveryEvents.CODE_CHANGE, this);
    };
    $scope.codeDeliveryChange = function (option) {
        eventBus.dispatch(accountRecoveryEvents.CODE_DELIVERY_CHANGE, this, { option: option });
    };
    $scope.verifyCode = function () {
        eventBus.dispatch(accountRecoveryEvents.VERIFY_CODE, this);
    };
    $scope.verifyCodeLoginHelp = function () {
        eventBus.dispatch(accountRecoveryEvents.VERIFY_CODE_LOGIN_HELP, this);
    };
    $scope.sendCode = function () {
        eventBus.dispatch(accountRecoveryEvents.SEND_CODE, this);
    };

    $scope.ssnInputChange = function () {
        eventBus.dispatch(accountRecoveryEvents.SSN_CHANGE, this);
    };
    $scope.zipChange = function () {
        eventBus.dispatch(accountRecoveryEvents.ZIP_CHANGE, this);
    };
    $scope.lastNameChange = function () {
        eventBus.dispatch(accountRecoveryEvents.LAST_NAME_CHANGE, this);
    };
    $scope.dobChange = function () {
        eventBus.dispatch(accountRecoveryEvents.DOB_CHANGE, this);
    };
    $scope.streetChange = function () {
        eventBus.dispatch(accountRecoveryEvents.STREET_CHANGE, this);
    };
    $scope.submitClick = function () {
        eventBus.dispatch(accountRecoveryEvents.SUBMIT, this);
    };

    $translate([
        "loginHelp",
        "enterResetInfo",
        "username",
        "passwordReset",
        "password",
        "passwordUppercasePattern",
        "passwordLowercasePattern",
        "passwordNumberPattern",
        "passwordSpecialCharPattern",
        "passwordNotMatchUsername",
        "passwordRequired",
        "passwordMinLength",
        "passwordCondition0",
        "passwordCondition1",
        "passwordCondition2",
        "passwordAcceptable",
        "reEnterPassword",
        "confirmPasswordRequired",
        "confirmPasswordNotMatch",
        "confirmPasswordValid",
        "returnToLogin",
        "confirmationHeader",
        "confirmationContent",
        "confirmationLink"
    ]).then(function (translations) {
        angular.extend($scope, {
            translatedData: {
                loginHelp: translations.loginHelp,
                enterResetInfo: translations.enterResetInfo,
                username: translations.username,
                passwordReset: translations.passwordReset,
                password: translations.password,
                passwordUppercasePattern: translations.passwordUppercasePattern,
                passwordLowercasePattern: translations.passwordLowercasePattern,
                passwordNumberPattern: translations.passwordNumberPattern,
                passwordSpecialCharPattern: translations.passwordSpecialCharPattern,
                passwordNotMatchUsername: translations.passwordNotMatchUsername,
                passwordRequired: translations.passwordRequired,
                passwordMinLength: translations.passwordMinLength,
                passwordCondition0: translations.passwordCondition0,
                passwordCondition1: translations.passwordCondition1,
                passwordCondition2: translations.passwordCondition2,
                passwordAcceptable: translations.passwordAcceptable,
                reEnterPassword: translations.reEnterPassword,
                confirmPasswordRequired: translations.confirmPasswordRequired,
                confirmPasswordNotMatch: translations.confirmPasswordNotMatch,
                confirmPasswordValid: translations.confirmPasswordValid,
                returnToLogin: translations.returnToLogin,
                confirmationHeader: translations.confirmationHeader,
                confirmationContent: translations.confirmationContent,
                confirmationLink: translations.confirmationLink
            }
        });
    });
};

accountRecoveryController.$inject = [
    "$scope",
    "$state",
    "accountRecoveryFactory",
    "eventBus",
    "$translate"
];
export default accountRecoveryController;
