const BasicAuthentication = function (base64Service, $http) {
    return {
        setCredentials: function (username, password) {
            const encoded = base64Service.encode(username + ":" + password);
            $http.defaults.headers.common.Authorization = "Basic " + encoded;
        },
        clearCredentials: function () {
            $http.defaults.headers.common.Authorization = "";
        },
        setidProof: function (idProofEnabled) {
            $http.defaults.headers.common.idProofEnabled = idProofEnabled;
        }
    };
};

BasicAuthentication.$inject = ["base64Service", "$http"];
export default BasicAuthentication;
