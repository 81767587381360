import isVisible from "core-ui/client/src/app/core/util/isVisible";

const PersonalContactInfoUpdateUtil = function PersonalContactInfoUpdateUtil() {
    return {
        getUpdateContactInfoJson: getUpdateContactInfoJson
    };

    function getUpdateContactInfoJson(flowName, emailAddress, domesticCntryCd, intlCntryCode) {
        let phoneNumber = "";
        let intlNumber = "";
        let cntryCode = "";
        let domesticCntryCode = "";
        let home = "";
        let temp;

        // eslint-disable-next-line no-undef
        if (isVisible(document.getElementById("phoneNumberIdD"))) {
            // eslint-disable-next-line no-undef
            phoneNumber = document.getElementById("phoneNumberIdD").value;
            temp = domesticCntryCd;
            domesticCntryCode = domesticCntryCd.substring(0, 2);
        }
        // eslint-disable-next-line no-undef
        if (isVisible(document.getElementById("intlNumberIdD"))) {
            // This condition becomes true when the domestic number being changed to INTLNumber
            // eslint-disable-next-line no-undef
            intlNumber = document.getElementById("intlNumberIdD").value;
            temp = domesticCntryCd;
            cntryCode = temp.substring(0, 2);
        }

        // eslint-disable-next-line no-undef
        if (isVisible(document.getElementById("phoneNumberIdI"))) {
            // eslint-disable-next-line no-undef
            phoneNumber = document.getElementById("phoneNumberIdI").value;
            temp = intlCntryCode;
            domesticCntryCode = intlCntryCode.substring(0, 2);
        }
        // eslint-disable-next-line no-undef
        if (isVisible(document.getElementById("intlNumberIdI"))) {
            const temp = intlCntryCode;
            // eslint-disable-next-line no-undef
            intlNumber = document.getElementById("intlNumberIdI").value;
            cntryCode = temp.substring(0, 2);
        }
        // eslint-disable-next-line no-undef
        if (isVisible(document.getElementById("homeNumberId"))) {
            // eslint-disable-next-line no-undef
            home = document.getElementById("homeNumberId").value;
        }

        const updatePersonalContactData = {
            email: emailAddress,
            phoneNumber: phoneNumber,
            cntryCode: cntryCode,
            domesticCntryCode: domesticCntryCode,
            intlNumber: intlNumber,
            flowName: flowName,
            home: home
        };
        return updatePersonalContactData;
    }
};
PersonalContactInfoUpdateUtil.$inject = [];
export default PersonalContactInfoUpdateUtil;
