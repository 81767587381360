import React, { PropsWithChildren } from "react";
import { useQuery } from "@tanstack/react-query";
import TranslationService from "../services/translationService";

const TranslationsServiceContext = React.createContext({});
TranslationsServiceContext.displayName = "TranslationsServiceContext";

const TranslationsServiceProvider = ({ children }: PropsWithChildren) => {
    const { data, isLoading } = useQuery({
        queryKey: ["get-translations"],
        queryFn: () => {
            return TranslationService.getTranslations();
        }
    });

    if (!data || isLoading) {
        return;
    }

    return (
        <TranslationsServiceContext.Provider value={data}>
            {children}
        </TranslationsServiceContext.Provider>
    );
};

TranslationsServiceProvider.displayName = "TranslationsServiceProvider";

export { TranslationsServiceProvider, TranslationsServiceContext };
