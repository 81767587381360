import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

import idProofEvents from "./events/IDProofEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const otpIdentityVerificationController = function (
    $scope,
    $injector,
    eventBus,
    $stateParams,
    otsRegistrationService
) {
    const vm = this;
    const $state = $injector.get("$state");
    const logger = ExternalLogger.getInstance("otpIdentityVerificationController");

    $scope.isOtsUser = $stateParams.flowName === "OTS";
    vm.options = {
        onConfirm: function (phoneNumber, data) {
            logger.debug("otpIdentityVerificationController - onConfirmClick - [{0}]", [
                phoneNumber ? phoneNumber : null
            ]);
            eventBus.dispatch(idProofEvents.OTP_AGREE_SEND_CODE, this);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: idProofEvents.OTP_AGREE_SEND_CODE
                }
            });
            const params = {
                phoneNumber: phoneNumber ? phoneNumber : null,
                flowName: $stateParams.flowName
            };
            otsRegistrationService.setFormData(formatPhone(phoneNumber));
            if (data.state === "ID_PROOFING_QUERIES") {
                $state.go("idProofQueries", params);
            } else if (data.state === "MAIL_PIN") {
                $state.go("mailPin");
            } else if (data.state === "ID_PROOFING_PIN_STATE") {
                $state.go("idProofWithPin");
            } else if (data.state === "ID_PROOFING_OTP_VERIFY_CODE") {
                $state.go("otpIdentityVerificationCodeEntry", params);
            } else {
                $state.go("idProofError", { errorCode: data.errorCode });
            }
        },
        onCancel: function () {
            logger.debug("otpIdentityVerificationController - onCancelClick");
            eventBus.dispatch(idProofEvents.OTP_SKIP_MAIL_PIN, this);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: idProofEvents.OTP_SKIP_MAIL_PIN
                }
            });
            $state.go("mailPin", { optOut: "optOut-Modal" });
        }
    };

    const formatPhone = function (phoneNumber) {
        const formatPhoneNumber = phoneNumber
            .split("(")
            .join("")
            .split(")")
            .join("")
            .split(" ")
            .join("")
            .split("-")
            .join("");
        return { phoneNumber: formatPhoneNumber };
    };
};

otpIdentityVerificationController.$inject = [
    "$scope",
    "$injector",
    "eventBus",
    "$stateParams",
    "otsRegistrationService"
];
export default otpIdentityVerificationController;
