import confirmPasswordTemplate from "./confirmPasswordTemplate";

const changePasswordTemplate = `

<!-- React Component
<pwr-change-password-component 
    cancel = "cancel"
    updatePassWord = "updatePassWord"
    saveStatus = "saveStatus"
    saveStatusParams = "saveStatusParams"
    error = "error"
></pwr-change-password-component>
-->

<div class="modal-header">
	<div class="modal-title" id="modal-title" role="heading" aria-level="2">{{ "profile.login.header.changePassword"
		| translate }}</div>
</div>
<div class="modal-body clearfix profile">
	<div class="modal-well with-background">
		<form role="form" name="credentialForm" novalidate autocomplete="off">
			${confirmPasswordTemplate}
		</form>
	</div>
</div>

<div class="modal-footer">
	<button ng-click="cancel('password')" class="btn btn-link">{{
		"preference.button.cancel" | translate }}</button>
	<button ng-click="updatePassWord('profile')" class="btn btn-primary" id="submit" ng-disabled="showSpinner==true">
		<span data-ng-hide="showSpinner==true">
			{{ "preference.button.save" | translate }}
		</span>
		<span data-ng-show="showSpinner==true">
			<i class="fa fa-circle-o-notch fa-spin"></i>
			Processing ...
		</span>
	</button>
</div>
`;
export default changePasswordTemplate;
