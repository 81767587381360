import type { AxiosResponse } from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executeGet } from "../../utils/appUtils";

export type DeliverySet = {
    deliveryMask: string;
    deliveryPhoneType: string;
    deliveryType: string;
    originalValue: string;
};

export interface DeliveryOptionsResponseData {
    deliverySet: DeliverySet[];
    destination: string;
    editContactInfoAllowed: boolean;
    flowName: string;
    intlPhoneSupportAllowed: boolean;
}

type DeliveryOptionsSuccessResponse = DeliveryOptionsResponseData | undefined;

const getDeliveryOptions = (): Promise<AxiosResponse<DeliveryOptionsSuccessResponse>> => {
    return executeGet(ReferenceData.GET_DELIVERY_OPTIONS, "deliveryOptionsFault");
};

export default getDeliveryOptions;
