import React from "react";
import PropTypes from "prop-types";

import useAccountRecovery from "./useAccountRecovery";
import AccountRecoveryErrorMessages from "./AccountRecoveryErrorMessages";
import PasswordErrorMessages from "./PasswordErrorMessages";
import ConfirmPasswordErrorMessages from "./ConfirmPasswordErrorMessages";

/**
 * Angular to react version
 * @param {*} param0
 * @returns
 */

const AccountRecovery = ({ redirect }) => {
    const {
        translations,
        passwordReset,
        userInfo,
        errorsBag,
        showSpinner,
        resetStatus,
        resetStatusParams,
        confirmPasswordError,
        passwordError,
        formValidations,
        returnClick,
        submitForm,
        updateFormInput,
        passwordHelpClick,
        setWasFocus,
        wasFocus
    } = useAccountRecovery({ mutltipleUserFlow: false, redirect });

    return (
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
            <header className="contained-form-header">
                <h1>
                    {translations.loginHelp}
                    <span className="registration-progress">(4 of 4)</span>
                </h1>
            </header>
            <div className="inner-container with-padding with-shadow">
                {translations.resetStatusParams}
                {resetStatus && (
                    <div className="form-group has-error">
                        <span className="help-block">{resetStatus || resetStatusParams}</span>
                    </div>
                )}
                {formValidations.isSubmited && !formValidations.isValid && (
                    <AccountRecoveryErrorMessages errorsLength={formValidations.errorsLength} />
                )}

                <form
                    id="accountRecoveryForm"
                    name="accountRecoveryForm"
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => submitForm(event)}
                >
                    <div className="form-group">
                        <label htmlFor="acUsernameInput">{translations.username}</label>
                        <input
                            type="text"
                            className="form-control form-disabled"
                            title={translations.username}
                            id="acUsernameInput"
                            name="usernameInput"
                            value={userInfo.username}
                            readOnly
                        />
                    </div>

                    {!passwordReset ? (
                        <div className="form-group" id="passwordReset">
                            <a
                                href="#/account-recovery"
                                className="reset-password-toggle"
                                onClick={() => passwordHelpClick()}
                            >
                                {translations.passwordReset}
                            </a>
                        </div>
                    ) : (
                        <>
                            <div
                                className={
                                    !errorsBag.password?.isValid
                                        ? "form-group has-error"
                                        : "form-group"
                                }
                            >
                                <label
                                    htmlFor="acPasswordInput"
                                    className={`control-label ${
                                        wasFocus && !errorsBag.password?.isValid && "danger"
                                    } animated`}
                                >
                                    {translations.password}
                                </label>
                                <span className="sr-only" id="passwordRules">
                                    Must be eight to sixteen characters. Must include three of these
                                    four. {translations.passwordUppercasePattern}.
                                    {translations.passwordLowercasePattern}.{" "}
                                    {translations.passwordNumberPattern}.{" "}
                                    {translations.passwordSpecialCharPattern}.{" "}
                                    {translations.passwordNotMatchUsername}.{" "}
                                </span>
                                <input
                                    type="password"
                                    maxLength={63}
                                    id="acPasswordInput"
                                    className={`form-control password-reset ${
                                        wasFocus && !errorsBag.password?.isValid && "danger"
                                    } animated`}
                                    onFocus={() => setWasFocus(false)}
                                    onBlur={() => setWasFocus(true)}
                                    name="password"
                                    onChange={(event) =>
                                        updateFormInput("password", event.target.value)
                                    }
                                    value={userInfo.password}
                                    aria-describedby="passwordRules"
                                />
                                {errorsBag.password?.isValid && (
                                    <p className="success">
                                        {translations.accountSetup.passwordAcceptable}
                                    </p>
                                )}
                                <PasswordErrorMessages
                                    wasFocus={wasFocus}
                                    label="password"
                                    errorsBag={errorsBag}
                                />
                                <span id="passwordError" className="help-block">
                                    {passwordError}
                                </span>
                            </div>
                            <div
                                className={
                                    !errorsBag.confirmPassword?.isValid
                                        ? "form-group has-error"
                                        : "form-group"
                                }
                            >
                                <label
                                    htmlFor="confirmPasswordInput"
                                    className={`control-label ${
                                        wasFocus && !errorsBag.confirmPassword?.isValid && "danger"
                                    } animated`}
                                >
                                    {translations.reEnterPassword}
                                </label>
                                <span className="sr-only" id="confirmPasswordRules">
                                    {translations.confirmPasswordRequired}.{" "}
                                    {translations.confirmPasswordNotMatch} .
                                </span>
                                <input
                                    type="password"
                                    id="confirmPasswordInput"
                                    name="confirmPassword"
                                    maxLength={63}
                                    className={`form-control password-reset ${
                                        wasFocus && !errorsBag.confirmPassword?.isValid && "danger"
                                    } animated`}
                                    onFocus={() => setWasFocus(false)}
                                    onBlur={() => setWasFocus(true)}
                                    value={userInfo.confirmPassword}
                                    onChange={(event) =>
                                        updateFormInput("confirmPassword", event.target.value)
                                    }
                                    aria-describedby="confirmPasswordRules"
                                />
                                {errorsBag.confirmPassword?.isValid && (
                                    <p className="success">{translations.passwordAcceptable}</p>
                                )}
                                <ConfirmPasswordErrorMessages
                                    label="confirmPassword"
                                    errorsBag={errorsBag}
                                    translations={translations}
                                    wasFocus={wasFocus}
                                />
                                <span id="confirmPasswordError" className="help-block">
                                    {confirmPasswordError}
                                </span>
                            </div>

                            <div className="form-group clearfix">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg btn-block margin-top-default"
                                    disabled={showSpinner}
                                >
                                    {!showSpinner && <span>Continue</span>}

                                    {showSpinner && (
                                        <span>
                                            <i className="fa fa-circle-o-notch fa-spin"></i>
                                            Processing ...
                                        </span>
                                    )}
                                </button>
                            </div>
                        </>
                    )}

                    <a
                        href="#/login"
                        className="margin-bottom-default"
                        title="Return to Login"
                        onClick={returnClick}
                    >
                        {translations.returnToLogin}
                    </a>
                </form>
            </div>
        </div>
    );
};

AccountRecovery.propTypes = {
    redirect: PropTypes.func
};

export default AccountRecovery;
