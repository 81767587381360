import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../services/translationService";

export default class UsernamePasswordComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            isUserNameUpdateAllowed: this.props.isUserNameUpdateAllowed,
            pinAuthCode: this.props.pinAuthCode,
            userName: this.props.userName
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    handleClickUsernameModal = () => {
        //React function, executing an angular external function.
        this.props.openUsernameModal();
    };

    handleClickPasswordModal = () => {
        //React function, executing an angular external function.
        this.props.openPasswordModal();
    };

    render() {
        if (!this.state.translations || !this.state.isUserNameUpdateAllowed) {
            return null;
        }

        return (
            <div className="profile-information login-information">
                <h2> {this.state.translations.profile.login.header.usernamePassword} </h2>

                <div className="row">
                    <strong className="col-sm-4 text-right">
                        {this.state.translations.profile.login.label.username}
                    </strong>
                    <div className="col-sm-4 profile-username">{this.state.userName}</div>
                    {this.state.pinAuthCode === "U" && (
                        <div className="btn-container">
                            <button
                                onClick={this.handleClickUsernameModal}
                                className="btn btn-primary"
                                id="updateUsernameId"
                            >
                                {this.state.translations.preference.button.changeUsername}
                            </button>
                        </div>
                    )}
                </div>

                <div className="row">
                    <strong className="col-sm-4 text-right">
                        {this.state.translations.profile.login.label.password}
                    </strong>
                    <div className="col-sm-4">
                        &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
                    </div>
                    {this.state.pinAuthCode === "U" && (
                        <div className="btn-container">
                            <button
                                onClick={this.handleClickPasswordModal}
                                className="btn btn-primary"
                                id="updatePassWordId"
                            >
                                {this.state.translations.preference.button.changePassword}
                            </button>
                        </div>
                    )}
                </div>
                <hr />
            </div>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

UsernamePasswordComponent.propTypes = {
    isUserNameUpdateAllowed: PropTypes.bool,
    pinAuthCode: PropTypes.string,
    userName: PropTypes.string,
    openUsernameModal: PropTypes.func,
    openPasswordModal: PropTypes.func
};
