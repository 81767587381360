const PAESSOLandingController = function ($location, $scope, $state, $window, PAESSOAuthFactory) {
    console.log("inside the controller");
    // Check to see if LIAT is enabled then transition the user to the liat
    // state or to the accounts state

    const paeSSOAuthParams = {
        SSO_TOKEN: $location.search().SSO_TOKEN,
        PAEUser: $location.search().PAEUser,
        accu: $location.search().accu,
        username: $location.search().username
    };

    $scope.paeAuthData = PAESSOAuthFactory.query(paeSSOAuthParams);

    $scope.paeAuthData.$promise.then(
        function (result) {
            $scope.relativeBaseURL = $window.location.href.substring(
                0,
                $window.location.href.indexOf("/#/")
            );
            $scope.domainURLForPAE = $scope.relativeBaseURL;
            if (result.destinationUrl && result.destinationUrl.indexOf("enroll") >= 0) {
                $scope.participantAppURLPAE = "/" + result.destinationUrl;
                console.log("after the successful PAE Authentication: " + $scope.domainURLForPAE);
                $window.location.href = $scope.domainURLForPAE + result.destinationUrl.substr(11);
            } else {
                $scope.participantAppURLPAE = "/participant/accounts/#/landing";
                console.log("after the successful PAE Authentication: " + $scope.domainURLForPAE);
                $window.location.href = $scope.domainURLForPAE + "/accounts/#/landing";
            }
        },
        function (response) {
            if (response.data.status == "FAIL") {
                $scope.authentication.errorMessage = response.data.error.code;
                $scope.authentication.errorMessageParams = response.data.error.errors[0];
                $scope.loggingIn = false;
            }
        }
    );
};

PAESSOLandingController.$inject = [
    "$location",
    "$scope",
    "$state",
    "$window",
    "PAESSOAuthFactory",
    "TransactionAccessRetrService"
];
export default PAESSOLandingController;
