import "angular";
import "angular-resource";
import "angular-ui-router";
import "core-ui/client/src/app/SocialSecurityNumberDirective";
import "core-ui/client/src/app/ZIPCodeDirective";
import "core-ui/client/src/app/DateDirective";
import "core-ui/client/src/app/PrintDirective";
import PrimaryPlanFactory from "core-ui/client/src/app/PrimaryPlanFactory";
import PrimaryPlanService from "core-ui/client/src/app/PrimaryPlanService";

import accountOpenController from "../accountOpen/AccountOpenController";
import otsAccountOpenFactory from "../accountOpen/OTSAccountOpenFactory";
import OtsRegistrationService from "../accountOpen/OtsRegistrationService";
import NPDIRegistrationService from "../npdi/NPDIRegistrationService.js";
import profileFactory from "../profile/ProfileFactory";

import preAccountOpeningController from "./PreAccountOpeningController";
import preAccountOpeningFactory from "./PreAccountOpeningFactory";
import registrationController from "./RegistrationController";
import registrationFactory from "./RegistrationFactory";
import registrationRoute from "./RegistrationRoute";
import registrationSummaryController from "./registrationSummaryController";
import registrationWithGroupPasswordController from "./RegistrationWithGroupPasswordController";

// eslint-disable-next-line no-undef
const registration = angular.module("registration", [
    "ngMessages",
    "ngResource",
    "ui.router",
    "validationDirectives",
    "FullHeight",
    "ZIPCodeDirective",
    "DateDirective",
    "SocialSecurityNumberDirective",
    "PrintDirective"
]);

registration.config(registrationRoute);
registration.controller("registrationController", registrationController);
registration.controller("accountOpenController", accountOpenController);
registration.controller("preAccountOpeningController", preAccountOpeningController);
registration.controller(
    "registrationWithGroupPasswordController",
    registrationWithGroupPasswordController
);
registration.controller("registrationSummaryController", registrationSummaryController);
registration.factory("registrationFactory", registrationFactory);
registration.factory("profileFactory", profileFactory);
registration.factory("preAccountOpeningFactory", preAccountOpeningFactory);
registration.factory("primaryPlanFactory", PrimaryPlanFactory);
registration.service("primaryPlanService", PrimaryPlanService);
registration.service("otsRegistrationService", OtsRegistrationService);
registration.service("otsAccountOpenFactory", otsAccountOpenFactory);
registration.service("npdiRegistrationService", NPDIRegistrationService);

export default registration;
