import { clearSpanishLanguageCookies } from "../../authentication/AuthenticationService";
import setSpanishLanguageCookies from "../../authentication/setSpanishLanguageCookies";

const getSpanishRedirectUrl = async (destinationUrl: string) => {
    try {
        let url = `../../${destinationUrl}`;
        await clearSpanishLanguageCookies();
        await setSpanishLanguageCookies();
        const path = destinationUrl.match(/(^https?:\/\/[^/]+\/?)?(.*)/);
        if (path) {
            url = `${window.location.protocol}//es.${window.location.hostname}/${path[2]}`;
        }
        return url;
    } catch (error) {
        console.error("Error getting Spanish redirect URL", error);
        throw error;
    }
};

export default getSpanishRedirectUrl;
