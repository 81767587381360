import type { AxiosResponse } from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executeGet } from "../../utils/appUtils";
import { DeliveryOptionsResponseData } from "./getDeliveryOptions";
import StringUtil from "core-ui/client/src/app/StringUtil";

type DeliveryOptionsSuccessResponse = DeliveryOptionsResponseData | undefined;

interface DeliveryOptionsParams {
    dbName: string;
    indId: string;
}

const getDeliveryOptionsByIndId = (
    params: DeliveryOptionsParams
): Promise<AxiosResponse<DeliveryOptionsSuccessResponse>> => {
    return executeGet(
        StringUtil.supplant(ReferenceData.GET_DELIVERY_OPTIONS_BY_IND_ID, params),
        "deliveryOptionsByIndIdFault"
    );
};

export default getDeliveryOptionsByIndId;
