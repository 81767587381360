/**
 *
 * @name app.accountSetup.events.AccountSetupEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for account setup
 */

"use strict";

const root = "AccountSetupEvent.";

const accountSetupEvents = {
    EMAIL_CHANGE: root + "contact_info_email_changed_event",
    PHONE_CHANGE: root + "contact_info_phone_number_changed_event",
    USRNM_CHANGE: root + "username_input_change_event",
    PASS_CHANGE: root + "user_password_input_changed_event",
    CONFIRM_PASS_CHANGE: root + "user_confirm_password_input_changed_event",
    REGISTER: root + "register_button_clicked_event",
    CONTINUE: root + "continue_button_clicked_event"
};

export default accountSetupEvents;
