import contactInfoTemplate from "./contactInfoTemplate";

const contactUpdateTemplate = `
<div class="alert alert-danger" data-ng-if="errors.length > 0">
    <p data-ng-repeat="error in errors">{{error}}</p>
</div>

<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
    <header class="contained-form-header">
        <h1>{{"accountSetup.completeContactInfoTitle"| translate}}</h1>
        <p>{{"accountSetup.completeRegistrationInfo"| translate}}</p>
    </header>

    <div class="inner-container with-padding with-shadow">
        <form role="form" id="registration-form" name="accountSetupForm" class="clearfix"
            data-ng-init="loadContactInfo()" data-ng-submit="updateContact('mfa')" novalidate autocomplete="off">

            <div class="form-group has-error">
                <span class="help-block">{{saveStatus| translate:saveStatusParams }}</span>
            </div>

           ${contactInfoTemplate}

            <button type="submit" class="btn btn-primary btn-lg btn-block margin-top-default" id="submit"
                ng-disabled="showSpinner==true">
                <span data-ng-hide="showSpinner==true">
                    CONTINUE
                </span>

                <span data-ng-show="showSpinner==true">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                    Processing ...
                </span>

            </button>
        </form>
    </div>
</div>
`;
export default contactUpdateTemplate;
