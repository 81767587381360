const CSRFTokenFactory = function ($injector) {
    const factory = {};
    const $q = $injector.get("$q");
    const $http = $injector.get("$http");
    const $rootScope = $injector.get("$rootScope");

    factory.generateCSRF = function () {
        const deferred = $q.defer();

        $http
            // eslint-disable-next-line no-undef
            .get(__authenticationUrl__ + "rest/nonauth/getCSRFToken")
            .success(function (data) {
                $rootScope.waiting = true;
                deferred.resolve(data);
            })
            .error(function (data) {
                // TODO: Use logger to log the error message

                deferred.reject(data);
            });

        return deferred.promise;
    };

    factory.saveCSRF = function (data) {
        const deferred = $q.defer();

        $http
            .post(__baseApiUrl__ + "/participant-web-services/rest/nonauth/setCSRFToken", data)
            .success(function (data) {
                deferred.resolve(data);
            })
            .error(function (data) {
                // TODO: Use logger to log the error message
                deferred.reject(data);
            });

        return deferred.promise;
    };

    return factory;
};

CSRFTokenFactory.$inject = ["$injector"];
export default CSRFTokenFactory;
