import "angular";
import "angular-resource";
import "angular-ui-router";
import "angular-cookies";
import reEnrollController from "./ReEnrollController";
import reEnrollFactory from "./ReEnrollFactory";
import reEnrollRoute from "./ReEnrollRoute";

// eslint-disable-next-line no-undef
const reEnrollModule = angular.module("reEnrollModule", ["ngResource", "ui.router", "ngCookies"]);

reEnrollModule.controller("reEnrollController", reEnrollController);
reEnrollModule.factory("reEnrollFactory", reEnrollFactory);
reEnrollModule.config(reEnrollRoute);

export default reEnrollModule;
