import { accountSetup } from "./constants";

export const getProps = (type, inputName, message = "") => {
    return {
        errors: {
            [inputName]: { type, message }
        },
        accountSetup
    };
};

export const getUpdateContactInfoJson = (data, flowName) => {
    let phoneNumber = "";
    let intlNumber = "";
    let cntryCode = "";
    let domesticCntryCode = "";

    if (data.phoneNumberIdD) {
        phoneNumber = data.phoneNumberIdD;
        domesticCntryCode = data.contactCountryNameD.slice(0, 2);
    }

    if (data.intlNumberIdD) {
        intlNumber = data.intlNumberIdD;
        cntryCode = data.contactCountryNameD.slice(0, 2);
    }

    if (data.phoneNumberIdI) {
        phoneNumber = data.phoneNumberIdI;
        domesticCntryCode = data.contactCountryNameI.slice(0, 2);
    }

    if (data.intlNumberIdI) {
        intlNumber = data.intlNumberIdI;
        cntryCode = data.contactCountryNameI.slice(0, 2);
    }

    return {
        email: data.email,
        phoneNumber,
        cntryCode,
        domesticCntryCode,
        intlNumber,
        flowName
    };
};
