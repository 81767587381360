import type { AxiosResponse } from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";

const setSpanishLanguageCookies = (): Promise<AxiosResponse> => {
    return executePost(
        ReferenceData.SET_SPANISH_LANGUAGE_COOKIES,
        "setSpanishLanguageCookiesFault"
    );
};

export default setSpanishLanguageCookies;
