import "angular";
import "angular-resource";
import "angular-ui-router";
import legSSOTokenRetrievalFactory from "../core/LegSSOTokenRetrievalFactory.js";
import StateInterceptor from "../core/StateInterceptor";

import mfaController from "./MFAController";
import mfaFactory from "./MFAFactory.js";
import routeStates from "./MFARoute";
import mfaVerifyCodeController from "./MFAVerifyCodeController";

// eslint-disable-next-line no-undef
const mfa = angular.module("mfa", ["ngResource", "ui.router", "focusDirectives"]);

mfa.factory("StateInterceptor", StateInterceptor);
mfa.config([
    "$httpProvider",
    function ($httpProvider) {
        $httpProvider.interceptors.push("StateInterceptor");
    }
]);

mfa.controller("mfaController", mfaController);
mfa.controller("mfaVerifyCodeController", mfaVerifyCodeController);
mfa.factory("mfaFactory", mfaFactory);
mfa.factory("legSSOTokenRetrievalFactory", legSSOTokenRetrievalFactory);
mfa.config(routeStates);

// adds directive to track when any element loads
// it is specifically used to track the MFA component
mfa.directive("elemReady", function ($parse) {
    return {
        restrict: "A",
        link: function ($scope, elem, attrs) {
            elem.ready(function () {
                $scope.$apply(function () {
                    const func = $parse(attrs.elemReady);
                    func($scope);
                });
            });
        }
    };
});

export default mfa;
