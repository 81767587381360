/**
 *
 * @name app.core.events.CoreEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for core files
 */

"use strict";

const root = "CoreEvent.";

const CoreEvents = {
    AUTHENTICATE: root + "authenticate_button_clicked_event",
    REGISTER: root + "register_button_clicked_event",
    INPUT_ERROR: root + "login_input_missing_error_detected",
    AUTH_SUCCESS: root + "authenticate_success",
    AUTH_FAIL: root + "authenticate_failed",
    OPEN_ACCOUNT_VALID: root + "open_account_registration_button_clicked_validated",
    OPEN_ACCOUNT_INVALID: root + "open_account_registration_button_clicked_invalid"
};

export default CoreEvents;
