import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

import CoreEvents from "../core/events/CoreEvents";

import existingRetailMessageTemplate from "./existingRetailMessageTemplate";
import PreAccountOpeningController from "./PreAccountOpeningController";

const preAccountOpeningController = function (
    $scope,
    $rootScope,
    $state,
    $timeout,
    $modal,
    $translate,
    eventBus,
    preAccountOpeningFactory,
    redirectService,
    $injector,
    $q
) {
    const vm = this;

    vm.isDataLoaded = false;
    vm.countryCodes = [];
    vm.stateCodes = [];
    vm.isRegistered = null;
    vm.loading = false;
    vm.serviceError = false;

    $scope.init = function () {
        const deferred = $q.defer();

        preAccountOpeningFactory.retrieveCountryCode.query({}).$promise.then(
            function (data) {
                if (data !== null) {
                    vm.countryCodes = data.filter((c) => c.code == "US" || c.code == "CA");
                }
                deferred.resolve();
            },
            function (error) {
                if (error?.data?.status == "FAIL") {
                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "preAccountOpening- retrieveCountryCode failed",
                        event_properties: {
                            login_error_code: error?.data?.error?.code,
                            error_message: error?.data?.error?.errors[0]
                        }
                    });
                }
                deferred.reject();
            }
        );
        preAccountOpeningFactory.retrieveStateCode.query({}).$promise.then(
            function (data) {
                if (data !== null) {
                    vm.stateCodes = data;
                }
                deferred.resolve();
            },
            function (error) {
                if (error?.data?.status == "FAIL") {
                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "preAccountOpening - retrieveStateCode failed",
                        event_properties: {
                            login_error_code: error?.data?.error?.code,
                            error_message: error?.data?.error?.errors[0]
                        }
                    });
                }
                deferred.reject();
            }
        );
        return deferred.promise;
    };

    function onInitSuccess() {
        vm.isDataLoaded = true;
    }

    function onInitFailure() {
        vm.isDataLoaded = true;
        vm.serviceError = true;
    }

    vm.openExistingRetailMessageModal = function () {
        $modal.open({
            templateUrl: existingRetailMessageTemplate,
            controller: PreAccountOpeningController,
            controllerAs: "paoCtl",
            size: "md",
            windowClass: "modal-wrapper",
            backdrop: "static",
            keyboard: false
        });
    };

    vm.redirect = function () {
        $state.go("registerInfo");
    };

    $scope.createRetailParticipant = function (body) {
        const deferred = $q.defer();
        vm.loading = true;

        preAccountOpeningFactory.createRetailParticipant.query({ ...body }).$promise.then(
            function (resp) {
                vm.loading = false;
                // eslint-disable-next-line no-prototype-builtins
                if (!resp.hasOwnProperty("error")) {
                    vm.isRegistered = resp.registered;
                    ga_valid();
                } else {
                    vm.serviceError = true;
                    ga_invalid();
                }
                deferred.resolve();
            },
            function (error) {
                vm.loading = false;
                vm.serviceError = true;
                if (error?.data?.status == "FAIL") {
                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "preAccountOpening - createRetailParticipant.query failed",
                        event_properties: {
                            login_error_code: error?.data?.error?.code,
                            error_message: error?.data?.error?.errors[0]
                        }
                    });
                }
                deferred.reject();
            }
        );

        return deferred.promise;
    };

    const ga_valid = () => eventBus.dispatch(CoreEvents.OPEN_ACCOUNT_VALID, this);
    const ga_invalid = () => eventBus.dispatch(CoreEvents.OPEN_ACCOUNT_INVALID, this);

    $scope.init().then(onInitSuccess, onInitFailure);
};

preAccountOpeningController.$inject = [
    "$scope",
    "$rootScope",
    "$state",
    "$timeout",
    "$modal",
    "$translate",
    "eventBus",
    "preAccountOpeningFactory",
    "redirectService",
    "$injector",
    "$q"
];
export default preAccountOpeningController;
