import type { AxiosResponse } from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";

interface CreateAndDeliverActivationCodePayload {
    accu: string | undefined;
    deliveryOption: string;
}

interface CreateAndDeliverActivationCodeResponseData {
    activationCode: string | null;
    activationCodeId: string;
    dbName: string;
    finalStatusInd: string | null;
    indId: string;
    initialStatusInd: string | null;
    messageDeliveryCatStatusCode: string | null;
    messageDeliveryStatusCode: string;
    messageDeliveryTypeCode: string;
    messageDeliveryVendorCode: string;
    usrRegId: string;
    vendorInterfaceStatusText: string | null;
}

export type CreateAndDeliverActivationCodeSuccessResponse =
    | CreateAndDeliverActivationCodeResponseData
    | undefined;

const createAndDeliverActivationCode = (
    payload: CreateAndDeliverActivationCodePayload
): Promise<AxiosResponse<CreateAndDeliverActivationCodeSuccessResponse>> => {
    return executePost(
        ReferenceData.CREATE_AND_DELIVER_ACTIVATION_CODE,
        "createAndDeliverActivationCodeFault",
        payload
    );
};

export default createAndDeliverActivationCode;
