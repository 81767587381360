import "angular";
import "angular-resource";
import "angular-ui-router";
import StateInterceptor from "../core/StateInterceptor";

import oauthController from "./OAUTHController";
import oauthFactory from "./OAUTHFactory.js";

// eslint-disable-next-line no-undef
const oauth = angular.module("oauth", ["ngResource", "ui.router", "focusDirectives"]);
oauth.config([
    "$httpProvider",
    function ($httpProvider) {
        $httpProvider.interceptors.push("StateInterceptor");
    }
]);
oauth.controller("oauthController", oauthController);
oauth.factory("StateInterceptor", StateInterceptor);
oauth.factory("oauthFactory", oauthFactory);

export default oauth;
