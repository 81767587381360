const mobileSSOIntermediateTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection">
   <header class="contained-form-header margin-top-default">
      <h1>
         {{ "mobile.redirect.header" | translate }}
      </h1>
      <p>   {{ "mobile.redirect.details" | translate }}</p>
   </header>

   <div id="statementsDocs-spinner" >
      <div class="modal-footer">
         <a href="{{redirectURL}}" target="_blank" >{{ "mobile.redirect.button" | translate }}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
   </div>
</div>
`;
export default mobileSSOIntermediateTemplate;
