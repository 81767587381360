import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import getDeliveryOptions from "../../services/mfa/getDeliveryOptions";
import getDeliveryOptionsByIndId from "../../services/mfa/getDeliveryOptionsByIndId";

const useDeliveryOptions = (indId?: string, dbName?: string) => {
    let queryPayload = {
        queryKey: [QUERY_KEYS.GET_DELIVERY_OPTIONS],
        queryFn: async () => {
            const { data } = await getDeliveryOptions();
            return data;
        }
    };
    if (indId && dbName) {
        queryPayload = {
            queryKey: [QUERY_KEYS.GET_DELIVERY_OPTIONS, indId, dbName],
            queryFn: async () => {
                const { data } = await getDeliveryOptionsByIndId({ indId, dbName });
                return data;
            }
        };
    }
    return useQuery(queryPayload);
};

export default useDeliveryOptions;
