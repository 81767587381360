import "angular";
import "angular-resource";
import "angular-ui-router";
import IndIdSelectionController from "./IndIdSelectionController";
import IndIdSelectionFactory from "./IndIdSelectionFactory";
import IndIdSelectionRoute from "./IndIdSelectionRoute";

// eslint-disable-next-line no-undef
const IndIdSelection = angular.module("IndIdSelection", ["ngResource", "ui.router"]);

IndIdSelection.controller("IndIdSelectionController", IndIdSelectionController);
IndIdSelection.factory("IndIdSelectionFactory", IndIdSelectionFactory);
IndIdSelection.config(IndIdSelectionRoute);

export default IndIdSelection;
