const regErrorTemplate = `
<a href="#" id="screenReader" ng-click="enableScreenReaderErrors($event)" class="sr-only">Unable to continue.</a>
<div class="sr-only col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
    <div class="inner-container with-padding with-shadow">  	
      <p translate="error.reg.hardstop" translate-default="You are not able to log into this website. Please use the link below to access your account."></p>
      <p> <a ng-href="{{loginURL.URL}}"> {{loginURL.URL}} </a> </p>
  	</div>
</div>
`;
export default regErrorTemplate;
