import accountSelectionEvents from "./events/AccountSelectionEvents";

const accountSelectionController = function (
    $scope,
    $location,
    $window,
    legSSOTokenRetrievalFactory,
    accountSelectionFactory,
    redirectService,
    eventBus,
    personalMessagingService,
    $translate,
    $cookies,
    IndIdSelectionFactory
) {
    $scope.accessPortalGroupedByAccu = null;
    $scope.nextGenDestination = "";

    $scope.currentDomain = $location.protocol() + "://" + $location.host();
    if ($window.location.port !== "") {
        $scope.currentDomain += ":" + $window.location.port;
    }

    $scope.currentDomain += "/";
    retriveAccessPortals();

    $scope.getNextGenDestination = function () {
        accountSelectionFactory.getNextGenDestination.query(
            {},
            function (data) {
                if (data !== null && data.accessPortalsNextgenUrl !== null) {
                    let nextgenDest = data.accessPortalsNextgenUrl;
                    if (nextgenDest.indexOf("liatLanding") >= 0) {
                        nextgenDest = `participant/home/#/dashboard/retirement-income`;
                    } else if (nextgenDest.indexOf("enroll") >= 0) {
                        // what should be here....
                    } else {
                        nextgenDest = "participant/accounts/#/overview";
                    }

                    $window.location.href = $scope.currentDomain + nextgenDest;
                }
            },
            function () {
                $scope.nextGenDestination = "participant/accounts/#/landing";
            }
        );
    };

    $scope.getDateForMap = function () {
        IndIdSelectionFactory.retrievePlansWithIndid.query({}, function (data) {
            $scope.plansMapWithIndId = data;
        });
    };

    $scope.redirectLegSSOPortal = function (accessPortal) {
        eventBus.dispatch(accountSelectionEvents.VIEW, this, {
            accessPortal: accessPortal
        });
        redirectService.redirectLegSSOPortal(accessPortal);
    };

    function retriveAccessPortals() {
        accountSelectionFactory.retriveAccessPortal.query(
            {},
            function (data) {
                if (data !== null) {
                    $scope.accessPortalGroupedByAccu = data.accessPortalGroups;
                }
            },
            function () {
                //DO nothing....
            }
        );
    }

    $scope.redirectToNextGenView = function (accuCode) {
        accountSelectionFactory.updatePrimaryPlanByAnAccu
            .query({
                accu: accuCode
            })
            .$promise.then(
                function (data) {
                    // console.log("Primary Plan has been updated in the session with the accu code:" + accuCode);
                    const pm_accu_cookie = $cookies.get("PM-ACCU-TOKEN");
                    if (pm_accu_cookie) {
                        personalMessagingService.updatePMCookies(data.gaId, accuCode);
                    }
                    $scope.getNextGenDestination();
                },
                function (error) {
                    console.log("error in updating the primary plan by the accu code", error);
                }
            );
    };

    $translate(["accountSelection.title", "accountSelection.intro", "button.view"]).then(
        function (translations) {
            angular.extend($scope, {
                translatedData: {
                    accountSelectionTitle: translations["accountSelection.title"],
                    accountSelectionIntro: translations["accountSelection.intro"],
                    buttonView: translations["button.view"]
                }
            });
        }
    );
};

accountSelectionController.$inject = [
    "$scope",
    "$location",
    "$window",
    "legSSOTokenRetrievalFactory",
    "accountSelectionFactory",
    "redirectService",
    "eventBus",
    "personalMessagingService",
    "$translate",
    "$cookies",
    "IndIdSelectionFactory"
];
export default accountSelectionController;
