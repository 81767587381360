import React from "react";

import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const OtsAccountModal = ({ show, handleClose, translations }) => {
    return (
        <Modal className="ots-modal-container" show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="modal-title">
                    {translations.accountOpen.existingAccount.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body cassName="modal-body">
                <p>{translations.accountOpen.existingAccount.body}</p>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <div className="footer-content">
                    <div className="done-btn-container">
                        <button
                            type="button"
                            className="btn btn-bare btn-bare--border"
                            onClick={handleClose}
                        >
                            {translations.accountOpen.existingAccount.cta}
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

OtsAccountModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired
};

export default OtsAccountModal;
