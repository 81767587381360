import { fieldValidations } from "../utils/otsConstants";

export const checkValidation = (name, label) => {
    if (name === "state") {
        return {
            [fieldValidations[name].required ? `required` : ""]: fieldValidations[name].required
                ? `${label} is required`
                : ""
        };
    } else if (name === "dateOfBirth") {
        return {
            [fieldValidations[name].required ? `required` : ""]: fieldValidations[name].required
                ? `${label} is required`
                : "",
            [fieldValidations[name].pattern ? `pattern` : ""]: fieldValidations[name].pattern
                ? getPattern(name, label)
                : "",
            validate: (value) => {
                const dob = new Date(value);
                const age = (Date.now() - dob.getTime()) / (1000 * 60 * 60 * 24 * 365);
                return age >= 18 || "You must be over the age of 18 to open this type of account.";
            }
        };
    } else {
        return {
            [fieldValidations[name].required ? `required` : ""]: fieldValidations[name].required
                ? `${label} is required`
                : "",
            maxLength: maxLengthMessaging(name, label),
            [fieldValidations[name].pattern ? `pattern` : ""]: fieldValidations[name].pattern
                ? getPattern(name, label)
                : ""
        };
    }
};

export const getPattern = (name, label) => {
    if (fieldValidations[name].inputType === "text") {
        return {
            value: /^[a-zA-Z0-9\s.'-]+$/,
            message: `${label} may only contain numbers, letters, spaces, apostrophes, hyphens, or periods.`
        };
    } else if (fieldValidations[name].inputType === "zip") {
        return {
            value: /^[0-9]{5}$/,
            message: `${label} must be 5 digits.`
        };
    } else if (fieldValidations[name].inputType === "ssn") {
        return {
            value: /^\d{3}-?\d{2}-?\d{4}$/,
            message: `${label} must be numeric and must be ${fieldValidations[name].maxLengthMsg} digits.`
        };
    } else if (fieldValidations[name].inputType === "dateOfBirth") {
        return {
            value: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
            message: `Date of Birth must be a valid date in MM/DD/YYYY format`
        };
    }
};

export const maxLengthMessaging = (name, label) => {
    // message for test
    if (fieldValidations[name].inputType === "text") {
        return {
            value: fieldValidations[name].maxLength,
            message: `${label} must be between 1-${fieldValidations[name].maxLength} characters.`
        };
    } else if (
        fieldValidations[name].inputType === "ssn" ||
        fieldValidations[name].inputType === "zip"
    ) {
        return {
            value: fieldValidations[name].maxLength,
            message: `${label} must be numeric and must be ${fieldValidations[name].maxLengthMsg} digits.`
        };
    }
};
