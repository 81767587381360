import jquery from "jquery";

window.jQuery = jquery;
window.$ = jquery;

window.jQuery.ajaxPrefilter(function (s) {
    if (s.crossDomain) {
        s.contents.script = false;
    }
});
