const registrationSSNMessagesTemplate = `
<ng-messages 
    for="getFieldError('ssn');"
    ng-show="displayFieldMessages('ssn', focusMgr['ssn']);"
    multiple
    data-ng-class="getValidationErrorClass('ssn');"
>
    <ng-message when="required">
        {{"ssnRequired" | translate }}
    </ng-message>
    <ng-message when="ssnDigits">
        {{"app.beneficiary.bene.validationMessages.socialSecurityNumberInvalid" | translate}}
    </ng-message>
</ng-messages>
`;
export default registrationSSNMessagesTemplate;
