import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import _ from "lodash";

import EConsentModalController from "../eConsent/EConsentModalController";

import HomeAddressController from "./HomeAddressController";
import ProfileContactInfoController from "./ProfileContactInfoController";
import ProfileCredentialsController from "./ProfileCredentialsController";

const profileContactInfoModalController = function (
    $modal,
    $modalInstance,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    ContactDetailsFactory,
    InternationalPhoneNumUtil,
    LanguageCodeFactory,
    PersonalContactInfoUpdateUtil,
    PersonalContactInfoCustomValidator,
    beneficiaryService,
    profileFactory,
    profileService
) {
    // ViewModel replaces $scope
    const vm = this;
    let modalInstance = $modalInstance;
    vm.plans = [];
    vm.hasBeneficiaries = false;
    $scope.showSpinner = false;

    $rootScope.showNonAuthHeader = null; //set to null so authenticated-header displays
    $scope.statusCode = "";
    $scope.pinAuthCode = "";
    $scope.personalEmailAddress = "";
    $scope.mobilePhoneNumber = "";
    $scope.workEmailAddress = "";
    $scope.workPhoneNumber = "";
    $scope.firstName = "";
    $scope.lastName = "";
    $scope.userName = "";
    $scope.gaId = "";
    $scope.indId = "";
    $scope.email = {};

    $scope.emailError = "";
    $scope.phoneNumerError = "";

    // templateURL names for UN & PW
    $scope.changeUsername = "change-username";
    $scope.changePassword = "change-password";
    $scope.changeHomeAddress = "change-homeAddress";
    $scope.communicationPreferences = "communication-preferences";
    $scope.personalContact = "change-personalContact";

    $scope.stline1 = "";
    $scope.stline2 = "";
    $scope.city = "";
    $scope.states = {};
    $scope.zipcode = "";
    $scope.countries = {};

    $scope.beneficiaries = {};
    $scope.eConsentDetails = {};

    $scope.isAddrUpdateAllowed = "";

    $scope.isUserNameUpdateAllowed = true;

    // BEGIN E-DELIVERY TEMPORARY ROUTING
    $scope.cancelLink =
        $stateParams.individualId && $stateParams.groupId
            ? "/participant/accounts/#/account/" +
              $stateParams.individualId +
              "/" +
              $stateParams.groupId +
              "/account-details"
            : null;
    const nextRoute =
        $stateParams.individualId && $stateParams.groupId ? "communicationModal" : "profile";
    // END E-DELIVERY TEMPORARY ROUTING

    $scope.isStateProfile = $state.current.url === "/profile";

    retriveContactInfor();

    $scope.secondaryPlans = true;
    $scope.togglePlans = function () {
        $scope.secondaryPlans = $scope.secondaryPlans === false;
    };

    $scope.getFormGroupClass = function (fieldName) {
        const field = $scope.personalContactForm[fieldName];
        let status = "";

        if (field.$invalid && field.$dirty) {
            status = "has-error";
        }

        return status;
    };

    $scope.fieldOnBlur = function (fieldName) {
        const field = $scope.accountSetupForm[fieldName];
        let status = "";

        if (field.$touched && field.$invalid) {
            status = "has-error";
        }
        return status;
    };

    $scope.getValidField = function (fieldName) {
        const field = $scope.personalContactForm[fieldName].$valid;

        return field;
    };

    $scope.isDirty = function (fieldName) {
        const dirty = $scope.personalContactForm[fieldName].$dirty;

        return dirty;
    };

    $scope.displayFieldError = function (fieldName) {
        const field = $scope.personalContactForm[fieldName];
        let displayFieldError = false;

        if (field.$invalid && field.$dirty) {
            displayFieldError = true;
        }

        if (field.$invalid && $scope.personalContactForm.$submitted) {
            displayFieldError = true;
        }

        return displayFieldError;
    };

    $scope.getFieldError = function (fieldName) {
        return $scope.personalContactForm[fieldName].$error;
    };

    /**
     * Use change Username template for modal
     **/
    $scope.getTemplateUsername = function () {
        return $scope.changeUsername;
    };

    /**
     * Use change Password template for modal
     **/
    $scope.getTemplatePassword = function () {
        return $scope.changePassword;
    };

    /**
     * Use change home address template for modal
     **/
    $scope.getTemplateHomeAddress = function () {
        return $scope.changeHomeAddress;
    };

    /**
     * Use communication preferences template for modal
     **/
    $scope.getTemplateCommunicationPreferences = function () {
        return $scope.communicationPreferences;
    };

    /**
     * Use personal contact template for modal
     **/
    $scope.getTemplatePersonalContact = function () {
        return $scope.personalContact;
    };

    /**
     * Open modal
     **/
    $scope.openModal = function (template) {
        modalInstance = $modal.open({
            templateUrl: "profile/" + template + ".html",
            controller: ProfileCredentialsController,

            size: "md",
            windowClass: "modal-wrapper"
        });
    };

    $scope.retrievePersonalContactInfo = function () {
        $scope.showSpinner = false;
        ContactDetailsFactory.retrieveContactDetails.query().$promise.then(
            function (data) {
                $scope.contactDetails = data;
                $scope.intlNumber = data.intlNumber;
                $scope.countryDetailsIntl = _.cloneDeep(data.countryDetails);
                _.remove($scope.countryDetailsIntl, (country) => country.countryCode === "US");
                data.domesticCntryCode =
                    data.domesticCntryCode !== null ? data.domesticCntryCode : "US1";
                $scope.selectedCntryPhCodeD = "+" + data.domesticCntryCode.substring(2);
                $scope.selectedCntryPhCodeH = "+" + data.domesticCntryCode.substring(2);
                $scope.contactDetails.homeCntryCode = $scope.contactDetails.domesticCntryCode;
                if (data.intCntryCode != null && data.intCntryCode != "")
                    $scope.selectedCntryPhCodeI = "+" + data.intCntryCode.substring(2);
            },
            function (error) {
                console.error("ContactDetailsFactory.retrieveContactDetails - ERROR: ", error);
            }
        );
    };

    $scope.populateSelectedCntryPhoneCode = function (fieldName) {
        // eslint-disable-next-line no-undef
        const temp = document.getElementById(fieldName).value;
        if (fieldName === "ContactCountryNameI")
            $scope.selectedCntryPhCodeI = `+${temp.substring(2)}`;
        else if (fieldName === "ContactCountryNameD")
            $scope.selectedCntryPhCodeD = `+${temp.substring(2)}`;
        else if (fieldName === "ContactCountryNameH")
            $scope.selectedCntryPhCodeH = `+${temp.substring(2)}`;
    };

    /**
     * Open modal
     **/
    $scope.openContactModal = function (template) {
        modalInstance = $modal.open({
            templateUrl: "profile/" + template + ".html",
            controller: ProfileContactInfoController,
            controllerAs: "ctrl",
            size: "md",
            windowClass: "modal-wrapper"
        });
    };

    /**
     * Open modal
     **/
    $scope.openHomeAddressModal = function (template) {
        modalInstance = $modal.open({
            templateUrl: "profile/" + template + ".html",
            controller: HomeAddressController,
            controllerAs: "ctrl",
            size: "md",
            windowClass: "modal-wrapper"
        });
    };

    /**
     * Open modal
     **/
    $scope.openEConsentModal = function (template) {
        modalInstance = $modal.open({
            templateUrl: "eConsent/" + template + ".html",
            controller: EConsentModalController,
            controllerAs: "ctrl",
            size: "lg",
            windowClass: "modal-wrapper"
        });
    };

    /**
     *  Close modal window
     **/
    $scope.cancel = function () {
        modalInstance.dismiss("cancel");
    };

    function retriveContactInfor() {
        $scope.mobilePhoneNumber = null;
        $scope.intlFormattedNbr = null;

        profileFactory.retriveContactInfor.query(
            {},
            function (data) {
                if (data !== null && data.status == "successful") {
                    if (data.data.statusCode !== null) {
                        $scope.statusCode = data.data.statusCode;
                    }

                    if (data.data.pinAuthCode !== null) {
                        $timeout(function () {
                            $scope.pinAuthCode = data.data.pinAuthCode;
                        });
                    }

                    if (data.data.userName !== null) {
                        $scope.userName = data.data.userName;
                        profileFactory.setUsername(data.data.userName);
                    }

                    if (
                        data.data.isUserNameUpdateAllowed != null &&
                        data.data.isUserNameUpdateAllowed != "undefined"
                    ) {
                        $scope.isUserNameUpdateAllowed = data.data.isUserNameUpdateAllowed;
                    }

                    if (data.data.firstName !== null) {
                        $scope.firstName = data.data.firstName;
                    }

                    $scope.intlFormattedNbr = data.data.intlFormattedNbr;
                    if ($scope.intlFormattedNbr == null && data.data.intlNumber != null) {
                        $scope.intlFormattedNbr = InternationalPhoneNumUtil.formatToE164(
                            data.data.intlNumber,
                            data.data.intlCntryCode
                        );
                    }

                    if (data.data.intlNumber !== null) {
                        $scope.intlNumberIdI = $scope.intlNumberIdD = data.data.intlNumber;
                    }

                    if (data.data.lastName !== null) {
                        $scope.lastName = data.data.lastName;
                    }

                    if (data.data.email !== null) {
                        $scope.personalEmailAddress = data.data.email;
                    }

                    if (data.data.phoneNumber !== null && data.data.phoneNumber != "") {
                        $scope.mobilePhoneNumber = data.data.phoneNumber;
                        $scope.phoneNumberIdD = $scope.mobilePhoneNumber;
                        $scope.phoneNumberIdI = $scope.mobilePhoneNumber;
                        $scope.selectedCntryPhCodeD =
                            "+" + data.data.domesticCntryCode.substring(2);
                    }
                    if (data.data.home) {
                        $scope.homeNumberId = data.data.home;
                        $scope.selectedCntryPhCodeH = `+${data.data.homeCntryCode.substring(2)}`;
                    }
                    if (data.data.workPhoneNumber !== null) {
                        $scope.workPhoneNumber = data.data.workPhoneNumber;
                    }

                    if (data.data.workEmail !== null) {
                        $scope.workEmailAddress = data.data.workEmail;
                    }
                    if (data.data.firstLineMailing !== null) {
                        $scope.stline1 = data.data.firstLineMailing;
                        profileFactory.setFirstLineMailing(data.data.firstLineMailing);
                    }
                    if (data.data.scndLineMailing !== null) {
                        $scope.stline2 = data.data.scndLineMailing;
                        profileFactory.setScndLineMailing(data.data.scndLineMailing);
                    }
                    if (data.data.city !== null) {
                        $scope.city = data.data.city;
                        profileFactory.setCity(data.data.city);
                    }
                    if (data.data.state !== null) {
                        $scope.state = data.data.state;
                        profileFactory.setState(data.data.state);
                    }
                    if (data.data.country !== null) {
                        $scope.country = data.data.country;
                        profileFactory.setCountry(data.data.country);
                    }
                    if (data.data.zipcode !== null) {
                        $scope.zipcode = data.data.zipcode;
                        profileFactory.setZipcode(data.data.zipcode);
                    }
                    if (data.data.isAddrUpdateAllowed !== null) {
                        $scope.isAddrUpdateAllowed = data.data.isAddrUpdateAllowed;
                    }
                    if (data.data.eConsentDetails && data.data.eConsentDetails.length > 0) {
                        $scope.eConsentDetails = data.data.eConsentDetails;
                        profileFactory.setEConsentDetails(data.data.eConsentDetails);
                        $scope.allowedToOverwrite = "true";
                    }
                    if (data.data.displayChangeCommPrefsButton !== null) {
                        $scope.displayChangeCommPrefsButton =
                            data.data.displayChangeCommPrefsButton;
                    }

                    $scope.indId = data.data.indid;
                    $scope.econsentemailAddress = data.data.econsentemailAddress;
                    profileFactory.setEconsentEmail(data.data.econsentemailAddress);

                    profileFactory.setSecondaryEmail(data.data.secondaryEmailAddress);

                    profileFactory.setSourcepage("profile");
                    $scope.sourcepage = "profile";

                    vm.loadPlansWithBeneficiaries();
                }
            },

            function (error) {
                console.error("profileFactory.retriveContactInfor - ERROR: ", error);
            }
        );
    }

    /**
     * There may be multiple plans, so we need to load all plans for the participant then get beneficiaries
     * for each plan.
     *
     * The beneficiaries will be in plans[i].beneficiaries
     */
    vm.loadPlansWithBeneficiaries = function () {
        profileService
            .loadPlansWithBeneficiaries($scope.indId)
            .then(onLoadPlansWithBeneficiariesSuccess, onLoadPlansWithBeneficiariesError);

        function onLoadPlansWithBeneficiariesSuccess(plans) {
            vm.plans = plans;

            // Check if there are any beneficiaries on any of the plans. If not, we hide them on the profile page
            for (let i = 0, n = plans.length; i < n; i++) {
                if (
                    plans[i].beneficiaries &&
                    ((plans[i].beneficiaries.Primary &&
                        plans[i].beneficiaries.Primary.length > 0) ||
                        (plans[i].beneficiaries.Contingent &&
                            plans[i].beneficiaries.Contingent.length > 0))
                ) {
                    vm.hasBeneficiaries = true;
                    break;
                }
            }
        }

        function onLoadPlansWithBeneficiariesError() {
            // We don't want to prevent the participant from seeing other information about his or her profile,
            // so we just won't show the beneficiaries section of the profile page.
            vm.hasBeneficiaries = false; // Probably unnecessary, but included for good measure.
        }
    };

    $scope.getBeneficiaryName = function (beneficiary) {
        return beneficiaryService.getBeneficiaryName(beneficiary);
    };

    $scope.isPae = false;
    LanguageCodeFactory.retrieveLanguageCode.query({}, (data) => {
        $scope.isPae = data && data.paeUser === "true";
    });

    $scope.updateIsAllowed = function () {
        return !$scope.isPae;
    };

    $scope.updatePersonalContact = function (flowName) {
        if (!$scope.updateIsAllowed()) {
            modalInstance.dismiss();
            return false;
        }

        const updatePersonalContactData = PersonalContactInfoUpdateUtil.getUpdateContactInfoJson(
            flowName,
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        const validaityOfForm = PersonalContactInfoCustomValidator.validatePersonalInfo(
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        if (!validaityOfForm.validForm) {
            $scope.personalContactForm.$valid = false;
            $scope.phoneNum_error = validaityOfForm.errorCode;
        } else {
            $scope.personalContactForm.$valid = true;
        }

        if ($scope.personalContactForm.$valid) {
            // if (true) {
            $scope.showSpinner = true;
            profileFactory.updatePersonalContact.query(updatePersonalContactData).$promise.then(
                function () {
                    if (modalInstance) {
                        // `modalInstance` is not set from the direct access to /participant/#/change-personal-contact
                        modalInstance.dismiss("cancel");
                    }

                    // E-DELIVERY TEMPORARY ROUTING: nextRoute is either 'profile' or 'communicationModal'
                    $state.go(
                        nextRoute,
                        {},
                        {
                            reload: true
                        }
                    );
                    $scope.showSpinner = false;
                },

                function (response) {
                    $scope.showSpinner = false;

                    if (response === "44403") {
                        modalInstance.dismiss("cancel");
                    }

                    if (
                        response.headers("exception") !== null ||
                        response.headers("exceptionMessage") !== null
                    ) {
                        $scope.saveStatusContact = response.data.error.code;

                        $scope.saveStatusParams = response.data.error.errors[0];
                        $scope.setFocusToFirstInputElement("#emailId", $scope.saveStatusContact);
                    } else {
                        if (response.data.data.email !== null) {
                            $scope.emailError = response.data.data.email;
                        }
                        if (response.data.data.phoneNumber !== null) {
                            $scope.phoneNumberError = response.data.data.phoneNumber;
                        }
                    }
                }
            );
        } else {
            //UnCommented to fix the DEFC-17927
            const inputs = ["email", "phoneNumber"];

            inputs.forEach(function (input) {
                $scope.personalContactForm[input].$touched = true;
                $scope.personalContactForm[input].$dirty = true;
            });

            return false;
        }
        $scope.setFocusToFirstInputElement("#emailId");
    };

    $scope.setFocusToFirstInputElement = function (fieldName, formStatusResponse = "") {
        if (Object.keys($scope.personalContactForm.$error).length !== 0 || !!formStatusResponse)
            // eslint-disable-next-line no-undef
            angular.element(document.querySelector(fieldName)).focus();
    };

    $scope.handleKeydownAddNewPhoneNumber = function (e, type) {
        handleKeyboardDownEvent({
            event: e,
            keys: keyboardInteractions.button,
            output: () => {
                $scope.addNewPhoneNumber(type);
            }
        });
    };

    $scope.addNewPhoneNumber = (type) => {
        $scope.hasAddHomePhoneNumberAnchorBeenClicked = false;
        switch (type) {
            case "homeOrIntl":
                $scope.hasAddHomePhoneNumberAnchorBeenClicked = true;
                $scope.hasAddIntlPhoneNumberAnchorBeenClicked = true;
                break;
            case "mobileOrIntl":
                $scope.hasAddMobilePhoneNumberAnchorBeenClicked = true;
                $scope.hasAddIntlPhoneNumberAnchorBeenClicked = true;
                break;
            case "mobileOrHome":
                $scope.hasAddMobilePhoneNumberAnchorBeenClicked = true;
                $scope.hasAddHomePhoneNumberAnchorBeenClicked = true;
                break;
            case "intl":
                $scope.hasAddIntlPhoneNumberAnchorBeenClicked = true;
                break;
            case "home":
                $scope.hasAddHomePhoneNumberAnchorBeenClicked = true;
                break;
            case "mobile":
                $scope.hasAddMobilePhoneNumberAnchorBeenClicked = true;
                break;
            default:
                break;
        }
        // eslint-disable-next-line no-undef
        angular.element(document.querySelector(`#${type}Anchor`)).remove();
    };
};

profileContactInfoModalController.$inject = [
    "$modal",
    "$modalInstance",
    "$rootScope",
    "$scope",
    "$state",
    "$stateParams",
    "$timeout",
    "ContactDetailsFactory",
    "InternationalPhoneNumUtil",
    "LanguageCodeFactory",
    "PersonalContactInfoUpdateUtil",
    "PersonalContactInfoCustomValidator",
    "beneficiaryService",
    "profileFactory",
    "profileService"
];
export default profileContactInfoModalController;
