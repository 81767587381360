import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import modalAccessibility from "core-ui/client/src/app/ModalAccessibility";

import idProofEvents from "./events/IDProofEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const otpNoCodeController = function ($modalInstance, eventBus, $state) {
    const vm = this;
    const logger = ExternalLogger.getInstance("otpNoCodeController");

    modalAccessibility.onOpenModal(".modal-content");

    vm.options = {
        onConfirm: function () {
            logger.debug("otpNoCodeController - onConfirmClick");
            eventBus.dispatch(idProofEvents.OTP_NO_CODE_CONTINUE, this);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: idProofEvents.OTP_NO_CODE_CONTINUE
                }
            });
            $state.go("mailPin", { optOut: "otp-Expired" });
        },
        onCancel: function () {
            logger.debug("otpNoCodeController - onCancelClick");
            eventBus.dispatch(idProofEvents.OTP_NO_CODE_CANCEL, this);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: idProofEvents.OTP_NO_CODE_CANCEL
                }
            });
            $modalInstance.close();
        }
    };
};

otpNoCodeController.$inject = ["$modalInstance", "eventBus", "$state"];
export default otpNoCodeController;
