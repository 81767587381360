const changeHomeAddressTemplate = `
<!-- React Component

<span ng-init = "loadHomeAddress()" ></span>
<pwr-change-home-address-component ng-if="addressPostDataReady"
  cancel = "cancel"
  updateHomeAddress = "updateHomeAddress"
  saveStatus = "saveStatus"
  saveStatusParams = "saveStatusParams"
  stline1 = "stline1"
  stline2 = "stline2"
  city = "city"
  zipcode = "zipcode"
  stateOptions = "stateOptions"
  countryOptions = "countryOptions"
  selectedCountryValue = "selectedCountryValue"
  selectedStateValue = "selectedStateValue"

>
</pwr-change-home-address-component>
-->

<div class="modal-header">
    <div class="modal-title" role="heading" aria-level="2">{{ "profile.login.header.changeHomeAddress" | translate }}</div>
</div>
<div class="modal-body clearfix profile">
    <div class="alert alert-warning">
    <p>{{"changeHomeAddressWarning" | translate}}</p>
    </div>
    <div class="modal-well with-background">
        <form role="form" name="homeAddressForm" data-ng-init="loadHomeAddress()"  novalidate autocomplete="off">

            <div data-ng-show="saveStatus !== null" class="form-group has-error">
                <span class="help-block">
                {{  saveStatus | translate:saveStatusParams}}
                </span>
            </div>
           <!-- Address line 1 - stline1-->

            <div class="form-group" data-ng-class="fieldOnBlur('stline1')">
                <label for="stline1Id" class="control-label">{{ "profile.login.label.homeAddress.stline1" | translate }}</label>
                <input type="text"
                       class="form-control"
                       id="stline1Id"
                       name="stline1"
                       data-ng-required="true"
                       data-ng-model="stline1"
                       ng-maxlength="35"
                       data-address-pattern-validator
                       ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
                       />
                <ng-messages for="getFieldError('stline1');"
                        data-ng-if="displayFieldError('stline1');"
                        multiple
                        data-ng-class="getValidationErrorClass('stline1');">
                        <ng-message when="required">
                            {{"stline1Required"  | translate }}
                        </ng-message>
                        <ng-message when="addressPattern">
                                {{"streetAddressInvalid"  | translate }}
                        </ng-message>
                        <ng-message when="maxlength">
                                {{"stline1InvalidLength"  | translate }}
                        </ng-message>
                </ng-messages>
            </div>

            <!-- Address line 2 - stline2-->

            <div class="form-group" data-ng-class="getFormGroupClass('stline2')">
                <label for="stline2Id" class="control-label">{{ "profile.login.label.homeAddress.stline2" | translate }}</label>
                <input type="text"
                       class="form-control"
                       id="stline2Id"
                       name="stline2"
                       data-ng-model="stline2"
                       maxlength="35"
                       data-cannot-match="stline1"
                       ng-pattern="/^[a-zA-Z0-9-/#&; ]+$/"
                       ng-focus="setInputFocus($event)"
                       ng-blur="removeInputFocus($event)"
                       ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
                       />
                <ng-messages for="getFieldError('stline2');"
                        data-ng-if="displayFieldError('stline2');"
                        multiple
                        data-ng-class="getValidationErrorClass('stline2');">
                        <ng-message when="cannotMatch">
                            {{ "stline2CannotMatchstline1" | translate }}
                        </ng-message>
                        <ng-message when="pattern">
                             {{"streetAddressInvalid"  | translate }}
                        </ng-message>
                        <ng-message when="maxlength">
                             {{"stline1InvalidLength"  | translate }}
                        </ng-message>
                </ng-messages>
            </div>

            <!-- City-->

            <div class="form-group" data-ng-class="fieldOnBlur('city')">
                <label for="cityId"
                        class="control-label"
                        ng-hide="!(selectedCountryValue.code =='US'||  selectedCountryValue.code =='CA')">
                        {{ "profile.login.label.homeAddress.city" | translate }}
                </label>
                <label for="cityId"
                        class="control-label"
                        ng-hide="(selectedCountryValue.code =='US'||  selectedCountryValue.code =='CA')">
                        {{ "profile.login.label.homeAddress.combinedCityState" | translate }}
                </label>

                <input type="text"
                       class="form-control"
                       id="cityId"
                       name="city"
                       data-ng-required="true"
                       data-ng-model="city"
                       maxlength="20"
                       ng-pattern="/^[a-zA-Z' ]+$/"
                       ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
                        />
                <ng-messages for="getFieldError('city');"
                        data-ng-if="displayFieldError('city');"
                        multiple
                        data-ng-class="getValidationErrorClass('city');">
                        <ng-message when="required">
                            {{"cityRequired"  | translate }}
                        </ng-message>
                        <ng-message when="pattern">
                            {{"cityInvalidPattern"  | translate }}
                        </ng-message>
                        <ng-message when="maxlength">
                            {{"cityInvalidLength"  | translate }}
                        </ng-message>
                </ng-messages>
            </div>

            <!-- State-->

            <div class="form-group" ng-hide="!(selectedCountryValue.code =='US'||  selectedCountryValue.code =='CA')" data-ng-class="fieldOnBlur('state')">
                <label for="state" class="control-label">{{ "profile.login.label.homeAddress.state" | translate }}</label>
                <div>
                    <select name="state"
                            id="state"
                            class="form-control"
                            ng-required="(selectedCountryValue.code =='US'||  selectedCountryValue.code =='CA')"
                            ng-model="selectedStateValue"
                            ng-options="opt as opt.description for opt in stateOptions">
                    </select>
                </div>
                <ng-messages for="getFieldError('state');"
                        data-ng-if="displayFieldError('state');"
                        multiple
                        data-ng-class="getValidationErrorClass('state');">
                        <ng-message when="required">
                            {{"stateRequired"  | translate }}
                        </ng-message>
                </ng-messages>
            </div>

            <!-- Zipcode for US-->

            <div class="form-group" ng-if="selectedCountryValue.code =='US'" data-ng-class="fieldOnBlur('zipcode')">
                <label for="zipcodeId" class="control-label">{{ "profile.login.label.homeAddress.zipcode" | translate }}</label>
                <input type="text"
                       inputmode="numeric"
                       class="form-control"
                       id="zipcodeId"
                       name="zipcode"
                       data-ng-required="true"
                       ng-cloak
                       data-ng-model="zipcode"
                       data-zipcode-pattern-validator
                       maxlength="10"

                       ng-change="zipcodeUpdated()"
                       ui-options="{clearOnBlur:false, allowInvalidValue: true}"
                       ui-mask-placeholder-char="space"
                       ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
                       />
                <ng-messages for="getFieldError('zipcode');"
                        data-ng-if="displayFieldError('zipcode');"
                        multiple
                        data-ng-class="getValidationErrorClass('zipcode');">
                        <ng-message when="required">
                            {{"zipcodeRequired"  | translate }}
                        </ng-message>
                        <ng-message when="zipcodePattern">
                             {{ "zipcodeInvalidPattern" | translate }}
                        </ng-message>
                </ng-messages>
            </div>

            <!-- Zipcode for Others-->

            <div class="form-group" ng-if="!(selectedCountryValue.code =='US')" data-ng-class="getFormGroupClass('zipcode');">
                <label for="zipcodeId" class="control-label">{{ "profile.login.label.homeAddress.zipcode" | translate }}</label>
                <input type="text"
                       inputmode="numeric"
                       class="form-control"
                       id="zipcodeId"
                       name="zipcode"
                       data-ng-required="true"
                       ng-change="zipcodeUpdated()"
                       ng-cloak
                       reg-ex-input
                       reg-ex="^[-a-zA-Z0-9 ]+$"
                       data-ng-model="zipcode"
                       data-zipcode-alpha-numeric-validator
                       maxlength="10"
                       ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
                       />
                <ng-messages for="getFieldError('zipcode');"
                        data-ng-if="displayFieldError('zipcode');"
                        multiple
                        data-ng-class="getValidationErrorClass('zipcode');">
                        <ng-message when="required">
                            {{"zipcodeRequired"  | translate }}
                        </ng-message>
                        <ng-message when="zipcodeAlphaNumeric">
                            {{"zipcodeInvalidSpecialChar"  | translate }}
                        </ng-message>
                </ng-messages>
            </div>

            <!-- Country-->

            <div class="form-group" data-ng-class="fieldOnBlur('country')">
                <label for="countryId" class="control-label">{{ "profile.login.label.homeAddress.country" | translate }}</label>
                <div class="custom-select form-control">
                    <select name="country"
                        id="countryId"
                        class="form-control"
                        ng-required="true"
                        ng-change="clearZipcode()"
                        ng-model="selectedCountryValue"
                        ng-options="opt as opt.description for opt in countryOptions">
                    </select>
                </div>
                <ng-messages for="getFieldError('country');"
                        data-ng-if="displayFieldError('country');"
                        multiple
                        data-ng-class="getValidationErrorClass('country');">
                        <ng-message when="required">
                            {{"countryRequired"  | translate }}
                        </ng-message>
                </ng-messages>
            </div>

        </form>
    </div>
</div>

<div class="modal-footer">
    <button ng-click="cancel('homeAddress')" class="btn btn-link" id="updateHomeAddressId">{{ "preference.button.cancel" | translate }}</button>
    <button ng-disabled="isPae" ng-click="updateHomeAddress('profile')" class="btn btn-primary" >{{ "preference.button.save" | translate }}</button>
</div>
`;
export default changeHomeAddressTemplate;
