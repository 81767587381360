/** Note:
 * Even if you think this code is really simillar to "EConsentModalController.js", we can not combine both in one controller, due to "$modalInstance"
 * that needs to be initiated in a previous screen to trigger the modal
 **/

"use strict";

const IraConsentController = function ($rootScope, $scope, $stateParams, redirectService) {
    $scope.individualId = $stateParams.indId;
    $scope.groupId = $stateParams.gaId;
    $scope.goToNextLocation = function (data) {
        redirectService.redirect(data, "ALL");
    };
};

IraConsentController.$inject = [
    "$rootScope",
    "$scope",
    "$stateParams",
    "redirectService",
    "$translate"
];
export default IraConsentController;
