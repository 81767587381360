const registerCredentialTemplate = `
<!-- username using validationDirectives-->
<div class="form-group" data-ng-class="getFormGroupClass('username')" >
    <label id="usernameInputId" for="usernameInput" class="control-label">{{"accountSetup.userInfo.username"| translate}}</label>
    <span class="sr-only" id="userNameRules">
     <div ng-messages="getFieldError('username');" multiple>
      <p ng-message="required || isTouched('username')">{{"accountSetup.usernameRequired"| translate }}.</p>
      <p ng-message="usernameNoSpace || isTouched('username')"> {{"accountSetup.usernameNoSpace"| translate }}.</p>
      <p ng-message="minLength || isTouched('username')"> {{"accountSetup.usernameMinLength"| translate }}.</p>
      <p ng-message="letterPattern || isTouched('username')">{{"accountSetup.usernameLetterPattern"| translate }}.</p>
      <p ng-message="usernameNumberPattern || isTouched('username')">{{"usernameNumberPattern"| translate }}.</p>
      <p ng-message="allowedSpecialCharactersPattern || isTouched('username')">{{"accountSetup.usernameAllowedSpecialCharacters"| translate }}.</p>
    </div>
    </span>
    <input type="text"
           class="form-control"
           id="usernameInput"
           name="username"
           data-ng-model="userInfo.username"
           data-ng-required="true"
           maxlength="16"
           data-username-validator
           ng-blur="usernameChange();removeInputFocus($event);"
           ng-focus="setInputFocus($event)"
           autocomplete="off"
           aria-describedby="userNameRules"
           aria-labelledby="usernameInputId"
           />

    <ng-messages
        for="getFieldError('username');"
        ng-if="displayFieldMessages('username')"
        multiple
        ng-class="getValidationErrorClass('username');"
        >

        <ng-message when="required || isTouched('username')">
            {{"accountSetup.usernameRequired"| translate }}
        </ng-message>

        <ng-message when="usernameNoSpace || isTouched('username') ">
            {{"accountSetup.usernameNoSpace"| translate }}
        </ng-message>

        <ng-message when="minLength || isTouched('username')" >
            {{"accountSetup.usernameMinLength"| translate }}
        </ng-message>

        <ng-message when="letterPattern || isTouched('username')" >
            {{"accountSetup.usernameLetterPattern"| translate }}
        </ng-message>

        <ng-message when="usernameNumberPattern || isTouched('username')" >
            {{"accountSetup.usernameNumberPattern"| translate }}
        </ng-message>

        <ng-message when="allowedSpecialCharactersPattern || isTouched('username')" >
            {{"accountSetup.usernameAllowedSpecialCharacters"| translate }}
        </ng-message>

    </ng-messages>

    <p data-ng-if="getValidField('username');" class="success">
        {{"accountSetup.usernameSuccess"| translate}}
    </p>

    <span id="usernameError" class="help-block">{{usernameError}}</span>
</div><!--//form-group//-->

<!-- password using validationDirectives-->
<div class="form-group" data-ng-class="getFormGroupClass('password');" >
    <label id="passwordInputId" for="passwordInput" class="control-label">{{"accountSetup.userInfo.password"| translate}}</label>
    <span class="sr-only" id="passwordRules">
     <div ng-messages="getFieldError('password');" multiple>
      <p ng-message="required || isTouched('password')">{{"accountSetup.passwordRequired"| translate }}.</p>
      <p ng-message="minLength || maxLength || isTouched('password')">{{"accountSetup.passwordMinLength" | translate }}.</p>
      <p ng-message="meetCondition0 || isTouched('password')">{{"accountSetup.passwordCondition0"| translate }}.</p>
      <p ng-message="meetCondition1 || isTouched('password')">{{"accountSetup.passwordCondition1"| translate }}.</p>
      <p ng-message="meetCondition2 || isTouched('password')">{{"accountSetup.passwordCondition2"| translate }}.</p>
      <p ng-message="uppercasePattern || isTouched('password')">{{"accountSetup.passwordUppercasePattern"| translate }}.</p>
      <p ng-message="lowercasePattern || isTouched('password')">{{"accountSetup.passwordLowercasePattern"| translate }}.</p>
      <p ng-message="numberPattern || isTouched('password')">{{"accountSetup.passwordNumberPattern"| translate }}.</p>
      <p ng-message="specialCharPattern || isTouched('password')">{{"accountSetup.passwordSpecialCharPattern"| translate }}.</p>
      <p ng-message="compareToUsername || isTouched('password') || passwordFocus">{{"accountSetup.passwordNotMatchUsername"| translate }}.</p>
     </div>
    </span>
    <input 
        type="password"
        class="form-control"
        id="passwordInput"
        name="password"
        data-ng-model="userInfo.password"
        data-ng-required="true"
        maxlength="16"
        data-password-validator
        data-compare-to-username="userInfo.username"
        ng-blur="passwordChange();removeInputFocus($event);"
        ng-focus="setInputFocus($event)"
        autocomplete="off"
        aria-describedby="passwordRules"
        aria-labelledby="passwordInputId"
    />

    <ng-messages
        for="getFieldError('password');"
        ng-if="displayFieldMessages('password')"
        multiple
        data-ng-class="getValidationErrorClass('password');"
        >

        <ng-message when="required || isTouched('password')">
            {{"accountSetup.passwordRequired"| translate }}
        </ng-message>

        <ng-message when="minLength || maxLength || isTouched('password')">
            {{"accountSetup.passwordMinLength"| translate }}
        </ng-message>

        <ng-message when="meetCondition0 || isTouched('password')">
            {{"accountSetup.passwordCondition0"| translate }}
        </ng-message>

        <ng-message when="meetCondition1 || isTouched('password')">
            {{"accountSetup.passwordCondition1"| translate }}
        </ng-message>

        <ng-message when="meetCondition2 || isTouched('password')">
            {{"accountSetup.passwordCondition2"| translate }}
        </ng-message>
    </ng-messages>

    <div class="row">
        <div class="col-sm-1"></div>

        <div class="col-sm-11">
            <!-- more password errors w/ separation.. -->
            <ng-messages
                for="getFieldError('password');"
                ng-if="displayFieldMessages('password')"
                multiple
                data-ng-class="getValidationErrorClass('password');"
            >
                <ng-message when="uppercasePattern || isTouched('password')">
                    {{"accountSetup.passwordUppercasePattern"| translate }}
                </ng-message>

                <ng-message when="lowercasePattern || isTouched('password')">
                    {{"accountSetup.passwordLowercasePattern"| translate }}
                </ng-message>

                <ng-message when="numberPattern || isTouched('password')">
                    {{"accountSetup.passwordNumberPattern"| translate }}
                </ng-message>

                <ng-message when="specialCharPattern || isTouched('password')">
                    {{"accountSetup.passwordSpecialCharPattern"| translate }}
                </ng-message>
            </ng-messages>
        </div>
    </div>

<!-- even more password errors w/ separation.. -->
 <ng-messages
        for="getFieldError('password');"
        ng-if="displayFieldMessages('password')"
        multiple
        data-ng-class="getValidationErrorClass('password');"
        >
        <ng-message when="compareToUsername || isTouched('password') || passwordFocus">
            {{"passwordNotMatchUsername"| translate }}
        </ng-message>

    </ng-messages>

    <p data-ng-if="getValidField('password');" class="success">
        {{"accountSetup.passwordAcceptable"| translate}}
    </p>
    <span id="passwordError" class="help-block">{{passwordError}}</span>
</div><!--//form-group//-->

<div class="form-group" data-ng-class="getFormGroupClass('confirmPassword');" >
    <label for="confirmPasswordInput" class="control-label">{{"reEnterPassword"|translate}}</label>
    <span class="sr-only" id="confirmPasswordRules">
      <div ng-messages="getFieldError('confirmPassword');" multiple>
       <p ng-message="required || isTouched('confirmPassword')">{{"accountSetup.confirmPasswordRequired"| translate }}.</p>
       <p ng-message="compareTo || isTouched('confirmPassword')"> {{"accountSetup.confirmPasswordNotMatch"| translate }}.</p>
      </div>
   </span>

    <input type="password"
           id="confirmPasswordInput"
           name="confirmPassword"
           maxlength="16"
           data-ng-model="userInfo.confirmPassword"
           data-ng-required="true"
           data-compare-to="userInfo.password"
           class="form-control"
           ng-blur="confirmPassChange();removeInputFocus($event);"
           ng-focus="setInputFocus($event)"
           aria-describedby="confirmPasswordRules"/>

    <ng-messages
        for="getFieldError('confirmPassword');"
        ng-if="displayFieldMessages('confirmPassword');"
        multiple
        data-ng-class="getValidationErrorClass('confirmPassword');"

        >

        <ng-message when="required">
            {{"accountSetup.confirmPasswordRequired"| translate}}
        </ng-message>

        <ng-message when="compareTo">
            {{"accountSetup.confirmPasswordNotMatch"| translate}}
        </ng-message>

        <ng-message when="!(compareTo)">
            {{"accountSetup.confirmPasswordValid"| translate}}
        </ng-message>
    </ng-messages>
</div><!--//form-group//-->
`;
export default registerCredentialTemplate;
