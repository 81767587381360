import DateUtil from "core-ui/client/src/app/DateUtil";

import profileEvents from "./events/ProfileEvents";

const INPUT_LENGTH = 65;

const authorizedDevicesController = function (
    $scope,
    $state,
    eventBus,
    $modalInstance,
    profileFactory
) {
    $scope.showSpinner = true;
    $scope.devices = [];
    let originalDevices = [];

    $scope.loadAuthorizedDevices = function () {
        const resp = profileFactory.getAuthorizedDevices();
        originalDevices = resp;
        // eslint-disable-next-line no-undef
        $scope.devices = angular.copy(resp);
        $scope.showSpinner = false;
    };

    const nameHasChanged = function () {
        let hasChanged = false;
        originalDevices.some((original) => {
            const dynamicName = $scope.devices.find(
                (d) => d.deviceFingerPrintHash === original.deviceFingerPrintHash
            );
            if (dynamicName && dynamicName.deviceCustomName) {
                if (dynamicName.deviceCustomName.length > 0) {
                    dynamicName.deviceCustomName = dynamicName.deviceCustomName.substring(
                        0,
                        INPUT_LENGTH
                    );
                }
                const originalName = original.deviceCustomName;
                if (originalName !== dynamicName.deviceCustomName) {
                    hasChanged = true;
                } else {
                    hasChanged = false;
                }
                return hasChanged;
            }
        });
        return hasChanged;
    };

    $scope.updateIsAllowed = function () {
        let canUpdate = false;
        const nameDiff = nameHasChanged();

        if (originalDevices.length !== $scope.devices.length || nameDiff) {
            const emptyNames = $scope.devices.filter((d) => d.deviceCustomName === undefined);
            canUpdate = !(emptyNames.length > 0);
        } else {
            canUpdate = false;
        }

        return canUpdate;
    };

    $scope.updateAuthorizedDevices = function (shouldUpdate) {
        if (!shouldUpdate) {
            return;
        }

        eventBus.dispatch(profileEvents.SAVE_UPDATE_AUTHORIZED_DEVICES, this);

        $scope.showSpinner = true;
        $scope.updateIsAllowed = false;

        const devicesToUpdate = getDevicesToUpdate();
        const devicesToDelete = getDevicesToDelete();

        const updateDevicesObject = [
            ...devicesToUpdate.map((device) => ({
                deviceFingerPrintHash: device.deviceFingerPrintHash,
                deviceCustomName: device.deviceCustomName,
                action: "UPDATE"
            })),
            ...devicesToDelete.map((device) => ({
                deviceFingerPrintHash: device.deviceFingerPrintHash,
                deviceCustomName: "",
                action: "DELETE"
            }))
        ];

        profileFactory.updateAuthorizedDevices.query(
            updateDevicesObject,
            function () {
                $scope.showSpinner = false;
                $modalInstance.dismiss("cancel");
                $state.go(
                    "profile",
                    {},
                    {
                        reload: true
                    }
                );
            },
            function () {
                $scope.showSpinner = false;
                $modalInstance.dismiss("cancel");
            }
        );
    };

    const getDevicesToUpdate = function () {
        const updated = [];
        $scope.devices.forEach((device) => {
            const original = originalDevices.find(
                (d) => d.deviceFingerPrintHash === device.deviceFingerPrintHash
            );
            if (device.deviceCustomName !== original.deviceCustomName) {
                updated.push(device);
            }
        });
        return updated;
    };

    const getDevicesToDelete = function () {
        let toDelete = [];
        if ($scope.devices.length !== originalDevices.length) {
            toDelete = originalDevices.filter((device) => {
                let isDeleted = true;
                $scope.devices.forEach((d) => {
                    if (device.deviceFingerPrintHash === d.deviceFingerPrintHash) {
                        isDeleted = false;
                    }
                });
                return isDeleted;
            });
        }
        return toDelete;
    };

    $scope.removeDeviceUI = function (id) {
        eventBus.dispatch(profileEvents.DELETE_AUTHORIZED_DEVICE, this);
        $scope.devices = $scope.devices.filter((d) => d.deviceFingerPrintHash != id);
    };

    $scope.created_format = function (date) {
        return DateUtil.getDateFormatted("M/D/YYYY", date);
    };

    $scope.lastSignIn_format = function (date) {
        return DateUtil.getDateFormatted("M/D/YYYY, hh:mm:ss a", date);
    };

    $scope.cancel = function () {
        eventBus.dispatch(profileEvents.CANCEL_UPDATE_AUTHORIZED_DEVICES, this);
        $modalInstance.dismiss("cancel");
    };
};

authorizedDevicesController.$inject = [
    "$scope",
    "$state",
    "eventBus",
    "$modalInstance",
    "profileFactory"
];
export default authorizedDevicesController;
