const registrationUniqueIdentifierMessagesTemplate = `
<ng-messages for="getFieldError('uniqueIdentifier');"
    ng-show="displayFieldMessages('uniqueIdentifier',focusMgr['uniqueIdentifier']);"
    multiple
    data-ng-class="getValidationErrorClass('uniqueIdentifier');">
    <ng-message when="required">
    {{"uniqueIdentifierRequired"  | translate}}
    </ng-message>
</ng-messages>
`;

export default registrationUniqueIdentifierMessagesTemplate;
