const accountRecoveryTemplate = `
  <pwr-account-recovery />
`;
export default accountRecoveryTemplate;

// <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">

//     <header class="contained-form-header">
//         <h1>
//             {{ ::translatedData.loginHelp }}
//             <span class="registration-progress">(4 of 4)</span>
//         </h1>
//     </header>

//     <div class="inner-container with-padding with-shadow">
//         <div data-ng-show="resetStatus !== null" class="form-group has-error">
//             <span class="help-block">{{resetStatus | translate:resetStatusParams}}</span>
//         </div>

//         <!-- Screen Reader Error Block -->
//         <div ng-if="accountRecoveryForm.$submitted && accountRecoveryForm.$invalid">
//             ${accountRecoveryErrorMessagesTemplate}
//         </div>

//         <form role="form" id="accountRecoveryForm" name="accountRecoveryForm" data-ng-submit="resetAccount(userInfo,false)"
//               novalidate autocomplete="off">

//             <!-- username disabled-->
//             <div class="form-group">
//                 <label for="acUsernameInput">{{ ::translatedData.username }}</label>
//                 <input type="text" class="form-control form-disabled" title="{{ ::translatedData.username }}" id="acUsernameInput" name="usernameInput"
//                        data-ng-model="userInfo.username" readonly/>
//             </div>
//             <!--//form-group//-->

//             <!-- I need help with my password too link-->
//             <div class="form-group" id="passwordReset" data-ng-hide="passwordReset">
//                 <a ui-sref="accountRecovery" class="reset-password-toggle" ng-click="passwordHelpClick()">{{ ::translatedData.passwordReset }}</a>
//             </div>

//             <!-- password using validationDirectives-->
//             <div class="form-group" data-ng-class="fieldOnBlur('password');" data-ng-show="passwordReset">
//                 <label for="acPasswordInput" class="control-label">
//                     {{ ::translatedData.password }}
//                 </label>
// <span class="sr-only" id="passwordRules">Must be eight to sixteen characters. Must include three of these four. {{ ::translatedData.passwordUppercasePattern }}.
//     {{ ::translatedData.passwordLowercasePattern }}. {{ ::translatedData.passwordNumberPattern }}. {{ ::translatedData.passwordSpecialCharPattern }}. {{ ::translatedData.passwordNotMatchUsername }}. </span>
//                 <input
//                         type="password"
//                         maxlength="63"
//                         id="acPasswordInput"
//                         class="form-control password-reset"
//                         name="password"
//                         data-ng-model="userInfo.password"
//                         data-ng-required="true"
//                         data-password-validator
//                         data-compare-to-username="userInfo.username"
//                         ng-blur="removeInputFocus('password');passwordBlur();"
//                         ng-focus="setInputFocus('password')"
//                         aria-describedby="passwordRules"
//                         />

//                 ${accountRecoveryPasswordMessagesTemplate}

//                 <p data-ng-if="getValidField('password');" class="success">
//                     {{ ::translatedData.passwordAcceptable }}
//                 </p>

//                 <span id="passwordError" class="help-block">{{ passwordError }}</span>
//             </div>
//             <!--//form-group//-->

//             <!-- Re-enter password using validationDirectives-->
//             <div class="form-group" data-ng-class="fieldOnBlur('confirmPassword');" data-ng-show="passwordReset">
//                 <label for="confirmPasswordInput" class="control-label">
//                     {{ ::translatedData.reEnterPassword }}
//                 </label>
//  <span class="sr-only" id="confirmPasswordRules">{{ ::translatedData.confirmPasswordRequired }}. {{ ::translatedData.confirmPasswordNotMatch }} .
//     </span>
//                 <input
//                         type="password"
//                         id="confirmPasswordInput"
//                         name="confirmPassword"
//                         maxlength="63"
//                         data-ng-model="userInfo.confirmPassword"
//                         data-ng-required="true"
//                         data-compare-to="userInfo.password"
//                         class="form-control"
//                         ng-focus="setInputFocus('confirmPassword')"
//                         ng-blur="removeInputFocus('confirmPassword');confirmPasswordBlur();"
//                         aria-describedby="confirmPasswordRules"
//                         />

//                 ${accountRecoveryConfirmPasswordMessagesTemplate}

//                 <span id="confirmPasswordError" class="help-block">{{confirmPasswordError}}</span>
//             </div>
//             <!--//form-group//-->

//             <!-- Continue to Account Recovery Confirmation screen. It will be dispalyed only if - "I need help with my password too" link is clicked-->

// 			  <div class="form-group clearfix" data-ng-show="passwordReset">

//                 <button type="submit" class="btn btn-primary btn-lg btn-block margin-top-default" ng-disabled="showSpinner==true">

//              <span data-ng-hide="showSpinner==true" >
//                             Continue
//                          </span>

//                         <span data-ng-show="showSpinner==true" >
//                            <i class="fa fa-circle-o-notch fa-spin"></i>
//                            Processing ...
//                         </span>
//                       </button>
//             </div>

//             <!--//form-group//-->

//             <!-- Return to Login screen-->
//             <a ui-sref="home" class="margin-bottom-default" title="Return to Login" ng-click="returnClick()">
//                 {{ ::translatedData.returnToLogin }}
//             </a>

//         </form>
//     </div>
//     <!--/inner-container with-padding with-shadow/-->
// </div>
