import React from "react";

import AccountRecovery from "../AccountRecovery";
import RecoveryWrapper, { RecoveryWrapperProps } from "./RecoveryWrapper";

const AccountRecoveryContainer = ({
    redirectService,
    reactFlow
}: Omit<RecoveryWrapperProps, "wrapElement">) => {
    return (
        <RecoveryWrapper
            reactFlow={reactFlow}
            redirectService={redirectService}
            wrapElement={(redirect) => <AccountRecovery redirect={redirect} />}
        />
    );
};

export default AccountRecoveryContainer;
