const eConsentTemplate = `
<div class="profile-information" ng-if="allowedToOverwrite=='true'">
    <div class="profile-information">
        <div class="row">
            <h2 class="col-md-12 margin-top-0">
                {{ "preference.eDelivery.headline" | translate }}
            </h2>

            <div class="btn-container" data-ng-show="displayChangeCommPrefsButton ==true">
                <a ng-click="openEConsentModal(getTemplateCommunicationPreferences())" class="btn btn-primary">
                    {{ "preference.button.change" | translate }}
                </a>
            </div>
        </div>

        <div class="row" ng-repeat="eConsentDetail in eConsentDetails | orderBy: ['-primaryPlan', 'planName']">

            <div ng-if="eConsentDetail.primaryPlan">
                <div class="col-sm-12 margin-bottom-100">
                    {{eConsentDetail.planName}}
                    <span ng-if="eConsentDetails.length > 1">
                        (<a ng-click="togglePlans()" href="">more plans</a>)
                    </span>
                </div>


                <strong class="col-sm-4 text-right">
                    {{ "preference.eDelivery.label.delivery" | translate }}
                </strong>

                <div class="col-sm-8">
                    <div
                        ng-show="eConsentDetail.eDeliveryOption==='NO_CONSENT_ANY_ELECTRONIC' && eConsentDetail.pptElectronicOptionsOnly">
                        {{ "preference.eDelivery.deliveryMethod.option6" |translate }}
                    </div>
                    <div
                        ng-show="eConsentDetail.eDeliveryOption==='NO_CONSENT_ANY_ELECTRONIC' && !eConsentDetail.pptElectronicOptionsOnly">
                        {{ "preference.eDelivery.deliveryMethod.option5" |translate }}
                    </div>

                    <div ng-show="eConsentDetail.eDeliveryOption==='NO_CONSENT_ALL_MAIL'">
                        {{ "preference.eDelivery.deliveryMethod.option6" |translate }}
                    </div>

                    <div ng-show="eConsentDetail.eDeliveryOption==='YES_CONSENT_ANY_ELECTRONIC'">
                        {{ "preference.eDelivery.deliveryMethod.option5" |translate }}
                    </div>

                    <div ng-show="eConsentDetail.eDeliveryOption==='YES_CONSENT_ALL_MAIL'">
                        {{ "preference.eDelivery.deliveryMethod.option6" |translate }}
                    </div>
                </div>
            </div>

            <div ng-if="!eConsentDetail.primaryPlan" ng-hide="secondaryPlans">
                <div class="col-sm-12  margin-bottom-100">
                    {{eConsentDetail.planName}}
                </div>


                <strong class="col-sm-4 text-right">
                    {{ "preference.eDelivery.label.delivery" | translate }}
                </strong>

                <div class="col-sm-8">
                    <div
                        ng-show="eConsentDetail.eDeliveryOption==='NO_CONSENT_ANY_ELECTRONIC' && eConsentDetail.pptElectronicOptionsOnly">
                        {{ "preference.eDelivery.deliveryMethod.option6" |translate }}
                    </div>
                    <div
                        ng-show="eConsentDetail.eDeliveryOption==='NO_CONSENT_ANY_ELECTRONIC' && !eConsentDetail.pptElectronicOptionsOnly">
                        {{ "preference.eDelivery.deliveryMethod.option5" |translate }}
                    </div>

                    <div ng-show="eConsentDetail.eDeliveryOption==='NO_CONSENT_ALL_MAIL'">
                        {{ "preference.eDelivery.deliveryMethod.option6" |translate }}
                    </div>

                    <div ng-show="eConsentDetail.eDeliveryOption==='YES_CONSENT_ANY_ELECTRONIC'">
                        {{ "preference.eDelivery.deliveryMethod.option5" |translate }}
                    </div>

                    <div ng-show="eConsentDetail.eDeliveryOption==='YES_CONSENT_ALL_MAIL'">
                        {{ "preference.eDelivery.deliveryMethod.option6" |translate }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row" ng-if="econsentemailAddress">
            <strong class="col-sm-4 text-right">
                {{ "preference.eDelivery.label.email" | translate }}
            </strong>
            <div class="col-sm-4">
                {{ econsentemailAddress }}
            </div>
        </div>
        <hr />
    </div>
</div>
`;
export default eConsentTemplate;
