import React, { Fragment } from "react";
import { useTranslations } from "../../queries";

interface NpdiAccountExists {
    accountExists: {
        action: {
            continue: string;
            link: string;
            linkText: string;
            please: string;
        };
        title: string;
    };
}

const NpdiAccountExists = () => {
    const { accountExists }: NpdiAccountExists = useTranslations();

    return (
        <Fragment>
            {/*<div className="alert alert-danger" data-ng-if="errors.length > 0">
                <p data-ng-repeat="error in errors">{"error"}</p>
            </div>*/}

            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
                <header className="contained-form-header">
                    <h1>{accountExists?.title}</h1>
                </header>

                <div className="inner-container with-padding with-shadow">
                    <p>
                        {accountExists.action.please}
                        <a href={accountExists.action.link}>{accountExists.action.linkText}</a>
                        {accountExists.action.continue}
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default NpdiAccountExists;
