const PscPathRetrievalFactory = function ($resource) {
    return {
        pscRetrievalPath: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + "/participant-web-services/rest/nonauth/getPSCPath",
            {},
            {
                query: {
                    method: "GET",
                    isArray: true
                }
            }
        )
    };
};

PscPathRetrievalFactory.$inject = ["$resource"];
export default PscPathRetrievalFactory;
