import modalAccessibility from "core-ui/client/src/app/ModalAccessibility";

import idProofEvents from "./events/IDProofEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const IDProofConsentController = function (
    $scope,
    param,
    $injector,
    $modalInstance,
    idProofFactory,
    redirectService,
    eventBus
) {
    const vm = this;
    const $state = $injector.get("$state");

    $scope.isOTSUser = param === "verifyIdentity";

    modalAccessibility.onOpenModal(".modal-content");

    vm.onConfirmClick = function () {
        eventBus.dispatch(idProofEvents.CONFIRM_CONSENT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: idProofEvents.CONFIRM_CONSENT
            }
        });

        idProofFactory.optInConsent.query(
            {},
            function (data) {
                $modalInstance.close();
                redirectService.redirect(data, "ALL");
            },
            function () {
                $modalInstance.close();
            }
        );
    };

    vm.onCancelClick = function () {
        $state.go("mailPin", { optOut: "optOut-Modal" });
        $modalInstance.close();
    };
};

IDProofConsentController.$inject = [
    "$scope",
    "param",
    "$injector",
    "$modalInstance",
    "idProofFactory",
    "redirectService",
    "eventBus"
];
export default IDProofConsentController;
