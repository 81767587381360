const ReSetAccuService = function ($resource) {
    return $resource(
        __baseApiUrl__ + "/participant-web-services/rest/nonauth/resetAccu/:accu",
        {
            accu: "@accu"
        },
        {
            query: {
                method: "GET",
                isArray: false
            }
        }
    );
};

ReSetAccuService.$inject = ["$resource"];
export default ReSetAccuService;
