import formatTimingPrefix from "./formatTimingPrefix";

const ACCOUNT_SETUP = "loginAccountSetup";

const sentryAccountSetupMetrics = () => {
    const timingPrefix = formatTimingPrefix();

    const eventName = `${timingPrefix}${ACCOUNT_SETUP}`;

    const accountSetupExists = sessionStorage.getItem(eventName);

    if (accountSetupExists) {
        // Remove old and set with new value
        sessionStorage.removeItem(eventName);
    }

    const timeStampOnAccountSetup = new Date().getTime();

    sessionStorage.setItem(eventName, String(timeStampOnAccountSetup));
};

export default sentryAccountSetupMetrics;
