const EligibilityService = function ($resource) {
    return $resource(
        __baseApiUrl__ + "/participant-web-services/rest/home/eligibility",
        {},
        {
            query: {
                method: "GET"
            }
        }
    );
};

EligibilityService.$inject = ["$resource", "$window"];
export default EligibilityService;
