import type { AxiosResponse } from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executeGet } from "../../utils/appUtils";
import StringUtil from "core-ui/client/src/app/core/util/StringUtil";

interface GetLegacySSOAuthTokenParams {
    gaId: string;
    indId: string;
}

const getLegacySSOAuthToken = async (
    params: GetLegacySSOAuthTokenParams
): Promise<AxiosResponse> => {
    return executeGet(
        StringUtil.supplant(ReferenceData.GET_LEGACY_SSO_AUTH_TOKEN, params),
        "getLegacySSOAuthTokenFault"
    );
};

export default getLegacySSOAuthToken;
