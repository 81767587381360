const PrimaryPlanSelectionFactory = function ($resource) {
    const retrievePlansAvailableForPrimaryPlan =
        "/participant-web-services/rest/partialauth/oneId/retrivePlansAvailableForPrimaryPlan";
    const chooseDefaultAndRefresh =
        "/participant-web-services/rest/partialauth/oneId/refreshSessionWithContextSwitch/:indId/:dbName/:accuCode/:updatedefault";
    const hasOutsideAssetsData = "/participant-web-services/rest/outsideAssets/hasOutsideAssets";

    return {
        retrievePlansAvailableForPrimaryPlan: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrievePlansAvailableForPrimaryPlan,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        chooseDefaultAndRefresh: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + chooseDefaultAndRefresh,
            {
                indId: "@indId",
                dbName: "@dbName",
                accuCode: "@accuCode",
                updatedefault: "@updatedefault"
            },
            {
                query: {
                    method: "GET",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        hasOutsideAssets: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + hasOutsideAssetsData,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        )
    };
};

PrimaryPlanSelectionFactory.$inject = ["$resource"];
export default PrimaryPlanSelectionFactory;
