const registrationLastNameMessagesTemplate = `
<ng-messages 
    for="getFieldError('lastName');"
    ng-show="displayFieldMessages('lastName',focusMgr['lastName']);"
    multiple
    data-ng-class="getValidationErrorClass('lastName');"
>
    <ng-message when="required">
        {{"lastNameRequired"  | translate}}
    </ng-message>
</ng-messages>
`;
export default registrationLastNameMessagesTemplate;
