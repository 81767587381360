import stateMap from "../StateMap";
import { RedirectData, RedirectParams } from "../types";

const getInternalRedirectUrl = (data: RedirectData, params?: RedirectParams) => {
    if (stateMap.get(data.state) || stateMap.get(data.destinationUrl)) {
        return stateMap.get(data.state) || stateMap.get(data.destinationUrl);
    } else if (data.destinationUrl.includes("marketing-consent") && params?.otpParams === "DC") {
        // Set url to the React version of client/src/app/oneid/linkablePlansTemplate.js once converted
        return "";
    } else if (data.deepLinkedUrl) {
        // Set url to the route for the React version of client/src/app/indIdSelection/indIdSelectionTemplate.js once converted
        return "";
    } else if (data.accessPortalGroups.length > 1) {
        // Set url to the route for the React version of client/src/app/accountSelection/accountSelectionTemplate.js once converted
        return "";
    }
    return "";
};

export default getInternalRedirectUrl;
