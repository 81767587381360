import npdiAccountExistsTemplate from "./npdiAccountExistsTemplate";
import npdiAccountSetupTemplate from "./npdiAccountSetupTemplate";
import npdiErrorTemplate from "./npdiErrorTemplate";
import npdiLinkAccountsTemplate from "./npdiLinkAccountsTemplate";
import npdiRegistrationTemplate from "./npdiRegistrationTemplate";

const npdiRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/npdi");
    // $urlMatcherFactoryProvider.strictMode(false);

    $stateProvider

        // npdi Registration =================================
        .state("npdi", {
            url: "/npdi",
            template: npdiRegistrationTemplate,
            controller: "npdiRegistrationController",
            data: {
                fullHeight: true,
                title: "Registration npdi"
            },
            params: {
                npdiData: null
            }
        })
        .state("accountExists", {
            url: "/npdi/accountExists",
            template: npdiAccountExistsTemplate,
            controller: "npdiRegistrationController",
            data: {
                fullHeight: true,
                title: "Registration npdi"
            }
        })

        .state("npdiError", {
            url: "/npdi/npdiError",
            template: npdiErrorTemplate,
            controller: "npdiRegistrationController",
            data: {
                fullHeight: true,
                title: "Registration npdi"
            }
        })

        .state("npdiAccountSetup", {
            url: "/npdi/npdiAccountSetup",
            template: npdiAccountSetupTemplate,
            controller: "npdiAccountSetupController",
            data: {
                fullHeight: true,
                title: "Profile settings"
            }
        })

        .state("npdiLinkAccounts", {
            url: "/npdi/linkAccounts",
            template: npdiLinkAccountsTemplate,
            controller: "npdiLinkAccountsController as ctrl",
            data: {
                fullHeight: true,
                title: "Link Accounts"
            }
        });
};

npdiRoute.$inject = ["$stateProvider", "$urlRouterProvider", "$urlMatcherFactoryProvider"];
export default npdiRoute;
