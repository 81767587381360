"use strict";

const MobileSSOController = function ($scope, $state, $window) {
    $scope.redirectURL =
        "/participant/#/mobileSSORedirect" +
        $window.location.href.substring($window.location.href.indexOf("?"));
    $scope.mobileWay =
        "https://com.empower://participant/#/mobileSSO" +
        $window.location.href.substring($window.location.href.indexOf("?"));
};

MobileSSOController.$inject = [
    "$scope",
    "$state",
    "$window",
    "$location",
    "TransactionAccessRetrService",
    "InboundSSOAuthFactory",
    "redirectService",
    "ContactDetailsFactory"
];
export default MobileSSOController;
