import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import FormUtil from "core-ui/client/src/app/FormUtil";

const EConsentModalController = function (
    $modalInstance,
    $scope,
    $state,
    EConsentFactory,
    profileFactory
) {
    /** * Logger. */
    const logger = ExternalLogger.getInstance("EConsentModalController");

    $scope.submitted = false;
    $scope.primaryPlanT = false;
    $scope.email = {};
    $scope.eConsentDetails = {};
    $scope.isModalWindow = true;

    if (profileFactory.getEconsentEmail() !== null && profileFactory.getEconsentEmail() !== "") {
        $scope.email.primary = profileFactory.getEconsentEmail();
    }

    if (profileFactory.getSecondaryEmail() !== null && profileFactory.getSecondaryEmail() !== "") {
        $scope.email.secondary = profileFactory.getSecondaryEmail();
    }

    if (
        profileFactory.getEConsentDetails() !== null &&
        profileFactory.getEConsentDetails().length > 0
    ) {
        $scope.eConsentDetails = profileFactory.getEConsentDetails();
    }

    $scope.sourcepage = profileFactory.getSourcepage();

    $scope.eConsent = {
        emailchoice: profileFactory.getEconsentEmail() || profileFactory.getSecondaryEmail(),
        eConsentDetails: profileFactory.getEConsentDetails()
    };

    //defaulting to electronic if no choice and
    if ($scope.eConsentDetails) {
        // eslint-disable-next-line no-undef
        angular.forEach($scope.eConsentDetails, function (detail) {
            logger.debug("detail: [{0}]", [JSON.stringify(detail)]);
            if (
                detail.pinAuthCode === "U" &&
                detail.eDeliveryOption === "NO_CONSENT_ANY_ELECTRONIC"
            ) {
                logger.debug("...defaulting to electronic...");
                detail.pptConsentForElectronic = true;
            }
            logger.debug("detail_: [{0}]", [JSON.stringify(detail)]);
        });
    }

    /**
     *
     */
    $scope.filterInquiryOnlyPlans = function (details) {
        const arr = [];
        if (details) {
            // eslint-disable-next-line no-undef
            angular.forEach(details, function (detail) {
                if (detail.pinAuthCode === "U") {
                    arr.push(detail);
                }
            });
        }
        return arr;
    };

    $scope.updatePPTConsent = function () {
        logger.debug(" submitting eConsentDetails {0}", [
            JSON.stringify($scope.eConsent.eConsentDetails)
        ]);

        if ($scope.eConsentForm["email"]) {
            $scope.eConsentForm["email"].$setDirty();
        }

        if ($scope.eConsentForm.$valid) {
            $scope.showSpinner = true;

            const filteredDetails = $scope.filterInquiryOnlyPlans($scope.eConsent.eConsentDetails);

            const updateEConsentParams = {
                email: $scope.eConsent.emailchoice || "",
                eConsentDetails: filteredDetails,
                flowName: $scope.sourcepage
            };

            logger.debug("..submitting eConsentParams={0}", [JSON.stringify(updateEConsentParams)]);

            EConsentFactory.updatePPTEConsent.query(
                updateEConsentParams,
                function (data) {
                    $scope.showSpinner = false;
                    $modalInstance.dismiss("cancel");
                    if (data.state == "profile") {
                        $state.go(
                            "profile",
                            {},
                            {
                                reload: true
                            }
                        );
                    }
                },
                function (error) {
                    $scope.showSpinner = false;
                    if (error === "44403") {
                        $modalInstance.dismiss("cancel");
                    }

                    $scope.saveStatus = error.data.error.code;
                    if (error.data.error.code === "ERROR_CODE_PRIMARY_PLAN_T") {
                        $scope.primaryPlanT = true;
                        $scope.saveStatus =
                            "To make changes to your communication preferences, please contact a Participant Services Representative.";
                    }
                    $scope.saveStatusParams = error.data.error.errors[0];
                }
            );
        }
    };

    /**
     *  Close modal window
     **/
    $scope.cancel = function () {
        logger.debug("does this click creds");
        $modalInstance.dismiss("cancel");
        $scope.eConsentForm.pptConsentForElectronic.$rollbackViewValue();
    };

    /**
     *  Return the name errors, of a field in a form
     **/
    $scope.getFieldError = function (fieldName) {
        return FormUtil.getFieldError($scope.eConsentForm, fieldName);
    };

    /**
     *  Return true if field is dirty
     **/
    $scope.isDirty = function (fieldName) {
        return FormUtil.isDirty($scope.eConsentForm, fieldName);
    };

    /**
     *  Return has-error if field is dirty and has any error
     **/
    $scope.getFormGroupClass = function (fieldName) {
        return FormUtil.getFormGroupClass($scope.eConsentForm, fieldName);
    };

    /**
     *  Each time the user change the e-delivery checks, 
        this will detec if any of them is true, then will set mustHaveEmail as true
     **/
    $scope.updateEConsentDetail = function () {
        $scope.mustHaveEmail = false;
        // eslint-disable-next-line no-undef
        angular.forEach($scope.eConsentDetails, function (detail) {
            if (detail.pptConsentForElectronic === true) {
                $scope.mustHaveEmail = true;
            }
        });
    };
    //Run first time to detect preloaded values
    $scope.updateEConsentDetail();
};

EConsentModalController.$inject = [
    "$modalInstance",
    "$scope",
    "$state",
    "EConsentFactory",
    "profileFactory"
];
export default EConsentModalController;
