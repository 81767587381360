import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import { retrieveContactDetails } from "../../services/accountSetup/accountSetupService";

const useContactDetails = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_DETAILS],
        queryFn: retrieveContactDetails
    });
};

export default useContactDetails;
