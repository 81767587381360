/**
 * Service to get the investment information for the provided Group ID.
 *
 * The response will include fund fact sheets, a fund performance document, and prospectuses for the group.
 */
const InvestmentInformationService = function ($resource) {
    return $resource(
        // eslint-disable-next-line no-undef
        __baseApiUrl__ + "/participant-web-services/rest/nonauth/ioag/getSortedAssets/:groupId",
        {
            groupId: "@groupId"
        },
        {
            query: {
                method: "GET",
                isArray: false
            }
        }
    );
};

InvestmentInformationService.$inject = ["$resource"];
export default InvestmentInformationService;
