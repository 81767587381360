const registrationPlanIDMessagesTemplate = `
<ng-messages for="getFieldError('planID');"
             ng-show="displayFieldMessages('planID',focusMgr['planID']);"
             multiple
             data-ng-class="getValidationErrorClass('planID');">
    <ng-message when="required">
        {{"planIDRequired"  | translate}}
    </ng-message>
</ng-messages>`;

export default registrationPlanIDMessagesTemplate;
