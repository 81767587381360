import "angular";
import "angular-resource";
import "angular-ui-router";
import IraConsentController from "./IraConsentController.js";
import IraConsentRoute from "./IraConsentRoute.js";

// eslint-disable-next-line no-undef
const iraConsent = angular.module("iraConsent", [
    "ngMessages",
    "ngResource",
    "ui.router",
    "validationDirectives",
    "LayoutModule"
]);

iraConsent.controller("iraConsentController", IraConsentController);
iraConsent.config(IraConsentRoute);
export default iraConsent;
