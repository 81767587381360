const OtsRegistrationService = function () {
    let formData = {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        ssn: "",
        dateOfBirth: "",
        phoneNumber: "",
        context: ""
    };

    this.getFormData = function () {
        return formData;
    };

    this.setFormData = function (data) {
        formData = Object.assign({}, formData, data);
    };
};

export default OtsRegistrationService;
