const reEnrollTemplate = `
<div class="no-background re-enroll" >
    <div class="content" pw-screen-size set-screen-size="setScreenSize">
        <div class="customized-container">
            <div class="container">
                <div class="inner-container with-padding with-shadow" id="reenrollment-container">

                    <!-- DEFAULT STATE TEXT -->
                    <div data-ng-show="ctrl.isDefaultState()">
                        <h1>{{ctrl.planName}}</h1>
                        <div class="header-row">
                            <div class="header-label"><h1>{{ ::ctrl.translatedData.importantChangesLabel }}</h1></div>
                            <div class="col-md-6 " id="language-space">
                                <div ng-if="isAuthenticated" class="language-preference" translate="{{languageMessageLabel}}"
                                    translate-values="{trnsltLabel:siteContactInfo.trnsltLabel}" translate-compile>
                                </div>
                            </div>
                        </div>  

                        <p style="font-weight: bold;">{{::ctrl.translatedData.otherAccountRenrollment}}</p>                   

                        <p>{{::ctrl.translatedData.importantChangesP1}}{{ ctrl.startDate | date : 'M/d/yyyy' }}.</p>

                        <p>{{::ctrl.translatedData.importantChangesP2}}</p>

                        <p>{{::ctrl.translatedData.importantChangesP3Pre}} {{ ctrl.effectiveDate | date : 'M/d/yyyy'}}{{ctrl.getTypeCodeSuffix()}}
                            <a ng-href="{{ctrl.showLearnLink}}"  target="_blank" ng-if="ctrl.showLearn">Learn more</a>
                        </p>
                        <br/>
                    </div>

                    <!-- ACKNOWLEDGE STATE TEXT -->
                    <div data-ng-show="ctrl.isAcknowledgeState()">
                        <h1>{{ctrl.planName}}</h1>
                        <div class="header-row">
                            <div ng-if="!ctrl.isOptIn" class="header-label"><h1>{{::ctrl.translatedData.acknowledgementLabel }}</h1></div>
                            <div ng-if="ctrl.isOptIn" class="header-label"><h1>{{::ctrl.translatedData.confirmationLabel }}</h1></div>
                            <div class="printer-container" ng-if-"ctrl.showPrint()"><a href="" type="button" class="btn-link btn-print" print-section print-element-id="reenrollment-container">{{::ctrl.translatedData.printLabel }}</a></div>
                        </div>
                        <p style="font-weight: bold;">{{::ctrl.translatedData.otherAccountRenrollment}}</p>
                        <p ng-if="!ctrl.isOptIn">
                            {{::ctrl.translatedData.acknowledgementPrefix }} {{ ctrl.todaysDate | date : 'M/d/yyyy'}} {{::ctrl.translatedData.acknowledgementSuffix}} {{ ctrl.effectiveDate | date : 'M/d/yyyy'}}.
                        </p>
                        <p ng-if="ctrl.isOptIn">
                            {{::ctrl.translatedData.acknowledgementPrefix }} {{ ctrl.todaysDate | date : 'M/d/yyyy'}} {{::ctrl.translatedData.acknowledgementSuffix}} {{ ctrl.effectiveDate | date : 'M/d/yyyy'}}.
                            <br>
                            {{::ctrl.translatedData.confirmationNumber}} {{ ctrl.eventId }}
                        </p>
                    </div>

                    <!-- CONFIRMATION STATE TEXT -->
                    <div data-ng-show="ctrl.isConfirmState()">
                         <h1>{{ctrl.planName}}</h1>
                        <div class="header-row">
                            <div class="header-label"><h1>{{::ctrl.translatedData.confirmationLabel }}</h1></div>
                            <div class="printer-container" ng-if-"ctrl.showPrint()"><a href="" type="button" class="btn-link btn-print" print-section print-element-id="reenrollment-container">{{::ctrl.translatedData.printLabel }}</a></div>
                        </div>
                         <p style="font-weight: bold;">{{::ctrl.translatedData.otherAccountRenrollment}}</p>
                        <div class="row">
                            <div class="col-md-12">
                                <p>{{::ctrl.translatedData.confirmationNumber}} {{ ctrl.eventId }}<br/>
                                    {{::ctrl.translatedData.confirmationPrefix}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- COMMON TEXT -->
                    <h2 ng-if="ctrl.isOptIn">{{::ctrl.translatedData.acknowledgementLabel }}</h2>
                    <h2 data-ng-show="!ctrl.isConfirmState()">{{::ctrl.translatedData.investmentsLabel }}</h2>

                    <div class="account-list-container-fb" ng-show="ctrl.showAccountList()">

                        <div class="from-accounts-col">
                            <div class="header">{{::ctrl.translatedData.fromLabel}}</div>
                            <div class="list">
                                <ul>
                                    <li ng-repeat="account in ctrl.investmentFromList">{{account.legalName}}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="arrow-col">
                            <span class="hidden-xs em-long-arrow-right hidden-print"></span>
                            <span class="hidden-sm hidden-md hidden-lg em-next-bold hidden-print"></span>
                        </div>


                        <div class="to-accounts-col">
                            <div class="header">{{::ctrl.translatedData.toLabel}}</div>
                            <div ng-switch="!ctrl.investmentToList || ctrl.investmentToList === null" class="list">
                                <ul ng-switch-when="true">
                                    <li>
                                        {{ ctrl.managedAccountsProgramName }}
                                    </li>
                                </ul>
                                <ul ng-switch-when="false">
                                    <li data-ng-repeat="account in ctrl.investmentToList">
                                        {{account.legalName}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- ACKLNOWLEDGE FOOTER -->
                    <div class="row" data-ng-show="ctrl.isAcknowledgeState()">
                        <div class="col-md-12">
                            <p >{{::ctrl.translatedData.acknowledgeSummaryMsgPrefix}} {{ ctrl.endDate | date : 'M/d/yyyy' }} {{::ctrl.translatedData.acknowledgeSummaryMsgSuffix }}</p>
                        </div>
                    </div>
                    <div class="row button-container hidden-print">
                        <div class="col-md-8 hidden-xs hidden-sm" data-ng-class="{'col-md-4': ctrl.isDefaultState()}"></div>
                        <div class="col-xs-12 col-sm-12 col-md-4 default-btns" data-ng-show="ctrl.isDefaultState()"><button class="btn btn-default btn-block" data-ng-click="ctrl.optOut()">{{ ::ctrl.translatedData.noThanks }}</button></div>
                        <div class="col-xs-12 col-sm-12 col-md-4 default-btns" data-ng-show="ctrl.isDefaultState()"><button class="btn btn-primary btn-block" data-ng-click="ctrl.optIn()">{{ ::ctrl.translatedData.iAgree }}</button></div>
                        <div class="col-xs-12 col-sm-12 col-md-4 default-btns" data-ng-show="!ctrl.isDefaultState()"><button class="btn btn-primary btn-default btn-block" data-ng-click="ctrl.continue()">{{ ::ctrl.translatedData.continue}}</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
`;
export default reEnrollTemplate;
