import StringUtil from "core-ui/client/src/app/core/util/StringUtil";

import ReferenceData from "../constants/ReferenceData";
import { executeGet } from "../utils/appUtils.js";

//PreLoginBulletinService
export const getPreLoginBulletin = (accu, primaryDBFlag) => {
    const getPreLoginBulletinSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const url = StringUtil.supplant(ReferenceData.GET_PRELOGIN_BULLETIN, {
        accu: accu,
        primaryDBFlag: primaryDBFlag
    });

    const liveUrl = __baseApiUrl__ + url;

    const payload = {
        accu: accu,
        primaryDBFlag: primaryDBFlag
    };

    return executeGet(liveUrl, "getPreLoginBulletinFault", payload)
        .then(getPreLoginBulletinSuccess)
        .catch((err) => {
            console.log("getPreLoginBulletin Error: ", err);
            throw err;
        });
};

// PreLoginContactInfoService
export const getPreloginContactInfo = (params) => {
    const getPreloginContactInfoSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl =
        __baseApiUrl__ +
        StringUtil.supplant(ReferenceData.GET_PRELOGIN_CONTACT_INFO, {
            accu: params.accu,
            accuSpecificPrimDb: params.accuSpecificPrimDb,
            portalType: params.portalType
        });

    return executeGet(liveUrl, "getPreloginContactInfoFault")
        .then(getPreloginContactInfoSuccess)
        .catch((err) => {
            console.log("getPreloginContactInfo Error: ", err);
            throw err;
        });
};
