import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslations } from "../../../queries";

interface NoCodeModalComponentTranslations {
    oneTimePassword: {
        otpNoCodeCancelButton: string;
        otpNoCodeContinueButton: string;
        otpNoCodeHeader: string;
        otpNoCodeMessage: string;
    };
}
interface NoCodeModalComponentProps {
    handleModalClose: () => void;
    handleModalConfirm: () => void;
    showModal: boolean;
}
const NoCodeModalComponent = ({
    showModal,
    handleModalClose,
    handleModalConfirm
}: NoCodeModalComponentProps) => {
    const { oneTimePassword } = useTranslations<NoCodeModalComponentTranslations>();
    return (
        <Modal show={showModal} data-testid="no-code-modal-component">
            <Modal.Header>
                <Modal.Title>{oneTimePassword?.otpNoCodeHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-well with-background">
                    <p>{oneTimePassword?.otpNoCodeMessage}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-link margin-top-default"
                    onClick={handleModalClose}
                >
                    {oneTimePassword?.otpNoCodeCancelButton}
                </button>
                <button
                    type="button"
                    className="btn btn-primary margin-top-default"
                    onClick={handleModalConfirm}
                >
                    {oneTimePassword?.otpNoCodeContinueButton}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default NoCodeModalComponent;
