const StateInterceptor = function ($injector, $rootScope, $q, $window) {
    let flowName;

    return {
        response: function (response) {
            if (response?.config?.nointercept) {
                return $q.when(response);
            } else {
                const data = response.data;
                if (data.state === "FULLY_AUTHENTICATED") {
                    const csrfTokenFactory = $injector.get("csrfTokenFactory");
                    response.config.nointercept = true;

                    flowName = data.flowName;
                    if (!flowName) {
                        flowName = $rootScope.flowName;
                    }
                    const tokenData = {
                        token: $window.sessionStorage.csrfToken
                    };
                    if (null !== tokenData && tokenData.token !== undefined) {
                        csrfTokenFactory.saveCSRF(tokenData).then(nextState);
                    } else {
                        nextState();
                    }
                }
            }

            return response;
        }
    }; //End of return

    function nextState() {
        const StateDestinationFactory = $injector.get("StateDestinationFactory");
        const redirectService = $injector.get("redirectService");
        StateDestinationFactory.determineNextDest(flowName).then(function (state) {
            if (state.postRegSummaryUrl !== null || state.postRegSummaryUrl !== "") {
                $rootScope.postRegSummaryUrl = state.postRegSummaryUrl;
                $rootScope.regData = state;
            }

            redirectService.redirect(state, "ALL");
        });
    }
};

StateInterceptor.$inject = ["$injector", "$rootScope", "$q", "$window"];
export default StateInterceptor;
