import AlternateSiteDialogDirectiveViewTemplate from "./AlternateSiteDialogDirectiveViewTemplate";

const AlternateSiteDialogDirective = function () {
    const directive = {
        restrict: "E",
        template: AlternateSiteDialogDirectiveViewTemplate,
        scope: {
            accuError: "=accuError",
            countDown: "="
        },
        link: function (scope) {
            scope.translate = {};

            scope.redirectToCorrectAccuSite = (obj) => {
                const nextUrl = new URL(obj.redirectAccuUrl);
                const currentUrl = new URL(window.location.href);
                window.location.replace(obj.redirectAccuUrl);

                // Edge case when we only changing the accu url search param.
                if (nextUrl.host === currentUrl.host) {
                    const [, nextUrlSearchParamsString] = nextUrl.hash.split("?");
                    const [, currentUrlSearchParamsString] = currentUrl.hash.split("?");
                    const nextUrlSearchParams = new URLSearchParams(nextUrlSearchParamsString);
                    const currentUrlSearchParams = new URLSearchParams(
                        currentUrlSearchParamsString
                    );
                    if (
                        nextUrlSearchParams.has("accu") &&
                        currentUrlSearchParams.has("accu") &&
                        nextUrlSearchParams.get("accu") !== currentUrlSearchParams.get("accu")
                    ) {
                        window.location.reload();
                    }
                }
            };

            scope.init = () => {
                const countDown = setInterval(() => {
                    scope.translate.seconds = scope.countDown--;

                    if (scope.countDown === 0) {
                        clearInterval(countDown);
                    }

                    scope.$apply();
                }, 1000);
            };
        }
    };

    return directive;
};

export default AlternateSiteDialogDirective;
