const EConsentFactory = function ($resource) {
    const updatePPTEConsentInd =
        "/participant-web-services/rest/partialauth/ecommService/updatePPTEConsent";

    return {
        updatePPTEConsent: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + updatePPTEConsentInd,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

EConsentFactory.$inject = ["$resource"];
export default EConsentFactory;
