const registrationPinMessagesTemplate = `
<ng-messages 
    id="pinDescription"
    for="getFieldError('pin');"
    ng-show="displayFieldMessages('pin', focusMgr['pin']);"
    multiple
    data-ng-class="getValidationErrorClass('pin');"
>
    <ng-message when="required">
        {{"pinRequired" | translate }}
    </ng-message>
    <ng-message when="pinDigits">
        {{"pinDigits" | translate}}
    </ng-message>
    <ng-message when="pinLength">
        {{"pinLength" | translate}}
    </ng-message>
</ng-messages>
`;
export default registrationPinMessagesTemplate;
