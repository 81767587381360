import axios from "axios";

export function IraConsent(formData, successCallback, errorCallback) {
    axios({
        method: "get",
        url:
            // eslint-disable-next-line no-undef
            __baseApiUrl__ +
            "/participant-web-services/rest/iraEnrollmentInfo/accountInfo/" +
            formData.individualId +
            "/" +
            formData.groupId,
        headers: {
            "Content-Type": "application/json"
        },
        params: {},
        withCredentials: true
    })
        .then((result) =>
            result.data != null ? successCallback(result.data) : errorCallback(result.data, null)
        )
        .catch((error) => errorCallback(null, error));
}

export function IraConsentAcceptTermsAndConditions(formData, successCallback, errorCallback) {
    axios({
        method: "post",
        url:
            // eslint-disable-next-line no-undef
            __baseApiUrl__ +
            "/participant-web-services/rest/iraEnrollmentInfo/accountInfo/updateIraTermsAndConditions/" +
            formData.individualId +
            "/" +
            formData.groupId,
        data: null,
        headers: {
            "Content-Type": "application/json"
        },
        params: {},
        withCredentials: true
    })
        .then((result) =>
            result.data ? successCallback(result.data) : errorCallback(result.data, null)
        )
        .catch((error) => errorCallback(null, error));
}
