import "angular";
import InvestmentInformationFormController from "./InvestmentInformationFormController";
import InvestmentInformationResultsController from "./InvestmentInformationResultsController";
import InvestmentInformationRoutes from "./InvestmentInformationRoutes";
import InvestmentInformationService from "./InvestmentInformationService";

// Register the module
// eslint-disable-next-line no-undef
const InvestmentInformationModule = angular.module("InvestmentInformationModule", []);
// Register controllers
InvestmentInformationModule.controller(
    "investmentInformationFormController",
    InvestmentInformationFormController
);
InvestmentInformationModule.controller(
    "investmentInformationResultsController",
    InvestmentInformationResultsController
);
// Register services
InvestmentInformationModule.service("investmentInformationService", InvestmentInformationService);
// Register routes
InvestmentInformationModule.config(InvestmentInformationRoutes);

export default InvestmentInformationModule;
