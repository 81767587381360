const PreLoginContactInfoService = function ($resource) {
    return $resource(
        __baseApiUrl__ +
            "/participant-web-services/rest/nonauth/getPreLoginContactInfoByAccu/:accu/:accuSpecificPrimDb/:portalType",
        {
            accu: "@accu",
            accuSpecificPrimDb: "@accuSpecificPrimDb",
            portalType: "@portalType"
        },
        {
            query: {
                method: "GET",
                isArray: false
            }
        }
    );
};

PreLoginContactInfoService.$inject = ["$resource"];
export default PreLoginContactInfoService;
