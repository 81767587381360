/**
 * @module ReEnrollFactory
 * @date 07/05/2017
 * @copyright 2017 Empower Retirement
 * @description
 *
 *      Encapsulates the data model, state, and business logic for Re-enrolling.
 *
 */

import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import _isNull from "lodash/isNull";
import _isUndefined from "lodash/isUndefined";

// --------------------------------------------------------------------------------
// Type Definitions
// --------------------------------------------------------------------------------

//-----------------------------------------------------------------------
// CONSTRUCTOR
//-----------------------------------------------------------------------

/**
 * The constructor's parameters are "injected" via AJS's IoC.
 *
 * @constructor
 * @param {$http} $http - Reference to the ng http API
 * @param {$q} $q - Reference to the AJS Promise API.
 */
const ReEnrollFactory = function ($http, $q) {
    const logger = ExternalLogger.getInstance("ReEnrollFactory");
    //-----------------------------------------------------------------------
    // CLASS MEMBERS
    //-----------------------------------------------------------------------

    /**
     * Utility function TODO: seems we haven't located this centrally yet in core?
     * @param value
     * @returns {*}
     */
    const isUndefinedOrNull = function (value) {
        return _isUndefined(value) || _isNull(value);
    };

    /**
     * Makes a HTTP GET request to the API endpoint for plan reset participant funds. Internally handles the success and fault
     * of that HTTP request.
     *
     * @param {string} individualId - The ppt id in question
     * @param {string} groupId - The ppt group id in question
     * @returns {Promise} A promise to the future resolution of the HTTP request.
     */
    const getPlanResetPartFunds = function (individualId, groupId) {
        const defer = $q.defer();

        if (isUndefinedOrNull(individualId) || isUndefinedOrNull(groupId)) {
            return defer.reject(
                new Error(
                    "Get Plan Reset Part Funds params requires valid `individualId` and `groupId` fields."
                )
            );
        }

        logger.info("getPlanResetPartFunds( individualId = {0}, groupId = {1} )", [
            individualId,
            groupId
        ]);

        const apiSuccess = function (result) {
            if (isUndefinedOrNull(result)) {
                logger.error("apiSuccess( Bad response data. )");
                return defer.reject(
                    new Error(
                        "Get Plan Reset Part Funds Data Failed. Bad response -- data is null or undefined."
                    )
                );
            } else {
                logger.info("apiSuccess()");
                defer.resolve(result.data);
            }
        }.bind(this);

        const apiFault = function (fault) {
            const msg = StringUtil.supplant("HTTP {0} {1}. {2}", [
                fault.status,
                fault.statusText,
                fault.data
            ]);
            logger.warn("apiFault( fault = {0} )", [msg]);
            return defer.reject(new Error(msg));
        }.bind(this);

        const url =
            // eslint-disable-next-line no-undef
            __baseApiUrl__ +
            "/participant-web-services/rest/re-enroll/getPlanResetPartFunds/{individualId}/{groupId}";

        const params = {
            individualId: individualId,
            groupId: groupId
        };
        const request = {
            url: StringUtil.supplant(url, params),
            method: "GET"
        };

        $http(request).then(apiSuccess, apiFault);

        return defer.promise;
    };

    /**
     * Makes a HTTP GET request to the API endpoint for opt out reset. Internally handles the success and fault
     * of that HTTP request.
     *
     * @param {string} individualId - The ppt id in question
     * @param {string} groupId - The ppt group id in question
     * @returns {Promise} A promise to the future resolution of the HTTP request.
     */
    const optOutReset = function (individualId, groupId) {
        const defer = $q.defer();

        if (isUndefinedOrNull(individualId) || isUndefinedOrNull(groupId)) {
            return defer.reject(
                new Error(
                    "Opt Out Reset params requires valid `individualId` and `groupId` fields."
                )
            );
        }

        logger.info("optOutReset( individualId = {0}, groupId = {1} )", [individualId, groupId]);

        const apiSuccess = function (result) {
            if (isUndefinedOrNull(result)) {
                logger.error("apiSuccess( Bad response data. )");
                return defer.reject(
                    new Error(
                        "Opt Out Reset Data Failed. Bad response -- data is null or undefined."
                    )
                );
            } else {
                logger.info("apiSuccess()");
                defer.resolve(result.data);
            }
        }.bind(this);

        const apiFault = function (fault) {
            const msg = StringUtil.supplant("HTTP {0} {1}. {2}", [
                fault.status,
                fault.statusText,
                fault.data
            ]);
            logger.warn("apiFault( fault = {0} )", [msg]);
            return defer.reject(new Error(msg));
        }.bind(this);

        const url =
            // eslint-disable-next-line no-undef
            __baseApiUrl__ +
            "/participant-web-services/rest/re-enroll/optoutreset/{individualId}/{groupId}";

        const params = {
            individualId: individualId,
            groupId: groupId
        };
        const request = {
            url: StringUtil.supplant(url, params),
            method: "POST",
            data: {},
            isArray: false,
            crossDomain: true
        };

        $http(request).then(apiSuccess, apiFault);

        return defer.promise;
    };

    /**
     * Makes a HTTP GET request to the API endpoint for opt out reset. Internally handles the success and fault
     * of that HTTP request.
     *
     * @param {string} individualId - The ppt id in question
     * @param {string} groupId - The ppt group id in question
     * @returns {Promise} A promise to the future resolution of the HTTP request.
     */
    const optInReset = function (individualId, groupId) {
        const defer = $q.defer();

        if (isUndefinedOrNull(individualId) || isUndefinedOrNull(groupId)) {
            return defer.reject(
                new Error("Opt In Reset params requires valid `individualId` and `groupId` fields.")
            );
        }

        logger.info("optInReset( individualId = {0}, groupId = {1} )", [individualId, groupId]);

        //Response structure:
        //{
        //    "eventId": "12312313",
        //    "postEnrollUrl" : "/participant/accounts/#/overview"
        //}

        const apiSuccess = function (result) {
            if (isUndefinedOrNull(result)) {
                logger.error("apiSuccess( Bad response data. )");
                return defer.reject(
                    new Error(
                        "Opt In Reset Data Failed. Bad response -- data is null or undefined."
                    )
                );
            } else {
                logger.info("apiSuccess()");
                defer.resolve(result.data);
            }
        }.bind(this);

        const apiFault = function (fault) {
            const msg = StringUtil.supplant("HTTP {0} {1}. {2}", [
                fault.status,
                fault.statusText,
                fault.data
            ]);
            logger.warn("apiFault( fault = {0} )", [msg]);
            return defer.reject(new Error(msg));
        }.bind(this);

        const url =
            // eslint-disable-next-line no-undef
            __baseApiUrl__ +
            "/participant-web-services/rest/re-enroll/optinreset/{individualId}/{groupId}";

        const params = {
            individualId: individualId,
            groupId: groupId
        };
        const request = {
            url: StringUtil.supplant(url, params),
            method: "POST",
            data: {},
            isArray: false,
            crossDomain: true
        };

        $http(request).then(apiSuccess, apiFault);

        return defer.promise;
    };

    const getStatements = function (individualId, groupId) {
        const defer = $q.defer();
        logger.info("getStatements (individualId = {0}) (groupId = {1})", [individualId, groupId]);

        // eslint-disable-next-line no-undef
        const url =
            __baseApiUrl__ +
            "/participant-web-services/rest/accounts/account/statements/{individualId}/{groupId}";

        const params = {
            individualId: individualId,
            groupId: groupId
        };

        const request = {
            url: StringUtil.supplant(url, params),
            method: "GET"
        };

        const apiSuccess = function (result) {
            if (isUndefinedOrNull(result)) {
                logger.error("apiSuccess( Bad response data. )");
                return defer.reject(
                    new Error(
                        "Opt In Reset Data Failed. Bad response -- data is null or undefined."
                    )
                );
            } else {
                logger.info("apiSuccess()");
                defer.resolve(result.data);
            }
        }.bind(this);

        const apiFault = function (fault) {
            const msg = StringUtil.supplant("HTTP {0} {1}. {2}", [
                fault.status,
                fault.statusText,
                fault.data
            ]);
            logger.warn("apiFault( fault = {0} )", [msg]);
            return defer.reject(new Error(msg));
        }.bind(this);

        $http(request).then(apiSuccess, apiFault);

        return defer.promise;
    };

    return {
        getPlanResetPartFunds: getPlanResetPartFunds,
        optOutReset: optOutReset,
        optInReset: optInReset,
        getStatements: getStatements
    };
};

ReEnrollFactory.$inject = ["$http", "$q"];
export default ReEnrollFactory;
