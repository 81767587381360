import ReferenceData from "../../../../constants/ReferenceData";
import {
    updateAccuCookie,
    deleteCookie,
    resetAccuService,
    getPreLoginAccuRetrieval
} from "../../../../services/accuCodeService";
import { getDefaultCustomizationRetr } from "../../../../services/authentication/AuthenticationService";
import getEmpowerRestrictedPlanAccuCode from "../getEmpowerRestrictedPlanAccuCode";
import { AuthActionTypes } from "../../useAuthenticateReducer";

import { AuthData } from "./authenticate";
import { MFA_ACTIVATION_CODE_DELIVERY_OPTIONS } from "../../../../routes";
import { Dispatch } from "react";
import { AuthAction } from "../../useAuthenticateReducer/useAuthenticateReducer";

interface AuthenticateSuccessArgs {
    accuCode: string;
    authData: AuthData;
    dispatch: Dispatch<AuthAction>;
    setAccuCode: (accu: string) => void;
    setLoggingInState: (loading: boolean) => void;
}

const onAuthenticateSuccess = async ({
    accuCode,
    authData,
    dispatch,
    setLoggingInState,
    setAccuCode
}: AuthenticateSuccessArgs) => {
    dispatch({
        type: AuthActionTypes.LOGIN_SUCCESS,
        payload: {
            userId: authData.userRegId,
            flowName: "mfa",
            errorMessage: ""
        }
    });

    // After successful authentication, toggle logginIn loading flag back to false
    setLoggingInState(false);

    if (accuCode.toLowerCase() === "empower") {
        const newAccuCode = getEmpowerRestrictedPlanAccuCode(authData);

        if (
            newAccuCode &&
            accuCode !== newAccuCode &&
            ReferenceData.supportedACCU.indexOf(newAccuCode) >= 0
        ) {
            window.accu = newAccuCode;
            setAccuCode(newAccuCode);

            let preLoginData;
            let defaultData;
            let resetAccuData;

            try {
                resetAccuData = await resetAccuService(newAccuCode);

                //set cookie
                updateAccuCookie(newAccuCode);
                deleteCookie("PM-ACCU-TOKEN", "/", null);
            } catch (error) {
                console.error("ERROR - with resetAccuService: ", error);
            }

            if (resetAccuData) {
                try {
                    defaultData = await getDefaultCustomizationRetr();
                } catch (error) {
                    console.error("ERROR - with getDefaultCustomizationRetr: ", error);
                }
            }

            if (defaultData) {
                try {
                    preLoginData = await getPreLoginAccuRetrieval(newAccuCode);

                    window.accuCustomization = { ...defaultData, ...preLoginData };
                } catch (error) {
                    console.error("ERROR - with getPreLoginAccuRetrieval: ", error);
                }
            }

            if (preLoginData) {
                try {
                    updateAccuCookie(newAccuCode);
                } catch (error) {
                    console.error("ERROR - with updateAccuCookie: ", error);
                }
            }
        }
    }

    return "/participant/#/" + MFA_ACTIVATION_CODE_DELIVERY_OPTIONS;
};

export default onAuthenticateSuccess;
