const legSSOTokenRetrievalFactory = function ($resource) {
    const legSSOTokenRetrievalApi =
        __baseApiUrl__ +
        "/participant-web-services/rest/sso/retrieveLegSSOAuthToken/indId/:indId/group/:gaId";

    const legSSOTokenRetrievalParams = {
        indId: "@indId",
        gaId: "@gaId"
    };

    return $resource(legSSOTokenRetrievalApi, legSSOTokenRetrievalParams, {
        query: {
            method: "GET",
            isArray: true
        }
    });
};

legSSOTokenRetrievalFactory.$inject = ["$resource", "$window"];
export default legSSOTokenRetrievalFactory;
