import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ACCOUNT_OPEN_PERSONAL_DETAILS } from "../../routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../queries/constants";
import { TranslationsServiceProvider } from "../../providers/TranslationsServiceProvider";
import AccountOpenPersonalDetailsForm from "./AccountOpenPersonalDetailsForm";
//TODO:This wrapper will remove once angular components completely migrated to react
interface Props {
    $state: { go: (path: string) => void };
    $stateParams: {
        formData: {
            addressLine1: string;
            addressLine2: string;
            city: string;
            dateOfBirth: string;
            firstName: string;
            lastName: string;
            ssn: string;
            state: string;
            zipCode: string;
        };
        hasReferrerParam: string;
        skipExperian: boolean;
    };
}

const AccountOpenPersonalDetailsFormWrapper = ({ $state, $stateParams }: Props) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: DEFAULT_STALE_TIME
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <TranslationsServiceProvider>
                <HashRouter>
                    <Routes>
                        <Route
                            path={ACCOUNT_OPEN_PERSONAL_DETAILS}
                            element={
                                <AccountOpenPersonalDetailsForm
                                    state={$state}
                                    stateParam={$stateParams}
                                />
                            }
                        ></Route>
                    </Routes>
                </HashRouter>
            </TranslationsServiceProvider>
        </QueryClientProvider>
    );
};

export default AccountOpenPersonalDetailsFormWrapper;
