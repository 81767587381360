import registrationSSNMessagesTemplate from "../registration/messages/registrationSSNMessagesTemplate";

import npdiPayStubInfoTemplate from "./npdiPayStubInfoTemplate";

const npdiRegistrationTemplate = `
<div class="col-sm-10 col-sm-offset-1 col-xs-12">
<form 
  name="npdiForm" 
  autocomplete="off" 
  novalidate role="form" 
  data-ng-init="retrieveDropdownValuesOnNpdiPageLoad()" 
  data-ng-submit="npdiValidatePPTData()"
>

  <header class="contained-form-header col-sm-10 col-sm-offset-1 col-xs-12">
    <h1>
      {{ "npdiForm.registrationTitle" | translate }}
    </h1>
    <p>{{ "npdiForm.enterNpdiInfo" | translate }}</p>
  </header>

  <div id="content-container" class="col-sm-10 col-sm-offset-1 col-xs-12 inner-container with-padding with-shadow">
    <h2 class="margin-top-0"> {{"npdiForm.personalInformationTitle"| translate}}</h2>

    <div class="form-group has-error" ng-if="autherizationMessage" id="screenReaderAuth" tabindex="0">
    <span class="help-block">{{autherizationMessage | translate:autherizationMessageParams}}</span>
  </div>

    <!-- firstName-->
    <div class="row">
      <div class="form-group col-xs-12 col-sm-5" data-ng-class="getFormGroupClass('firstName');">
        <label  for="firstNameInput" class="control-label">{{"npdiForm.personalInfo.firstName" | translate}}</label>
        <input  type="text"
                name="firstName"
                id="firstNameInput"
                class="form-control"
                data-name-pattern-validator
                maxlength="20"
                data-ng-model="personalInfo.firstName"
                ng-blur="removeInputFocus($event);"
                ng-focus="setInputFocus($event)"
                data-ng-required="true"
          >
        <ng-messages  for="getFieldError('firstName');"
                ng-if="displayFieldMessages('firstName',focusMgr['firstName']);"
                multiple
                data-ng-class="getValidationErrorClass('firstName');">
                <ng-message when="required">
                    {{"npdiForm.firstNameRequired" | translate }}
                </ng-message>
                <ng-message when="namePattern">
                    {{ "npdiForm.firstNameSpecialChar" | translate }}
                </ng-message>
                <ng-message when="firstNameLength">
                    {{ "npdiForm.firstNameInvalidLength" | translate }}
                </ng-message>
        </ng-messages>
      </div>



    <!-- middleName-->
      <div class="form-group col-xs-12 col-sm-2" data-ng-class="getFormGroupClass('middleName');">
        <label for="middleNameInput" class="control-label">{{"npdiForm.personalInfo.middleName" | translate}}</label>
        <input  type="text"
            name="middleName"
            id="middleNameInput"
            class="form-control"
            data-name-pattern-validator
            data-ng-model="personalInfo.middleName"
            data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }">
            <ng-messages  for="getFieldError('middleName');"
                          data-ng-if="displayFieldMessages('middleName');"
                          multiple
                          data-ng-class="getValidationErrorClass('middleName');">
              <ng-message when="namePattern">
                {{ "npdiForm.middleNameSpecialChar" | translate }}
              </ng-message>
              <ng-message when="firstNameLength">
                {{ "npdiForm.middleNameInvalidLength" | translate }}
              </ng-message>
            </ng-messages>
      </div>

    <!-- lastName-->
      <div class="form-group col-xs-12 col-sm-5" data-ng-class="getFormGroupClass('lastName');">
        <label for="lastNameInput" class="control-label">{{"npdiForm.personalInfo.lastName" | translate}}</label>
        <input  type="text"
            name="lastName"
            id="lastNameInput"
            class="form-control"
            data-name-pattern-validator
            maxlength="35"
            data-ng-model="personalInfo.lastName"
            data-ng-required="true"
            ng-blur="removeInputFocus($event);"
            ng-focus="setInputFocus($event)"
            data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }">
            <ng-messages  for="getFieldError('lastName');"
                          ng-if="displayFieldMessages('lastName',focusMgr['lastName']);"
                          multiple
                          data-ng-class="getValidationErrorClass('lastName');">
              <ng-message when="required">
                {{"npdiForm.lastNameRequired" | translate }}
              </ng-message>
              <ng-message when="namePattern">
                {{ "npdiForm.lastNameSpecialChar" | translate }}
              </ng-message>
              <ng-message when="lastNameLength">
                {{ "npdiForm.lastNameInvalidLength" | translate }}
              </ng-message>
            </ng-messages>
      </div>
    </div>

    <!-- dateOfBirth-->
    <div class="row">
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('dateOfBirth');">
        <label for="dateOfBirth" class="control-label">{{"npdiForm.personalInfo.dateOfBirth" | translate}}&nbsp;<span class="label-format" aria-hidden="true">{{"npdiForm.personalInfo.dateFormat" | translate}}</span></label>
        <pw-beneficiary-date
            date-id="dateOfBirth"
            date-name="dateOfBirth"
            date-model="personalInfo.dateOfBirth"
            is-required="true"
            min-date="getMinimumDateOfBirth()"
            ui-options="{clearOnBlur:false, allowInvalidValue: true}"
            date-blur="handleMaskDateOfBirth($event);removeInputFocus($event);dobChange()"
            date-focus="touchDateOfBirth($event);toggleDateOfBirthFromClick($event);setInputFocus($event)"
            date-title="Format two digit month, two digit day and four digit year"
            allow-future-date="false"
            mask-date="true"
            ng-keydown="toggleDOBfromKeyDown($event)">
        </pw-beneficiary-date>
        <ng-messages  
          for="getFieldError('dateOfBirth');"
          ng-if="displayFieldMessages('dateOfBirth',focusMgr['dateOfBirth']);"
          multiple
          data-ng-class="getValidationErrorClass('dateOfBirth');">
          <ng-message when="required">
            {{ 'npdiForm.dateOfBirthRequired' | translate }}
          </ng-message>
          <ng-message when="valid || pattern">
              {{ 'npdiForm.dateOfBirthInvalid' | translate }}
          </ng-message>
          <ng-message when="minYear">
              {{ 'npdiForm.dateOfBirthYearOutOfRange' | translate }}
          </ng-message>
          <ng-message when="futureDateNotAllowed">
              {{ 'npdiForm.dateOfBirthFutureDateNotAllowed' | translate }}
          </ng-message>
        </ng-messages>
      </div>





    <!-- gender-->
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('gender');">
        <label for="genderInput" class="control-label">{{"npdiForm.personalInfo.gender" | translate}}</label>
        <div class="custom-select form-control">
          <select name="gender"
              id="genderInput"
              class="form-control"
              data-ng-required="true"
              data-ng-model="selectedGenderValue"
                ng-blur="removeInputFocus($event);"
              ng-focus="setInputFocus($event)"
              ng-options="opt as opt for opt in genderListOptions | orderBy:opt">
          </select>
        </div>
        <ng-messages  for="getFieldError('gender');"
                  ng-if="displayFieldMessages('gender',focusMgr['gender']);"
                class="rule-validations">
                <ng-message when="required">
                    <span>{{"npdiForm.genderRequired"  | translate }}</span>
                </ng-message>
        </ng-messages>
      </div>
    </div>

    <!-- SSN-->


    <div class="row">



      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('ssn');">


      <label for="ssnInput" class="control-label">{{"npdiForm.personalInfo.ssn" | translate}}</label>
        <input type="text"
              class="form-control"
              id="ssnInput"
              name="ssn"
              inputmode="numeric"
              ng-model="personalInfo.ssn"
              ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
              ng-required="true"
              ng-blur="handleMaskSSN($event);removeInputFocus($event)"
              ng-focus="toggleSSNfromMaskedClick($event);setInputFocus($event)"
              title="Social Security must be numeric and must be nine digits"
              cleave="cleaveoptions.ssn"
              ng-keydown="toggleSSNfromMaskedKeyDown($event)"
              pw-social-security-number />

        ${registrationSSNMessagesTemplate}
        <span class="help-block">{{ssnError}}</span>

          </div>

    <!-- maritalStatus-->
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('maritalStatus');">
        <label for="maritalStatusInput" class="control-label">{{"npdiForm.personalInfo.maritalStatus" | translate}}</label>
        <div class="custom-select form-control">
          <select name="maritalStatus"
              id="maritalStatusInput"
              class="form-control"
              data-ng-required="true"
              ng-blur="removeInputFocus($event);"
              ng-focus="setInputFocus($event)"
              data-ng-model="selectedMaritalStatusValue"
              data-ng-options="opt as opt.description for opt in maritalStatusOptions | orderBy:opt.description">
          </select>
        </div>
        <ng-messages  for="getFieldError('maritalStatus');"
                ng-if="displayFieldMessages('maritalStatus',focusMgr['maritalStatus']);"
                class="rule-validations">
                <ng-message when="required">
                    <span>{{"npdiForm.maritalStatusRequired"  | translate }}</span>
                </ng-message>
        </ng-messages>
      </div>
    </div>

    <header>
        <h2> {{"npdiForm.employmentInformationTitle"| translate}}</h2>
    </header>

    <!-- currentAnnualIncome-->
    <div class="row">
      <div class="form-group col-xs-12 col-sm-6" ng-class="getFormGroupClass('currentAnnualIncome');">
      <label for="currentAnnualIncomeInput" class="control-label">{{"npdiForm.employmentInfo.currentAnnualIncome" | translate}}</label>
      <input  type="text"
          inputmode="numeric"
                name="currentAnnualIncome"
                id="currentAnnualIncomeInput"
                class="form-control"
                data-ng-model="employmentInfo.currentAnnualIncome"
                data-ng-required="true"
                ng-maxlength="13"
                data-ng-focus="setInputFocus($event)"
                data-ng-blur="removeInputFocus($event);"
                ui-money-mask
                ui-hide-space
                />
          <ng-messages  for="getFieldError('currentAnnualIncome');"
              data-ng-if="displayFieldMessages('currentAnnualIncome', touchedMgr['currentAnnualIncome']);"
              multiple
              class="rule-validations">
              <ng-message when="required">
                  <span>{{"npdiForm.currentAnnualIncomeRequired" | translate }}</span>
              </ng-message>
              <ng-message when="maxlength">
                <span>{{"You exceed the maximum amount" | translate }}</span>
            </ng-message>
          </ng-messages>
      </div>

    <!-- dateOfHire-->
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('dateOfHire');">
        <label for="dateOfHire" class="control-label">{{"npdiForm.employmentInfo.dateOfHire" | translate}}&nbsp;<span class="label-format" aria-hidden="true">{{"npdiForm.personalInfo.dateFormat" | translate}}</span></label>
        <pw-beneficiary-date
          date-id="dateOfHire"
          date-name="dateOfHire"
          date-model="employmentInfo.dateOfHire"
          is-required="true"
          ng-blur="removeInputFocus($event);"
          ng-focus="setInputFocus($event)"
          min-date="getMinimumDateOfBirth()"
          ui-options="{clearOnBlur:false, allowInvalidValue: true}"
          date-blur="removeInputFocus($event)"
          date-focus="setInputFocus($event)"
          date-title="Format two digit month, two digit day and four digit year"
          allow-future-date="false">
        </pw-beneficiary-date>
        <ng-messages  for="getFieldError('dateOfHire');"
                  ng-if="displayFieldMessages('dateOfHire',focusMgr['dateOfHire']);"
                  multiple
                  data-ng-class="getValidationErrorClass('dateOfHire');">
          <ng-message when="required">
              {{ 'npdiForm.dateOfHireRequired' | translate }}
          </ng-message>
          <ng-message when="valid || pattern">
              {{ 'npdiForm.dateOfHireInvalid' | translate }}
          </ng-message>
          <ng-message when="minYear">
              {{ 'npdiForm.dateOfHireYearOutOfRange' | translate }}
          </ng-message>
          <ng-message when="futureDateNotAllowed">
              {{ 'npdiForm.dateOfHireFutureDateNotAllowed' | translate }}
          </ng-message>
        </ng-messages>
      </div>
      </div>

    <!-- divisions -->
        <!--created with Typeahead from https://angular-ui.github.io/bootstrap/ 
        with an additional directive of typeahead-show-on-focus from https://embed.plnkr.co/ifaZMhsFaHrQZFOaIA0w/
    <pre>Model: {{thisScope.selectedDivCodesValue | json}}</pre>
    -->

        <script type="text/ng-template" id="customPopupTemplate.html">
          <div class="custom-popup-wrapper"
            ng-style="{top: position().top+'px', left: position().left+'px', width: position().width+'px'}"
            style="display: block;"
            ng-show="isOpen() && !moveInProgress"
            aria-hidden="{{!isOpen()}}">
           
           
            <!--p class="message">select division from drop down.</p-->

            <ul class="dropdown-menu" role="listbox" style="margin-top: 0px;">
              <li class="uib-typeahead-match" ng-repeat="match in matches track by $index" ng-class="{active: isActive($index) }"
                ng-mouseenter="selectActive($index)" ng-click="selectMatch($index)" role="option" id="{{::match.id}}">
                <div uib-typeahead-match index="$index" match="match" query="query" template-url="templateUrl"></div>
              </li>
            </ul>
          </div>
        </script>
        
        <div class="row" data-ng-if="showDivisionCode === true">

          <!-- converting it to drop down as type ahead is not working as expected in IE browser -->
              <!--<div class="form-group col-xs-12 col-sm-6"
                data-ng-class="getFormGroupClass('divisionCodes');">
                <label for="divisionInput"
                  class="control-label">{{"npdiForm.employmentInfo.divisionCode" | translate}}
                </label>

                <div class="custom-select form-control">

                  <select name="divisionCodes"
                    id="divisionInput"
                    class="form-control"
                    data-ng-required="true"
                    ng-blur="removeInputFocus($event);"
                    ng-focus="setInputFocus($event)"
                    ng-model="thisScope.selectedDivCodesValue"
                    data-ng-options="division as division.name for division in divisionCodesOptions">
                  </select>
                </div>

                <ng-messages for="getFieldError('divisionCodes');"
                  ng-if="displayFieldMessages('divisionCodes',focusMgr['divisionCodes']);"
                  class="rule-validations">

                  <ng-message when="required">
                    <span>{{"npdiForm.divisionCodeRequired" | translate }}</span>
                  </ng-message>

                  <ng-message when="editable">
                    <span>{{"npdiForm.divisionCodeNotFound" | translate }}</span>
                  </ng-message>
                </ng-messages>
              </div>-->
             <div class="form-group col-xs-12 col-sm-6" show-errors data-ng-class="getFormGroupClass('divisionCodes');">

                <label for="divisionInput" class="control-label">{{"npdiForm.employmentInfo.divisionCode" | translate}}</label>
                <span class="sr-only" id="division-input">Cancel {{"npdiForm.employmentInfo.divisionCode" | translate}}</span>
                <input type="text" 
                      ng-model="thisScope.selectedDivCodesValue" 
                      placeholder="" 
                      uib-typeahead="division as division.name for division in divisionCodesOptions | filter:{name:$viewValue}"
                      typeahead-popup-template-url="customPopupTemplate.html" 
                      class="form-control bootstrap-selectize-input"
                      typeahead-min-length="0"
                      typeahead-select-on-blur="true"
                      name="divisionCodes"
                      id="divisionInput"
                      data-ng-required="true"
                      ng-focus="setInputFocus($event);"                     
                      ng-blur="removeInputFocus($event);"
                      typeahead-editable="false"
                      typeahead-show-on-focus
                      typeahead-select-on-exact="true"/>
                      
                      <input type="button" ng-show ="selectedDivCodesValue" ng-click="clearValues()" value="x" aria-labelledby="division-input" class="bootstrap-selectize-input-clear-button bootstrap-selectize-input-clear-button-bar-format"/>
                
                <ng-messages for="getFieldError('divisionCodes');"
                            ng-if="displayFieldMessages('divisionCodes',focusMgr['divisionCodes']);"
                            class="rule-validations">
                    <ng-message when="required">
                        <span>{{"npdiForm.divisionCodeRequired" | translate }}</span>
                    </ng-message>
                    <ng-message when="editable">
                        <span>{{"npdiForm.divisionCodeNotFound" | translate }}</span>
                    </ng-message>
                </ng-messages>
            </div> 
        </div>
        
    <header>
        <h2> {{"npdiForm.mailingAddressInformationTitle"| translate}}</h2>
    </header>

    <!-- Address line 1-->
    <div class="row">
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('addressLine1');">
        <label for="addressLine1Input" class="control-label">{{"npdiForm.mailingAddress.addressLine1" | translate}}</label>
        <input  type="text"
            name="addressLine1"
            id="addressLine1Input"
            class="form-control"
            data-address-pattern-validator
            data-ng-maxlength="35"
            ng-blur="removeInputFocus($event);"
              ng-focus="setInputFocus($event)"
            data-ng-model="mailingAddress.addressLine1"
            data-ng-required="true"
            data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }">
            <ng-messages  for="getFieldError('addressLine1');"
                    ng-if="displayFieldMessages('addressLine1',focusMgr['addressLine1']);"
                    multiple
                    data-ng-class="getValidationErrorClass('addressLine1');">
              <ng-message when="required">
                  {{"npdiForm.addressLine1Required" | translate }}
              </ng-message>
              <ng-message when="addressPattern">
                  {{"npdiForm.addressLine1Invalid"  | translate }}
              </ng-message>
              <ng-message when="maxlength">
                  {{"npdiForm.addressLine1InvalidLength"  | translate }}
              </ng-message>
            </ng-messages>
      </div>

    <!-- Address line 2-->
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('addressLine2');">
        <label for="addressLine2Input" class="control-label">{{"npdiForm.mailingAddress.addressLine2" | translate}}</label>
        <input  type="text"
            name="addressLine2"
            id="addressLine2Input"
            class="form-control"
            data-ng-pattern="/^[a-zA-Z0-9-/#&; ]+$/"
            data-ng-maxlength="35"
            ng-blur="removeInputFocus($event);"
              ng-focus="setInputFocus($event)"
            data-ng-model="mailingAddress.addressLine2"
            data-cannot-match="mailingAddress.addressLine1"
            data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }">
            <ng-messages  for="getFieldError('addressLine2');"
                    ng-if="displayFieldMessages('addressLine2',focusMgr['addressLine2']);"
                    multiple
                    data-ng-class="getValidationErrorClass('addressLine2');">
                    <ng-message when="cannotMatch">
                        {{ "npdiForm.addressLine2CannotMatchAddressLine1" | translate }}
                    </ng-message>
                    <ng-message when="pattern">
                        {{"npdiForm.addressLine2Invalid"  | translate }}
                    </ng-message>
                    <ng-message when="maxlength">
                        {{"npdiForm.addressLine2InvalidLength"  | translate }}
                    </ng-message>
            </ng-messages>
      </div>
    </div>

    <!-- City-->
    <div class="row">
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('city');">
        <label  for="cityInput"
            class="control-label"
            data-ng-hide="!(selectedCountryValue.code ==''||
                          selectedCountryValue.code =='U.S.A.'||
                          selectedCountryValue.code =='US'||
                selectedCountryValue.code =='CA')">
            {{"npdiForm.mailingAddress.city" | translate}}
        </label>
        <label for="cityInput"
            class="control-label"
            data-ng-hide="(selectedCountryValue.code ==''||
                          selectedCountryValue.code =='U.S.A.'||
                          selectedCountryValue.code =='US'||
                selectedCountryValue.code =='CA')">
            {{ "npdiForm.mailingAddress.combinedCityState" | translate }}
        </label>
        <input  type="text"
            name="city"
            id="cityInput"
            class="form-control"
            ng-pattern="/^[a-zA-Z' ]+$/"
            data-ng-maxlength="20"
            data-ng-required="true"
            ng-blur="removeInputFocus($event);"
              ng-focus="setInputFocus($event)"
            data-ng-model="mailingAddress.city"
            data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }">
        <ng-messages  for="getFieldError('city');"
                      ng-if="displayFieldMessages('city',focusMgr['city']);"
                      multiple
                      data-ng-class="getValidationErrorClass('city');">
              <ng-message when="required">
                  {{"npdiForm.cityRequired" | translate }}
              </ng-message>
              <ng-message when="pattern">
                  {{"npdiForm.cityInvalidPattern"  | translate }}
              </ng-message>
              <ng-message when="maxlength">
                  {{"npdiForm.cityInvalidLength"  | translate }}
              </ng-message>
        </ng-messages>
      </div>

      
    <!-- State-->
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('state')"
          data-ng-hide="!(selectedCountryValue.code ==''||
                  selectedCountryValue.code =='U.S.A.'||
                  selectedCountryValue.code =='US'||
                  selectedCountryValue.code =='CA')">
        <label for="stateInput" class="control-label">{{ "npdiForm.mailingAddress.state" | translate }}</label>
        <div class="custom-select form-control">
          <select name="state"
              id="stateInput"
              ng-blur="removeInputFocus($event);"
              ng-focus="setInputFocus($event)"
              class="form-control"
              ng-required="(selectedCountryValue.code =='U.S.A.'||
              selectedCountryValue.code =='US'||  selectedCountryValue.code =='CA')"
              ng-model="selectedStateValue"
              ng-options="opt as opt.description for opt in stateOptions">
          </select>
        </div>
        <ng-messages  for="getFieldError('state');"
                ng-if="displayFieldMessages('state',focusMgr['state']);"
                class="rule-validations">
                <ng-message when="required">
                  <span>{{"npdiForm.stateRequired"  | translate }}</span>
                </ng-message>
        </ng-messages>
      </div>
    </div>

    <!-- Zipcode for US-->
    <div class="row">
        <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('zipcode');"
          data-ng-if="selectedCountryValue.code =='U.S.A.' ||
                selectedCountryValue.code =='US'">
            <label for="zipcodeInput" class="control-label">{{ "npdiForm.mailingAddress.zipcode" | translate }}</label>
            <input  type="text"
                    inputmode="numeric"
                    name="zipcode"
            id="zipcodeInput"
            class="form-control"
            data-zipcode-pattern-validator
            maxlength="5"
            ng-blur="removeInputFocus($event);"
              ng-focus="setInputFocus($event)"
            data-ng-required="true"
            data-ng-model="mailingAddress.zipcode"
            data-ng-change="zipcodeUpdated()"
            ui-mask="99999"
            ui-options="{clearOnBlur:false, allowInvalidValue: true}"
            ui-mask-placeholder-char="space"
            data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }"/>
            <ng-messages  for="getFieldError('zipcode');"
                            ng-if="displayFieldMessages('zipcode',focusMgr['zipcode']);"
                            aria-live="polite">
                <ng-message when="required">
                    <span>{{"npdiForm.zipcodeRequired"  | translate }}</span>
                </ng-message>
                <ng-message when="zipcodePattern">
                    <span>{{ "npdiForm.zipcodeInvalidPattern" | translate }}</span>
                </ng-message>
            </ng-messages>
        </div>

    <!-- Zipcode for Others-->
      <div 
        class="form-group col-xs-12 col-sm-6" 
        data-ng-class="getFormGroupClass('zipcode');"
        data-ng-if="!(selectedCountryValue.code =='U.S.A.' || selectedCountryValue.code =='US')"
      >
        <label for="zipcodeInput" class="control-label">{{"npdiForm.mailingAddress.zipcode" | translate}}</label>
        <input
          type="text"
          inputmode="numeric"
          name="zipcode"
          id="zipcodeInput"
          class="form-control"
          data-zipcode-alpha-numeric-validator
          reg-ex-input
          reg-ex="^[-a-zA-Z0-9 ]+$"
          maxlength="10"
          ng-blur="removeInputFocus($event);"
          ng-focus="setInputFocus($event)"
          data-ng-required="true"
          data-ng-model="mailingAddress.zipcode"
          data-ng-change="zipcodeUpdated()"
          data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
        >
        <ng-messages 
          for="getFieldError('zipcode');"
          ng-if="displayFieldMessages('zipcode',focusMgr['zipcode']);"
          aria-live="polite"
        >
          <ng-message when="required">
            <span>{{"npdiForm.zipcodeRequired" | translate }}</span>
          </ng-message>
          <ng-message when="zipcodeAlphaNumeric">
            <span>{{"npdiForm.zipcodeInvalidSpecialChar"  | translate }}</span>
          </ng-message>
        </ng-messages>
      </div>

    <!-- Country-->
      <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('country')">
        {{showLawEnforcement}}
        <label for="countryInput" class="control-label">{{ "npdiForm.mailingAddress.country" | translate }}</label>
        <div class="custom-select form-control">
          <select name="country"
              id="countryInput"
              class="form-control"
              data-ng-required="true"
              ng-blur="removeInputFocus($event);"
              ng-focus="setInputFocus($event)"
              data-ng-change="clearZipcode()"
              data-ng-model="selectedCountryValue"
              data-ng-options="opt as opt.description for opt in countryOptions"
              >
          </select>
        </div>
        <ng-messages  for="getFieldError('country');"
                data-ng-if="displayFieldError('country');"
                class="rule-validations">
                <ng-message when="required">
                    <span>{{"npdiForm.countryRequired"  | translate }}</span>
                </ng-message>
        </ng-messages>
      </div>
    </div>
    <!-- law officer-->
    <div data-ng-if="showLawOfficer">
      <header>
        <h2 id="law-officer-header"> {{"npdiForm.employmentInfo.lawOfficer"| translate}}</h2>
      </header>
        
      <div class="row">
        <div class="form-group col-xs-12 col-sm-6" data-ng-class="getFormGroupClass('lawOfficer')">
          <div aria-labelledby="law-officer-header" class="input-row" role="radiogroup">
            <label className="radio-inline panel-title collapsed">
              <input name="lawOfficer"
                type="radio"
                data-ng-required="true"
                ng-blur="removeInputFocus($event);"
                ng-focus="setInputFocus($event)"
                ng-click="changeLawOfficerSelection($event)"
                role="radio"
                value="Y"
                data-ng-model="isLawOfficer"/>
                <span style="vertical-align: text-bottom">
                  {{"npdiForm.labels.yes"  | translate }}
                </span>
            </label>
            <label className="radio-inline panel-title collapsed" style="margin-left: 10px">
              <input name="lawOfficer"
                type="radio"
                data-ng-required="true"
                ng-blur="removeInputFocus($event);"
                ng-focus="setInputFocus($event)"
                ng-click="changeLawOfficerSelection($event)"
                role="radio"
                value="N"
                data-ng-model="isLawOfficer"/>
                <span style="vertical-align: text-bottom">
                  {{"npdiForm.labels.no"  | translate }}
                </span>
            </label>
          </div>
          <ng-messages  for="getFieldError('lawOfficer');"
            data-ng-if="displayFieldMessages('lawOfficer', focusMgr['lawOfficer']);"
            aria-live="polite">
            <ng-message when="required">
                <span>{{"npdiForm.lawOfficerRequired"  | translate }}</span>
            </ng-message>
          </ng-messages>
      
        </div>
      </div>
    </div>

    <!-- State of CT hide state agency -->
    <div data-ng-if="showCTPEC">
      <header>
        <h3> {{"npdiForm.employmentInfo.CTPec"| translate}}</h3>
      </header>
      ${npdiPayStubInfoTemplate}
    </div>

    <!-- State of CT show state agency -->
    <div ng-if="showStateAgency">
      <h2 id="state-agency-heading" tabindex="0">
        {{"npdiForm.employmentInfo.employerQuestion" | translate}} 
        <span tabindex="0" id="state-agency-tooltip" tooltip-placement="top" tooltip-trigger="mouseenter focus" uib-tooltip="{{ 'npdiForm.employmentInfo.tooltip' | translate }}" class="info-link"></span>
      </h2>
      <div role="radiogroup" class="panel-group" data-ng-class="getFormGroupClass('stateAgency');">
        <div class="panel panel-default margin-bottom-100">
          <header ng-class="{'inactive': stateAgency.value !== 'Y'}" class="panel-heading form-group">
            <label class="radio-inline panel-title" for="stateAgencyYesInput">
              <input
                aria-describedby="state-agency-heading"
                data-ng-required="true"
                name="stateAgency"
                id="stateAgencyYesInput"
                ng-focus="setInputFocus($event)"
                ng-blur="removeInputFocus($event);"
                ng-model="stateAgency.value"
                value="Y"
                type="radio"
                tabindex="0"
              />
              <span style="vertical-align: text-bottom">{{"npdiForm.labels.yes" | translate}}</span>
            </label>
          </header>
        </div>
        <div class="padding-100" ng-if="stateAgency.value === 'Y'">
          <header>
            <h3> {{"npdiForm.employmentInfo.CTPec"| translate}}</h3>
          </header>
          ${npdiPayStubInfoTemplate}
        </div>
        <div class="panel panel-default margin-bottom-100">
          <header ng-class="{'inactive': stateAgency.value !== 'N'}" class="panel-heading form-group">
            <label class="radio-inline panel-title" for="stateAgencyNoInput">
              <input
                aria-describedby="state-agency-heading"
                data-ng-required="true"
                name="stateAgencyNo"
                id="stateAgencyNoInput"
                ng-focus="setInputFocus($event)"
                ng-blur="removeInputFocus($event);"
                ng-model="stateAgency.value"
                value="N"
                type="radio"
                tabindex="0"
              />
              <span style="vertical-align: text-bottom">{{"npdiForm.labels.no" | translate}}</span>
            </label>
          </header>
        </div>
        <div class="padding-100" ng-if="stateAgency.value === 'N'">
          <header>
            <h3>{{"npdiForm.employmentInfo.select" | translate}}</h3>
          </header>
          ${npdiPayStubInfoTemplate}
        </div>
          <ng-messages for="getFieldError('stateAgency');"
            data-ng-if="displayFieldMessages('stateAgency', focusMgr['stateAgency']);"
            aria-live="polite">
            <ng-message when="required">
                <span>{{"npdiForm.stateAgencyRequired" | translate }}</span>
            </ng-message>
          </ng-messages>
      </div>
    </div>
    <pwr-reg></pwr-reg>
  </div>
</div>

</form>
`;
export default npdiRegistrationTemplate;
