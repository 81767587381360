import communicationPreferencesTemplate from "./communicationPreferencesTemplate";

const eConsentRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/eConsent/changeCommPref");

    $stateProvider

        // Delivery options =================================
        .state("changeCommPref", {
            url: "/eConsent/change-Comm-Pref",
            template: communicationPreferencesTemplate,
            controller: "EConsentController",
            data: {
                fullHeight: true,
                title: "Change Communication Preferences"
            }
        });
};

eConsentRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default eConsentRoute;
