import React, { useState, useEffect, useRef, useCallback } from "react";

import { useQuery } from "@tanstack/react-query";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import EventBusEvents from "../../events/EventBusEvents";
import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";
import { getTranslationsService } from "core-ui/client/react/core/services/translationService";
import StringUtil from "core-ui/client/src/app/StringUtil";
import PropTypes from "prop-types";
import { sentryLoginClickMetrics } from "../../../utils/sentryMetricsLogging";

import ReferenceData from "../../constants/ReferenceData";

const TRANSLATIONS_QUERY_KEY = ReferenceData.QUERY_KEYS.TRANSLATIONS;

const DEFAULT_TEXT = "Sign In";
const PROCESSING_TEXT = "Signing In";
const ALL_STYLE_CLASSES = "pw-loader btn btn-primary text-center ";

import eventBus from "../../../utils/setEventBus";

/**
 * LoginForm
 * Shared component used by drupal iframe login, react login, angular to react login, and oauth login
 * Oauth login - hides login help link, register button, and open account button
 * @param {*} param0
 * @returns
 */
const LoginForm = ({
    accu,
    authentication,
    authenticateInput,
    loggingIn,
    errorCode,
    errorMessageParams,
    showLoginUpd,
    registerButtonClickInput,
    openAccountClickInput,
    loginDomain,
    from = ""
}) => {
    const { data: translations, isSuccess: translationsLoaded } = useQuery({
        queryKey: [TRANSLATIONS_QUERY_KEY],
        queryFn: getTranslationsService,
        staleTime: ReferenceData.DEFAULT_STALE_TIME
    });
    const inputUserNmEl = useRef(null);
    const inputPasswordEl = useRef(null);
    const [passwordType, setPasswordType] = useState("password");
    const [showEye, setShowEye] = useState(false);
    const clsShowEye = showEye ? "fa-eye" : "fa-eye-slash";
    const [showLogin, setShowLogin] = useState(showLoginUpd);
    if (showLoginUpd !== showLogin) {
        setShowLogin(showLoginUpd);
    }
    const clsShowLogin = !showLogin ? "hidden" : "";

    const [errorMessage, setErrorMessage] = useState("");
    const [submitInProgress, setSubmitInProgress] = useState(false);

    let disabledLoading = loggingIn || false;
    const [buttonLoadingDisabled, setButtonLoadingDisabled] = useState(false);
    const isOauthLogin = from === "oauth";
    const showRegister = !isOauthLogin;
    const showLoginHelp = !isOauthLogin;
    const showOpenAccount = !isOauthLogin;

    /**
     * authentication
     *    userId
     *    flowName
     *    errorMessage
     *    errorMessageParams
     *        code
     * @returns
     */

    const isDisabledLoading = () => {
        disabledLoading = disabledLoading !== undefined ? disabledLoading : buttonLoadingDisabled;
        return disabledLoading;
    };

    const isDisabled = () => {
        return isDisabledLoading();
    };

    const clsDisabledLoading = isDisabledLoading() ? "disabled-loading" : "";

    const resetFormInput = () => {
        if (inputUserNmEl.current) {
            inputUserNmEl.current.value = "";
        }
        if (inputPasswordEl.current) {
            inputPasswordEl.current.value = "";
        }
    };

    const handlePopEvent = useCallback(() => {
        return () => window.removeEventListener("popstate", handlePopEvent);
    }, []);

    useEffect(() => {
        setShowLogin(showLoginUpd);
        window.addEventListener("popstate", handlePopEvent);
    }, [handlePopEvent, showLoginUpd]);

    useEffect(() => {
        const errorTag =
            errorCode > ""
                ? errorCode
                : authentication.errorMessage > ""
                  ? authentication.errorMessage
                  : "";
        const errorParams = errorMessageParams;

        if (
            errorTag &&
            errorTag > "" &&
            submitInProgress &&
            authentication.processing === "failed"
        ) {
            const isAccountLocked = String(errorTag).indexOf("locked") > -1;
            const isOauthFlow = String(window.location.href).indexOf("/oauth") > -1;

            let errorText =
                !isAccountLocked && isOauthFlow
                    ? translations?.logon["error.oauth.passcode.incorrect"]
                    : translations?.logon[errorTag];

            if (isAccountLocked) {
                errorText = StringUtil.supplant(errorText, {
                    EndLockTime: errorParams["EndLockTime"]
                });
            }

            if (String(errorTag).indexOf("passcode.incorrect") > -1) {
                inputPasswordEl.current.value = "";

                // redirects to login help page, clear only pw input
                // update error message in cases when user clicks on back button
                if (errorParams["attempts"] === "1") {
                    errorText = StringUtil.supplant(errorText, {
                        attempts: errorParams["attempts"]
                    });

                    setShowLogin(false);
                }
                // stays on login page
                if (errorParams["attempts"] === "2") {
                    errorText = StringUtil.supplant(errorText, {
                        attempts: errorParams["attempts"]
                    });
                }
            }
            errorText = String(errorText).replace("{", "").replace("}", "");
            setErrorMessage(errorText);
            const eventBusPayload = {
                // eslint-disable-next-line camelcase
                error_code: errorTag
            };
            eventBus.dispatch(EventBusEvents.ERROR_CODE, "", eventBusPayload);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                event_properties: {
                    selection: EventBusEvents.ERROR_CODE,
                    payload: eventBusPayload
                }
            });

            setSubmitInProgress(false);
        } else if (authentication.processing === "loginsuccess") {
            setSubmitInProgress(false);
        }
    }, [
        errorCode,
        errorMessageParams,
        authentication.errorMessage,
        submitInProgress,
        authentication.processing,
        translations?.logon
    ]);

    const showHidePwd = (e) => {
        setShowEye(!showEye);
        const passwordType = showEye ? "password" : "text";
        setPasswordType(passwordType);
        eventBus.dispatch(EventBusEvents.SHOW_HIDE_PWD, e.target, { showPwd: !showEye });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: EventBusEvents.SHOW_HIDE_PWD,
                payload: {
                    showPwd: !showEye
                }
            }
        });
    };

    const updateUserId = (e) => {
        inputUserNmEl.current.value = e.target.value;
    };

    const updatePassword = (e) => {
        inputPasswordEl.current.value = e.target.value;
    };

    const redirectToLoginHelp = (e) => {
        e.preventDefault();
        if (from !== "iframe") {
            eventBus.dispatch(EventBusEvents.FORGOT_USERNAME_PASSWORD, e.target);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: EventBusEvents.FORGOT_USERNAME_PASSWORD
                }
            });
        }
        resetFormInput();
        setSubmitInProgress(false);
        setErrorMessage("");
        setShowLogin(false);
        window.top.location.href =
            from === "iframe"
                ? loginDomain + "/participant/#/loginHelp?accu=" + accu + "&iframe=true"
                : loginDomain + "/participant/#/loginHelp?accu=" + accu;
    };

    const submitFunction = (e) => {
        e.preventDefault();

        sentryLoginClickMetrics();

        const formData = new FormData(e.target);
        const username = formData.get("usernameInput")?.trimEnd();
        const password = formData.get("passwordInput");

        setSubmitInProgress(true);
        setButtonLoadingDisabled(true);
        setErrorMessage("");
        authenticateInput({ username, password });
        localStorage.setItem(PerformanceTrackingKeys.PT_SIGNIN_CLICKED, new Date().getTime());
        localStorage.setItem(PerformanceTrackingKeys.PT_FIRST_LOAD, true);
        localStorage.setItem(PerformanceTrackingKeys.PT_SIDEBAR_FIRST_LOAD, true);

        eventBus.dispatch(EventBusEvents.SUBMIT);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: EventBusEvents.SUBMIT
            }
        });
    };

    const registerButtonClick = (e) => {
        e.preventDefault();
        resetFormInput();
        setSubmitInProgress(false);
        setErrorMessage("");
        registerButtonClickInput();

        if (from !== "iframe") {
            eventBus.dispatch(EventBusEvents.REGISTER);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: "CoreEvent.register_button_clicked_event"
                }
            });
        }

        window.top.location.href =
            from === "iframe"
                ? loginDomain + "/participant/#/register/?accu=" + accu + "&iframe=true"
                : loginDomain + "/participant/#/register/?accu=" + accu;
    };

    const openAccountClick = (event) => {
        event.preventDefault();
        resetFormInput();
        setSubmitInProgress(false);
        setErrorMessage("");
        eventBus.dispatch(EventBusEvents.OPEN_NEW_ACCOUNT);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: AMPLITUDE_EVENTS.OPEN_NEW_ACCOUNT
            }
        });
        openAccountClickInput(event);
    };

    const loginDiv = isOauthLogin
        ? `col-xs-12 col-sm-6 col-sm-push-4 login-form `
        : `col-xs-12 col-sm-3 col-sm-push-9 login-form ${clsShowLogin}`;
    const appendLoginStyle = isOauthLogin ? `top: containerTop` : ``;

    return translationsLoaded ? (
        <div className={loginDiv} style={{ appendLoginStyle }} data-testid="login-form">
            <div className="prelogin-pod login-pod">
                {errorMessage > "" ? (
                    <div className="form-group has-error" aria-live="assertive">
                        <span id="helpBlock" className="help-block" role="alert">
                            {errorMessage}
                        </span>
                    </div>
                ) : null}
                <h1 className="flush-top"> {translations?.logon?.logonTitle} </h1>
                <form id="authenticate" onSubmit={submitFunction}>
                    <div className="form-group">
                        <label htmlFor="usernameInput" className="sr-only">
                            {" "}
                            {translations?.logon?.username}{" "}
                        </label>
                        <div className="input-group">
                            <div className="input-group-addon">
                                <span
                                    className="em-user"
                                    role="img"
                                    aria-label={translations?.logon?.labels?.userLabel}
                                ></span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                id="usernameInput"
                                name="usernameInput"
                                placeholder={translations?.logon?.username}
                                onChange={updateUserId}
                                ref={inputUserNmEl}
                                autoComplete="off"
                                aria-label={translations?.logon?.labels?.userLabel}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="passwordInput" className="sr-only">
                            {translations?.logon?.password}
                        </label>
                        <div className="input-group">
                            <div className="input-group-addon">
                                <span
                                    className="em-lock"
                                    role="img"
                                    aria-label={translations?.logon?.labels?.passLabel}
                                ></span>
                            </div>
                            <input
                                type={passwordType}
                                className="form-control"
                                id="passwordInput"
                                name="passwordInput"
                                placeholder={translations?.logon?.password}
                                onChange={updatePassword}
                                ref={inputPasswordEl}
                                autoComplete="off"
                                minLength={8}
                                maxLength={64}
                                aria-label={translations?.logon?.labels?.passLabel}
                            />{" "}
                            {/* data-ng-model="credentials.password" */}
                            <div className="input-group-addon">
                                <span
                                    className={`fa-regular  ${clsShowEye}`}
                                    onClick={showHidePwd}
                                    onKeyUp={showHidePwd}
                                    role="button"
                                    tabIndex="0"
                                    aria-label={
                                        showEye
                                            ? translations?.logon?.labels?.showPassLabel
                                            : translations?.logon?.labels?.hidePassLabel
                                    }
                                ></span>
                                <div role="alert" className="sr-only">
                                    {showEye
                                        ? translations?.logon?.labels?.passwordHiddenAlert
                                        : translations?.logon?.labels?.passwordShownAlert}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-right margin-bottom-default">
                        {showLoginHelp && (
                            <button
                                type="button"
                                className="btn-link no-underline no-padding"
                                title="Need help logging in?"
                                onClick={redirectToLoginHelp}
                            >
                                {translations?.logon?.loginHelp}
                            </button>
                        )}
                    </div>
                    <div className="form-group clearfix">
                        <button
                            type="submit"
                            id="submit"
                            className={` btn-lg btn-block ${ALL_STYLE_CLASSES} ${clsDisabledLoading}`}
                            disabled={isDisabled()}
                            data-fired={PROCESSING_TEXT}
                        >
                            <span>{DEFAULT_TEXT}</span>
                        </button>
                    </div>
                </form>
                <form id="portalRedirectForm" method="post">
                    <input type="hidden" id="synctoken" value="" />
                    <input type="hidden" id="accu" value="" />
                    <input type="hidden" id="gaId" value="" />
                </form>

                {showRegister && (
                    <button
                        className="btn btn-success btn-lg btn-block text-center"
                        onClick={registerButtonClick}
                    >
                        {translations?.logon?.register}
                    </button>
                )}

                {String(accu).toLowerCase() === "myerira" && showOpenAccount ? (
                    <div className="text-right center-block margin-top-default margin-bottom-default">
                        <button
                            onClick={openAccountClick}
                            className="btn-link no-underline no-padding"
                            title={translations?.logon?.openAccount + "?"}
                        >
                            {translations?.logon?.openAccount}
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    ) : null;
};

LoginForm.propTypes = {
    accu: PropTypes.string,
    authentication: PropTypes.object,
    authenticateInput: PropTypes.func,
    loggingIn: PropTypes.bool,
    errorCode: PropTypes.string,
    errorMessageParams: PropTypes.object,
    showLoginUpd: PropTypes.bool,
    registerButtonClickInput: PropTypes.func,
    openAccountClickInput: PropTypes.func,
    loginDomain: PropTypes.string,
    from: PropTypes.string
};

export default LoginForm;
