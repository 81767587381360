import changeHomeAddressTemplate from "./changeHomeAddressTemplate";
import changePersonalContactTemplate from "./changePersonalContactTemplate";
import forceChangePasswordTemplate from "./forceChangePasswordTemplate";
import profileContactInfoTemplate from "./profileContactInfoTemplate";

const profileRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/profile");

    $stateProvider
        .state("profile", {
            url: "/profile",
            template: profileContactInfoTemplate,
            controller: "ProfileContactInfoController",
            controllerAs: "ctrl",
            data: {
                title: "Profile"
            }
        })
        .state("communicationModal", {
            url: "/profile/communication-modal",
            template: profileContactInfoTemplate,
            controller: "ProfileContactInfoController",
            controllerAs: "ctrl",
            data: {
                title: "Profile"
            }
        })
        .state("changePersonalContact", {
            url: "/change-personal-contact",
            template: changePersonalContactTemplate,
            controller: "ProfileContactInfoController",
            data: {
                title: "Change personal contact information"
            }
        })
        .state("changeHomeAddress", {
            url: "/change-home-address",
            template: changeHomeAddressTemplate,
            controller: "HomeAddressController"
        })
        .state("toForceChangePassword", {
            url: "/force-change-password",
            template: forceChangePasswordTemplate,
            controller: "profileChangePasswordController"
        });
};

profileRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default profileRoute;
