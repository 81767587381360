import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AccountRecoveryConfirmation from "../AccountRecoveryConfirmation";

const queryClient = new QueryClient();

const AccountRecoveryConfirmationContainer = () => {
    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <AccountRecoveryConfirmation />
            </QueryClientProvider>
        </React.StrictMode>
    );
};

AccountRecoveryConfirmationContainer.propTypes = {};
export default AccountRecoveryConfirmationContainer;
