import axios from "axios";

export function sendOneTimePasswordCode(formData, successCallback, errorCallback) {
    axios({
        method: "post",
        url:
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + "/participant-web-services/rest/partialauth/idProof/otpSend",
        data: formData,
        headers: {
            "Content-Type": "application/json"
        },
        params: {},
        withCredentials: true
    })
        .then((result) =>
            result.data && result.data.status === "successful"
                ? successCallback(result.data)
                : errorCallback(result.data, null)
        )
        .catch((error) => errorCallback(null, error));
}

export function verifyOneTimePasswordCode(formData, successCallback, errorCallback) {
    axios({
        method: "post",
        url:
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + "/participant-web-services/rest/partialauth/idProof/otpVerify",
        data: formData,
        headers: {
            "Content-Type": "application/json"
        },
        params: {},
        withCredentials: true
    })
        .then((result) =>
            result.data && result.data.status === "successful"
                ? successCallback(result.data)
                : errorCallback(result.data, null)
        )
        .catch((error) => errorCallback(null, error));
}
