const AuthenticationService = function ($resource) {
    return {
        authenticate: $resource(
            __authenticationUrl__ + "rest/nonauth/authenticate/",
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        ),
        authenticateLite: $resource(
            __authenticationUrl__ + "rest/nonauth/authenticateLite/",
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        ),
        authenticatePart: function (setPcBaseApiUrl, fp) {
            return $resource(
                setPcBaseApiUrl + "/api/auth/multiauth/noauth/authenticate",
                {},
                {
                    query: {
                        method: "POST",
                        isArray: false,
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    },
                    post: {
                        method: "POST",
                        isArray: false,
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                }
            ).post(fp);
        },
        isAuthenticated: $resource(
            __baseApiUrl__ + "/participant-web-services/rest/nonauth/isAuthenticated",
            {},
            {
                query: {
                    method: "POST",
                    isArray: false
                }
            }
        )
    };
};

AuthenticationService.$inject = ["$resource", "$window"];
export default AuthenticationService;
