"use strict";

const AccountOpenEvents = {
    OPEN_NEW_ACCOUNT: "preLogin.openNewAccount.click",
    CLICK_PREMIER_IRA: "openAccount.premierIRA.click",
    CLICK_PREMIER_INVESTMENT: "openAccount.premierInvestment.click",
    PRE_ENROLL_FORM_1_NEXT: "preEnrollmentForm.next.click",
    PRE_ENROLL_FORM_2_NEXT: "preEnrollmentForm.next.click"
};

export default AccountOpenEvents;
