const npdiErrorTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
    <header class="contained-form-header margin-top-default">
    <h1>
      {{"npdi.error.header.panel" | translate}}
    </h1>
  </header>
  <div class="inner-container with-padding with-shadow">
   <p>{{"Ssn_exists_status_update" | translate}}</p>
  </div>
</div>
`;
export default npdiErrorTemplate;
