const LinkablePlansFactory = function ($resource) {
    const retrievePlansAvailableForLinkingForProfile =
        "/participant-web-services/rest/partialauth/oneId/retrieveLinkablePlans?flowName=profile";
    const retrievePlansAvailableForLinking =
        "/participant-web-services/rest/partialauth/oneId/retrieveLinkablePlans";
    const skipAndNavigate = "/participant-web-services/rest/partialauth/oneId/skipAndNavigate";
    const linkAccounts = "/participant-web-services/rest/partialauth/oneId/linkAccounts";
    const isMfaFinishedPrior =
        "/participant-web-services/rest/partialauth/oneId/isMfaFinishedPrior/:indId/:dbName";
    const getContextEligibilityForHiding =
        "/participant-web-services/rest/partialauth/oneId/getContextEligibilityForHiding?indId=:indId&dbName=:dbName&accuCode=:accuCode";
    const updatePinAuthCode =
        "/participant-web-services/rest/partialauth/oneId/updatePinAuthCode/:dbName/:indId?accuCode=:accuCode";

    return {
        retrievePlansAvailableForLinking: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrievePlansAvailableForLinking,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        retrievePlansAvailableForLinkingForProfile: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrievePlansAvailableForLinkingForProfile,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        linkAccounts: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + linkAccounts,
            {
                key: "@key"
            },
            {
                query: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        skipAndNavigate: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + skipAndNavigate,
            {
                key: "@key"
            },
            {
                query: {
                    method: "GET"
                }
            }
        ),

        isMfaFinishedPrior: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + isMfaFinishedPrior,
            {
                indId: "@indId",
                dbName: "@dbName"
            },
            {
                query: {
                    method: "GET"
                }
            }
        ),

        getContextEligibilityForHiding: $resource(
            __baseApiUrl__ + getContextEligibilityForHiding,
            {
                indId: "@indId",
                dbName: "@dbName",
                accuCode: "@accuCode"
            },
            {
                query: {
                    method: "GET"
                }
            }
        ),

        updatePinAuthCode: $resource(
            __baseApiUrl__ + updatePinAuthCode,
            {
                indId: "@indId",
                dbName: "@dbName",
                accuCode: "@accuCode"
            },
            {
                query: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

LinkablePlansFactory.$inject = ["$resource"];
export default LinkablePlansFactory;
